import * as React from 'react'
import { Link } from 'react-router-dom'
import { Localized } from '@fluent/react'
import Notification, { Data } from '../../../../api/notification'
import NotificationComp from '../../../../components/Notification'

const UnreadNotificationsList = () => {
  const [items, setItems] = React.useState<Notification<Data>[]>([])

  React.useEffect(() => {
    let mounted = true

    Notification.pagination().loadPage(1)
      .then(res => {
        if (mounted) setItems(res.items)
      })
      .catch(() => {
        if (mounted) setItems([])
      })

    return () => {
      mounted = false
      setItems([])
    }
  }, [])

  if (items.length === 0) return null

  return <div className="nav__hoverbox">
    {
      items.map((noti: Notification<Data>, index: number) => {
        if (index > 2) return null
        return <NotificationComp key={noti.data.id} notification={noti}/>
      })
    }
    <Link to="/notifications" className="nav__link show-more">
      <Localized id="navigation-notifications-show-all">
        Show all
      </Localized>
    </Link>
  </div>
}

export default UnreadNotificationsList
