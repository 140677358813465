import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Ticket } from '../../../../api'
import {
  addConversationTab,
  joinConversation,
  openConversation,
  refreshConversation,
} from '../../../../store/actions/conversations'
import { addAlert } from '../../../../store/actions/alerts'
import { confirmDialog } from '../../../../helpers'
import TicketOpenDate from '../TicketOpenDate'
import Chat from '../../../../containers/Chat'
import Spinner from '../../../../components/Spinner'
import LimitedUI from '../../../../components/LimitedUI'
import Button from '../../../../components/ui/Button'
import Icon from '../../../../components/ui/Icon'

import './index.css'

interface TicketManagerProps {
  ticket: Ticket
}

const TicketManager = ({ ticket }: TicketManagerProps) => {
  const [ticketState, setTicketState] = React.useState({ ...ticket })
  const isClosed = Boolean(ticketState.closed)
  const sockets = useSelector(state => state.conversations.sockets)
  const user = useSelector(state => state.user.user)
  const dispatch = useDispatch()
  const conversation = React.useMemo(() => sockets.get(ticketState.conversation.id), [ticketState, sockets])

  const openAsConversationTab = () => {
    dispatch(addConversationTab({
      convId: ticketState.conversation.id,
      title: ticketState.title,
    }))
  }

  const updateTicketStatus = (ticket: Ticket, status: boolean) => {
    const l10nSuccess = status ? 'tickets-reopen-success' : 'tickets-close-success'

    ;(status ? ticket.reopen() : ticket.close())
      .then(() => {
        setTicketState({ ...ticket })
        dispatch(addAlert('success', l10nSuccess))
      })
  }

  const joinTicket = async (e: React.MouseEvent) => {
    if (!user) return

    e.stopPropagation()

    const res = await confirmDialog({
      title: 'tickets-join-ticket-confirm',
      vars: { ticket: ticketState.title },
      size: 'medium',
      buttons: {
        cancel: 'tickets-join-cancel',
        join: 'tickets-join-ticket',
      },
    })

    if (res !== 'join') return

    await ticket.join()
      .then(() => {
        ticket.conversation.members.push(user.id)
        ticket.conversation.write = true
        setTicketState({ ...ticket })
        dispatch(joinConversation(conversation!.id, user.id))
        dispatch(refreshConversation(conversation!.id))
      })
  }

  React.useEffect(() => {
    setTicketState({ ...ticket })
  }, [ticket])

  React.useEffect(() => {
    if (ticket.conversation.read && (!conversation || conversation.id !== ticket.conversation.id)) {
      dispatch(openConversation(ticket.conversation.id))
    }
  }, [ticket, conversation, dispatch])

  if (!user) return null

  return (
    <div className="tickets__manager">
      <div className="tickets__controls">
        <div className="tickets__constrols--group">
          <Button to="/helpdesk">
            <Icon size="small" name="arrow-left" />
          </Button>
          <span className="tickets__title">{ticketState.title}</span>
        </div>
        <div className="tickets__constrols--group">
          <LimitedUI onlySupport={true}>
            {
              isClosed
                ?
                <Button l10nId="tickets-reopen" clickHandler={() => updateTicketStatus(ticket, true)}>
                  Reopen ticket
                </Button>
                :
                <Button l10nId="tickets-close" type="danger" clickHandler={() => updateTicketStatus(ticket, false)}>
                  Close ticket
                </Button>
            }
            {
              !ticketState.conversation.write && <Button l10nId="tickets-join-ticket" clickHandler={joinTicket}>
                Join ticket
              </Button>
            }
          </LimitedUI>
          <Button id="ticket-open-conversation-in-tab" clickHandler={openAsConversationTab}>
            <Icon size="small" name="www" />
          </Button>
          <TicketOpenDate date={ticketState.opened} />
        </div>
      </div>
      {
        conversation
          ? <Chat conversation={conversation} disableInput={isClosed} />
          : <Spinner />
      }
    </div>
  )
}

export default TicketManager
