import { ProcessingInstruction } from 'cnx-designer'
import { Editor, Range } from 'slate'

export function onKeyDown(editor: Editor, event: KeyboardEvent) {
  const { selection } = editor
  if (!selection || Range.isExpanded(selection)) return false

  const [start, end] = Editor.edges(editor, selection)
  const before = Editor.before(editor, start.path)
  const after = Editor.after(editor, end.path)
  const startBlock = before && Editor.parent(editor, before.path)
  const endBlock = after && Editor.parent(editor, after.path)
  const node = Editor.above(editor, { match: ProcessingInstruction.isProcessingInstruction })

  if (
    event.key === 'Backspace'
    && (
      (startBlock && start.offset === 0 && ProcessingInstruction.isProcessingInstruction(startBlock[0]))
      || (node && start.offset === 0 && ProcessingInstruction.isProcessingInstruction(node[0]))
    )
  ) {
    event.preventDefault()
    return true
  } else if (
    event.key === 'Delete'
    && (
      (endBlock && Editor.isEnd(editor, end, end.path) && ProcessingInstruction.isProcessingInstruction(endBlock[0]))
      || (node && Editor.isEnd(editor, end, end.path) && ProcessingInstruction.isProcessingInstruction(node[0]))
    )
  ) {
    event.preventDefault()
    return true
  }

  return false
}
