import * as React from 'react'
import * as CNXML from 'slate-cnxml'
import { Code, Media } from 'cnx-designer'
import { Editor, Node, NodeEntry, Path, Element as SlateElement, Text, Transforms } from 'slate'
import { RenderElementProps } from 'slate-react'

export interface SourceElement extends Code, SlateElement {
  language: 'cnxml-fragment'
}

export const SourceElement = {
  isSourceElement(value: unknown): value is SourceElement {
    return Code.isCode(value) && value.language === 'cnxml-fragment'
  },
}

const VALID_CHILDRENS = [
  Text.isText,
  Media.isMedia,
]

export const normalizeSourceElement = (editor: Editor, entry: NodeEntry): boolean => {
  const [element, path] = entry
  if (SourceElement.isSourceElement(element)) {
    for (const [i, child] of element.children.entries()) {
      if (VALID_CHILDRENS.some(v => v(child))) continue
      Transforms.unwrapNodes(editor, { at: [...path, i] })
      return true
    }
  }
  return false
}

/**
 * We do not support fully those elements yet so for now they are
 * transformed into normal text so magicians can edit them in
 * source mode.
 */

const SOURCE_TAGS: string[] = [
  'cite', // https://legacy.cnx.org/eip-help/cite
  'media',
]

/**
 * Deserialize SourceElement element. Return true if it was dserialized and false otherwise.
 */
export const deserializeSourceElement = (
  editor: CNXML.DeserializingEditor,
  el: Element,
  at: Path,
): boolean => {
  if (SOURCE_TAGS.includes(el.localName)) {
    if (Editor.parent(editor, at)[0].type === 'figure') return false

    Transforms.insertNodes(
      editor,
      {
        type: 'code',
        placement: 'block',
        language: 'cnxml-fragment',
        children: [{ text: el.outerHTML }],
      },
      { at },
    )
    return true
  }
  return false
}

/**
 * Serialize SourceElement element to xml element.
 */
export const serializeSourceElement: CNXML.PartialSerializer<any, any> = (node, _attrs, _children) => {
  if (SourceElement.isSourceElement(node)) {
    const parser = new DOMParser()
    const source = Node.string(node).trim()
    // Source inlines are created with text: " " (there is no way to start writing in
    // an empty inline) and if user will forgot to remove this empty space then we should
    // still handle this instead of throwing an error.
    if (!source) return null

    // Add CNXML_NAMESPACE as default namespace for elements.
    const xmlDoc = parser.parseFromString(
      `<content xmlns="${CNXML.CNXML_NAMESPACE}">${source}</content>`,
      'application/xml',
    )

    const error = xmlDoc.getElementsByTagName('parsererror')
    if (error.length) {
      throw new Error(`${error[0].textContent}, Content: ${source}`)
    }

    const elements = xmlDoc.getElementsByTagName('content')[0].childNodes

    return Array.from(elements)
  }
  return null
}

interface SourceElementProps extends RenderElementProps {
  element: SourceElement
}

const SourceElementComp = (props: SourceElementProps) => {
  if (props.element.placement === 'line') {
    return <span className="source source--inline" {...props.attributes}>
      <span contentEditable={false} />
      {props.children}
    </span>
  }

  return <div className="source source--block" {...props.attributes}>
    {props.children}
  </div>
}

export default SourceElementComp
