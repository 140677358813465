import * as React from 'react'
import { Code } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import Input from '../../../../../../../components/ui/Input'
import { SourceElement } from '../../../../../../../screens/app/Draft/plugins/SourceElements'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

interface CodeToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const CodeTools = ({ editor, ...props }: CodeToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Code.isCode })

  if (!node || SourceElement.isSourceElement(node[0])) return null

  const [code, path] = node

  const onChange = (val: string | null) => {
    Transforms.setNodes(editor, { language: val }, { at: path })
  }

  return (
    <ToolGroup
      title="editor-tools-code-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('codeTools')}
    >
      <Input
        type="text"
        l10nId="editor-tools-code-lang"
        value={code.language || ''}
        onChange={onChange}
      />
    </ToolGroup>
  )
}

export default CodeTools
