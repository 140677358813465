import * as React from 'react'
import { History } from 'history'
import Header from '../../../components/Header'
import Section from '../../../components/Section'
import HelpdeskTools from './components/HelpdeskTools'
import Tickets from '../../../containers/Tickets'

import './index.css'

interface HelpdeskProps {
  match: { params: { id?: string } }
  history: History
}

const Helpdesk = ({ match, history }: HelpdeskProps) => (
  <div className="container container--splitted helpdesk">
    <Section>
      <Header l10nId="helpdesk-view-title" title="Helpdesk" />
      <div className="section__content">
        <HelpdeskTools />
      </div>
    </Section>
    <Section>
      <Header l10nId="helpdesk-view-tickets-title" title="Tickets manager" />
      <Tickets match={match} history={history} />
    </Section>
  </div>
)

export default Helpdesk
