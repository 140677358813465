import * as React from 'react'
import { Link, ProcessSingleStep } from '../../../../../../../api/process'
import Dialog from '../../../../../../../components/ui/Dialog'
import Button from '../../../../../../../components/ui/Button'

interface DetailsDialogProps {
  show: boolean
  step: ProcessSingleStep
  onClose: () => void
  onStepChange: (link: Link) => void
}

const DetailsDialog = (props: DetailsDialogProps) => {
  if (!props.show) return null

  return (
    <Dialog
      size="medium"
      l10nId="step-changer-details-dialog-title"
      placeholder="Choose next step"
      onClose={props.onClose}
      showCloseButton={false}
    >
      <div className="dialog__buttons">
        <Button l10nId="step-changer-cancel" clickHandler={props.onClose}>
          Cancel
        </Button>
        <div className="step-changer__dialog-content">
          {
            props.step.links.map(l => (
              <Button
                key={l.to}
                id="step-changer-details-select-step"
                clickHandler={() => props.onStepChange(l)}
              >
                {l.name}
              </Button>
            ))
          }
        </div>
      </div>
    </Dialog>
  )
}

export default DetailsDialog
