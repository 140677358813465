import User from '../../api/user'
import * as constants from '../../store/constants'
import { addAlert, AddAlert } from './alerts'

export interface FetchUser {
  (dispatch: any): void
}

export interface FetchUserBegin {
  type: constants.FETCH_USER_BEGIN,
}

export interface FetchUserSuccess {
  type: constants.FETCH_USER_SUCCESS,
  data: User,
}

export interface FetchUserFailure {
  type: constants.FETCH_USER_FAILURE,
  error: string,
}

export interface ClearUserData {
  type: constants.CLEAR_USER_DATA,
}

export interface UpdateUserInState {
  type: constants.UPDATE_USER
  userId: number
  data: { name?: string, is_support?: boolean }
}

export type UserDataAction =
  FetchUserBegin |
  FetchUserSuccess |
  FetchUserFailure |
  ClearUserData |
  UpdateUserInState

export const fetchUserBegin = (): FetchUserBegin => ({
  type: constants.FETCH_USER_BEGIN,
})

export const fetchUserSuccess = (payload: User): FetchUserSuccess => ({
  type: constants.FETCH_USER_SUCCESS,
  data: payload,
})

export const fetchUserFailure = (error: string): FetchUserFailure => ({
  type: constants.FETCH_USER_FAILURE,
  error,
})

export const fetchUser = () => (dispatch: React.Dispatch<UserDataAction>) => {
  dispatch(fetchUserBegin())

  return User.me()
    .then(user => {
      if (user === null) {
        window.location.href = `/account/login?next=${window.location.pathname}`
        return Promise.resolve()
      }
      return user.isInSuperMode()
        .then(() => {
          dispatch(fetchUserSuccess(user))
        })
    })
    .catch(e => {
      dispatch(fetchUserFailure(e.message))
      dispatch(clearUserData())
    })
}

export const clearUserData = (): ClearUserData => ({
  type: constants.CLEAR_USER_DATA,
})

export const updateUserInState = (
  userId: number,
  data: { name?: string, is_support?: boolean },
): UpdateUserInState => ({
  type: constants.UPDATE_USER,
  userId,
  data,
})

export const updateUserName = (
  user: User,
  name: string,
  onSuccess: (user: User) => void,
) => (dispatch: React.Dispatch<UpdateUserInState | AddAlert>) => user.changeName(name)
  .then(usr => {
    if (usr) {
      dispatch(updateUserInState(user.id, { name }))
      dispatch(addAlert('success', 'user-profile-update-name-success'))
      onSuccess(usr)
    }
  })
  .catch(() => {
    dispatch(addAlert('error', 'user-profile-update-name-error'))
  })

export const updateUserIsSupport = (
  user: User,
  is_support: boolean,
) => (dispatch: React.Dispatch<UpdateUserInState | AddAlert>) => user.setIsSupportFlag(is_support)
  .then(() => {
    dispatch(updateUserInState(user.id, { is_support }))
  })
  .catch(() => {
    dispatch(addAlert('error', 'user-profile-support-flag-error'))
  })
