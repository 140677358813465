import Task, { ErrorData, State as TaskState } from '../../api/task'
import { TasksAction } from '../../store/actions/tasks'
import {
  ADD_TASK,
  ADD_TASK_ERROR,
  REMOVE_TASK,
  SET_ACTIVE_TASK,
  SET_TASK_PROGRESS,
  SET_TASK_STATE,
} from '../../store/constants'

export interface State {
  tasks: Map<string, TaskData>
  sockets: Map<string, Task>
  activeTask: string | null
}

export interface TaskData {
  state: TaskState
  progress: number | null
  message: string
  errors: ErrorData[]
}

export const initialState: State = {
  tasks: new Map(),
  sockets: new Map(),
  activeTask: null,
}

// eslint-disable-next-line default-param-last
export function reducer(state: State = initialState, action: TasksAction): State {
  switch (action.type) {
  case ADD_TASK:
    return {
      ...state,
      tasks: new Map(state.tasks).set(
        action.data,
        { state: 'created', progress: null, message: '', errors: [] },
      ),
      sockets: new Map(state.sockets).set(action.data, action.socket),
      activeTask: state.activeTask ?? action.data,
    }

  case REMOVE_TASK: {
    const tasks = new Map(state.tasks)
    tasks.delete(action.data)

    const sockets = new Map(state.sockets)
    sockets.delete(action.data)

    return {
      ...state,
      tasks,
      sockets,
    }
  }

  case SET_ACTIVE_TASK:
    return {
      ...state,
      activeTask: action.data,
    }

  case SET_TASK_STATE: {
    const data = state.tasks.get(action.data)!

    return {
      ...state,
      tasks: new Map(state.tasks).set(
        action.data,
        {
          ...data,
          state: action.state,
        },
      ),
    }
  }

  case SET_TASK_PROGRESS: {
    const data = state.tasks.get(action.data)!

    return {
      ...state,
      tasks: new Map(state.tasks).set(
        action.data,
        {
          ...data,
          progress: action.progress,
          message: action.message,
        },
      ),
    }
  }

  case ADD_TASK_ERROR: {
    const data = state.tasks.get(action.data)!

    return {
      ...state,
      tasks: new Map(state.tasks).set(
        action.data,
        {
          ...data,
          errors: [...data.errors, action.error],
        },
      ),
    }
  }

  default:
    return state
  }
}
