import * as React from 'react'
import store from '../../../store'
import { updateLabel } from '../../../store/actions/modules'
import { ModuleLabel as ModuleLabelType } from '../../../store/types'
import { useIsLabelExisting } from '../../../hooks'
import ColorGenerator from '../../../components/ColorGenerator'
import ModuleLabel from '../../../components/ModuleLabel'
import Input from '../../../components/ui/Input'
import Button from '../../../components/ui/Button'

import './index.css'

interface ModuleLabelManagerProps {
  label: ModuleLabelType
  onCancel?: () => void
  onUpdate?: (label: ModuleLabelType) => void
}

const ModuleLabelManager = ({ label, onCancel, onUpdate }: ModuleLabelManagerProps) => {
  const [labelPreview, setLabelPreview] = React.useState(label)

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const updateLabelPreview = (data: { name?: string, color?: string }) => {
    setLabelPreview({
      ...labelPreview,
      ...data,
    })
  }

  const isLabelExisting = useIsLabelExisting(labelPreview.name)

  const onChange = (name: string) => {
    updateLabelPreview({ name })
  }

  const onColorChange = (color: string) => {
    updateLabelPreview({ color })
  }

  const handleUpdateLabel = () => {
    if (isLabelExisting && labelPreview.name !== label.name) return

    store.dispatch(
      updateLabel(labelPreview.id, {
        name: labelPreview.name,
        color: labelPreview.color,
      }),
    )
    if (onUpdate) {
      onUpdate(labelPreview)
    }
  }

  return (
    <div className="module-label-manager">
      <ModuleLabel label={labelPreview} />
      <div className="module-label-manager__controls">
        <Input
          type="text"
          value={labelPreview.name}
          l10nId="module-labels-editor-label-name-placeholder"
          onChange={onChange}
          validator={input => input === label.name || !isLabelExisting}
          errorMessage="module-labels-editor-label-name-taken"
          required
        />
        <ColorGenerator startColor={labelPreview.color} onChange={onColorChange} />
        <div className="module-label-manager__buttons">
          <Button l10nId="module-labels-editor-cancel" clickHandler={handleCancel}>
            Cancel
          </Button>
          <Button
            l10nId="module-labels-editor-update-label"
            clickHandler={handleUpdateLabel}
            isDisabled={
              !labelPreview.name
              || !labelPreview.color
              || (isLabelExisting && labelPreview.name !== label.name)
            }
          >
            Update label
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ModuleLabelManager
