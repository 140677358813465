import * as React from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { withLocalization, WithLocalizationProps } from '@fluent/react'
import Team, { TeamPermission } from '../../api/team'
import { useLoadAllTeams } from '../../api/hooks'
import { useIsInSuperMode } from '../../hooks'

interface SelectOption { value: Team, label: string }

interface TeamSelectorProps extends WithLocalizationProps {
  // Filter user teams against specific permission.
  permission?: TeamPermission | TeamPermission[]
  isDisabled?: boolean
  team?: Team | number
  onChange: (team: Team) => void
}

const TeamSelector = (props: TeamSelectorProps) => {
  const [teams] = useLoadAllTeams()
  const user = useSelector(state => state.user.user)
  const [selectedTeam, setSelectedTeam] = React.useState<Team | null>(null)
  const isInSuperMode = useIsInSuperMode(user)

  const handleChange = ({ value }: SelectOption) => {
    setSelectedTeam(value)
    props.onChange(value)
  }

  const { permission } = props
  const selectOptions = React.useMemo((): { value: Team, label: string }[] => {
    // If teams.lenght === 0 probably means that team list is still fetching.
    if (teams.length === 0) return []

    if (isInSuperMode) {
      return teams
        .filter(t => t.status !== 'archived')
        .map(t => ({ value: t, label: t.name }))
    }

    const filterdUserTeams = user
      ? user.teams.filter(t => {
        const team = teams.find(team => team.id === t.id)
        return team && team.status !== 'archived'
      })
      : []

    if (Array.isArray(permission)) {
      return filterdUserTeams
        .filter(t => permission.every(p => t.allPermissions.has(p)))
        .map(ut => {
          const team = teams.find(team => team.id === ut.id)!
          return { value: team, label: team.name }
        })
    }

    if (permission) {
      return filterdUserTeams
        .filter(t => t.allPermissions.has(permission))
        .map(ut => {
          const team = teams.find(team => team.id === ut.id)!
          return { value: team, label: team.name }
        })
    }

    return filterdUserTeams.map(ut => {
      const team = teams.find(team => team.id === ut.id)!
      return { value: team, label: team.name }
    })
  }, [isInSuperMode, permission, teams, user])

  React.useEffect(() => {
    if (!props.team) {
      setSelectedTeam(null)
    } else if (props.team instanceof Team) {
      setSelectedTeam(props.team)
    } else {
      Team.load(props.team)
        .then(team => setSelectedTeam(team))
        .catch(() => setSelectedTeam(null))
    }
  }, [props.team])

  return (
    <Select
      className="react-select team-selector"
      isDisabled={props.isDisabled}
      placeholder={props.getString('team-selector-placeholder')}
      value={selectedTeam ? { value: selectedTeam, label: selectedTeam.name } : null}
      options={selectOptions}
      onChange={handleChange as any}
    />
  )
}

export default withLocalization(TeamSelector)
