import * as React from 'react'
import { useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import { Book } from '../../../../../api'
import BookCard from '../../../../../components/BookCard'
import AddBookDialog from '../AddBookDialog'
import './index.css'

interface BooksManagerProps {
  showAddBook: boolean
  onCloseAddBook: () => void
  isEditingUnlocked: boolean
  books: Book[]
  onAdd: (book: Book) => void
  onDelete: (book: Book) => void
}

const BooksManager = ({
  showAddBook,
  onCloseAddBook,
  isEditingUnlocked,
  books,
  onAdd,
  onDelete,
}: BooksManagerProps) => {
  const selectedTeams = useSelector(state => state.app.selectedTeams)

  return <>
    <div className="books__book-cards">
      {
        books.length ?
          books.map(book => selectedTeams.includes(book.team) && (
            <BookCard
              key={book.id}
              book={book}
              isEditingUnlocked={isEditingUnlocked}
              afterDelete={onDelete}
            />
          ))
          :
          <Localized id="book-list-empty">
            No books found.
          </Localized>
      }
    </div>
    <AddBookDialog
      show={showAddBook}
      onClose={onCloseAddBook}
      onSuccess={onAdd}
    />
  </>
}

export default BooksManager
