import * as React from 'react'
import * as Counters from 'react-counters'
import { Caption } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { FIGURE } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface CaptionProps extends RenderElementProps {
  element: Caption
}

const CaptionComp = ({ children, attributes }: CaptionProps) => {
  const { document } = React.useContext(LOCALIZATION)
  const counter = Counters.useCounterValue(attributes.ref, FIGURE)
  const message = document.getString('figure-label', { figure: counter })

  return <figcaption {...attributes} data-label={message}>
    {children}
  </figcaption>
}

export default CaptionComp
