import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import * as api from '../../api'
import { useLoadProcess } from '../../api/hooks'
import * as types from '../../store/types'
import { addAlert } from '../../store/actions/alerts'
import confirmDialog from '../../helpers/confirmDialog'
import { assertDefined } from '../../helpers/asserttions'
import LimitedUI from '../../components/LimitedUI'
import ModuleLabels from '../../components/ModuleLabels'
import HighlightText from '../../components/HighlightText'
import Button from '../../components/ui/Button'
import Dialog from '../../components/ui/Dialog'
import BeginProcess from '../../containers/BeginProcess'
import ChangeProcess from '../../containers/ChangeProcess'
import ProcessPreview from '../../containers/ProcessPreview'
import UpdateSlots from '../../containers/UpdateSlots'
import Icon from '../ui/Icon'

import './index.css'

export interface ModuleProps {
  item: api.BookPart
  isEditingUnlocked: boolean
  isChangingIdUnlocked: boolean
  highlightText: string
  onModuleClick: (item: api.BookPart) => void
  onModuleLabelClick: (label: types.ModuleLabel) => void
  afterAction: () => void
}

const Module = ({
  item,
  isEditingUnlocked,
  isChangingIdUnlocked,
  highlightText,
  onModuleClick,
  onModuleLabelClick,
  afterAction,
}: ModuleProps,
) => {
  const itemId = assertDefined(item.id, 'item in BookPartModule has to have an id')
  const selectedTeamsIds = useSelector(state => state.app.selectedTeams)
  const [showBeginProcess, setShowBeginProcess] = React.useState(false)
  const [showProcessDetails, setShowProcessDetails] = React.useState(false)
  const dispatch = useDispatch()
  const [process] = useLoadProcess(item.process?.process)

  const showRemoveModuleDialog = async () => {
    const res = await confirmDialog({
      title: 'book-remove-module-title',
      buttons: {
        cancel: 'book-remove-module-cancel',
        confirm: 'book-remove-module-confirm',
      },
      showCloseButton: false,
    })

    if (res === 'confirm') {
      removeModule()
    }
  }

  const removeModule = () => {
    item.delete()
      .then(() => {
        afterAction()
        dispatch(
          addAlert(
            'success',
            'book-remove-module-alert-success',
            { title: item.title },
          ),
        )
      })
  }

  const showCancelProcess = async () => {
    const res = await confirmDialog({
      title: 'book-process-cancel-title',
      buttons: {
        cancel: 'book-process-cancel-button-cancel',
        cancelProcess: 'book-process-cancel-button',
      },
      showCloseButton: false,
    })

    if (res === 'cancelProcess') {
      cancelProcess()
    }
  }

  const cancelProcess = async () => {
    try {
      const draft = await api.Draft.load(itemId)
      await draft.cancelProcess()

      afterAction()
      dispatch(addAlert('success', 'book-process-cancel-success'))
      setShowProcessDetails(false)
    } catch (e) {
      dispatch(addAlert('success', 'book-process-cancel-error', { details: e.toString() }))
    }
  }

  const setIdForRepo = async (id: string) => {
    await (module as any).setAlternativeIdForRepository(repoName, id)
  }

  const [showChangeProcess, setShowChangeProcess] = React.useState(false)
  const [alternativeId, setAlternativeId] = React.useState<string>('')
  const [alternativeIDBackup, setAlternativeIDBackup] = React.useState<string>('')
  const [noRepo, setNoRepo] = React.useState(true)
  const [repoName, setRepoName] = React.useState('')
  const [module, setModule] = React.useState()
  const [altIdPlaceholder, setAltIdPlaceholder] = React.useState('')
  const openChangeProcess = React.useCallback(() => setShowChangeProcess(true), [])
  const closeChangeProcess = React.useCallback(() => setShowChangeProcess(false), [])


  React.useEffect(() => {
    const loadAlternativeId = async (id?: string) => {
      const moduleLoaded = await api.Module.load(itemId)
      const team = await api.Team.load(selectedTeamsIds[moduleLoaded.team - 1])
      if (!team.repository) {
        const noChosenRepo = (
          <Localized id="book-alternative-id-no-repo">
            No repo choosen
          </Localized>
        )
        return noChosenRepo.props.children
      }
      setModule(moduleLoaded as any)
      setRepoName(team.repository)
      setNoRepo(false)
      const placeholderID = (
        <Localized id="book-alternative-id-placeholder">
          set new ID
        </Localized>
      )
      setAltIdPlaceholder(placeholderID.props.children)
      if (`${team.repository}` in moduleLoaded.alternative_ids) {
        return moduleLoaded.alternative_ids[`${team.repository}`]
      }
      return ""
    }
    loadAlternativeId()
      .then(data => {
        setAlternativeId(data)
        setAlternativeIDBackup(data)
      })
  }, [itemId, selectedTeamsIds])

  const processChanged = React.useCallback(() => {
    setShowChangeProcess(false)
    setShowProcessDetails(false)
    afterAction()
  }, [afterAction])

  return (
    <>
      <span
        className="bookpart__title"
        data-testid="bookpart-title"
        onClick={() => onModuleClick(item)}
      >
        <HighlightText text={item.title} highlight={highlightText} />
      </span>
      <span className="bookpart__info">
        {isChangingIdUnlocked ?
          <>
            {(!noRepo && alternativeIDBackup !== alternativeId) &&
            <div className="bookpart_id-controls">
              <span
                onClick={ev => {
                  setIdForRepo(alternativeId)
                  setAlternativeIDBackup(alternativeId)
                }}
              >
                <Icon size="medium" name="check" />
              </span>
              <span
                onClick={() => setAlternativeId(alternativeIDBackup)}
              >
                <Icon size="medium" name="close" />
              </span>
            </div>}
            <span className="bookpart_id_input">
              <input
                type="text"
                placeholder={altIdPlaceholder}
                value={alternativeId}
                onChange={e => setAlternativeId(e.target.value)}
                disabled={noRepo}
              />
            </span>
          </>
          :null}
        {
          isEditingUnlocked && !isChangingIdUnlocked ?
            <LimitedUI permissions="module:edit">
              <Button
                id="book-button-remove"
                type="danger"
                clickHandler={showRemoveModuleDialog}
                data-testid="remove"
              >
                <Localized id="book-button-remove">Remove</Localized>
              </Button>
            </LimitedUI>
            : null
        }
        {!isChangingIdUnlocked && <>
          <ModuleLabels module={itemId} onModuleLabelClick={onModuleLabelClick} />
          {
            item.process && process ?
              <Button
                id="book-in-process"
                className="bookpart__process"
                clickHandler={() => setShowProcessDetails(true)}
                data-testid="process-details"
              >
                <Localized
                  id="book-in-process"
                  vars={{
                    process: process.name,
                    step: item.process.step.name,
                  }}
                >
                  {`{ $step } in { $process }`}
                </Localized>
              </Button>
              :
              <Button
                id="book-begin-process"
                clickHandler={() => setShowBeginProcess(true)}
                data-testid="begin-process"
              >
                <Localized id="book-begin-process">Begin process</Localized>
              </Button>
          }
        </>}
      </span>
      {
        showBeginProcess ?
          <Dialog
            l10nId="book-begin-process-title"
            placeholder="Configure and begin process"
            size="medium"
            className="bookpart__item--module begin-process-dialog"
            onClose={() => setShowBeginProcess(false)}
          >
            <BeginProcess
              modules={[itemId]}
              onClose={() => setShowBeginProcess(false)}
              afterUpdate={() => afterAction()}
            />
          </Dialog>
          : null
      }
      {
        showProcessDetails && process ?
          <Dialog
            l10nId="book-process-preview-title"
            placeholder="Process details:"
            size="big"
            onClose={() => setShowProcessDetails(false)}
          >
            <UpdateSlots
              process={process}
              modules={[itemId]}
              team={process.team}
            />
            <ProcessPreview process={process} />
            <LimitedUI permissions="editing-process:manage">
              <div className="dialog__buttons">
                <Button l10nId="book-process-change-button" clickHandler={openChangeProcess}>
                  Change process
                </Button>
                <Button l10nId="book-process-cancel-button" clickHandler={showCancelProcess}>
                  Cancel process
                </Button>
              </div>
            </LimitedUI>
          </Dialog>
          : null
      }
      {
        showChangeProcess ?
          <Dialog
            l10nId="book-process-change-title"
            placeholder="Change process:"
            size="medium"
            onClose={closeChangeProcess}
          >
            <ChangeProcess
              draft={itemId}
              onChange={processChanged}
            />
          </Dialog>
          : null
      }
    </>
  )
}

export default Module
