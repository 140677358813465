import * as React from 'react'
import { ReactLocalization } from '@fluent/react'

export const SCROLL_OFFSET_FOR_ELEMENTS = -50

export const SCROLL_ATTEMPTS = 5

export const SCROLL_ATTEMPTS_DELAY = 100

export const LOCALIZATION = React.createContext({
  ui: new ReactLocalization([], null),
  document: new ReactLocalization([], null),
})

export const INVALID_REFERENCE_CLASS = 'reference-invalid'
