import * as React from 'react'
import { ErrorData } from '../../../../api/task'

export interface ValidationError extends ErrorData {
  error: "import:validation",
  data: Record<string, string>,
}

export type AllErrors = ValidationError | ErrorData

export type ErrorProps = {
  error: AllErrors,
}

export default function Error(props: ErrorProps) {
  const { error } = props

  let data: React.ReactNode | null
  switch (error.error) {
  case "import:validation":
    data = (
      <dl className="message-list">
        {Object.entries((error as ValidationError).data).map(([path, message]) => <>
          <dt>{path}</dt>
          <dd>{message}</dd>
        </>)}
      </dl>
    )
    break

  default:
    data = null
    break
  }

  return (
    <div className="task__error">
      <span>{error.message}</span>
      {data}
    </div>
  )
}
