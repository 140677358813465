import Notification, {
  Data,
  isConversationNewMessage,
  isProcessCanceled,
  isProcessChangeAdvanced,
  isProcessChangeChangedProcess,
  isProcessFinished,
  isSlotAssignmentFilled,
  isSlotAssignmentVacated,
  isSupportNewTicket,
} from '../api/notification'

const getLinkToNotification = ({ data }: Notification<Data>): string | undefined => {
  if (isConversationNewMessage(data)) {
    return `/helpdesk/${data.conversation}#${data.message.id}`
  } else if (isProcessCanceled(data) || isProcessFinished(data)) {
    return `/modules/${data.module.id}`
  } else if (
    isProcessChangeAdvanced(data)
    || isProcessChangeChangedProcess(data)
    || isSlotAssignmentFilled(data)
    || isSlotAssignmentVacated(data)
  ) {
    return `/drafts/${data.draft.id}`
  } else if (isSupportNewTicket(data)) {
    return `/helpdesk/${data.ticket.id}`
  }
  return undefined
}

export default getLinkToNotification
