import * as React from 'react'
import { Notification } from '../../../../api'

const UnreadNotificationsCounter = () => {
  const [counter, setCounter] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(false)

  React.useEffect(() => {
    let mounted = true

    const pagination = Notification.pagination()
    pagination.loadPage(1)
      .then(() => {
        if (mounted) {
          setCounter(pagination.items().length)
          const last = pagination.last()
          setHasMore(Boolean(last && last[1] && last[1].has_next_page))
        }
      })

    return () => {
      mounted = false
      setCounter(0)
      setHasMore(false)
    }
  }, [])

  if (counter) return null

  return <span className="notifications__counter">
    <span>{counter}{hasMore ? '+' : ''}</span>
  </span>
}

export default UnreadNotificationsCounter
