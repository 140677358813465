import * as React from 'react'
import Notification, { Data } from '../../../../../api/notification'
import NotificationComp from '../../../../../components/Notification'
import Button from '../../../../../components/ui/Button'
import Icon from '../../../../../components/ui/Icon'

interface NotificationListItemProps {
  notification: Notification<Data>
  afterDelete: (notification: Notification<Data>) => void
}

const NotificationListItem = ({ notification, afterDelete }: NotificationListItemProps) => {
  const deleteNotification = () => {
    notification.delete().then(afterDelete)
  }

  // Currently we are displaying trash icon instead of some kind of "mark as read" because
  // there is no way to display read notifications so from user perspective it is remove action
  return (
    <li className="notificationsList__item">
      <NotificationComp
        notification={notification}
        avatarSize="medium"
        deleteOnClick={false}
      />
      <Button id="notification-list-item-delete" clickHandler={deleteNotification} type="danger">
        <Icon name="trash" size="medium" />
      </Button>
    </li>
  )
}

export default NotificationListItem
