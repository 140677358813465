import * as React from 'react'
import { useSlate } from 'slate-react'
import { Localized } from '@fluent/react'
import { withCurrentSelection } from '../../withCurrentSelection'
import AdmonitionTools from './components/AdmonitionTools'
import AudioTools from './components/AudioTools'
import CodeTools from './components/CodeTools'
import DocumentTools from './components/DocumentTools'
import ExerciseTools from './components/ExerciseTools'
import FigureTools from './components/FigureTools'
import FormatTools from './components/FormatTools'
import FootnoteTools from './components/FootnoteTools'
import ForeignTools from './components/ForeignTools'
import HighlightTools from './components/HighlightTools'
import InsertTools from './components/InsertTools'
import LinkTools from './components/LinkTools'
import ListTools from './components/ListTools'
import MediaTools from './components/MediaTools'
import ImageTools from './components/ImageTools'
import RuleTools from './components/RuleTools'
import SectionTools from './components/SectionTools'
import SuggestionsTools from './components/SuggestionTools'
import SourceTools from './components/SourceTools'
import TermTools from './components/TermTools'
import VideoTools from './components/VideoTools'
import XrefTools from './components/XrefTools'
import SeeAlsoTools from './components/SeeAlsoTools'
import MeaningTools from './components/MeaningTools'
import DefinitionTools from './components/DefinitionTools'
import MathTools from './components/MathTools'
import Icon from '../../../../../components/ui/Icon'
import Button, { ButtonHandler } from '../../../../../components/ui/Button'

import './index.css'

type ToolName =
  'admonitionTools' |
  'audioTools' |
  'codeTools' |
  'definitionTools' |
  'documentTools' |
  'exerciseTools' |
  'figureTools' |
  'footnoteTools' |
  'foreignTools' |
  'glossaryTools' |
  'highlightTools' |
  'imageTools' |
  'insertTools' |
  'linkTools' |
  'listTools' |
  'mathTools' |
  'meaningTools' |
  'mediaTools' |
  'ruleTools' |
  'sectionTools' |
  'seeAlsoTools' |
  'sourceTools' |
  'suggestionsTools' |
  'termTools' |
  'videoTools' |
  'xrefTools'

export type OnToggle = (toolName: ToolName, state?: boolean) => void

const defaultState: Set<ToolName> = new Set([
  'codeTools',
  'definitionTools',
  'footnoteTools',
  'foreignTools',
  'highlightTools',
  'insertTools',
  'linkTools',
  'mathTools',
  'sourceTools',
  'suggestionsTools',
  'termTools',
  'xrefTools',
])

export interface ToolboxProps {
  showSuggestions: boolean
  setShowSuggestions: (val: boolean) => void
}

const Toolbox = ({ showSuggestions, setShowSuggestions }: ToolboxProps) => {
  const editor = useSlate()
  const [openTools, setOpenTools] = React.useState<Set<ToolName>>(defaultState)
  const [isExpanded, setIsExpanded] = React.useState(false)

  const onToggle: OnToggle = React.useCallback((toolName, state) => {
    setOpenTools(tools => {
      const newState = new Set(tools)
      if (typeof state === 'undefined') {
        const isOpen = newState.has(toolName)
        if (isOpen) {
          newState.delete(toolName)
        } else {
          newState.add(toolName)
        }
        return newState
      }

      if (state) {
        newState.add(toolName)
      } else {
        newState.delete(toolName)
      }
      return newState
    })
  }, [])

  const toggleToolbox: ButtonHandler = event => {
    event.stopPropagation()
    setIsExpanded(prevState => !prevState)
  }

  const expandToolbox: React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()
    if (isExpanded === false) setIsExpanded(true)
  }

  const commonProps = {
    editor,
    onToggle,
  }

  const toolboxLabelInstance = (
    <div
      className={isExpanded ? 'toolbox-label--invisible' : 'toolbox-label toolbox-label'}
    >
      <Localized id="toolbox-label">Toolbox</Localized>
    </div>
  )

  return (
    <div
      className={isExpanded ? "toolbox" : "toolbox toolbox--collapsed"}
      onMouseDown={onMouseDown}
      onClick={expandToolbox}
    >
      <Button
        id="toolbox-toggle"
        className="toolbox-toggler"
        dataTest-id="toolbox-toggler"
        clickHandler={toggleToolbox}
        withBorder={false}
      >
        <Icon size='small' name={isExpanded ? 'arrow-down' : 'arrow-left'} />
      </Button>
      {toolboxLabelInstance}
      <div className={isExpanded ? "toolbox-tools" : "toobox-tools--collapsed"}>
        <FormatTools editor={editor} />
        <InsertTools toggleState={openTools.has('insertTools')} {...commonProps} />
        <SuggestionsTools
          toggleState={openTools.has('suggestionsTools')}
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
          {...commonProps}
        />
        <CodeTools toggleState={openTools.has('codeTools')} {...commonProps} />
        <TermTools toggleState={openTools.has('termTools')} {...commonProps} />
        <LinkTools toggleState={openTools.has('linkTools')} {...commonProps} />
        <XrefTools toggleState={openTools.has('xrefTools')} {...commonProps} />
        <FootnoteTools toggleState={openTools.has('footnoteTools')} {...commonProps} />
        <ForeignTools toggleState={openTools.has('foreignTools')} {...commonProps} />
        <HighlightTools toggleState={openTools.has('highlightTools')} {...commonProps} />
        <MathTools toggleState={openTools.has('mathTools')} {...commonProps} />
        <SourceTools toggleState={openTools.has('sourceTools')} {...commonProps} />
        <ListTools toggleState={openTools.has('listTools')} {...commonProps} />
        <AdmonitionTools toggleState={openTools.has('admonitionTools')} {...commonProps} />
        <ExerciseTools toggleState={openTools.has('exerciseTools')} {...commonProps} />
        <RuleTools toggleState={openTools.has('ruleTools')} {...commonProps} />
        <FigureTools toggleState={openTools.has('figureTools')} {...commonProps} />
        <MediaTools toggleState={openTools.has('mediaTools')} {...commonProps} />
        <AudioTools toggleState={openTools.has('audioTools')} {...commonProps} />
        <ImageTools toggleState={openTools.has('imageTools')} {...commonProps} />
        <VideoTools toggleState={openTools.has('videoTools')} {...commonProps} />
        <SectionTools toggleState={openTools.has('sectionTools')} {...commonProps} />
        <DefinitionTools toggleState={openTools.has('definitionTools')} {...commonProps} />
        <MeaningTools toggleState={openTools.has('meaningTools')} {...commonProps} />
        <SeeAlsoTools toggleState={openTools.has('seeAlsoTools')} {...commonProps} />
        <DocumentTools toggleState={openTools.has('documentTools')} {...commonProps} />
      </div>
    </div>)
}

export default withCurrentSelection(Toolbox)

// We do not want to lose selection from Editor when clicking on toolbox.
export const onMouseDown = (ev: React.MouseEvent<HTMLDivElement>) => {
  const target = ev.target as HTMLElement
  if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA') {
    ev.preventDefault()
  }
}
