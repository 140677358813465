import * as React from 'react'
import { createEditor, Node, Transforms } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import { Localized } from '@fluent/react'
import { isKeyHotkey } from 'is-hotkey'
import { Conversation } from '../../../../api'
import * as render from './render'
import serialize from './serialize'
import { Hyperlink, Mark, withPlugins } from './plugins'
import Suggestions from '../Suggestions'

import './index.css'

const INITIAL: Node[] = [{ type: 'paragraph', children: [{ text: '' }] }]

// If socket is provided message will be sent using it.
// If onEnter is provided it will be called with message.
interface InputEditorProps {
  socket?: Conversation
  onEnter?: (msg: Uint8Array, text: string) => void
}

const InputEditor = (props: InputEditorProps) => {
  const [value, setValue] = React.useState<Node[]>(INITIAL)
  const editor = React.useMemo(
    () => withPlugins(withReact(withHistory(createEditor()))), [])

  const sendMessage = React.useCallback(() => {
    const msg = serialize(editor, value)
    const hasLinks = Array.from(Node.nodes(editor, { pass: Hyperlink.isHyperlink })).length > 0
    const text = value.map(node => Node.string(node))
      .join('')
      .trim()

    if (!text && !hasLinks) return

    if (props.socket) {
      props.socket.sendMessage(msg)
    }

    if (props.onEnter) {
      props.onEnter(msg, text)
    }

    Transforms.deselect(editor)
    setValue(INITIAL)
    // We need to use setTimeout to make sure that other updates are already done.
    setTimeout(() => Transforms.select(editor, [0, 0]), 0)
  }, [editor, props, value])

  const onKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.shiftKey) {
      return false
    }

    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
      return true
    }

    if (isKeyHotkey('mod+i', event as any as KeyboardEvent)) {
      event.preventDefault()
      Mark.toggleMark(editor, 'emphasis')
      return true
    } else if (isKeyHotkey('mod+b', event as any as KeyboardEvent)) {
      event.preventDefault()
      Mark.toggleMark(editor, 'strong')
      return true
    }

    return false
  }, [editor, sendMessage])

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={setValue}
    >
      <Suggestions />
      <div className="chat__input-editor">
        <Localized id="chat-input-placeholder" attrs={{ placeholder: true }}>
          <Editable
            placeholder="Type your message"
            renderElement={render.renderElement}
            renderLeaf={render.renderLeaf}
            onKeyDown={onKeyDown}
          />
        </Localized>
      </div>
    </Slate>
  )
}

export default InputEditor
