import { match } from 'react-router'
import { DocumentDB, PersistDB } from 'slate-persistence'
import { Draft, Storage } from '../../../api'
import { confirmDialog, timeout } from '../../../helpers'
import PersistanceError from './PersistanceError'
import { fetchReferenceTargetsForDocument } from './utils'

async function loader({ match: { params: { id } } }:
  { match: match<{ id: string }> },
) {
  const [documentDB, storage, draft] = await Promise.all([
    Promise.race([
      PersistDB.load(id)
        .catch(() => {
          throw new PersistanceError()
        }),
      timeout(10000),
    ]) as unknown as DocumentDB,
    Storage.load(id),
    Draft.load(id),
  ])

  const mediaUrl = (src: string) => `/api/v1/drafts/${draft.module}/files/${src}`
  const permissions = draft.permissions || []
  const readOnly = permissions.length === 0 || permissions.every(p => p === 'view')

  const dirty = !readOnly && documentDB.dirty

  const index = await storage.read()
  const {
    classes,
    content: document,
    language: CNXMLLanguage,
    suggestions,
    deserializationErrors,
  } = index.deserialize()
  const { language: DraftLanguage } = draft

  storage.setDocumentClasses(classes)
  storage.setLanguage(CNXMLLanguage || DraftLanguage || 'en')

  if (dirty && index.version !== documentDB.version) {
    const res = await confirmDialog({
      title: 'draft-load-incorrect-version-title',
      content: 'draft-load-incorrect-version-info',
      buttons: {
        discard: 'draft-load-incorrect-version-button-discard',
        keepWorking: 'draft-load-incorrect-version-button-keep-working',
      },
      showCloseButton: false,
      closeOnBgClick: false,
      closeOnEsc: false,
    })

    switch (res) {
    case 'discard': {
      await documentDB.discard()
      break
    }
    case 'keepWorking':
      // Local changes will be loaded.
      storage.tag = documentDB.version!
      index.version = documentDB.version!
      break
    default:
      throw new Error(
        `Unknown action: "${res}" while trying to handle loading of incorrect version of document.`,
      )
    }
  }

  let shouldBeRestored = false

  if (!readOnly) {
    if (documentDB.dirty) {
      shouldBeRestored = true
    } else {
      await documentDB.save(document, index.version)
    }
  }

  fetchReferenceTargetsForDocument(document, draft.module)

  return {
    documentDB,
    draft,
    mediaUrl,
    readOnly,
    storage,
    shouldBeRestored,
    value: document,
    suggestions,
    deserializationErrors,
  }
}

export default loader
