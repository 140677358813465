import * as React from 'react'
import * as Counters from 'react-counters'
import { RuleExample } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { RULE, RULE_EXAMPLE } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface RuleExampleProps extends RenderElementProps {
  element: RuleExample
}

const RuleExampleComp = ({ children, attributes }: RuleExampleProps) => {
  const { document } = React.useContext(LOCALIZATION)
  Counters.useCounter(attributes.ref, { increment: [RULE_EXAMPLE] })
  const rule = Counters.useCounterValue(attributes.ref, RULE)
  const example = Counters.useCounterValue(attributes.ref, RULE_EXAMPLE)
  const message = document.getString('rule-example-label', { rule, example })

  return <div {...attributes} className="rule-example" data-label={message}>
    {children}
  </div>
}

export default RuleExampleComp
