import * as React from 'react'
import { User } from '../../api'
import Avatar from '../../components/ui/Avatar'

interface UserInfoProps {
  user: User
}

const UserInfo = ({ user }: UserInfoProps) => (
  <div className="usersList__info">
    <span className="usersList__avatar">
      <Avatar size="small" disableLink={true} user={user} />
    </span>
    <h2 className="userList__name">
      {user.name}
    </h2>
  </div>
)

export default UserInfo
