import * as React from 'react'
import Nestable, { RenderItem } from 'react-nestable'
import { Localized } from '@fluent/react'
import { connect } from 'react-redux'
import * as api from '../../api'
import { PartData } from '../../api/bookpart'
import { useLoadAllBooks, useLoadBookPart } from '../../api/hooks'
import { ReferenceTargets } from '../../store/types'
import { State } from '../../store/reducers'
import { fetchReferenceTargets } from '../../store/actions/modules'
import Button from '../../components/ui/Button'
import Icon from '../../components/ui/Icon'
import RemoteSource from './components/RemoteSource'
import LocalizationLoader from '../../screens/app/Draft/components/LocalizationLoader'
import { CurrentDraftContext } from '../../screens/app/Draft'
import RefTargets from '../../containers/ReferenceTargets'
import { XrefTargetOnSelect } from '../XrefTargetSelector'

import './index.css'

interface RemoteReferenceTargetsProps {
  targets: ReferenceTargets,
  /**
   * Function to call when user selects a resource target.
   */
  onSelect: XrefTargetOnSelect
  fetchReferenceTargets: (module: api.Module) => void,
}

const mapStateToProps = ({
  modules: { referenceTargets },
}: State) => ({
  targets: referenceTargets,
})

const mapDispatchToProps = { fetchReferenceTargets }

/**
 * Display a list of reference targets located in any module.
 */
const RemoteReferenceTargets = ({ onSelect, ...props }: RemoteReferenceTargetsProps) => {
  const [selected, setSelected] = React.useState<api.Module | null>(null)
  const [books] = useLoadAllBooks()

  const selectModule = () => {
    if (selected) {
      onSelect({
        type: 'docref',
        source: selected,
      })
    }
  }

  const selectRefSource = async (ev: React.MouseEvent) => {
    let target = ev.target as HTMLElement | null
    while (target && !target.dataset.id) {
      target = target.parentElement
    }

    if (!target) return

    const id = target.dataset.id!
    const { targets, fetchReferenceTargets } = props
    const mod = await api.Module.load(id)

    setSelected(mod)

    if (targets.get(id) == null) {
      fetchReferenceTargets(mod)
    }
  }

  const targets = React.useMemo(
    () => selected ? props.targets.get(selected.id) : null,
    [selected, props.targets])

  return (
    <>
      {
        targets && <div className="remote-reference-targets">
          <div className="remote-reference-targets__controls">
            <Button id="reference-target-list-go-back" clickHandler={() => setSelected(null)}>
              <Icon name="arrow-left" size="small" />
              <Localized id="reference-target-list-go-back">Back</Localized>
            </Button>
            <Button l10nId="reference-target-link-module" clickHandler={selectModule}>
              Link to this module
            </Button>
          </div>
          <CurrentDraftContext.Consumer>
            {value => (
              <LocalizationLoader
                locale={value.state.currentDraftLang || 'en'}
              >
                <RefTargets
                  module={selected}
                  targets={targets}
                  onSelect={onSelect}
                />
              </LocalizationLoader>
            )}
          </CurrentDraftContext.Consumer>
        </div>
      }
      <div className={`remote-reference-targets ${targets ? 'hide' : ''}`}>
        {
          books.map(book => (
            <NestableCustomized
              key={book.id}
              book={book}
              onModuleClick={selectRefSource}
            />
          ))
        }
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteReferenceTargets)

interface NestableCustomizedProps {
  book: api.Book
  onModuleClick: (ev: React.MouseEvent) => void
}

const NestableCustomized = ({ book, onModuleClick }: NestableCustomizedProps) => {
  const [bookpart] = useLoadBookPart(book)

  const renderItem = ({ item, collapseIcon }: RenderItem<PartData>) => {
    const toggleGroup = () => {
      nestable.current!.toggleCollapseGroup(item.number)
    }

    return (
      <div className={`bookpart__item bookpart__item--${item.kind}`}>
        {
          item.kind === 'group' ?
            <>
              <span className="bookpart__icon">
                {collapseIcon}
              </span>
              <div
                className="bookpart__title"
                onClick={toggleGroup}
              >
                {item.title}
              </div>
            </>
            :
            <RemoteSource
              book={book}
              module={item.id}
              onClick={onModuleClick}
            />
        }
      </div>
    )
  }

  const renderCollapseIcon = ({ isCollapsed }: { isCollapsed: boolean }) => {
    if (isCollapsed) {
      return <Icon name="arrow-right"/>
    }
    return <Icon name="arrow-down" />
  }

  const nestable = React.createRef<Nestable>()

  return (
    <Nestable
      ref={nestable}
      isDisabled={true}
      items={bookpart ? [bookpart] : []}
      className="book-collection"
      childrenProp="parts"
      renderItem={renderItem}
      renderCollapseIcon={renderCollapseIcon}
      collapsed
    />
  )
}
