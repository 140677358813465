import * as React from 'react'
import { Localized } from '@fluent/react'
import { Resource, Team } from '../../../../../../../api'

interface ResourceNameProps {
  resource: Resource
  team?: Team
}

const ResourceName = ({ resource, team }: ResourceNameProps) => <h2 className="resource__title">
  <span className="resource__name">
    {resource.name}
  </span>
  {
    team ?
      <span className="resource__team">
        <Localized
          id="resources-card-team"
          vars={{ team: team.name }}
        >
          Team: ...
        </Localized>
      </span>
      : null
  }
</h2>

export default ResourceName
