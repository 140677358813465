import * as React from 'react'
import Select from 'react-select'
import { Localized } from '@fluent/react'
import { Editor } from 'slate'
import { StorageContext } from '../../../../../../../api/storage'
import data from '../../../../../../../locale/data'
import { CurrentDraftContext } from '../../../../../../../screens/app/Draft'
import { setCurrentDraftLang } from '../../../../../../../screens/app/Draft/store/actions'
import ToolGroup from '../ToolGroup'
import CharactersCounter from '../CharactersCounter'
import { OnToggle } from '../../index'
import { DocumentClasses } from '../Classes'

import './index.css'

interface SelectOption { value: string, label: string }

interface DocumentToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const DocumentTools = ({ editor, ...props }: DocumentToolsProps) => {
  const currentDraftContext = React.useContext(CurrentDraftContext)
  const storageContext = React.useContext(StorageContext)
  const code = storageContext.language
  const language = data.languages.find(lang => lang.code === code)

  const setLanguage = ({ value: code }: {value: string, label: string}) => {
    storageContext.setLanguage(code)
    currentDraftContext.state.draft!.update({ language: code })
    currentDraftContext.dispatchCurrentDraftAction(setCurrentDraftLang(code))
  }

  return (
    <ToolGroup
      title="editor-tools-document-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('documentTools')}
      className="document-tools"
    >
      <label className="toolbox__label">
        <span className="toolbox__title">
          <Localized id="editor-tools-document-select-language">
            Select document language
          </Localized>
        </span>
        <Select
          className="toolbox__select react-select"
          value={language ? { value: language.code, label: language.name } : null}
          onChange={setLanguage as any}
          options={data.languages.map(l => ({ value: l.code, label: l.name }))}
          getOptionLabel={getOptionLabel}
        />
      </label>
      <label className="toolbox__label">
        <span className="toolbox__title">
          <Localized id="editor-tools-document-classes">
            Specify classes for Document
          </Localized>
        </span>
        <DocumentClasses />
      </label>
      <CharactersCounter />
    </ToolGroup>
  )
}

export default DocumentTools

function getOptionLabel({ label: name }: SelectOption) {
  return name
}
