import * as React from 'react'
import { Localized } from '@fluent/react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Module, User } from '../../../../../../api'
import { useLoadDraft, useLoadTeam } from '../../../../../../api/hooks'
import { useQuery } from '../../../../../../hooks'
import { ModuleLabel } from '../../../../../../store/types'
import { archiveModule } from '../../../../../../store/actions/modules'
import { confirmDialog } from '../../../../../../helpers'
import HighlightText from '../../../../../../components/HighlightText'
import ModuleLabels from '../../../../../../components/ModuleLabels'
import LimitedUI from '../../../../../../components/LimitedUI'
import Button from '../../../../../../components/ui/Button'
import Icon from '../../../../../../components/ui/Icon'

import './index.css'

interface ModuleInfoProps {
  module: Module
  activePreviewId: string
  highlight: string
  onModuleLabelClick: (label: ModuleLabel) => void
  isEditingUnlocked: boolean
  user: User | null
  afterDelete: (mod: Module) => void
}

const ModuleInfo = ({
  module,
  activePreviewId,
  highlight,
  onModuleLabelClick,
  isEditingUnlocked,
  user,
  afterDelete,
}: ModuleInfoProps) => {
  const [team] = useLoadTeam(module.team)
  const [draft] = useLoadDraft(module.process ? module.id : undefined)
  const dispatch = useDispatch()
  const { page } = useQuery<{ page?: string }>()

  const removeModule = async () => {
    const res = await confirmDialog({
      title: 'modules-dialog-remove-title',
      vars: { module: module.title },
      buttons: {
        cancel: 'modules-dialog-buttons-cancel',
        confirm: 'modules-remove',
      },
    })
    if (res === 'confirm') {
      dispatch(archiveModule(module, undefined, () => afterDelete(module)))
    }
  }

  const showRemoveButton = React.useMemo(() => {
    if (isEditingUnlocked && team && user?.hasPermissionsInTeam('module:edit', team)) return true
    return false
  }, [isEditingUnlocked, team, user])

  return (
    <li className={`modules__item ${activePreviewId === module.id ? 'active' : ''}`}>
      <div className="modules__name-wrapper">
        <div className="modules__name">
          <HighlightText text={module.title} highlight={highlight} />
        </div>
        {draft
          ? <div className="modules__draft">
            <Link to={`drafts/${draft.module}/view`}>
              <Localized
                id="modules-view-draft-link"
              >
                There is a draft for this module
              </Localized>
            </Link>
          </div>
          : null}
        <div className="modules__team">
          <Localized
            id="modules-team"
            vars={{ team: team?.name || '...' }}
          >
            Team: ...
          </Localized>
        </div>
      </div>
      <div className="modules__controls">
        <ModuleLabels module={module} onModuleLabelClick={onModuleLabelClick} />
        <LimitedUI permissions="module:edit">
          {
            showRemoveButton && !draft &&
              <Button l10nId="processes-remove" type="danger" clickHandler={removeModule}>
                Remove
              </Button>
          }
        </LimitedUI>
        <Button id="modules-view-draft-preview" to={`/modules/${module.id}/preview${page ? `?page=${page}` : ''}`}>
          <Icon name="eye" />
        </Button>
      </div>
    </li>
  )
}

export default ModuleInfo
