import * as React from 'react'
import { Editor, Node } from 'slate'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'
import { SourceElement } from '../../../../../../../screens/app/Draft/plugins/SourceElements'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import './index.css'
import { getXmlError } from './utils'

interface SourceToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const SourceTools = ({ editor, ...props }: SourceToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: SourceElement.isSourceElement })
  if (!node) return null

  const validateSource = getXmlError(Node.string(node[0]))

  return (
    <ToolGroup
      title="editor-tools-source-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('sourceTools')}
    >
      <div className="source__error">
        { validateSource }
      </div>
    </ToolGroup>
  )
}

export default SourceTools
