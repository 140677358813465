import * as React from 'react'
import Section from '../../../components/Section'
import Header from '../../../components/Header'
import ModuleLabelsEditor from '../../../containers/ModuleLabelsEditor'

import './index.css'

const ModuleLabelsScreen = () => (
  <Section className="module-labels-screen">
    <Header l10nId="module-labels-screen-title" title="Manage labels" />
    <div className="section__content">
      <ModuleLabelsEditor />
    </div>
  </Section>
)

export default ModuleLabelsScreen
