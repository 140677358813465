import * as React from 'react'
import * as Counters from 'react-counters'
import * as CNXML from 'slate-cnxml'
import { Caption, Title, WithClasses } from 'cnx-designer'
import { Editor, Node, NodeEntry, Path, Point, Element as SlateElement, Text, Transforms } from 'slate'
import { RenderElementProps } from 'slate-react'
import { TABLE } from '../../counters'
import { pointMax, pointMin } from '../../utils'

export interface Table extends SlateElement {
  type: 'table'
  summary: string
  id?: string
}

export interface TableTitle extends SlateElement {
  type: 'table_title'
}

export interface TableCaption extends SlateElement {
  type: 'table_caption'
}

export interface TableTgroup extends SlateElement {
  type: 'table_tgroup'
}

export interface TableColspec extends SlateElement {
  type: 'table_colspec'
}

export interface TableSpanspec extends SlateElement {
  type: 'table_spanspec'
}

export interface TableThead extends SlateElement {
  type: 'table_thead'
}

export interface TableTbody extends SlateElement {
  type: 'table_tbody'
}

export interface TableTfoot extends SlateElement {
  type: 'table_tfoot'
}

export interface TableRow extends SlateElement {
  type: 'table_row'
}

export interface TableEntry extends SlateElement {
  type: 'table_entry'
}

export const Table = {
  isTable: (value: any): value is Table => {
    return SlateElement.isElement(value) && value.type === 'table'
  },
  isTableTitle: (value: any): value is TableTgroup => {
    return SlateElement.isElement(value) && value.type === 'table_title'
  },
  isTableCaption: (value: any): value is TableCaption => {
    return SlateElement.isElement(value) && value.type === 'table_caption'
  },
  isTableTgroup: (value: any): value is TableTgroup => {
    return SlateElement.isElement(value) && value.type === 'table_tgroup'
  },
  isTableColspec: (value: any): value is TableColspec => {
    return SlateElement.isElement(value) && value.type === 'table_colspec'
  },
  isTableSpanspec: (value: any): value is TableSpanspec => {
    return SlateElement.isElement(value) && value.type === 'table_spanspec'
  },
  isTableThead: (value: any): value is TableThead => {
    return SlateElement.isElement(value) && value.type === 'table_thead'
  },
  isTableTbody: (value: any): value is TableTbody => {
    return SlateElement.isElement(value) && value.type === 'table_tbody'
  },
  isTableTfoot: (value: any): value is TableTfoot => {
    return SlateElement.isElement(value) && value.type === 'table_tfoot'
  },
  isTableRow: (value: any): value is TableRow => {
    return SlateElement.isElement(value) && value.type === 'table_row'
  },
  isTableEntry: (value: any): value is TableEntry => {
    return SlateElement.isElement(value) && value.type === 'table_entry'
  },
}

export const normalizeTable = (editor: Editor, nodeEntry: NodeEntry): boolean => {
  const [element, path] = nodeEntry

  let normalizations = 0

  if (Table.isTable(element)) {
    for (const [i, child] of element.children.entries()) {
      if (Caption.isCaption(child)) {
        Transforms.setNodes(editor, { type: 'table_caption' }, { at: [...path, i] })
        normalizations++
      } else if (Title.isTitle(child)) {
        Transforms.setNodes(editor, { type: 'table_title' }, { at: [...path, i] })
        normalizations++
      }
    }
  }

  return normalizations !== 0
}

const DESERIALIZERS: CNXML.Deserializers = {
  ...CNXML.BLOCK,
  ...CNXML.INLINE,
  colspec: deserializeTableElement,
  entry: deserializeTableElement,
  tbody: deserializeTableElement,
  tgroup: deserializeTableElement,
  thead: deserializeTableElement,
  tfoot: deserializeTableElement,
  row: deserializeTableElement,
}

function deserializeTableElement(
  editor: CNXML.DeserializingEditor,
  el: Element,
  at: Path,
) {
  if (el.namespaceURI !== CNXML.CNXML_NAMESPACE) {
    editor.unknownElement(el, at, DESERIALIZERS)
    return
  }
  const attributes = {}
  Array.from(el.attributes).forEach(a => {
    attributes[a.name] = a.value
  })
  if (el.tagName === 'colspec') {
    CNXML.buildElement(editor, el, at, { type: 'table_colspec', ...attributes }, DESERIALIZERS)
    CNXML.normalizeVoid(editor, at)
  } else if (['colspec', 'tbody', 'tgroup', 'thead', 'tfoot', 'row'].includes(el.tagName)) {
    CNXML.buildElement(editor, el, at, {
      type: `table_${el.tagName}`,
      ...attributes,
    }, DESERIALIZERS)
    CNXML.normalizeBlock(editor, at)
  } else if (['caption', 'title'].includes(el.tagName)) {
    CNXML.buildElement(editor, el, at, {
      type: `table_${el.tagName}`,
    }, CNXML.INLINE)
    CNXML.normalizeLine(editor, at)
  } else if (el.tagName === 'entry') {
    CNXML.buildElement(editor, el, at, {
      type: `table_${el.tagName}`,
    }, CNXML.MIXED)
    CNXML.normalizeMixed(editor, at)
  } else {
    editor.unknownElement(el, at, DESERIALIZERS)
  }
}

/**
 * Deserialize Table elements. Return true if it was dserialized and false otherwise.
 */
export const deserializeTable = (
  editor: CNXML.DeserializingEditor,
  el: Element,
  at: Path,
): boolean => {
  if (el.namespaceURI === CNXML.CNXML_NAMESPACE && el.tagName === 'table') {
    const attributes = {}
    Array.from(el.attributes).forEach(a => {
      attributes[a.name] = a.value
    })
    CNXML.buildElement(editor, el, at, {
      type: 'table',
      summary: el.getAttribute('summary') || '',
      ...attributes,
    }, {
      title: deserializeTableElement,
      caption: deserializeTableElement,
      colspec: deserializeTableElement,
      entry: deserializeTableElement,
      tbody: deserializeTableElement,
      tgroup: deserializeTableElement,
      thead: deserializeTableElement,
      tfoot: deserializeTableElement,
      row: deserializeTableElement,
    })
    removeWhiteSpaces(editor, at)
    return true
  }
  return false
}

const TYPE_TO_TAG = {
  table: 'table',
  table_title: 'title',
  table_tgroup: 'tgroup',
  table_colspec: 'colspec',
  table_thead: 'thead',
  table_tbody: 'tbody',
  table_tfoot: 'tfoot',
  table_row: 'row',
  table_entry: 'entry',
  table_caption: 'caption',
}

/**
 * Serialize Table element to xml element.
 */
export const serializeTable: CNXML.PartialSerializer<any, any> = (node, attrs, children) => {
  const tag = TYPE_TO_TAG[node.type as string]
  if (tag) {
    const attributes: Record<string, any> = {}
    for (const [key, val] of Object.entries(node)) {
      if (key !== 'children' && key !== 'type' && key !== 'classes') {
        attributes[key] = val
      }
    }

    if (WithClasses.hasClasses(node)) {
      attributes.class = node.classes.join(' ')
    }

    return CNXML.jsx(tag, {
      ...attributes,
      ...attrs,
      xmlns: CNXML.CNXML_NAMESPACE,
      children,
    })
  }
  return null
}

// eslint-disable-next-line
export function onKeyDown(editor: Editor, ev: KeyboardEvent): void {
  switch (ev.key) {
  case 'Enter': return onEnter(editor, ev)
  case 'Backspace': return deleteContents(editor, ev, 'backward')
  case 'Delete': return deleteContents(editor, ev, 'forward')
  default:
  }
}
function onEnter(editor: Editor, ev: KeyboardEvent): void {
  const { selection } = editor

  if (selection == null) {
    return
  }

  const [tableRow, tableRowPath] = Editor.above(editor, { match: Table.isTableRow }) ?? []
  if (tableRow != null && tableRowPath != null) {
    const newPath = [...tableRowPath]
    newPath[newPath.length - 1] += 1
    if (Node.has(editor, newPath)) {
      newPath.push(selection.focus.path[newPath.length], 0)
      const nextRowSelection = {
        anchor: {
          path: newPath,
          offset: 0,
        },
        focus: {
          path: newPath,
          offset: 0,
        },
      }
      Transforms.setSelection(editor, nextRowSelection)
    } else {
      const moveTillNotTab = () => {
        const [, tablePath] = Editor.above(editor, { match: Table.isTable }) ?? []
        if (tablePath) {
          Transforms.move(editor, { unit: 'line' })
          moveTillNotTab()
        }
      }
      const [, tablePath] = Editor.above(editor, { match: Table.isTable }) ?? []
      tablePath && Transforms.insertNodes(editor, { type: 'paragraph', children: [] }, { at: Path.next(tablePath) })
      moveTillNotTab()
    }

    // eslint-disable-next-line
    return ev.preventDefault()

  }
  const [tableCap] = Editor.above(editor, { match: Table.isTableCaption }) ?? []
  if (tableCap != null) {
    // eslint-disable-next-line
    return ev.preventDefault()
  }
}

function deleteContents(
  editor: Editor,
  ev: KeyboardEvent,
  direction: 'forward' | 'backward',
): void {
  const { selection } = editor

  if (selection == null) return

  const [table] = Editor.above(editor, { match: Table.isTable }) ?? []
  if (table == null) return

  const start = pointMin(selection.anchor, selection.focus)
  const end = pointMax(selection.anchor, selection.focus)

  Transforms.collapse(editor, { edge: direction === 'forward' ? 'end' : 'start' })

  for (const [, path] of Editor.nodes(editor, { at: selection, match: Table.isTable })) {
    const anchor = pointMax(start, Editor.start(editor, path))
    const focus = pointMin(end, Editor.end(editor, path))

    if (!Point.equals(anchor, focus)) {
      Transforms.delete(editor, { at: { anchor, focus } })
    } else if (anchor.offset === 0 && direction === 'backward') {
      Transforms.move(editor, { unit: 'character', reverse: true })
    } else {
      return
    }
  }

  ev.preventDefault()
}

interface TableProps extends RenderElementProps {
  element: Table
}

const TableComp = ({ element, attributes, children }: TableProps) => {
  Counters.useCounter(attributes.ref, { increment: [TABLE] })

  return (
    <div
      className="adr-table"
      id={element.id}
      {...attributes}
    >
      {children}
    </div>
  )
}

interface TableNodeProps extends RenderElementProps {
  element: Table | TableCaption | TableColspec | TableEntry
    | TableRow | TableSpanspec | TableTbody | TableTfoot | TableTgroup
    | TableThead | TableTitle
}

const TableNodeComp = ({ element, attributes, children }: TableNodeProps) => {
  if (Table.isTable(element)) {
    return (
      <TableComp
        element={element}
        attributes={attributes}
      >
        {children}
      </TableComp>
    )
  }

  return (
    <div
      className={`adr-${element.type.split('_')[1]}`}
      {...attributes}
    >
      {children}
    </div>
  )
}

const removeWhiteSpaces = (editor: Editor, at: Path) => {
  for (const [node, path] of Node.children(editor, at, { reverse: true })) {
    if (!Text.isText(node)) continue
    if (/^\s*$/.test(node.text)) {
      editor.apply({ type: 'remove_node', path, node })
    }
  }
}

export default TableNodeComp
