import * as React from 'react'
import { AltText } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { LOCALIZATION } from '../../constants'

interface AltTextProps extends RenderElementProps {
  element: AltText
}

const AltTextComp = ({ children, attributes }: AltTextProps) => {
  const { ui } = React.useContext(LOCALIZATION)
  const message = ui.getString('media-alt-label') || 'Alternative text:'

  return <div {...attributes} className="media__alt" data-label={message}>
    {children}
  </div>
}

export default AltTextComp
