import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import APICache from './apicache'
import Pagination from './pagination'
import ProcessVersion, { VersionID } from './processversion'
import { TeamID } from './team'
import { DraftData } from './draft'
import { elevated, extend } from './utils'

export type ProcessStatus = 'archived'

export type ProcessID = number

/**
 * Process data.
 */
export type ProcessData = {
  id: [ProcessID, VersionID]
  name: string
  version: string // date this version was created
  team: TeamID
  status?: ProcessStatus
}

/**
 * Process structure.
 */
export type ProcessStructure = {
  name: string,
  start: number,
  slots: ProcessSlot[],
  steps: ProcessStep[],
}

/**
 * Slot in process.
 */
export type ProcessSlot = {
  id: number,
  name: string,
  autofill: boolean,
  roles: number[],
}

/**
 * Step in process.
 */
export type ProcessStep = {
  id: number,
  name: string,
  slots: StepSlot[],
  links: Link[],
}

/**
 * Step's slot.
 */
export type StepSlot = {
  slot: number,
  permission: SlotPermission,
}

/**
 * Data for process step, but returned when request was for single step.
 */
export type ProcessSingleStep = {
  id: number,
  name: string,
  links: Link[],
  process: [number, number], // [ProcessID, VersionID]
  slots: SingleStepSlot[],
}

/**
 * Represent assignment of slots to steps.
 */
export type SingleStepSlot = {
  slot: number,
  permissions: SlotPermission[],
  user: number | null,
}

/**
 * Slot permissions.
 */
export type SlotPermission = 'view' | 'edit' | 'propose-changes' | 'accept-changes'

/**
 * Link data.
 */
export type Link = {
  name: string,
  to: number,
  slot: number,
}

/**
 * Free slot.
 */
export type FreeSlot = {
  id: number
  name: string
  draft: DraftData
}

/**
 * Slot's data.
 */
export type Slot = {
  id: number
  name: string
  roles: number[]
}

export default class Process {
  /**
   * Fetch data for specific process from the server.
   */
  static load(id: ProcessID, fromCache = true): Promise<Process> {
    return APICache.getOrSetNested(
      ['Processes', id.toString()],
      fromCache,
      async () => new Process((await axios.get(`processes/${id}`)).data),
    )
  }

  static pagination(fromCache = true): Pagination<Process> {
    return APICache.getOrSetNestedSync(
      ['Pagination', 'processes'],
      fromCache,
      new Pagination(
        'processes',
        (data: ProcessData) => new Process(data),
        processes => APICache.update(
          'Processes',
          processes.reduce((obj, process) => ({ ...obj, [process.id.toString()]: process }), {}),
        ),
      ),
    )
  }

  /**
   * Create a new process.
   *
   * This function requires editing-process:edit permission.
   */
  static async create(structure: ProcessStructure, team: TeamID): Promise<Process> {
    const process = new Process(
      (await elevated(() => axios.post('processes', { ...structure, team }))).data)
    return APICache.setNested(['Processes', process.id.toString()], process)
  }

  /**
   * Assign self to a free slot.
   */
  static async takeSlot(data: { draft: string, slot: number }): Promise<void> {
    await axios.post('processes/slots', data)
    APICache.invalidate('Drafts')
  }

  /**
   * Process's id.
   */
  id!: ProcessID

  /**
   * Process's name.
   */
  name!: string

  /**
   * Version of process (data-time).
   */
  version!: string

  /**
   * ID of team in for which this process was created.
   */
  team!: TeamID

  /**
   * Process status.
   */
  status?: ProcessStatus

  constructor(data: ProcessData) {
    extend(this, data)
  }

  /**
   * Fetch list of all versions of processes.
   */
  async versions(fromCache = true): Promise<ProcessVersion[]> {
    const data = await APICache.getOrSetNested(
      ['Processes/Versions', this.id.toString()],
      fromCache,
      async () => {
        const versions: ProcessVersion[] = (await axios.get(`processes/${this.id}/versions`)).data
          .map((v: ProcessData) => new ProcessVersion(v, this.id))
        return versions.reduce((obj, v) => ({ ...obj, [v.id.toString()]: v }), {})
      },
    )
    return Object.values(data)
  }

  /**
   * Return structure for this process. If @param fromCache is true then cached data
   * will be returned.
   */
  structure(fromCache = true): Promise<ProcessStructure> {
    return APICache.getOrSetNested(
      ['Processes/Structures', this.id.toString()],
      fromCache,
      async () => (await axios.get(`processes/${this.id}/structure`)).data,
    )
  }

  /**
   * Fetch list of all slots of this process.
   */
  slots(fromCache = true): Promise<Slot[]> {
    return APICache.getOrSetNested(
      ['Processes/Slots', this.id.toString()],
      fromCache,
      async () => (await axios.get(`processes/${this.id}/slots`)).data,
    )
  }

  /**
   * Fetch list of all steps of this process.
   */
  steps(fromCache = true): Promise<ProcessSingleStep[]> {
    return APICache.getOrSetNested(
      ['Processes/Steps', this.id.toString()],
      fromCache,
      async () => (await axios.get(`processes/${this.id}/steps`)).data,
    )
  }

  /**
   * Update name of process.
   *
   * This function requires editing-process:edit permission.
   */
  async updateName(name: string): Promise<Process> {
    const process = new Process(
      (await elevated(() => axios.put(`processes/${this.id}`, { name }))).data)
    APICache.invalidate(['Processes/Structures', this.id.toString()])
    return APICache.setNested(['Processes', this.id.toString()], process)
  }

  /**
   * Update name or roles for slot in process.
   *
   * This function requires editing-process:edit permission.
   */
  async updateSlot(slot: number, data: { name?: string, roles?: number[] }): Promise<void> {
    await elevated(() => axios.put(`processes/${this.id}/slots/${slot}`, data))
    const processId = this.id.toString()
    APICache.invalidate(['Processes/Structures', processId])
  }

  /**
   * Update name of step in process.
   *
   * This function requires editing-process:edit permission.
   */
  async updateStepName(step: number, name: string): Promise<void> {
    await elevated(() => axios.put(`processes/${this.id}/steps/${step}`, { name }))
    const processId = this.id.toString()
    APICache.invalidate(['Processes/Structures', processId])
  }

  /**
   * Update name of link in process.
   *
   * This function requires editing-process:edit permission.
   */
  async updateLinkName(step: number, slot: number, target: number, name: string): Promise<void> {
    await elevated(() => axios.put(
      `processes/${this.id}/steps/${step}/links/${slot}/${target}`,
      { name },
    ))
    const processId = this.id.toString()
    APICache.invalidate(['Processes/Structures', processId])
  }

  /**
   * Create new version of this process with updated structure.
   *
   * This function requires editing-process:edit permission.
   */
  async createVersion(structure: ProcessStructure): Promise<ProcessVersion> {
    const version = new ProcessVersion(
      (await elevated(() => axios.post(`processes/${this.id}/versions`, structure))).data, this.id)
    APICache.updateNested(
      ['Processes/Versions', this.id.toString()],
      { [version.id.toString()]: version })
    return version
  }

  /**
   * Archive this process.
   *
   * This method requires editing-process:manage permission.
   */
  archive(): Promise<AxiosResponse> {
    this.status = 'archived'
    return axios.post(`processes/${this.id}/archive`, { archive: true })
  }

  /**
   * Restore this process from archive.
   *
   * This method requires editing-process:manage permission.
   */
  restore(): Promise<AxiosResponse> {
    this.status = undefined
    return axios.post(`processes/${this.id}/archive`, { archive: false })
  }
}
