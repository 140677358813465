import * as React from 'react'
import { negotiateLanguages } from '@fluent/langneg'
import { FluentBundle, FluentResource } from '@fluent/bundle'
import { LocalizationProvider, ReactLocalization } from '@fluent/react'
import { connect } from 'react-redux'
import store from './store'
import User from './api/user'
import { State } from './store/reducers'
import { setAvailableLocales, setLocale } from './store/actions/app'
import Load from './components/Load'

export const MANIFEST = fetch('/locale/manifest.json')
  .then(rsp => rsp.json())
  .then(manifest => {
    store.dispatch(setAvailableLocales(manifest.available.application))
    return manifest
  })

const mapStateToProps = ({ app }: State) => ({ locale: app.locale })

async function loader(
  { locale }: { locale: string[]},
): Promise<{ l10n: ReactLocalization }> {
  const user = await User.me()
  if (user && user.language !== locale[0]) {
    store.dispatch(setLocale([user.language]))
  }

  const manifest = await MANIFEST

  const languages = negotiateLanguages(
    locale,
    manifest.available.application,
    { defaultLocale: manifest.default },
  )
  console.log(languages)
  const bundles = await Promise.all(languages.map(async language => {
    const rsp = await fetch(`/locale/${language}/ui.ftl`)
    const bundle = new FluentBundle(language)
    bundle.addResource(new FluentResource(await rsp.text()))
    return bundle
  }))

  return { l10n: new ReactLocalization(bundles) }
}

interface LocalizationLoaderProps {
  l10n: ReactLocalization
  children?: React.ReactNode
}

const LocalizationLoader = (
  { l10n, children }: LocalizationLoaderProps,
) => <LocalizationProvider l10n={l10n}>
  {children}
</LocalizationProvider>

export default connect(mapStateToProps)(
  Load(loader, ['locale'])<{ children: React.ReactNode }>(LocalizationLoader),
)
