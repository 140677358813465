import {
  NewMessageEventData,
  StateChangedData,
  UserJoinedEventData,
} from "../../api/conversation"
import { LoadingMessagesData } from '../../store/reducers/conversations'

export const isNewMessageEventData = (data: object): data is NewMessageEventData => {
  if (
    (data as NewMessageEventData).userId
    && (data as NewMessageEventData).message
  ) return true
  return false
}

export const isUserJoinedEventData = (data: object): data is UserJoinedEventData => {
  if ((data as UserJoinedEventData).users) return true
  return false
}

export const isStateChangedEventData = (data: object): data is StateChangedData => {
  if (Array.isArray((data as StateChangedData).flags)) return true
  return false
}

export const isLoadingMessagesData = (data: object): data is LoadingMessagesData => {
  if (
    typeof (data as LoadingMessagesData).refId === 'number'
    && (data as LoadingMessagesData).direction
  ) return true
  return false
}
