import * as React from 'react'
import Select from 'react-select'
import { Localized } from '@fluent/react'
import { useSelector } from 'react-redux'
import store from '../../../../../store'
import { State } from '../../../../../store/reducers'
import { setLocale } from '../../../../../store/actions/app'
import { confirmDialog } from '../../../../../helpers'
import data from '../../../../../locale/data'
import Spinner from '../../../../../components/Spinner'

const GeneralSettings = () => {
  const user = useSelector((state: State) => state.user.user)
  const locale = useSelector((state: State) => state.app.locale)

  if (!user) return <Spinner />

  const handleLanguageChange = async (
    { value }: { value: typeof data.languages[0], label: string },
  ) => {
    const res = await confirmDialog({
      title: 'settings-language-dialog-title',
      buttons: {
        cancel: 'settings-language-dialog-cancel',
        confirm: 'settings-language-dialog-confirm',
      },
      showCloseButton: false,
    })

    if (res === 'confirm') {
      changeLanguage(value)
    }
  }

  const changeLanguage = (value: typeof data.languages[0]) => {
    user.changeLanguage(value.code)
    store.dispatch(setLocale([value.code]))
  }

  const language = data.languages.find(lang => lang.code === locale[0])
  console.log('OWOC', language, locale)
  return <div className="settings--subsection">
    <h2 className="settings__title settings__title--large">
      <Localized id="settings-general">
        General settings
      </Localized>
    </h2>
    <h3 className="settings__title">
      <Localized id="settings-section-language">
        Change language
      </Localized>
    </h3>
    <Select
      className="react-select"
      value={language ? { value: language, label: language.name } : null}
      onChange={handleLanguageChange as any}
      options={data.languages.map(lan => ({ value: lan, label: lan.name }))}
      formatOptionLabel={formatOptionLabel}
    />
  </div>
}

export default GeneralSettings

const formatOptionLabel = (option: { label: string, value: any }) => option.label
