import * as React from 'react'
import { Localized } from '@fluent/react'
import { FilesError } from 'react-files'
import { useSelector } from 'react-redux'
import Draft, { DRAFT_CONTENT_EDITING_PERMISSIONS, DraftFile } from '../../api/draft'
import { confirmDialog } from '../../helpers'
import store from '../../store'
import { addAlert } from '../../store/actions/alerts'
import DraftFilePreview from '../../components/DraftFilePreview'
import Spinner from '../../components/Spinner'
import Button from '../../components/ui/Button'
import Dialog from '../../components/ui/Dialog'
import FilesUploader from '../../containers/FilesUploader'

import './index.css'

const parser = new DOMParser()

interface DraftFilesProps {
  draft: Draft
}

const DraftFiles = ({ draft }: DraftFilesProps) => {
  const user = useSelector(state => state.user.user)
  const [isLoading, setIsLoading] = React.useState(true)
  const [draftFiles, setDraftFiles] = React.useState<DraftFile[]>([])
  const [showImportDialog, setShowImportDialog] = React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])
  const [fileToReplace, setFileToReplace] = React.useState<DraftFile>()
  const [isImporting, setIsImporting] = React.useState(false)
  const [showPreview, setShowPreview] = React.useState<DraftFile | null>(null)
  const [draftCnxml, setDraftCnxml] = React.useState<string | null>(null)
  const [filesInCnxml, setFilesInCnxml] = React.useState<Set<string>>(new Set())

  const fetchDraftFiles = async () => {
    const fls = await draft.files(false)
    setDraftFiles(fls)
    setIsLoading(false)
  }

  const fetchDraftCnxml = () => {
    draft.read('index.cnxml')
      .then(res => {
        setDraftCnxml(res)
      })
      .catch(() => setDraftCnxml(null))
  }

  React.useEffect(() => {
    fetchDraftFiles()
    fetchDraftCnxml()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (draftCnxml) {
      const dom = parser.parseFromString(draftCnxml, 'application/xml')
      const newSet: Set<string> = new Set()
      for (const file of draftFiles) {
        if (dom.querySelector(`[src="${file.name}"]`)) {
          newSet.add(file.name)
        }
      }
      setFilesInCnxml(newSet)
    }
  }, [draftFiles, draftCnxml])

  const addFile = () => {
    setShowImportDialog(true)
  }

  const importFile = async () => {
    setIsImporting(true)
    const file = files[0]

    if (fileToReplace) {
      await draft.replaceFile(fileToReplace, file)
        .then(() => {
          store.dispatch(addAlert('success', 'draft-files-import-and-replace-success'))
          fetchDraftFiles()
          setShowPreview(null)
        })
    } else {
      await draft.writeFile(file)
        .then(() => {
          store.dispatch(addAlert('success', 'draft-files-import-success'))
          fetchDraftFiles()
        })
    }

    setIsImporting(false)
    setShowImportDialog(false)
  }

  const onFileClick = (file: DraftFile) => {
    setShowPreview(file)
  }

  const onFileRemove = async (file: DraftFile) => {
    const res = await confirmDialog({
      title: 'draft-files-remove-title',
      vars: { file: file.name },
      size: 'medium',
      buttons: {
        cancel: 'draft-files-button-cancel',
        confirm: 'draft-files-button-remove',
      },
    })

    if (res === 'confirm') {
      await draft.removeFile(file)
      fetchDraftFiles()
    }
  }

  const onFileReplace = (file: DraftFile) => {
    setFileToReplace(file)
    setShowImportDialog(true)
  }

  const closeImportDialog = () => {
    setShowImportDialog(false)
    setFileToReplace(undefined)
  }

  const onFilesChange = (files: File[]) => {
    setFiles(files)
  }

  const onFilesError = (error: FilesError, _: File) => {
    store.dispatch(addAlert('error', 'file-upload-error', { code: error.code }))
  }

  if (isLoading) return <Spinner />

  const hasEditingPermissions = draft.hasPermission(DRAFT_CONTENT_EDITING_PERMISSIONS)
  const hasReplacePermission = draft.hasPermission(DRAFT_CONTENT_EDITING_PERMISSIONS)
    || Boolean(user?.teams.find(
      ut => ut.id === draft.team && ut.allPermissions.has('editing-process:manage'),
    ))

  return (
    <div className="draft-files">
      <div className="draft-files__title">
        <h3>
          <Localized id="draft-files-title">
            Files associated with this draft:
          </Localized>
        </h3>
        {
          hasEditingPermissions
            ? <Button id="draft-files-add" className="draft-files__add-new" clickHandler={addFile}>
              <Localized id="draft-files-add">
                Add file
              </Localized>
            </Button>
            : null
        }
      </div>
      <ul className="draft-files__list">
        {
          draftFiles.map(file => (
            <DraftFilePresentation
              key={file.name}
              draftId={draft.module}
              file={file}
              isInCnxml={filesInCnxml.has(file.name)}
              onClick={onFileClick}
              showRemove={hasEditingPermissions}
              onFileRemove={onFileRemove}
              showReplace={hasReplacePermission}
              onFileReplace={onFileReplace}
            />
          ))
        }
      </ul>
      {
        showPreview
          ? (
            <DraftFilePreview
              draftId={draft.module}
              file={showPreview}
              onClose={() => setShowPreview(null)}
              showRemove={hasEditingPermissions}
              onFileRemove={onFileRemove}
              showReplace={hasReplacePermission}
              onFileReplace={onFileReplace}
            />
          )
          : null
      }
      {
        showImportDialog ?
          <Dialog
            size="medium"
            l10nId={
              fileToReplace
                ? 'draft-files-import-and-replace-title'
                : 'draft-files-import-title'
            }
            vars={{ file: fileToReplace?.name || 'File' }}
            placeholder="Upload audio, image or video file."
            onClose={closeImportDialog}
          >
            {
              isImporting ?
                <Spinner />
                :
                <>
                  <FilesUploader
                    onFilesChange={onFilesChange}
                    onFilesError={onFilesError}
                    accepts={['audio/*', 'image/*', 'video/*']}
                    multiple={false}
                    optional={false}
                  />
                  <div className="dialog__buttons">
                    <Button id="draft-files-button-cancel" clickHandler={closeImportDialog}>
                      <Localized id="draft-files-button-cancel">
                        Cancel
                      </Localized>
                    </Button>
                    <Button id="draft-files-button-confirm" clickHandler={importFile} isDisabled={!files.length}>
                      <Localized id="draft-files-button-confirm">
                        Confirm
                      </Localized>
                    </Button>
                  </div>
                </>
            }
          </Dialog>
          : null
      }
    </div>
  )
}

export default DraftFiles

interface DraftFilePresentationProps {
  draftId: string
  file: DraftFile
  isInCnxml: boolean
  showRemove: boolean
  onClick: (file: DraftFile) => void
  onFileRemove: (file: DraftFile) => void
  showReplace: boolean
  onFileReplace: (file: DraftFile) => void
}

const DraftFilePresentation = ({
  draftId,
  file,
  isInCnxml,
  showRemove,
  onClick,
  onFileRemove,
  showReplace,
  onFileReplace,
}: DraftFilePresentationProps) => {
  const url = `/api/v1/drafts/${draftId}/files/${file.name}`

  return (
    <li className="draft-files__file">
      <span
        className="draft-files__preview"
        onClick={() => onClick(file)}
      >
        {
          /^image/.test(file.mime)
            ? <img className="draft-files__thumb" src={url} alt={file.name} />
            : null
        }
        <span className="draft-files__file-info">
          <span className="draft-files__file-name">
            {file.name}
          </span>
          {
            isInCnxml
              ? <>
                <br/>
                <span className="draft-files__file-usage">
                  <Localized id="draft-files-used-in-draft">
                    (This file is used in the draft)
                  </Localized>
                </span>
              </>
              : null
          }
        </span>
      </span>
      {
        (showRemove || showReplace) && <div className="draft-files__controls">
          {
            showRemove && <Button id="draft-files-button-remove" type="danger" clickHandler={() => onFileRemove(file)}>
              <Localized id="draft-files-button-remove">
                Remove
              </Localized>
            </Button>
          }
          {
            showReplace && <Button id="draft-files-button-replace" clickHandler={() => onFileReplace(file)}>
              <Localized id="draft-files-button-replace">
                Replace
              </Localized>
            </Button>
          }
        </div>
      }
    </li>
  )
}
