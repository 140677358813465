import * as React from 'react'
import LimitedUI from '../../../../../../../components/LimitedUI'
import Button from '../../../../../../../components/ui/Button'

interface EditingButtonsProps {
  show: boolean
  onEdit: () => void
  onRemove: () => void
}

const EditingButtons = ({ show, onEdit, onRemove }: EditingButtonsProps) => {
  if (!show) return null

  return <div className="resource__buttons">
    <LimitedUI permissions="resources:manage">
      <Button
        l10nId="resources-card-edit"
        clickHandler={e => {
          e.preventDefault()
          onEdit()
        }}
      >
        Edit
      </Button>
      <Button
        l10nId="resources-card-remove"
        type="danger"
        clickHandler={e => {
          e.preventDefault()
          onRemove()
        }}
      >
        Remove
      </Button>
    </LimitedUI>
  </div>
}

export default EditingButtons
