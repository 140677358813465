import * as React from 'react'
import { Localized } from '@fluent/react'
import { isValidUrl } from '../../helpers'
import Input from '../../components/ui/Input'
import { XrefTargetOnSelect } from '../XrefTargetSelector'

import './index.css'

interface LinkBoxProps {
  text?: string
  onSelect: XrefTargetOnSelect
}

const LinkBox = ({ text: defaultText = '', onSelect }: LinkBoxProps) => {
  const [url, setUrl] = React.useState('')
  const [text, setText] = React.useState(defaultText)

  const handleUrlChange = (val: string) => {
    // If text wasn't procided then it should be equal to url
    if (!text || text === url) {
      setText(val)
    }
    setUrl(val)
  }

  const handleTextChange = (val: string) => setText(val)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSelect({
      type: 'link',
      url,
      text,
    })
  }

  /**
   * Check if values can be used to create xref, docref or link
   */
  const isSubmitDisabled = React.useMemo(() => {
    if (url && isValidUrl(url) && text) return false
    return true
  }, [url, text])

  return (
    <form className="linkbox" onSubmit={handleSubmit}>
      <label className="linkbox__url">
        <span className="linkbox__label-text">
          <Localized id="link-box-label-link">
            Provide link
          </Localized>
        </span>
        <Input
          type="text"
          value={url}
          onChange={handleUrlChange}
          validator={isValidUrl}
          required
        />
      </label>
      <label className="linkbox__text">
        <span className="linkbox__label-text">
          <Localized id="link-box-label-text">
            Provide text
          </Localized>
        </span>
        <Input
          type="text"
          autoFocus={true}
          value={text}
          onChange={handleTextChange}
          minLength={1}
          required
        />
      </label>
      <div className="dialog__buttons dialog__buttons">
        <Localized id="link-box-submit" attrs={{ value: true }}>
          <input
            className="button"
            type="submit"
            value="Confirm"
            disabled={isSubmitDisabled}
          />
        </Localized>
      </div>
    </form>
  )
}

export default LinkBox
