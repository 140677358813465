import * as React from 'react'
import { Localized } from '@fluent/react'

import './index.css'

interface SpinnerProps {
  l10nId?: string | undefined
  size?: 'small' | 'medium' | 'large'
}

const Spinner = ({ l10nId, size }: SpinnerProps) => (
  <div className="spinner-holder">
    {
      l10nId ?
        <div className="spinner__message">
          <Localized id={l10nId}>
              ...
          </Localized>
        </div>
        : null
    }
    <div className={`spinner spinner--${size || 'large'}`} />
  </div>
)

export default Spinner
