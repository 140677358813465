import * as React from 'react'
import { RenderElementProps, useFocused, useSelected } from 'slate-react'
import { useLoadUser } from '../../../../api/hooks'
import { Mention as MentionInterface } from '../InputEditor/plugins'

import './index.css'

export interface MentionProps extends RenderElementProps {
  element: MentionInterface
}

/**
 * Component for displaying Mention in the Slate editor.
 */
const Mention = (props: MentionProps) => {
  const isSelected = useSelected()
  const isFocused = useFocused()

  const propsToPass = {
    ...props,
    isFocused,
    isSelected,
  }

  return <MentionDisplay {...propsToPass} userId={props.element.userId} />
}

export default Mention

interface MentionDisplayProps {
  userId: number
  attributes?: { [key: string]: any }
  isSelected?: boolean
  isFocused?: boolean
  children?: Node[]
}

/**
 * Component for displaying Mention in the Slate editor and in the messages lists.
 */
export const MentionDisplay = (
  { attributes, userId, isSelected, isFocused, children }: MentionDisplayProps,
) => {
  const [user] = useLoadUser(userId)
  const href = user ? `/users/${user.id}` : '#'

  return (
    <a
      {...attributes}
      contentEditable={false}
      className={`mention ${isSelected && isFocused ? 'mention--focused' : ''}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      @{user ? user.name : 'Unknown user'}
      {children}
    </a>
  )
}
