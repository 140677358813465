import * as React from 'react'
import { DocumentReference } from 'cnx-designer'
import { FluentVariable } from '@fluent/bundle'
import { RenderElementProps } from 'slate-react'
import { INVALID_REFERENCE_CLASS, LOCALIZATION } from '../../constants'
import { useLoadModule } from '../../../../../api/hooks'

interface DocrefProps extends RenderElementProps {
  element: DocumentReference
}

const Docref = ({ children, attributes, element }: DocrefProps) => {
  const [mod, isLoading] = useLoadModule(element.document)
  const href = mod ? `/modules/${mod.id}` : undefined
  const args = { case: element.case || 'nominative' }
  const { document } = React.useContext(LOCALIZATION)

  const foreWord = document.getString('docref-label', args as Record<string, FluentVariable>)
  // Slate will disable clicks on <a> which are not void for default.
  const onClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (ev.ctrlKey && element.document) {
      window.open(`/modules/${element.document}`, '_blank')
    }
  }

  const isValidReference = Boolean(isLoading || href)

  return (
    <span contentEditable={false}>
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        title={mod?.title}
        onClick={onClick}
        className={isValidReference ? '' : INVALID_REFERENCE_CLASS}
        {...attributes}
      >
        {children}
        {foreWord} {mod?.title}
      </a>
    </span>
  )
}

export default Docref
