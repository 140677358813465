import { Localized } from '@fluent/react'
import { Link } from '../../../../../../../api/process'
import Dialog from '../../../../../../../components/ui/Dialog'
import Button from '../../../../../../../components/ui/Button'
import Spinner from '../../../../../../../components/Spinner'

interface ConfirmDialogProps {
  isAdvancing: boolean
  link: Link
  unsavedChanges: boolean
  onClose: () => void
  onNextStep: (link: Link) => void
  onSaveAndAdvance: (link: Link) => void
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <Dialog
      size="medium"
      l10nId="step-changer-confirm-dialog-title"
      onClose={props.onClose}
      showCloseButton={false}
    >
      {props.isAdvancing && <div className="step-changer__overlay"><Spinner size="medium" /></div>}
      {
        props.unsavedChanges ?
          <>
            <p className="step-changer__info">
              <Localized id="step-changer-unsaved-changes">
                You have unsaved changes.
              </Localized>
            </p>
            <div className="dialog__buttons">
              <Button l10nId="step-changer-cancel" isDisabled={props.isAdvancing} clickHandler={props.onClose}>
                Cancel
              </Button>
              <Button
                l10nId="step-changer-discard-advance"
                isDisabled={props.isAdvancing}
                clickHandler={() => props.onNextStep(props.link)}
              >
                Discard changes and advance
              </Button>
              <Button
                l10nId="step-changer-save-advance"
                isDisabled={props.isAdvancing}
                clickHandler={() => props.onSaveAndAdvance(props.link)}
              >
                Save and advance
              </Button>
            </div>
          </>
          :
          <div className="dialog__buttons">
            <Button
              l10nId="step-changer-cancel"
              isDisabled={props.isAdvancing}
              clickHandler={props.onClose}
            >
              Cancel
            </Button>
            <Button
              l10nId="step-changer-advance"
              isDisabled={props.isAdvancing}
              clickHandler={() => props.onNextStep(props.link)}
            >
              Advance
            </Button>
          </div>
      }
    </Dialog>
  )
}

export default ConfirmDialog
