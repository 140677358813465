import * as React from 'react'
import { Localized } from '@fluent/react'
import { Draft, Process } from '../../api'
import { ProcessDetails } from '../../api/draft'
import { ProcessSingleStep, SingleStepSlot } from '../../api/process'
import store from '../../store'
import { addAlert } from '../../store/actions/alerts'
import ConfigureSlots from './ConfigureSlots'
import Button from '../../components/ui/Button'
import ProcessSelector from '../../components/ProcessSelector'
import StepSelector from '../../components/StepSelector'
import './index.css'

interface ChangeProcessProps {
  draft: Draft | string
  onChange: () => void
}

export default function ChangeProcess({ onChange, draft: draftProp }: ChangeProcessProps) {
  const [draft, setDraft] = React.useState<Draft | null>(null)
  const [sourceProcess, setSourceProcess] = React.useState<ProcessDetails | null>(null)
  const [targetProcess, setTargetProcess] = React.useState<Process | null>(null)
  const [targetStep, setTargetStep] = React.useState<ProcessSingleStep | null>(null)
  const [slotMap, setSlotMap] = React.useState<Map<number, number | null>>(new Map())

  const filterProcess = React.useCallback(
    process => process.id !== sourceProcess?.id,
    [sourceProcess],
  )

  const changeProcess = React.useCallback(async () => {
    if (draft == null || targetProcess == null || targetStep == null) {
      return
    }

    try {
      await draft.changeProcess(targetProcess.id, targetStep.id, slotMap)
      store.dispatch(addAlert('success', 'change-process-success', {
        process: targetProcess.name,
        draft: draft.title,
      }))
      onChange()
    } catch (ex) {
      store.dispatch(addAlert('error', 'change-process-error', { draft: draft.title }))
    }
  }, [draft, slotMap, targetProcess, targetStep, onChange])

  const onChangeProcess = (option: { value: Process } | null) => {
    setTargetProcess(option ? option.value : null)
    setTargetStep(null)
  }

  React.useEffect(() => {
    let draftId

    if (draftProp instanceof Draft) {
      draftId = draftProp.module
      setDraft(draftProp)
    } else {
      draftId = draftProp
      Draft.load(draftId)
        .then(setDraft)
        .catch(e => store.dispatch(addAlert('error', e.response.data.message)))
    }

    Draft.details(draftId).then(setSourceProcess)

    return () => setDraft(null)
  }, [draftProp])

  const currentStepIsInSuggestionMode = React.useMemo(
    () => Boolean(draft && draft.step?.slots.some(isInSuggestionMode)),
    [draft])

  const stepFilter = React.useCallback((step: ProcessSingleStep) => {
    // Target step can't be final step
    if (isFinal(step)) return false
    // If current step is in suggestion mode then target step also has to be in suggestion mode
    if (currentStepIsInSuggestionMode && !step.slots.some(isInSuggestionMode)) return false
    return true
  }, [currentStepIsInSuggestionMode])

  if (draft == null || sourceProcess == null) {
    return null
  }

  if (draft.validation_messages) {
    return <div className="change-process-validation-error">
      <Localized id="change-process-validation-error">
        This document has validation errors. Please resolve them before changing process.
      </Localized>
    </div>
  }

  return (
    <div className="change-process">
      {
        currentStepIsInSuggestionMode && <div className="change-process-info">
          <Localized id="change-process-suggestion-mode">
            Current step is in suggestion mode so target step
            have to have slot with propose or accept changes permission
          </Localized>
        </div>
      }
      <ProcessSelector
        team={draft.team}
        filter={filterProcess}
        isClearable={false}
        onChange={onChangeProcess}
      />
      <StepSelector
        process={targetProcess}
        filter={stepFilter}
        onChange={setTargetStep}
        isClearable={false}
        isDisabled={targetProcess == null}
      />
      {
        targetProcess && targetStep && (
          <ConfigureSlots
            slots={sourceProcess.slots}
            process={targetProcess}
            onChange={setSlotMap}
          />
        )
      }
      <Button
        l10nId="change-process-change"
        clickHandler={changeProcess}
        isDisabled={targetProcess == null || targetStep == null}
      >
        Change process
      </Button>
    </div>
  )
}

function isFinal(step: ProcessSingleStep): boolean {
  return step.links.length === 0
}

function isInSuggestionMode(slot: SingleStepSlot): boolean {
  return slot.permissions.includes('accept-changes') || slot.permissions.includes('propose-changes')
}
