import * as React from 'react'
import { useSelector } from 'react-redux'
import { ConversationTabData } from '../../store/types'
import ConversationTab from '../../components/ConversationTab'
import Icon from '../../components/ui/Icon'

import './index.css'

const ConversationTabs = () => {
  const [{
    hiddenTabs,
    visibleTabs,
  }, setTabs] = React.useState<{
    hiddenTabs: ConversationTabData[]
    visibleTabs: ConversationTabData[]
  }>({ hiddenTabs: [], visibleTabs: [] })
  const {
    sockets,
    tabs,
  } = useSelector(state => state.conversations)

  React.useEffect(() => {
    let hTabs: ConversationTabData[] = hiddenTabs.filter(t => tabs.has(t.convId))
    const vTabs: ConversationTabData[] = visibleTabs.filter(t => tabs.has(t.convId))

    for (const tab of Array.from(tabs.values()).reverse()) {
      // If tab were in visible tabs earlier then keep it there
      if (vTabs.find(t => t.convId === tab.convId)) continue

      // First three tabs should be always visible
      if (vTabs.length < 3) {
        vTabs.push(tab)
        // After closing tab, new tab from hidden tabs will be moved
        // to vTabs so filter hTabs againts duplicates
        hTabs = hTabs.filter(t => t.convId !== tab.convId)
        continue
      }

      // If tab were added to hiddenTabs earlier then keep it there
      if (hTabs.find(t => t.convId === tab.convId)) continue

      // New tab was added. Replace last of visible tabs with this tab
      const last = vTabs.pop()!
      hTabs.push(last)
      vTabs.push(tab)
    }

    setTabs({ hiddenTabs: hTabs, visibleTabs: vTabs })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs])

  const onHiddenTabClick = (tab: ConversationTabData) => {
    setTabs(state => {
      const filteredHiddenTabs = state.hiddenTabs.filter(t => t.convId !== tab.convId)
      const newVisibleTabs = [...state.visibleTabs]

      if (state.visibleTabs.length < 3) {
        newVisibleTabs.push(tab)
      } else {
        const last = newVisibleTabs.pop()!
        filteredHiddenTabs.push(last)
        newVisibleTabs.push(tab)
      }

      return {
        hiddenTabs: filteredHiddenTabs,
        visibleTabs: newVisibleTabs,
      }
    })
  }

  return (
    <div className="conversation-tabs">
      {
        visibleTabs.map(tab => {
          const conversation = sockets.get(tab.convId)
          return (
            conversation &&
            <ConversationTab
              key={tab.convId}
              conversation={conversation}
              openAtStart={tab.openAtStart}
              title={tab.title}
            />
          )
        })
      }
      {
        hiddenTabs.filter(tab => sockets.has(tab.convId)).length
          ? <HiddenTabs tabs={hiddenTabs} onTabClick={onHiddenTabClick} />
          : null
      }
    </div>
  )
}

export default ConversationTabs

interface HiddenTabsProps {
  tabs: ConversationTabData[]
  onTabClick: (tab: ConversationTabData) => void
}
const HiddenTabs = ({ tabs, onTabClick }: HiddenTabsProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleOpen = () => {
    setIsOpen(open => !open)
  }

  const handleTabClick = (ev: React.MouseEvent<HTMLSpanElement>) => {
    onTabClick(tabs[Number(ev.currentTarget.dataset.index!)])
    setIsOpen(false)
  }

  React.useEffect(() => {
    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('click', clickOutside)
    }
  }, [])

  const clickOutside = (ev: MouseEvent) => {
    if (
      hiddenTabs.current &&
      !hiddenTabs.current.contains(ev.target as HTMLElement)
    ) {
      setIsOpen(false)
    }
  }

  const hiddenTabs = React.useRef<HTMLDivElement>(null)

  return (
    <div ref={hiddenTabs} className="hidden-tabs">
      {
        isOpen
          ?
          <ul className="hidden-tabs__list">
            {
              tabs.map((tab, i) => (
                <span
                  key={tab.convId}
                  id="conversation-tab-close"
                  className="hidden-tabs__tab"
                  data-index={i}
                  onClick={handleTabClick}
                >
                  {tab.title}
                </span>
              ))
            }
          </ul>
          : null
      }
      <span id="conversation-tab-open" className="hidden-tabs__toggler" onClick={toggleOpen}>
        <Icon name="chat"/>
      </span>
    </div>
  )
}
