import { RenderOutlineElementProps } from 'document-outline'
import * as CNXML from 'slate-cnxml'
import {
  Admonition, AltText, Audio, Caption, CnxEditor, Code, Commentary,
  CrossReference, Definition, DefinitionExample, DocumentReference, Exercise, Figure, Footnote,
  Foreign, Glossary, IdEditor, Image, Link, List, Meaning,
  onKeyDown as onKeyDownCnx, Paragraph, Preformat, Problem, ProcessingInstruction,
  Proof, Quotation, Rule, RuleExample, Section,
  SeeAlso, Solution, Statement, Title, Video, withCnx, withIds,
} from 'cnx-designer'
import { createEditor as createSlateEditor, Editor, Node, Transforms } from 'slate'
import { HistoryEditor, withHistory } from 'slate-history'
import { ReactEditor, RenderElementProps, RenderLeafProps, withReact } from 'slate-react'
import { DocumentDB, PersistingEditor, withPersistence } from 'slate-persistence'
import {
  Marker, Suggestion,
  SuggestionsEditor, withSuggestions,
} from 'slate-suggestions'
import { ListEditor, ListEditorOptions, ListItem, onKeyDown as onListKeyDown, withLists } from 'slate-lists'
import GlossaryComp from '../components/Glossary'
import AdmonitionComp from '../components/Admonition'
import AltTextComp from '../components/AltText'
import CaptionComp from '../components/Caption'
import DefinitionComp from '../components/Definition'
import DefinitionExampleComp from '../components/DefinitionExample'
import DefinitionSeeAlsoComp from '../components/DefinitionSeeAlso'
import Docref from '../components/Docref'
import ExerciseComp from '../components/Exercise'
import ExerciseCommentaryComp from '../components/ExerciseCommentary'
import ExerciseProblemComp from '../components/ExerciseProblem'
import ExerciseSolutionComp from '../components/ExerciseSolution'
import FigureComp from '../components/Figure'
import ForeignComp from '../components/Foreign'
import RuleComp from '../components/Rule'
import ListComp from '../components/List'
import RuleStatementComp from '../components/RuleStatement'
import RuleProofComp from '../components/RuleProof'
import RuleExampleComp from '../components/RuleExample'
import QuotationComp from '../components/Quotation'
import onKeyDownShortcut from './Shortcuts'
import onKeyDownQuotationMark from './QuotationMarks'
import FootnoteComp from './Footnotes'
import { onKeyDown as onKeyDownFootnote } from './Footnotes/handlers'
import { deserialize as deserializeMath, Equation, Math, withMath } from './Math'
import EquationComp from './Math/Equation'
import MathComp from './Math/Math'
import MediaTextComp, { deserializeMediaText, MediaText, normalizeMediaText } from './MediaText'
import { deserializeHighlight, Highlight, normalizeHighlight } from './Highlights'
import HighlightComp from './Highlights/Highlight'
import { onKeyDown as onKeyDownProcessingInstruction } from './ProcessingInstruction/handlers'
import SourceElementComp, {
  deserializeSourceElement,
  normalizeSourceElement,
  SourceElement,
} from './SourceElements'
import { HiddenSuggestionsEditor, withDeserializeSuggestions, withHiddenSuggestions } from './Suggestions'
import XrefComp from './Xref'
import TableNodeComp, { deserializeTable, onKeyDown as onKeyDownTables, Table } from './Tables'
import './cnxml'
import { DraftFile } from '../../../../api/draft'
import ImageComp from '../components/Image'
import ExpandableOutlineElement from '../components/Outline/components/ExpandableOutlineElement'
import OutlineElement from '../components/Outline/components/OutlineElement'
import Icon from '../../../../components/ui/Icon'

const LIST_OPTIONS: Partial<ListEditorOptions> = {
  isSpecialListItem: node => Marker.is(node),
}

export type FullEditor =
  HistoryEditor & SuggestionsEditor & ListEditor & ReactEditor & CnxEditor & IdEditor

export function createEditor(documentDB: DocumentDB): FullEditor & PersistingEditor & HiddenSuggestionsEditor
export function createEditor(documentDB: null): FullEditor & HiddenSuggestionsEditor

export function createEditor(documentDB: DocumentDB | null) {
  // withPersistence must be the very first plugin, otherwise restoration may
  // not work properly
  const persistingEditor = documentDB != null
    ? withPersistence(documentDB, createSlateEditor())
    : createSlateEditor()

  return withHiddenSuggestions(withAllPlugins(persistingEditor))
}

export function withAllPlugins<T extends Editor>(baseEditor: T): T & FullEditor {
  // withIds must be below withBaseSuggestions, otherwise when suggesting a
  // remove withIds will observe the element removed, and consider its ID freed.
  const coreEditor = withSuggestions(withIds(withHistory(baseEditor)))

  return withPrivatePlugins(withCnx(withReact(withLists(LIST_OPTIONS, coreEditor))))
}

export const withPluginsDeserialize =
  (filesList: DraftFile[]) => <T extends CNXML.DeserializingEditor>(baseEditor: T): T => {
    const editor = withPrivatePlugins(withCnx(withLists(LIST_OPTIONS, withIds(baseEditor))))

    const { reportError, finalize, deserializeElement, isInline, isVoid } = editor
    const deserializationErrors: string[] = []

    editor.reportError = (type, description) => {
      reportError(type, description)
      deserializationErrors.push(type)
    }

    editor.finalize = () => {
      const doc = finalize()
      doc.deserializationErrors = deserializationErrors
      return doc
    }

    editor.deserializeElement = (el, at, context) => {
      deserializeMediaText(editor, el, at)
      || deserializeHighlight(editor, el, at)
      || deserializeSourceElement(editor, el, at)
      || deserializeTable(editor, el, at)
      || deserializeMath(editor, el, at)
      || deserializeElement(el, at, context)

      if (el.localName === 'image') {
        const src = el.getAttribute('src')

        if (!filesList.some(({ name }) => name === src)) {
          editor.reportError('missing-file', { src })
          // The original src of the image will be replaced with replacementSrc
          Transforms.setNodes(editor, { replacementSrc: '/images/missing-image.jpg' }, { at })
        }
      }
    }

    // Taken from withSuggestions. We cannot apply suggestions plugin directly,
    // as it rejects certain operations during deserialization
    editor.isVoid = e => Marker.is(e) || isVoid(e)
    editor.isInline = e => (Marker.is(e) && e.inline) || isInline(e)

    return withDeserializeSuggestions(editor)
  }

function withPrivatePlugins<T extends CnxEditor>(baseEditor: T): T {
  const editor = withMath(baseEditor)
  const { normalizeNode, isInline, isVoid } = editor

  editor.normalizeNode = entry => normalizeMediaText(editor, entry)
    || normalizeHighlight(editor, entry)
    || normalizeSourceElement(editor, entry)
    || normalizeNode(entry)

  editor.isInline = element => Highlight.isHighlight(element)
    || (SourceElement.isSourceElement(element) && element.placement === 'line')
    || (Math.isMath(element) && element.placement === 'line')
    || isInline(element)

  editor.isVoid = element => Math.isMath(element)
    || isVoid(element)

  return editor
}

interface Options {
  mediaUrl: (src: string) => string
}

export const renderElement = (props: RenderElementProps, editor: Editor, options: Options) => {
  const { mediaUrl = (src: string) => src } = options

  if (Suggestion.is(props.element)) {
    props.attributes['data-suggestion'] = props.element.suggestion
  }

  if (Admonition.isAdmonition(props.element)) {
    return <AdmonitionComp {...props} element={props.element} />
  } else if (AltText.isAltText(props.element)) {
    return <AltTextComp {...props} element={props.element} />
  } else if (Audio.isAudio(props.element)) {
    return (
      <audio
        {...props.attributes}
        src={mediaUrl(props.element.src)}
        id={props.element.id as string | undefined}
      />
    )
  } else if (Caption.isCaption(props.element)) {
    return <CaptionComp {...props} element={props.element} />
  } else if (SourceElement.isSourceElement(props.element)) {
    return <SourceElementComp {...props} element={props.element} />
  } else if (Code.isCodeLine(props.element)) {
    return (
      <code
        {...props.attributes}
        id={props.element.id as string | undefined}
      >
        {props.children}
      </code>
    )
  } else if (Code.isCodeBlock(props.element)) {
    return (
      <pre
        {...props.attributes}
        id={props.element.id as string | undefined}
      >
        {props.children}
      </pre>
    )
  } else if (Definition.isDefinition(props.element)) {
    return <DefinitionComp {...props} element={props.element} />
  } else if (DefinitionExample.isDefinitionExample(props.element)) {
    return <DefinitionExampleComp {...props} element={props.element} />
  } else if (SeeAlso.isSeeAlso(props.element)) {
    return <DefinitionSeeAlsoComp {...props} element={props.element} />
  } else if (CrossReference.isCrossReference(props.element)) {
    return <XrefComp {...props} element={props.element} />
  } else if (DocumentReference.isDocumentReference(props.element)) {
    return <Docref {...props} element={props.element} />
  } else if (Exercise.isExercise(props.element)) {
    return <ExerciseComp {...props} element={props.element} />
  } else if (Problem.isProblem(props.element)) {
    return <ExerciseProblemComp {...props} element={props.element} />
  } else if (Solution.isSolution(props.element)) {
    return <ExerciseSolutionComp {...props} element={props.element} />
  } else if (Commentary.isCommentary(props.element)) {
    return <ExerciseCommentaryComp {...props} element={props.element} />
  } else if (Figure.isFigure(props.element)) {
    return <FigureComp {...props} element={props.element} />
  } else if (Foreign.isForeign(props.element)) {
    return <ForeignComp {...props} element={props.element} />
  } else if (Footnote.isFootnote(props.element)) {
    return <FootnoteComp {...props} element={props.element} />
  } else if (Glossary.isGlossary(props.element)) {
    return (<GlossaryComp {...props} element={props.element} />)
  } else if (Highlight.isHighlight(props.element)) {
    return <HighlightComp {...props} element={props.element} />
  } else if (Image.isImage(props.element)) {
    return <ImageComp {...props} element={props.element} mediaUrl={mediaUrl}/>
  } else if (List.isList(props.element)) {
    return <ListComp {...props} element={props.element} />
  } else if (ListItem.isListItem(props.element)) {
    return <li {...props.attributes}>{props.children}</li>
  } else if (Meaning.isMeaning(props.element)) {
    return (
      <div
        className="definition-meaning"
        {...props.attributes}
        id={props.element.id as string | undefined}
      >
        {props.children}
      </div>
    )
  } else if (MediaText.isMediaText(props.element)) {
    return <MediaTextComp {...props} element={props.element} />
  } else if (Paragraph.isParagraph(props.element)) {
    return <p {...props.attributes} id={props.element.id as string | undefined}>{props.children}</p>
  } else if (ProcessingInstruction.isProcessingInstruction(props.element)) {
    return <div className="pi" {...props.attributes} contentEditable={false} style={{ display: 'none' }}>
      {`<?${props.element.target} ${props.element.value}?>`}
    </div>
  } else if (Preformat.isPreformat(props.element)) {
    return (
      <pre
        {...props.attributes}
        id={props.element.id as string | undefined}
      >
        {props.children}
      </pre>
    )
  } else if (Rule.isRule(props.element)) {
    return <RuleComp {...props} element={props.element} />
  } else if (Statement.isStatement(props.element)) {
    return <RuleStatementComp {...props} element={props.element} />
  } else if (Proof.isProof(props.element)) {
    return <RuleProofComp {...props} element={props.element} />
  } else if (RuleExample.isRuleExample(props.element)) {
    return <RuleExampleComp {...props} element={props.element} />
  } else if (Section.isSection(props.element)) {
    return (
      <section
        {...props.attributes}
        id={props.element.id as string | undefined}
      >
        {props.children}
      </section>
    )
  } else if (
    Table.isTable(props.element)
    || Table.isTableCaption(props.element)
    || Table.isTableColspec(props.element)
    || Table.isTableEntry(props.element)
    || Table.isTableRow(props.element)
    || Table.isTableSpanspec(props.element)
    || Table.isTableTbody(props.element)
    || Table.isTableTfoot(props.element)
    || Table.isTableTgroup(props.element)
    || Table.isTableThead(props.element)
    || Table.isTableTitle(props.element)
  ) {
    return <TableNodeComp {...props} element={props.element} />
  } else if (Title.isTitle(props.element)) {
    return (
      <h2
        className="title"
        {...props.attributes}
        id={props.element.id as string | undefined}
      >
        {props.children}
      </h2>
    )
  } else if (Quotation.isQuotation(props.element)) {
    return <QuotationComp {...props} element={props.element} />
  } else if (Link.isLink(props.element)) {
    return (
      <a
        href={props.element.url}
        {...props.attributes}
      >
        {props.children}
      </a>
    )
  } else if (Video.isVideo(props.element)) {
    return (
      <video
        {...props.attributes}
        src={mediaUrl(props.element.src)}
        id={props.element.id as string | undefined}
      />
    )
  } else if (Equation.isEquation(props.element)) {
    return <EquationComp {...props} element={props.element} />
  } else if (Math.isMath(props.element)) {
    return <MathComp {...props} element={props.element} />
  }

  if (Editor.isInline(editor, props.element)) {
    // We are adding an empty span with contenteditable=false to unify selection behavior across Chrome and Firefox
    // https://github.com/openstax-poland/adaptarr-front/issues/502
    return (
      <span
        className={(props.element.type as string).replace(/_/g, '-')}
        {...props.attributes}
      >
        <span contentEditable={false} />
        {props.children}
      </span>
    )
  }

  return <div className={(props.element!.type as string).replace(/_/g, '-')} {...props.attributes}>
    {props.children}
  </div>
}

export const renderLeaf = ({ attributes, leaf, children }: RenderLeafProps) => {
  if (leaf.emphasis) {
    children = <em {...attributes}>{children}</em>
  }

  if (leaf.strong) {
    children = <strong {...attributes}>{children}</strong>
  }

  if (leaf.underline) {
    children = <u {...attributes}>{children}</u>
  }

  if ('suggestion' in leaf) {
    attributes['data-suggestion'] = leaf.suggestion
  }

  // if ('suggestion' in leaf) {
  //   children = <span className={`suggestion suggestion--${leaf.suggestion}`}>{children}</span>
  // }

  if (leaf.position === 'superscript') {
    return <sup {...attributes}>{children}</sup>
  } else if (leaf.position === 'subscript') {
    return <sub {...attributes}>{children}</sub>
  }

  return <span {...attributes}>{children}</span>
}

export const renderOutlineElement = (props: RenderOutlineElementProps, editor: Editor) => {
  const renderLine = () => {
    return (
      <div className="OutlineLineContainer">
        <div className="OutlineLineContainer--upper"/>
        <div className="OutlineLineContainer--lower"/>
      </div>
    )
  }
  if (Section.isSection(props.element)) {
    const title = props.element.children.find((child: any) => Title.isTitle(child))
    return (
      <div className="lineSection-title">
        {renderLine()}
        <ExpandableOutlineElement className="section" id={props.element.id as string | undefined}>
          {title
          // since there is no title id in cnxml, for scrolling id is the same as in section,
            ? <OutlineElement className="section__title" id={props.element.id as string | undefined}>
              {Node.string(title)}
            </OutlineElement>
            : null}
          {props.children}
        </ExpandableOutlineElement>
      </div>

    )
  } else if (Figure.isFigure(props.element)) {
    return (
      <div className="lineSection">
        {renderLine()}
        <OutlineElement
          className="figure"
          id={props.element.id as string | undefined}
          icon={<Icon size="extra-small" name="image"/>}
        />
      </div>
    )
  } else if (Admonition.isAdmonition(props.element)) {
    const title = props.element.children.find((child: any) => Title.isTitle(child))

    return (
      <div className="lineSection-admonition">
        {renderLine()}
        <ExpandableOutlineElement
          className="admonition"
          id={props.element.id as string | undefined}
          icon={<Icon size="extra-small" name="sticky-note"/>}
        >
          {title
            ?<OutlineElement className="admonition__title" id={title.id as string | undefined}>
              {Node.string(title)}
            </OutlineElement>
            : null}
          {props.children}
        </ExpandableOutlineElement>
      </div>
    )
  } else if (Rule.isRule(props.element)) {
    const kind = props.element.kind
    return (
      <div className="lineSection">
        {renderLine()}
        <OutlineElement
          className="rule"
          id={props.element.id as string | undefined}
          icon={<Icon size="extra-small" name="rule"/>}
        >
          <span className="rule__kind">{`(${kind})`}</span>
        </OutlineElement>
      </div>
    )
  } else if (Exercise.isExercise(props.element)) {
    return (
      <div className="lineSection">
        {renderLine()}
        <OutlineElement
          className="exercise"
          id={props.element.id as string | undefined}
          icon={<Icon size="extra-small" name="flask"/>}
        />
      </div>
    )
  } else if (
    Paragraph.isParagraph(props.element)
    || Quotation.isQuotation(props.element)
    || Code.isCodeBlock(props.element)
    || Preformat.isPreformat(props.element)
  ) {
    return (
      props.element.id
        ? <div className="lineSection">
          {renderLine()}
          <OutlineElement className="text" id={props.element.id as string | undefined}>
            {Node.string(props.element)}
          </OutlineElement>
        </div>
        : null
    )
    // return (

    //     <div className="lineSection">
    //       {renderLine()}
    //       <OutlineElement className="text" id={props.element.id as string | undefined}>
    //         {Node.string(props.element)}
    //       </OutlineElement>
    //     </div>

    // )
  }

  return null
}

export const onKeyDown = (editor: Editor, ev: KeyboardEvent) => {
  onKeyDownShortcut(editor, ev)
  onKeyDownFootnote(editor, ev)
  onKeyDownCnx(editor, ev)
  onKeyDownProcessingInstruction(editor, ev)
  onListKeyDown(editor, ev)
  onKeyDownTables(editor, ev)
  onKeyDownQuotationMark(editor, ev)
}
