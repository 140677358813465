import { Footnote } from 'cnx-designer'
import { Editor, Transforms } from 'slate'

export function onKeyDown(editor: Editor, event: KeyboardEvent) {
  const node = Editor.above(editor, { match: Footnote.isFootnote })
  if (!node) return false

  const [footnote, path] = node

  if (!footnote.collapse) return false

  switch (event.key) {
  case 'ArrowLeft': {
    const before = Editor.before(editor, path)
    if (before) {
      Transforms.select(editor, before)
    }
    return true
  }

  case 'ArrowRight': {
    const after = Editor.after(editor, path)
    if (after) {
      Transforms.select(editor, after)
    }
    return true
  }

  default:
    return false
  }
}
