import * as React from 'react'
import { Localized } from '@fluent/react'
import { Title } from 'cnx-designer'
import { Node } from 'slate'
import * as api from '../../api'
import { ReferenceTarget as RefTarget } from '../../store/types'
import { renderXref } from '../../screens/app/Draft/plugins/Xref'
import { LOCALIZATION } from '../../screens/app/Draft/constants'
import { XrefTargetOnSelect, XrefTargetReference } from '../../containers/XrefTargetSelector'

import './index.css'

interface ReferenceTargetProps {
  /**
   * Module in which this reference target is located.
   */
  module: api.Module | null,
  /**
   * Details about the reference target itself.
   */
  target: RefTarget,
  /**
   * Details about target's context.
   */
  context?: RefTarget,
  /**
   * Function to call when user clicks on this component.
   */
  onSelect: XrefTargetOnSelect
}

/**
 * Component rendering a single (possibly compound) reference target.
 */
function ReferenceTarget({ target, module, onSelect }: ReferenceTargetProps) {
  const { document } = React.useContext(LOCALIZATION)
  const label = renderXref(document, { key: target.id, type: target.type }, target.counters)

  const onClick = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    const data: XrefTargetReference = {
      type: 'xref',
      target,
    }
    if (module) {
      data.source = module
    }
    onSelect(data)
  }

  return (
    <div id="reference-target" className="target" onClick={onClick}>
      <span className="target__description">
        {Title.isTitle(target.description) ?
          Node.string(target.description) :
          <Localized
            id={target.description ? 'reference-target-description' : 'reference-target'}
            vars={{
              label,
              description: target.description || '',
            }}
          >
            {target.description
              ? `${label}: ${target.description}`
              : label}
          </Localized>}
      </span>
      {target.children.length ?
        <ul className="target__nestedList">
          {target.children.map(child => (
            <li key={child.id} className="target__nestedItem">
              <ReferenceTarget
                module={module}
                target={child}
                context={target}
                onSelect={onSelect}
              />
            </li>
          ))}
        </ul>
        : null}
    </div>
  )
}

export default React.memo(ReferenceTarget)
