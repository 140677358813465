import * as React from 'react'
import { Localized } from '@fluent/react'
import { useDispatch, useSelector } from 'react-redux'
import { Role, TeamMember, User } from '../../../../../api'
import { UserTeam } from '../../../../../api/user'
import { addAlert } from '../../../../../store/actions/alerts'
import { confirmDialog } from '../../../../../helpers'
import RoleSelector from '../../../../../components/RoleSelector'

import './index.css'

interface UserTeamsAndRolesProps {
  user: User
}

const UserTeamsAndRoles = ({ user }: UserTeamsAndRolesProps) => {
  const currentUser = useSelector(state => state.user.user)
  const dispatch = useDispatch()

  if (!currentUser) return null

  const handleRoleChange = async (option: { value: Role } | null, team: UserTeam) => {
    const res = option
      ? await confirmDialog({
        title: 'user-profile-role-change',
        vars: { role: option.value.name },
        buttons: {
          cancel: 'user-profile-role-button-cancel',
          confirm: 'user-profile-role-button-change',
        },
        showCloseButton: false,
      })
      : await confirmDialog({
        title: 'user-profile-role-remove',
        buttons: {
          cancel: 'user-profile-role-button-cancel',
          confirm: 'user-profile-role-button-remove',
        },
        showCloseButton: false,
      })

    if (res === 'confirm') {
      changeRole(team.id, option ? option.value : null)
    }
  }

  const changeRole = (team: number, newRole: Role | null) => {
    const member = new TeamMember({ user: user.id, permissions: [], role: newRole }, team)
    member.update({ role: newRole ? newRole.id : null })
      .then(() => {
        if (newRole) {
          dispatch(addAlert('success', 'user-profile-change-role-success', {
            name: newRole.name,
          }))
        } else {
          dispatch(addAlert('success', 'user-profile-unassign-role-success'))
        }
        user.teams.find(t => t.id === team)!.role = newRole
      })
      .catch(e => {
        if (newRole) {
          dispatch(addAlert('error', 'user-profile-change-role-error', {
            details: e.response.data.error,
          }))
        } else {
          dispatch(addAlert('error', 'user-profile-unassign-role-error', {
            details: e.response.data.error,
          }))
        }
      })
  }

  return <div className="user-teams-and-roles">
    {
      user.teams.map(team => (
        <div key={team.id} className="user-teams-and-roles__team">
          <span className="user-teams-and-roles__name">
            {team.name}
          </span>
          <span className="user-teams-and-roles__role">
            {
              currentUser.is_super || currentUser.hasPermissionsInTeam('role:edit', team.id)
                ?
                <RoleSelector
                  team={team.id}
                  className="react-select"
                  isClearable={true}
                  value={team.role ? { value: team.role, label: team.role.name } : null}
                  onChange={(option: { value: Role } | null) => handleRoleChange(option, team)}
                />
                : team.role
                  ? team.role.name
                  : <Localized id="user-profile-no-role-in-team">
                    User does not have role in this team
                  </Localized>
            }
          </span>
        </div>
      ))
    }
  </div>
}

export default UserTeamsAndRoles
