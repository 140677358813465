import * as React from 'react'
import { Localized } from '@fluent/react'
import { match, NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { Location } from 'history'
import { Ticket } from '../../api'
import store from '../../store'
import { addConversationTab } from '../../store/actions/conversations'
import { ROUTE_TEAMS_PERMISSIONS } from '../../App'
import LimitedUI from '../../components/LimitedUI'
import TeamSwitcher from '../../components/TeamSwitcher'
import Button from '../../components/ui/Button'
import Dialog from '../../components/ui/Dialog'
import Icon from '../../components/ui/Icon'
import Tooltip from '../../components/ui/Tooltip'
import TicketCreator from '../../containers/Tickets/components/TicketCreator'
import UnreadNotificationsCounter from './components/UnreadNotificationsCounter'
import UnreadNotificationsList from './components/UnreadNotificationsList'
import './index.css'

const isActive = (location: any, pathnames: string[]): boolean => {
  const pathname = location.pathname
  return pathnames.some(el => pathname.match(el))
}

interface NavigationState {
  toggleSidebar: boolean
  showTicketCreator: boolean
}

class Navigation extends React.Component<RouteComponentProps> {
  state: NavigationState = {
    toggleSidebar: localStorage.getItem('toggleSidebar') === 'true',
    showTicketCreator: false,
  }

  private toggleSidebar = () => {
    const currentVal = this.state.toggleSidebar
    this.setState({ toggleSidebar: !currentVal })
    localStorage.setItem('toggleSidebar', JSON.stringify(!currentVal))
  }

  private isActiveUsersOrSettings = (_: match | null, location: Location) => {
    return isActive(location, ['users', 'settings'])
  }

  private openTicketCreator = () => {
    this.setState({ showTicketCreator: true })
  }

  private closeTicketCreator = () => {
    this.setState({ showTicketCreator: false })
  }

  private onTicketCreate = (ticket: Ticket) => {
    this.setState({ showTicketCreator: false }, () => {
      store.dispatch(addConversationTab({
        convId: ticket.conversation.id,
        title: ticket.title,
      }))
    })
  }

  public render() {
    const { toggleSidebar, showTicketCreator } = this.state
    const sidebarClasses = `sidebar ${toggleSidebar ? 'small': null}`

    return (
      <aside className={sidebarClasses} data-testid='Aside-cy'>
        <div className="menu-toggler">
          <Button
            id="navigation-title"
            data-testid='MenuSidebarToggle-cy'
            className="sidebar__toggle"
            clickHandler={this.toggleSidebar}
          >
            <Icon name="menu" />
            <Localized id="navigation-title">
              Menu
            </Localized>
          </Button>
        </div>
        <TeamSwitcher />
        <nav className="nav">
          <ul>
            <li className="nav__link">
              <Tooltip l10nId="navigation-dashboard" direction="right" isDisabled={!toggleSidebar}>
                <NavLink exact to="/" activeClassName="active">
                  <span className="nav__content">
                    <Icon size="medium" name="dashboard" />
                    <span className="nav__text">
                      <Localized id="navigation-dashboard">
                        Dashboard
                      </Localized>
                    </span>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav__link">
              <Tooltip l10nId="navigation-notifications" isDisabled={!toggleSidebar}>
                <NavLink to="/notifications" activeClassName="active">
                  <span className="nav__content">
                    <Icon size="medium" name="bell" />
                    <span className="nav__text">
                      <Localized id="navigation-notifications">
                        Notifications
                      </Localized>
                    </span>
                  </span>
                  <UnreadNotificationsCounter />
                </NavLink>
              </Tooltip>
              <UnreadNotificationsList />
            </li>
            <li className="nav__link">
              <Tooltip l10nId="navigation-books" direction="right" isDisabled={!toggleSidebar}>
                <NavLink
                  to="/books"
                  activeClassName="active"
                >
                  <span className="nav__content">
                    <Icon size="medium" name="books" />
                    <span className="nav__text">
                      <Localized id="navigation-books">
                        Books
                      </Localized>
                    </span>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav__link">
              <Tooltip l10nId="navigation-resources" direction="right" isDisabled={!toggleSidebar}>
                <NavLink to="/resources" activeClassName="active">
                  <span className="nav__content">
                    <Icon size="medium" name="resources" />
                    <span className="nav__text">
                      <Localized id="navigation-resources">
                        Resources
                      </Localized>
                    </span>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav__link">
              <Tooltip l10nId="navigation-profile" isDisabled={!toggleSidebar}>
                <NavLink
                  to="/users/me"
                  activeClassName="active"
                  isActive={this.isActiveUsersOrSettings}
                >
                  <span className="nav__content">
                    <Icon size="medium" name="user"/>
                    <span className="nav__text">
                      <Localized id="navigation-profile">
                        Your profile
                      </Localized>
                    </span>
                  </span>
                </NavLink>
              </Tooltip>
              <div className="nav__hoverbox">
                <NavLink to="/users/me" activeClassName="active" className="nav__link">
                  <Localized id="navigation-profile">
                    Your profile
                  </Localized>
                </NavLink>
                <NavLink to="/settings" activeClassName="active" className="nav__link">
                  <Localized id="navigation-settings">
                    Settings
                  </Localized>
                </NavLink>
              </div>
            </li>
            <li className="nav__link">
              <Tooltip l10nId="navigation-helpdesk" isDisabled={!toggleSidebar}>
                <NavLink to="/helpdesk" activeClassName="active">
                  <span className="nav__content">
                    <Icon size="medium" name="help" />
                    <span className="nav__text">
                      <Localized id="navigation-helpdesk">
                        Helpdesk
                      </Localized>
                    </span>
                  </span>
                </NavLink>
              </Tooltip>
              <div className="nav__hoverbox">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav__link" onClick={this.openTicketCreator}>
                  <Localized id="navigation-new-ticket">
                    New ticket
                  </Localized>
                </a>
              </div>
            </li>
            {/* Temporarily allow inviting external users only to super admins */}
            <LimitedUI onlySuper={true} /* permissions="member:add" */>
              <li className="nav__link">
                <Tooltip l10nId="navigation-invite" direction="right" isDisabled={!toggleSidebar}>
                  <NavLink to="/invitations" activeClassName="active">
                    <span className="nav__content">
                      <Icon size="medium" name="user-plus" />
                      <span className="nav__text">
                        <Localized id="navigation-invite">
                          Invitations
                        </Localized>
                      </span>
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            </LimitedUI>
            <LimitedUI onePermissionFrom={ROUTE_TEAMS_PERMISSIONS}>
              <li className="nav__link">
                <Tooltip l10nId="navigation-teams" direction="right" isDisabled={!toggleSidebar}>
                  <NavLink to="/teams" activeClassName="active">
                    <span className="nav__content">
                      <Icon size="medium" name="users" />
                      <span className="nav__text">
                        <Localized id="navigation-teams">
                          Manage teams
                        </Localized>
                      </span>
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            </LimitedUI>
            <LimitedUI permissions="editing-process:edit">
              <li className="nav__link">
                <Tooltip
                  l10nId="navigation-processes"
                  direction="right"
                  isDisabled={!toggleSidebar}
                >
                  <NavLink to="/processes" activeClassName="active">
                    <span className="nav__content">
                      <Icon size="medium" name="paper-pen" />
                      <span className="nav__text">
                        <Localized id="navigation-processes">
                          Editing processes
                        </Localized>
                      </span>
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            </LimitedUI>
            <li className="nav__link">
              <Tooltip
                l10nId="navigation-modules"
                direction="right"
                isDisabled={!toggleSidebar}
              >
                <NavLink to="/modules" activeClassName="active">
                  <span className="nav__content">
                    <Icon size="medium" name="list-ul" />
                    <span className="nav__text">
                      <Localized id="navigation-modules">
                        Modules list
                      </Localized>
                    </span>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <LimitedUI onlySuper={true}>
              <li className="nav__link">
                <Tooltip
                  l10nId="navigation-administration"
                  direction="right"
                  isDisabled={!toggleSidebar}
                >
                  <NavLink to="/admin" activeClassName="active">
                    <span className="nav__content">
                      <span className="nav__text">
                        <Localized id="navigation-administration">
                          Administration
                        </Localized>
                      </span>
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            </LimitedUI>
          </ul>
          <div className="nav__link">
            <a href="/account/logout">
              <span className="nav__content">
                <Icon size="medium" name="logout" />
                <span className="nav__text">
                  <Localized id="navigation-logout">
                    Logout
                  </Localized>
                </span>
              </span>
            </a>
          </div>
        </nav>
        {
          showTicketCreator ?
            <Dialog
              size="medium"
              className="nav__ticket-creator-dialog"
              onClose={this.closeTicketCreator}
            >
              <TicketCreator
                onClose={this.closeTicketCreator}
                onCreate={this.onTicketCreate}
              />
            </Dialog>
            : null
        }
      </aside>
    )
  }
}

export default withRouter(Navigation)
