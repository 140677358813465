import * as React from 'react'
import { Editor } from 'slate'
import LocalizationLoader from '../../screens/app/Draft/components/LocalizationLoader'
import { CurrentDraftContext } from '../../screens/app/Draft'
import ReferenceTargets from '../../containers/ReferenceTargets'
import { XrefTargetOnSelect } from '../XrefTargetSelector'
import { getRefTargets } from './utils'

interface LocalResourceTargetsProps {
  /**
   * Editor session for the document for which to display reference targets.
   */
  editor: Editor,
  /**
   * Function to call when user selects a reference target.
   */
  onSelect: XrefTargetOnSelect
}

const LocalResourceTargets = ({ editor, onSelect }: LocalResourceTargetsProps) => {
  const targets = React.useMemo(() => getRefTargets(editor), [editor])

  return (
    targets.length ?
      <CurrentDraftContext.Consumer>
        {value => (
          <LocalizationLoader
            locale={value.state.currentDraftLang || 'en'}
          >
            <ReferenceTargets
              module={null}
              targets={targets}
              onSelect={onSelect}
            />
          </LocalizationLoader>
        )}
      </CurrentDraftContext.Consumer>
      : null
  )
}

export default LocalResourceTargets
