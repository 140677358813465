import * as React from 'react'
import { Glossary } from 'cnx-designer'
import { Localized } from '@fluent/react'
import { Node } from 'slate'
import { useSlate } from 'slate-react'
import Button from '../../../../../../../components/ui/Button'

import './index.css'

const CharactersCounter = () => {
  const editor = useSlate()
  const [documentCharacters, setDocumentCharacters] = React.useState(0)
  const [glossaryCharacters, setGlossaryCharacters] = React.useState(0)

  const countCharacters = () => {
    let docString = ''
    let glossaryString = ''
    for (const node of editor.children) {
      if (Glossary.isGlossary(node)) {
        glossaryString += Node.string(node)
      } else {
        docString += Node.string(node)
      }
    }
    const docCharacters = normalizeText(docString).length
    const glossaryCharacters = normalizeText(glossaryString).length
    setDocumentCharacters(docCharacters)
    setGlossaryCharacters(glossaryCharacters)
  }

  React.useEffect(countCharacters, [editor.children])

  return (
    <div className="characters-counter">
      <label className="toolbox__label">
        <span className="toolbox__title">
          <span>
            <Localized
              id="editor-tools-characters-counter-document"
              vars={{ document: documentCharacters }}
            >
              {`Number of characters without glossary: { $document }`}
            </Localized>
          </span>
          <span>
            <Localized
              id="editor-tools-characters-counter-glossary"
              vars={{ glossary: glossaryCharacters }}
            >
              {`Number of characters in the glossary: { $glossary }`}
            </Localized>
          </span>
          <span datatest-id="editor-tools-characters-counter-total">
            <Localized
              id="editor-tools-characters-counter-total"
              vars={{ total: documentCharacters + glossaryCharacters }}
            >
              {`Total number of characters: { $total }`}
            </Localized>
          </span>
        </span>
        <Button l10nId="editor-tools-characters-counter-refresh" clickHandler={countCharacters}>
          Refresh
        </Button>
      </label>
    </div>
  )
}

export default CharactersCounter

const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ')
