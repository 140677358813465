import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Localized } from '@fluent/react'
import { Process, Team } from '../../../../../../api'
import { addAlert } from '../../../../../../store/actions/alerts'
import { updateProcessName } from '../../../../../../store/actions/processes'
import { confirmDialog } from '../../../../../../helpers'
import Button from '../../../../../../components/ui/Button'
import Icon from '../../../../../../components/ui/Icon'
import LimitedUI from '../../../../../../components/LimitedUI'
import './index.css'

interface ProcessInfoProps {
  isEditingUnlocked: boolean
  process: Process
  afterDelete: (process: Process) => void
  afterUpdate: (process: Process, data: Partial<Process>) => void
}

const ProcessInfo = ({
  isEditingUnlocked, process, afterDelete, afterUpdate,
}: ProcessInfoProps) => {
  const [name, setName] = React.useState(process.name)
  const [team, setTeam] = React.useState<Team | null>(null)
  const nameRef = React.useRef<HTMLSpanElement>(null)
  const dispatch = useDispatch()

  const removeProcess = async () => {
    const res = await confirmDialog({
      title: 'processes-dialog-remove-title',
      vars: { process: process.name },
      buttons: {
        cancel: 'processes-dialog-buttons-cancel',
        confirm: 'processes-remove',
      },
    })
    if (res === 'confirm') {
      process.archive()
        .then(() => {
          dispatch(addAlert('success', 'processes-remove-success'))
          afterDelete(process)
        })
    }
  }

  const handleNameChange = (e: React.FormEvent<HTMLSpanElement>) => {
    const name = e.currentTarget.innerText
    setName(name)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    switch (e.key) {
    case 'Enter':
      e.preventDefault()
      onSubmit()
      break
    case 'Escape':
      cancelEdit()
      break
    default:
      break
    }
  }

  const onSubmit = () => {
    if (name.length) {
      dispatch(updateProcessName(process, name))
      afterUpdate(process, { name })
    }
  }

  const cancelEdit = () => {
    setName(process.name)
    nameRef.current!.innerText = process.name
  }

  const fetchTeam = React.useCallback(async () => {
    setTeam(await Team.load(process.team))
  }, [process.team])

  React.useEffect(() => {
    fetchTeam()
  }, [fetchTeam])

  React.useEffect(() => {
    if (name !== process.name) {
      setName(process.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.name])

  return (
    <>
      <div className="processes__name-wrapper">
        <form
          className="processes__name"
          onSubmit={onSubmit}
        >
          <span
            className="process__content-editable"
            contentEditable
            onInput={handleNameChange}
            onKeyDown={onKeyDown}
            dangerouslySetInnerHTML={{ __html: process.name }}
            ref={nameRef}
          />
          {
            name !== process.name ?
              <div className="process__controls">
                <span
                  id="process-info-submit-name-edit"
                  className="process__small-icon"
                  onClick={onSubmit}
                >
                  <Icon name="check" />
                </span>
                <span
                  id="process-info-cancel-name-edit"
                  className="process__small-icon"
                  onClick={cancelEdit}
                >
                  <Icon name="close" />
                </span>
              </div>
              : null
          }
        </form>
        <span className="processes__team">
          <Localized
            id="processes-team"
            vars={{ team: team ? team.name : '...' }}
          >
            Team: ...
          </Localized>
        </span>
      </div>
      <div className="processes__controls">
        <LimitedUI team={process.team} permissions="editing-process:edit">
          {
            isEditingUnlocked &&
            <>
              <Button id="process-info-edit" to={`/processes/${process.id}/edit`}>
                <Icon name="pencil" />
              </Button>
              <Button l10nId="processes-remove" type="danger" clickHandler={removeProcess}>
                Remove
              </Button>
            </>
          }
        </LimitedUI>
        <Button id="process-info-go-to-preview" to={`/processes/${process.id}/preview`}>
          <Icon name="eye" />
        </Button>
      </div>
    </>
  )
}

export default ProcessInfo
