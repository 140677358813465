import * as React from 'react'
import * as Counters from 'react-counters'
import { Figure } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { FIGURE } from '../../counters'

interface FigureProps extends RenderElementProps {
  element: Figure
}

const FigureComp = (props: FigureProps) => {
  Counters.useCounter(props.attributes.ref, { increment: [FIGURE] })

  return <figure {...props.attributes} id={props.element.id as string | undefined}>
    {props.children}
  </figure>
}

export default FigureComp
