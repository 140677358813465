import * as React from 'react'
import { Localized } from '@fluent/react'
import NotificationClass from '../../../api/notification'
import { Alert } from '../../../store/types'
import Notification from '../../../components/Notification'
import Icon from '../../../components/ui/Icon'

interface AlertProps {
  alert: Alert
  onClick: (alert: Alert) => void
}

const _Alert = (props: AlertProps) => {
  const { alert } = props

  const onClick = () => {
    props.onClick(props.alert)
  }

  let body: JSX.Element
  switch (alert.kind) {
  case 'alert':
    if (alert.data.kind === 'error') {
      body =
            <>
              <Localized id={alert.data.message} vars={alert.data.arguments}>
                {alert.data.message ? alert.data.message : "Something went wrong."}
              </Localized>
              <span className="alert__close">
                <Icon size="small" name="close" />
              </span>
            </>

      break
    }

    body =
          <Localized id={alert.data.message} vars={alert.data.arguments}>
            Unknow alert
          </Localized>

    break

  case 'notification':
    body =
          <Notification
            notification={alert.data}
          />

    break

  default:
    return null
  }

  const kind = alert.data instanceof NotificationClass ? alert.data.data.kind : alert.data.kind

  return (
    <li
      id="alert"
      className={`alerts__alert alert--${kind}`}
      onClick={onClick}
    >
      {body}
    </li>
  )
}

export default _Alert
