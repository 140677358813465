import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import User, { LoginMethodsProvider } from '../../../../../api/user'
import { useLoadAllLoginMethods, useLoadUserLoginMethods } from '../../../../../api/hooks'
import store from '../../../../../store'
import { State } from '../../../../../store/reducers'
import { addAlert } from '../../../../../store/actions/alerts'
import Input from '../../../../../components/ui/Input'
import Button from '../../../../../components/ui/Button'

import './index.css'

const LoginMethods = () => {
  const user = useSelector((state: State) => state.user.user)
  const [loginMethods] = useLoadAllLoginMethods()
  const [userLoginMethods] = useLoadUserLoginMethods(user)
  const [oldPassword, setOldPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [newPassword2, setNewPassword2] = React.useState('')
  const oldPasswordInputRef = useRef<Input>(null)
  const newPasswordInputRef = useRef<Input>(null)
  const newPassword2InputRef = useRef<Input>(null)


  const arePasswordsValid = React.useMemo(() => {
    if (oldPassword !== null && newPassword !==null) {
      if (oldPassword.length && newPassword.length > 5 && newPassword === newPassword2) {
        return true
      }
    }
    return false
  }, [oldPassword, newPassword, newPassword2])

  const changePassword = (e: React.FormEvent) => {
    e.preventDefault()

    User.changePassword(oldPassword, newPassword, newPassword2)
      .then(() => {
        clearPasswordForm()
        store.dispatch(addAlert('success', 'settings-change-password-alert-success'))
      })
      .catch(e => {
        clearPasswordForm()
        store.dispatch(addAlert('error', e.response.data.message))
      })
  }

  const clearPasswordForm = () => {
    setOldPassword('')
    setNewPassword('')
    setNewPassword2('')
    oldPasswordInputRef.current?.unTouch()
    newPasswordInputRef.current?.unTouch()
    newPassword2InputRef.current?.unTouch()
  }

  const providers = (loginMethods || [])
    .filter(data => data.method === 'openid') as LoginMethodsProvider[]
  const userHasPasswordLoginMethod = Boolean(
    (userLoginMethods || []).find(data => data.method === 'password'))

  return <div className="settings--subsection">
    <h2 className="settings__title settings__title--large">
      <Localized id="settings-login-methods">
        Login methods
      </Localized>
    </h2>
    {
      providers.map(data => {
        const connected = Boolean((userLoginMethods || []).find(
          method => method.method === 'openid' && method.provider === data.provider,
        ))
        return (
          <Button
            key={data.provider}
            id="settings-connect-with-provider"
            className="openid-provider"
            clickHandler={() => window.location.replace(`/account/openid/${data.provider}/connect`)}
            isDisabled={connected}
          >
            <Localized
              id="settings-connect-with-provider"
              vars={{ provider: data.name, connected: connected.toString() }}
            >
              Connect with OpenID provider
            </Localized>
          </Button>
        )
      })
    }
    {
      userHasPasswordLoginMethod
        ? <>
          <h3 className="settings__title">
            <Localized id="settings-section-password">
              Change password
            </Localized>
          </h3>
          <form onSubmit={changePassword}>
            <Input
              ref={oldPasswordInputRef}
              type="password"
              l10nId="settings-value-old-password"
              value={oldPassword}
              onChange={(val: string) => setOldPassword(val)}
              errorMessage="settings-validation-password-bad-length"
            />
            <Input
              ref={newPasswordInputRef}
              type="password"
              l10nId="settings-value-new-password"
              value={newPassword}
              onChange={(val: string) => setNewPassword(val)}
              minLength={6}
              errorMessage="settings-validation-password-bad-length"
            />
            <Input
              ref={newPassword2InputRef}
              type="password"
              l10nId="settings-value-new-password-repeat"
              value={newPassword2}
              onChange={(val: string) => setNewPassword2(val)}
              sameAs={newPassword}
              errorMessage="settings-validation-password-no-match"
            />
            <Localized id="settings-password-change" attrs={{ value: true }}>
              <input type="submit" value="Confirm" disabled={!arePasswordsValid} />
            </Localized>
          </form>
        </>
        : null
    }
  </div>
}

export default LoginMethods
