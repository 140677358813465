import React from 'react'
import { Localized, useLocalization } from '@fluent/react'
import Creatable from 'react-select/creatable'

export type LicenseSelectorProps = {
  license: string | null,
  onChange: (license: string | null) => void,
}

const LICENSES = [
  { label: 'All rights reserved', value: null },
  { label: 'CC BY', value: 'https://creativecommons.org/licenses/by/4.0/' },
  { label: 'CC BY-SA', value: 'https://creativecommons.org/licenses/by-sa/4.0/' },
  { label: 'CC BY-NC', value: 'https://creativecommons.org/licenses/by-nc/4.0/' },
  { label: 'CC BY-NC-SA', value: 'https://creativecommons.org/licenses/by-nc-sa/4.0/' },
  { label: 'CC BY-ND', value: 'https://creativecommons.org/licenses/by-nd/4.0/' },
  { label: 'CC BY-NC-ND', value: 'https://creativecommons.org/licenses/by-nc-nd/4.0/' },
  { label: 'CC0', value: 'https://creativecommons.org/publicdomain/zero/1.0/' },
]

export default function LicenseSelector({ license, onChange }: LicenseSelectorProps) {
  const { l10n } = useLocalization()
  const formatCreateLabel = React.useCallback(url => {
    return l10n.getString('module-book-license-custom', { url }, `Custom: ${url}`)
  }, [l10n])

  const defaultValue = React.useMemo(() => {
    return LICENSES.find(l => l.value == license) ?? { label: license, value: license }
  }, [])

  const onChangeInner = React.useCallback(value => onChange(value.value), [onChange])

  return (
    <div className="license-selector">
      <Localized id="module-book-license-title">
        Licence
      </Localized>
      <Creatable
        isClearable={false}
        options={LICENSES}
        defaultValue={defaultValue}
        formatCreateLabel={formatCreateLabel}
        onChange={onChangeInner}
      />
    </div>
  )
}
