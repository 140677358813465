import * as React from 'react'
import { Transforms as CnxTransforms, DefinitionTerm, SeeAlso } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import { Localized } from '@fluent/react'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

import './index.css'

interface SeeAlsoToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const SeeAlsoTools = ({ editor, ...props }: SeeAlsoToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: SeeAlso.isSeeAlso })
  if (!node) return null

  const removeTerm = () => {
    const termNode = Editor.above(editor, { match: DefinitionTerm.isDefinitionTerm })
    if (termNode) {
      Transforms.removeNodes(editor, { at: termNode[1] })
    }
  }

  return (
    <ToolGroup
      title="editor-tools-seealso-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('seeAlsoTools')}
    >
      <Button
        // insertSeeAlso will insert new term if SeeAlso already exists
        id="editor-tools-seealso-add-term"
        clickHandler={() => CnxTransforms.insertSeeAlso(editor)}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="plus" />
        <Localized id="editor-tools-seealso-add-term">
          Add term
        </Localized>
      </Button>
      <Button
        id="editor-tools-seealso-remove-term"
        clickHandler={removeTerm}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="minus" />
        <Localized id="editor-tools-seealso-remove-term">
          Remove term
        </Localized>
      </Button>
    </ToolGroup>
  )
}

export default SeeAlsoTools
