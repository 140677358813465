import * as React from 'react'
import { Localized } from '@fluent/react'
import { ProcessSlot } from '../../../api/process'
import { TeamID } from '../../../api/team'
import { useLoadRoles } from '../../../api/hooks'

import './index.css'

interface SlotProps {
  team: TeamID
  slot: ProcessSlot
}

const Slot = ({ team, slot }: SlotProps) => {
  const [roles] = useLoadRoles(team, slot.roles)
  const rolesNames = React.useMemo(
    () => (roles || []).map(r => r.name).join(', '),
    [roles])

  return (
    <div className="process-preview-slot">
      <span>
        <Localized id="process-preview-slot-name" vars={{ name: slot.name }}>
          Slot name:
        </Localized>
      </span>
      <span>
        <Localized id="process-preview-slot-autofill" vars={{ value: slot.autofill.toString() }}>
          Automatic assignment of users
        </Localized>
      </span>
      <span>
        <Localized
          id="process-preview-roles"
          vars={{ roles: rolesNames }}
        >
          {`Roles: ${rolesNames}`}
        </Localized>
      </span>
    </div>
  )
}

export default Slot
