import { createCounter } from 'react-counters'

export const ADMONITION = createCounter({ name: 'admonition' })

export const EQUATION = createCounter({ name: 'equation' })

export const EXERCISE = createCounter({ name: 'exercise' })
export const EXERCISE_COMMENTARY = createCounter({ name: 'exercise_commentary' })
export const EXERCISE_PROBLEM = createCounter({ name: 'exercise_problem' })
export const EXERCISE_SOLUTION = createCounter({ name: 'exercise_solution' })

export const FIGURE = createCounter({ name: 'figure' })

export const RULE = createCounter({ name: 'rule' })
export const RULE_EXAMPLE = createCounter({ name: 'rule_example' })
export const RULE_PROOF = createCounter({ name: 'rule_proof' })
export const RULE_STATEMENT = createCounter({ name: 'rule_statement' })

export const TABLE = createCounter({ name: 'table' })
