import { Title } from 'cnx-designer'
import * as React from 'react'
import * as Sentry from '@sentry/react'
import { History } from 'history'
import { Editor, Element, Node, NodeEntry, Path, Point, Range, Transforms } from 'slate'
import { Module } from '../../../api'
import { scrollToTarget } from '../../../helpers'
import store from '../../../store'
import { fetchReferenceTargets } from '../../../store/actions/modules'
import { SCROLL_ATTEMPTS, SCROLL_ATTEMPTS_DELAY, SCROLL_OFFSET_FOR_ELEMENTS } from './constants'
import { collectForeignDocuments } from './plugins/Xref'

export const fetchReferenceTargetsForDocument = async (value: Node[], documentId: string) => {
  for (const doc of collectForeignDocuments(value)) {
    const module = await Module.load(doc)

    if (!module) {
      Sentry.captureMessage(
        `Draft with id: ${documentId} contains reference to a non-existent external document, id: ${doc}`,
        'warning',
      )
      continue
    }

    store.dispatch(fetchReferenceTargets(module))
  }
}

export type Validator = (value: any) => boolean

export const validateParents = (
  editor: Editor,
  validators: Validator[],
  selection: Range | undefined,
  selectionMaySpanBlocks: boolean = true,
): boolean => {
  if (!selection) return false
  // checks if selected node is not already at root
  // https://github.com/udecode/slate-plugins/issues/96
  let node = Editor.node(editor, selection)
  if (!node) { return false }

  node = Editor.isEditor(node[0]) ? node : Editor.parent(editor, selection)
  node = node[0].type === 'paragraph' ? Editor.parent(editor, node[1]) : node

  if (!selectionMaySpanBlocks) {
    const [, start] = Editor.above(editor, {
      at: selection.anchor,
      match: node => !Editor.isInline(editor, node),
    })!!
    const [, end] = Editor.above(editor, {
      at: selection.focus,
      match: node => !Editor.isInline(editor, node),
    })!!

    if (!Path.equals(start, end)) return false
  }

  return validators.some(v => v(node[0]))
}

export const useGoToReadOnly = (history: History, draftId: string, readOnly: boolean) => {
  React.useEffect(() => {
    if (readOnly) {
      history.replace(`/drafts/${draftId}/view${window.location.hash || ''}`)
    }
  }, [history, draftId, readOnly])
}

export const useScrollIntoHash = (hash: string, deps: any[] = []) => {
  const scrollAttempts = React.useRef(0)

  const scrollToHash = React.useCallback(() => {
    if (scrollAttempts.current > SCROLL_ATTEMPTS) return

    const targetSelector = `#${hash}`
    if (document.querySelector(targetSelector)) {
      scrollToTarget(targetSelector, SCROLL_OFFSET_FOR_ELEMENTS)
      scrollAttempts.current = 0
    } else {
      scrollAttempts.current++
      setTimeout(() => {
        scrollToHash()
      }, SCROLL_ATTEMPTS_DELAY)
    }
  }, [hash])

  React.useEffect(() => {
    if (hash) {
      scrollToHash()
    }
  }, [hash, scrollToHash])
}

export const unwrapChildrenFromNode = (editor: Editor, node: NodeEntry<Element>) => {
  node[0].children.forEach((n, i) => {
    if (Title.isTitle(n)) {
      Transforms.setNodes(editor, { type: 'paragraph' }, { at: [...node[1], i] })
    }
  })
  Transforms.unwrapNodes(editor, { at: node[1] })
}

export const getNodeAtSelection = <T extends Node>(
  editor: Editor,
  match: ((node: Node) => node is T) | ((node: Node) => boolean),
  selection?: Range,
) => {
  const [nodeAncestor] = Editor.levels(editor, { at: selection, match, reverse: true })
  if (nodeAncestor || !selection) {
    return nodeAncestor
  }

  const node = Editor.node(editor, selection)
  return match(node[0]) ? node : undefined
}

export const pointMin = (a: Point, b: Point): Point => Point.isBefore(a, b) ? a : b
export const pointMax = (a: Point, b: Point): Point => Point.isAfter(a, b) ? a : b
