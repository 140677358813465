import * as React from 'react'
import * as Counters from 'react-counters'
import { Statement } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { RULE, RULE_STATEMENT } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface RuleStatementProps extends RenderElementProps {
  element: Statement
}

const RuleStatementComp = ({ children, attributes }: RuleStatementProps) => {
  const { document } = React.useContext(LOCALIZATION)
  Counters.useCounter(attributes.ref, { increment: [RULE_STATEMENT] })
  const rule = Counters.useCounterValue(attributes.ref, RULE)
  const statement = Counters.useCounterValue(attributes.ref, RULE_STATEMENT)
  const message = document.getString('rule-statement-label', { rule, statement })

  return <div {...attributes} className="rule-statement" data-label={message}>
    {children}
  </div>
}

export default RuleStatementComp
