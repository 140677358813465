import * as React from 'react'
import { Localized } from '@fluent/react'
import { Module, Process } from '../../api'
import { ProcessStructure } from '../../api/process'
import { useLoadTeam } from '../../api/hooks'
import store from '../../store'
import { addAlert } from '../../store/actions/alerts'
import ConfigureSlots from './ConfigureSlots'
import ProcessSelector from '../../components/ProcessSelector'
import Button from '../../components/ui/Button'

import './index.css'

interface BeginProcessProps {
  // All modules should belongs to one team.
  modules: (Module | string)[]
  onClose: () => any
  afterUpdate?: (errors: Module[]) => void
}

export type SlotId = number
export type UserId = number

const BeginProcess = (props: BeginProcessProps) => {
  const [process, setProcess] = React.useState<Process>()
  const [structure, setStructure] = React.useState<ProcessStructure>()
  const [slots, setSlots] = React.useState<Map<SlotId, UserId>>(new Map())
  const [modules, setModules] = React.useState<Module[]>([])
  const [team] = useLoadTeam(process?.team)

  const startProcess = async () => {
    if (!process) return

    const processData = {
      process: process.id,
      slots: Array.from(slots.entries()),
    }

    const errors: Module[] = []

    await Promise.all(modules.map(m => m.beginProcess(processData)
      .catch(() => errors.push(m))))
      .then(() => {
        store.dispatch(addAlert('success', 'begin-process-success', {
          process: process.name,
          success: props.modules.length - errors.length,
          total: props.modules.length,
        }))
        props.onClose()
      })

    errors.forEach(m => {
      store.dispatch(addAlert('error', 'begin-process-error', { module: m.title }))
    })

    if (props.afterUpdate) {
      props.afterUpdate(errors)
    }
  }

  const handleProcessChange = async (option: { value: Process } | null) => {
    if (!option) {
      setProcess(undefined)
      setStructure(undefined)
      return
    }
    const structure = await option.value.structure()
    setProcess(option.value)
    setStructure(structure)
  }

  const handleConfigureSlotsChange = (slots: Map<SlotId, UserId>) => {
    setSlots(slots)
  }

  const fetchModules = React.useCallback(async () => {
    const modules: Module[] = []
    for (const modOrId of props.modules) {
      if (typeof modOrId === 'string') {
        modules.push(await Module.load(modOrId))
        continue
      }
      modules.push(modOrId)
    }
    setModules(modules)
  }, [props.modules])

  React.useEffect(() => {
    fetchModules()
  }, [fetchModules])

  return (
    <div className="begin-process">
      {
        modules.length &&
          <ProcessSelector
            team={modules[0].team}
            isClearable={false}
            onChange={handleProcessChange}
          />
      }
      {
        structure && team ?
          <ConfigureSlots
            structure={structure}
            team={team}
            onChange={handleConfigureSlotsChange}
          />
          : null
      }
      <p>
        <strong>
          <Localized id="begin-process-info">
            You are about to start process for:
          </Localized>
        </strong>
      </p>
      <ul>
        {modules.map(m => <li key={m.id}>{m.title}</li>)}
      </ul>
      {
        process ?
          <Button l10nId="begin-process-start" clickHandler={startProcess}>
            Start process
          </Button>
          : null
      }
    </div>
  )
}

export default BeginProcess
