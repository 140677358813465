import * as React from 'react'
import { createPortal } from 'react-dom'
import { Localized } from '@fluent/react'

import './index.css'

interface TooltipProps {
  l10nId: string,
  l10nVars?: { [key: string]: string }
  children: JSX.Element,
  direction?: 'up' | 'down' | 'left' | 'right',
  isDisabled?: boolean
  className?: string
}

const directionToArrow = {
  up: 'down',
  down: 'up',
  left: 'right',
  right: 'left',
}

// Arrow size in px (it should be equal to css values)
const ARROW_SIZE = 8

// Tip padding from one side (it should be equal to css values)
const TIP_PADDING = 5

const Tooltip = ({
  l10nId,
  l10nVars,
  children,
  direction = 'up',
  isDisabled,
  className = '',
}: TooltipProps) => {
  const [show, setShow] = React.useState(false)
  const [itemLeftOffset, setItemLeftOffset] = React.useState(0)
  const [itemTopOffset, setItemTopOffset] = React.useState(0)
  const [tipStyle, setTipStyle] = React.useState<React.CSSProperties>({})

  const onMouseEnter = () => {
    setShow(true)
    if (item.current) {
      const rect = item.current.getBoundingClientRect()
      setItemLeftOffset(rect.left)
      setItemTopOffset(rect.top)
    }
  }

  const onMouseLeave = () => {
    setShow(false)
  }

  const getLeftOffset = () => {
    if (!tip.current || !item.current) return -9999
    const tipWidth = tip.current.offsetWidth
    const itemWidth = item.current.offsetWidth
    if (tipWidth === 0 || itemWidth === 0) return -9999

    if (['up', 'down'].includes(direction)) {
      return itemLeftOffset - (tipWidth / 2) + (itemWidth / 2)
    }
    if (direction === 'left') {
      return itemLeftOffset - tipWidth - ARROW_SIZE
    }
    // direction === 'right'
    return itemLeftOffset + itemWidth + ARROW_SIZE
  }

  const getTopOffset = () => {
    if (!tip.current || !item.current) return -9999
    const tipHeight = tip.current.offsetHeight
    const itemHeight = item.current.offsetHeight
    if (tipHeight === 0 || itemHeight === 0) return -9999

    if (['left', 'right'].includes(direction)) {
      return itemTopOffset +
        (itemHeight / 2) -
        (tipHeight / 2)
    }
    if (direction === 'up') {
      return itemTopOffset - tipHeight - ARROW_SIZE - TIP_PADDING
    }
    // direction === 'down'
    return itemTopOffset + itemHeight + ARROW_SIZE + TIP_PADDING
  }

  const item = React.useRef<HTMLSpanElement>(null)
  const tip = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    if (item.current && tip.current) {
      const style: React.CSSProperties = {
        left: getLeftOffset(),
        top: getTopOffset(),
      }
      setTipStyle(style)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction, itemLeftOffset, itemTopOffset, item.current, tip.current])

  if (isDisabled) return children

  return <>
    <span
      ref={item}
      className={`tooltip ${className}`}
      // Use PointerEvents instead of MouseEvents because of bug in Chrome
      // which is not always fireing onMouseLeave.
      onPointerEnter={onMouseEnter}
      onPointerLeave={onMouseLeave}
    >
      { children }
    </span>
    {
      createPortal(
        <span
          ref={tip}
          className="tooltip__wrapper"
          style={{
            ...tipStyle,
            display: show ? 'block' : 'none',
          }}
        >
          <span className="tooltip__content">
            <Localized id={l10nId} vars={l10nVars}>{l10nId}</Localized>
            <span className={'tooltip__arrow tooltip__arrow--' + directionToArrow[direction]}/>
          </span>
        </span>, document.body)
    }
  </>
}

export default Tooltip
