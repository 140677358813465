import { RenderElementProps } from 'slate-react'
import { Equation } from '..'

import './index.css'

interface EquationProps extends RenderElementProps {
  element: Equation
}

export default function EquationComp({ attributes, children }: EquationProps) {
  return (
    <div className="equation" {...attributes} contentEditable={false}>
      {children}
    </div>
  )
}
