import * as React from 'react'
import { Localized } from '@fluent/react'
import { Task, Team } from '../../api'
import Button from '../ui/Button'
import Dialog from '../ui/Dialog'
import TeamSelector from '../TeamSelector'

export default function ContentTools() {
  const [importFile, setImportFile] = React.useState<File | null>(null)

  const onImport = React.useCallback(() => {
    const form = document.createElement('input')
    form.type = 'file'
    form.accept = 'application/zip'
    form.addEventListener('change', ev => setImportFile((ev.target as HTMLInputElement).files![0]))
    form.click()
  }, [setImportFile])
  const closeImport = React.useCallback(() => setImportFile(null), [setImportFile])

  return (
    <div className="content-tools">
      <Button l10nId="dashboard-tools-import" clickHandler={onImport} />

      {importFile != null && <ImportDialog file={importFile} onClose={closeImport} />}
    </div>
  )
}

type ImportDialogProps = {
  file: File,
  onClose: () => void,
}

function ImportDialog({ file, onClose }: ImportDialogProps) {
  const [team, setTeam] = React.useState<Team | undefined>()

  const doSubmit = React.useCallback(async () => {
    const id = await Task.import(team!, file)
    window.location.replace(`/import/${id}`)
  }, [team, file])

  return (
    <Dialog onClose={onClose}>
      <TeamSelector team={team} onChange={setTeam} />
      <Button l10nId="dashboard-tools-import" isDisabled={team == null} clickHandler={doSubmit} />
    </Dialog>
  )
}
