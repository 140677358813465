import * as React from 'react'
import { ErrorData } from '../../../../api/task'

export interface ErrorProps {
  error: ErrorData
}

export default function Error({ error }: ErrorProps) {
  return <div className="task__error">
    {error.raw}
  </div>
}
