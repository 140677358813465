import * as React from 'react'
import * as Counters from 'react-counters'
import { Exercise } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { EXERCISE, EXERCISE_PROBLEM, EXERCISE_SOLUTION } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface ExerciseProps extends RenderElementProps {
  element: Exercise
}


const ExerciseComp = ({ children, attributes, element }: ExerciseProps) => {
  const { document } = React.useContext(LOCALIZATION)
  const message = document.getString('exercise-label')

  Counters.useCounter(attributes.ref, {
    increment: [EXERCISE],
    reset: [EXERCISE_PROBLEM, EXERCISE_SOLUTION],
  })
  return (
    <div
      className="exercise"
      data-label={message}
      id={element.id as string | undefined}
      {...attributes}
    >
      {children}
    </div>
  )
}

export default ExerciseComp
