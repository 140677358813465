import APICache from '../../api/apicache'
import Conversation, {
  ConversationData,
  ConversationEventData,
  NewMessageEventData,
  ReplaceLoadingMessageData,
  StateChangedData,
  UserJoinedEventData,
} from '../../api/conversation'
import {
  ADD_CONVERSATION,
  ADD_CONVERSATION_TAB,
  ADD_MESSAGE,
  ADD_MESSAGES,
  JOIN_CONVERSATION,
  REMOVE_CONVERSATION_TAB,
  REPLACE_LOADING_MESSAGE,
} from '../../store/constants'
import { ConversationTabData } from '../../store/types'
import { assertNonNullable } from '../../helpers'

export interface OpenConversation {
  (dispatch: React.Dispatch<AddConversation | AddMessage>): void
}

export interface RefreshConversation {
  (dispatch: React.Dispatch<OpenConversation>): void
}

export interface AddConversation {
  type: ADD_CONVERSATION
  data: ConversationData
  socket: Conversation
}

export interface JoinConversation {
  type: JOIN_CONVERSATION
  data: { conversationId: number, userId: number }
}

export interface AddMessage {
  type: ADD_MESSAGE
  data: NewMessageEventData | UserJoinedEventData | StateChangedData
  conversationId: number
}

export type AddMessagesData = {
  messages: (NewMessageEventData | UserJoinedEventData | StateChangedData)[]
  conversationId: number
  position: 'start' | 'end'
  addLoadingMsgBefore: boolean
  addLoadingMsgAfter: boolean
}

export interface AddMessages {
  type: ADD_MESSAGES
  data: AddMessagesData
}

export interface ReplaceLoadingMessage {
  type: REPLACE_LOADING_MESSAGE
  data: ReplaceLoadingMessageData
  conversationId: number
}

export interface AddConversationTab {
  type: ADD_CONVERSATION_TAB
  data: ConversationTabData
}

export interface RemoveConversationTab {
  type: REMOVE_CONVERSATION_TAB
  data: number // Conversation id
}

export type ConversationsAction =
  AddConversation
  | JoinConversation
  | AddConversationTab
  | AddMessage
  | AddMessages
  | RemoveConversationTab
  | ReplaceLoadingMessage

export const openConversation = (
  id: number,
  data: ConversationData | null = null,
): OpenConversation => async (dispatch: React.Dispatch<any>) => {
  const conv = new Conversation(id)
  if (data == null) {
    data = await Conversation.load(id)
  }

  return conv.connect()
    .then(socket => {
      const handleEvent = (ev: CustomEvent<ConversationEventData>) => dispatch(addMessage(ev, id))

      socket.addEventListener('newmessage', handleEvent as any)
      socket.addEventListener('userjoined', handleEvent as any)
      socket.addEventListener('statechanged', handleEvent as any)

      dispatch(addConversation(assertNonNullable(data, 'conversation data is not defined'), socket))
    })
}

export const refreshConversation = (id: number): RefreshConversation => async (dispatch: React.Dispatch<any>) => {
  const data = await Conversation.load(id)
  APICache.invalidate(['Conversations', id.toString()])
  dispatch(openConversation(id, data))
}

export const addConversation = (data: ConversationData, socket: Conversation): AddConversation => ({
  type: ADD_CONVERSATION,
  data,
  socket,
})

export const joinConversation = (conversationId: number, userId: number): JoinConversation => ({
  type: JOIN_CONVERSATION,
  data: { conversationId, userId },
})

export const addMessage = (ev: CustomEvent<ConversationEventData>, convId: number): AddMessage => ({
  type: ADD_MESSAGE,
  data: ev.detail as NewMessageEventData | UserJoinedEventData | StateChangedData,
  conversationId: convId,
})

export const addMessages = (data: AddMessagesData): AddMessages => ({
  type: ADD_MESSAGES,
  data,
})

export const replaceLoadingMessage = (
  convId: number,
  data: ReplaceLoadingMessageData,
): ReplaceLoadingMessage => ({
  type: REPLACE_LOADING_MESSAGE,
  data,
  conversationId: convId,
})

export const addConversationTab = (data: ConversationTabData): AddConversationTab => ({
  type: ADD_CONVERSATION_TAB,
  data,
})

export const removeConversationTab = (convId: number): RemoveConversationTab => ({
  type: REMOVE_CONVERSATION_TAB,
  data: convId,
})
