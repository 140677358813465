import * as React from 'react'
import { Localized } from '@fluent/react'
import { Book } from '../../../../api'
import LimitedUI from '../../../../components/LimitedUI'
import Button from '../../../../components/ui/Button'
import Dialog from '../../../../components/ui/Dialog'

export interface ExportButtonProps {
  book?: Book
}

interface Form {
  format: HTMLInputElement
  drafts: HTMLInputElement
  suggestions: HTMLInputElement
}

export default function ExportButton({ book }: ExportButtonProps) {
  const [open, setOpen] = React.useState(false)
  const [drafts, setDrafts] = React.useState(false)

  const draftsChanged = React.useCallback(ev => setDrafts(ev.target.checked), [setDrafts])

  const showDialog = React.useCallback(() => setOpen(true), [setOpen])
  const closeDialog = React.useCallback(() => setOpen(false), [setOpen])

  const doExport = React.useCallback((ev: React.FormEvent) => {
    ev.preventDefault()

    const form = ev.target as unknown as Form

    book!.export({
      format: form.format.value as 'legacy' | 'git',
      drafts: form.drafts.checked,
      suggestions: form.suggestions.value as 'accept' | 'reject',
    })
  }, [book])

  if (book == null) return null

  return (
    <LimitedUI team={book.team} permissions="content:export">
      <Button l10nId="book-export" clickHandler={showDialog} withBorder={true}>
        Export
      </Button>
      {
        open && (
          <Dialog
            l10nId="export-book-options"
            placeholder="Export options"
            size="medium"
            onClose={closeDialog}
          >
            <form onSubmit={doExport} className="export-book">
              <label>
                <Localized id="book-export-format">
                  Format
                </Localized>
                <select name="format">
                  <Localized id="book-export-format-legacy">
                    <option value="legacy">
                      ZIP archive compatible with legacy.cnx.org
                    </option>
                  </Localized>
                  <Localized id="book-export-format-git">
                    <option value="git">
                      ZIP archive compatible with modern Git-based publishing system
                    </option>
                  </Localized>
                </select>
              </label>

              <label>
                <input name="drafts" type="checkbox" onChange={draftsChanged} />
                <Localized id="book-export-use-drafts">
                  Use content of drafts
                </Localized>
              </label>

              <select name="suggestions" style={{ display: drafts ? "block" : "none" }}>
                <Localized id="book-export-reject-suggestions">
                  <option value="reject">
                    Reject suggestions
                  </option>
                </Localized>
                <Localized id="book-export-accept-suggestions">
                  <option value="accept">
                    Accept suggestions
                  </option>
                </Localized>
              </select>

              <input type="submit" value="Export" />
            </form>
          </Dialog>
        )
      }
    </LimitedUI>
  )
}
