import * as React from 'react'
import { Editor, Transforms } from 'slate'
import Modal from '../../../../../../../components/Modal'
import Button from '../../../../../../../components/ui/Button'
import MathEditor from '../../../../../../../containers/MathEditor'
import { Math } from '../../../../../../../screens/app/Draft/plugins/Math/interfaces'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

interface MathToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

export default function MathTools({ editor, toggleState, onToggle, ...props }: MathToolsProps) {
  const onToggleHandler = React.useCallback(() => onToggle('mathTools'), [onToggle])

  const selection = useCurrentSelection()
  const [math, mathPath] = Editor.above(editor, { at: selection, match: Math.isMath }) ?? []

  const modal = React.useRef<Modal>(null)

  const onEditEquation = React.useCallback(() => {
    modal.current!.open()
  }, [])

  const onRemoveEquation = React.useCallback(() => {
    Transforms.removeNodes(editor, { at: mathPath })
  }, [editor, mathPath])

  const onCancel = React.useCallback(() => {
    modal.current!.close()
  }, [])

  const onFinish = React.useCallback((latex: string) => {
    Transforms.setNodes(editor, { latex, mathml: null }, { at: mathPath })
    modal.current!.close()
  }, [editor, mathPath])

  const renderEditor = React.useCallback(() => (
    <MathEditor equation={math!.latex} onCancel={onCancel} onFinish={onFinish} />
  ), [math, onCancel, onFinish])

  if (!math) return null

  return (
    <ToolGroup
      title="editor-tools-math-title"
      toggleState={toggleState}
      onToggle={onToggleHandler}
    >
      <Button
        l10nId="editor-tools-math-edit"
        clickHandler={onEditEquation}
        className="toolbox__button--insert"
        isDisabled={true}
      >
        Edit equation
      </Button>
      <Button
        l10nId="editor-tools-math-remove"
        clickHandler={onRemoveEquation}
        className="toolbox__button--insert"
      >
        Remove equation
      </Button>
      <Modal
        ref={modal}
        showCloseButton={false}
        closeOnBgClick={false}
        closeOnEsc={false}
        fullscreen
        content={renderEditor}
      />
    </ToolGroup>
  )
}
