import axios from '../config/axios'
import APICache from './apicache'
import Module from './module'
import Process, { Link, ProcessID, ProcessSingleStep, ProcessSlot, ProcessStructure } from './process'
import { elevated, extend } from './utils'

export type VersionID = number

/**
 * Versions's data.
 */
export type VersionData = {
  id: [ProcessID, VersionID]
  name: string
  version: string // date this version was created
}

export default class ProcessVersion {
  /**
   * Fetch data for specific version of process from the server.
   */
  static async load(process: ProcessID, version: VersionID, fromCache = true): Promise<ProcessVersion> {
    const processId = process.toString()
    const versionId = version.toString()
    const cached = APICache.getNested(['Processes/Versions', processId])
    if (fromCache && cached && cached[versionId]) return cached[versionId]
    const ver = new ProcessVersion(
      (await axios.get(`processes/${process}/versions/${version}`)).data, process)
    APICache.setNested(
      ['Processes/Versions', processId],
      { ...cached, [ver.id[1].toString()]: ver })
    return ver
  }

  static async loadStep(
    process: number, version: number, step: number,
  ): Promise<ProcessSingleStep> {
    const processVersion = await this.load(process, version)
    return processVersion.step(step)
  }

  /**
   * Load process structure for specific module.
   */
  static async loadStructureForModule(module: Module | string): Promise<ProcessStructure | null> {
    const mod = typeof module === 'string' ? await Module.load(module) : module
    if (!mod.process) return null
    const processVersion = await this.load(mod.process.process, mod.process.version)
    return processVersion.structure()
  }

  /**
   * Version's id.
   */
  id!: [ProcessID, VersionID]

  /**
   * Version's name.
   */
  name!: string

  /**
   * Version (data-time).
   */
  version!: string

  /**
   * ID of the process this is a version of.
   */
  private process: number

  constructor(data: VersionData, process: Process | number) {
    extend(this, data)

    this.process = process instanceof Process ? process.id : process
  }

  /**
   * Return structure for this version.
   */
  async structure(fromCache = true): Promise<ProcessStructure> {
    const processId = this.process.toString()
    const versionId = this.id[1].toString()
    const cached = APICache.getNested(['Processes/Versions/Structures', processId])
    if (fromCache && cached && cached[versionId]) return cached[versionId]
    const strc = (await axios.get(
      `processes/${this.process}/versions/${versionId}/structure`)).data
    APICache.setNested(
      ['Processes/Versions/Structures', processId],
      { ...cached, [versionId]: strc })
    return strc
  }

  /**
   * Return list of slots for this version.
   */
  async slots(fromCache = true): Promise<ProcessSlot[]> {
    const processId = this.process.toString()
    const versionId = this.id[1].toString()
    const cached = APICache.getNested(['Processes/Versions/Slots', processId])
    if (fromCache && cached && cached[versionId]) return cached[versionId]
    const slots = (await axios.get(`processes/${this.process}/versions/${versionId}/slots`)).data
    APICache.setNested(['Processes/Versions/Slots', processId], {
      ...cached,
      [versionId]: slots,
    })
    return slots
  }

  /**
   * Return informations about specific slot in this version.
   */
  async slot(slot: number, fromCache = true): Promise<ProcessSlot> {
    const processId = this.process.toString()
    const versionId = this.id[1].toString()
    const cached = APICache.getNested(['Processes/Versions/Slots', processId])
    if (fromCache && cached && cached[versionId]) {
      const proccessSlot = cached[versionId].find(s => s.id === slot)
      if (proccessSlot) return proccessSlot
    }
    const processSlot: ProcessSlot = (await axios.get(
      `processes/${this.process}/versions/${versionId}/slots/${slot}`)).data
    APICache.setNested(
      ['Processes/Versions/Slots', processId],
      {
        ...cached,
        [versionId]: [...(cached || {})[versionId] || [], processSlot],
      },
    )
    return processSlot
  }

  /**
   * Update name or roles for slot in version.
   *
   * This function requires editing-process:edit permission.
   */
  async updateSlot(slot: number, data: { name?: string, roles?: number[] }): Promise<void> {
    await elevated(() => axios.put(
      `processes/${this.process}/versions/${this.id[1]}/slots/${slot}`,
      data,
    ))
    const processId = this.process.toString()
    APICache.invalidate(
      ['Processes/Versions/Slots', processId],
      ['Processes/Versions/Structures', processId],
    )
  }

  /**
   * Return list of steps for this version.
   */
  async steps(fromCache = true): Promise<ProcessSingleStep[]> {
    const processId = this.process.toString()
    const versionId = this.id[1].toString()
    const cached = APICache.getNested(['Processes/Versions/Steps', processId])
    if (fromCache && cached && cached[versionId]) return cached[versionId]
    const steps = (await axios.get(`processes/${this.process}/versions/${versionId}/steps`)).data
    APICache.setNested(['Processes/Versions/Steps', processId], {
      ...cached,
      [versionId]: steps,
    })
    return steps
  }

  /**
   * Return informations about specific step in this version.
   */
  async step(step: number, fromCache = true): Promise<ProcessSingleStep> {
    const processId = this.process.toString()
    const versionId = this.id[1].toString()
    const cached = APICache.getNested(['Processes/Versions/Steps', processId])
    if (fromCache && cached && cached[versionId]) {
      const proccessStep = cached[versionId].find(s => s.id === step)
      if (proccessStep) return proccessStep
    }
    const processStep: ProcessSingleStep = (await axios.get(
      `processes/${this.process}/versions/${versionId}/steps/${step}`)).data
    APICache.setNested(
      ['Processes/Versions/Steps', processId],
      {
        ...cached,
        [versionId]: [...(cached || {})[versionId] || [], processStep],
      },
    )
    return processStep
  }

  /**
   * Update name of step in version.
   *
   * This function requires editing-process:edit permission.
   */
  async updateStepName(step: number, name: string): Promise<void> {
    await elevated(() => axios.put(
      `processes/${this.process}/versions/${this.id[1]}/steps/${step}`,
      { name },
    ))
    const processId = this.process.toString()
    APICache.invalidate(
      ['Processes/Versions/Steps', processId],
      ['Processes/Versions/Structures', processId],
    )
  }

  /**
   * Return list of links for given step this version.
   */
  async links(step: number, fromCache = true): Promise<Link[]> {
    const processId = this.process.toString()
    const versionId = this.id[1].toString()
    const stepId = step.toString()
    const cached = APICache.getNested(['Processes/Versions/Links', processId])
    if (fromCache && cached && cached[versionId] && cached[versionId][stepId]) {
      return cached[versionId][stepId]
    }
    const links = (await axios.get(
      `processes/${this.process}/versions/${versionId}/steps/${step}/links`)).data
    APICache.setNested(['Processes/Versions/Links', processId], {
      ...cached,
      [versionId]: {
        ...(cached || {})[versionId],
        [stepId]: links,
      },
    })
    return links
  }

  /**
   * Return informations about link for given @param slot in @param step
   * which have target set to @param target in this version of process.
   */
  async link(step: number, slot: number, target: number, fromCache = true): Promise<Link> {
    const processId = this.process.toString()
    const versionId = this.id[1].toString()
    const stepId = step.toString()
    const cached = APICache.getNested(['Processes/Versions/Links', processId])
    if (fromCache && cached && cached[versionId] && cached[versionId][stepId]) {
      const proccessLink = cached[versionId][stepId].find(l => l.to === target && l.slot === slot)
      if (proccessLink) return proccessLink
    }
    const proccessLink: Link = (await axios.get(
      `processes/${this.process}/versions/${versionId}/steps/${step}/links/${slot}/${target}`,
    )).data
    APICache.setNested(['Processes/Versions/Links', processId], {
      ...cached,
      [versionId]: {
        ...(cached || {})[versionId],
        [stepId]: [...((cached || {})[versionId] || {})[stepId] || [], proccessLink],
      },
    })
    return proccessLink
  }

  /**
   * Update name of link in version.
   *
   * This function requires editing-process:edit permission.
   */
  async updateLinkName(step: number, slot: number, target: number, name: string): Promise<void> {
    await elevated(() => axios.put(
      `processes/${this.process}/versions/${this.id[1]}/steps/${step}/links/${slot}/${target}`,
      { name },
    ))
    const processId = this.process.toString()
    APICache.invalidate(
      ['Processes/Versions/Links', processId],
      ['Processes/Versions/Structures', processId],
    )
  }
}
