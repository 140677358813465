import * as React from 'react'
import { Localized } from '@fluent/react'
import { Book, Module, Team } from '../../api'
import { sortArrayByTitle } from '../../helpers'
import SingleModule from './SingleModule'

interface ModulesListProps {
  modules: Module[]
  // Show only modules from specific team
  team?: Team | number
  filter: string
  book?: Book
  onModuleClick: (mod: Module) => any
  onModuleRemoveClick: (mod: Module) => any
}

const ModulesListComp = ({
  modules,
  team,
  filter,
  book,
  onModuleClick,
  onModuleRemoveClick,
}: ModulesListProps) => {
  const filterReg = React.useMemo(() => new RegExp(filter, 'gi'), [filter])
  const [filteredModules, setFilteredModules] = React.useState<Module[]>([])
  const teamId = team ? typeof team === 'number' ? team : team.id : null

  const filterModules = React.useCallback(async () => {
    const filtered = []
    for (const mod of modules) {
      if (!filterReg.test(mod.title)) continue
      if (teamId && teamId !== mod.team) continue
      if (book && !await book.hasBookPart(mod.id)) continue
      filtered.push(mod)
    }
    filtered.sort(sortArrayByTitle)
    setFilteredModules(filtered)
  }, [teamId, modules, book, filterReg])

  React.useEffect(() => {
    filterModules()
  }, [filterModules])

  return (
    <div className="modulesList">
      {
        filteredModules.length ?
          <ul className="modulesList__list">
            {
              filteredModules.map(mod => (
                <SingleModule
                  key={mod.id}
                  mod={mod}
                  onModuleClick={onModuleClick}
                  onModuleRemove={onModuleRemoveClick}
                />
              ))
            }
          </ul>
          :
          <div className="modulesList__no-results">
            <Localized id="modules-list-no-results">
              No results for specified criteria.
            </Localized>
          </div>
      }
    </div>
  )
}

export default ModulesListComp
