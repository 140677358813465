import * as React from 'react'
import { Caption, Transforms as CnxTransforms, Figure } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import { Localized } from '@fluent/react'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import ToolGroup from '../ToolGroup'
import Classes from '../Classes'
import { OnToggle } from '../../index'
import { MediaText } from '../../../../../../../screens/app/Draft/plugins/MediaText'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'

import './index.css'

interface FigureToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const FigureTools = ({ editor, ...props }: FigureToolsProps) => {
  const selection = useCurrentSelection()

  let mediaEntry = Editor.above(editor, { at: selection, match: MediaText.isMediaText })
  let figureEntry = Editor.above(editor, { at: selection, match: Figure.isFigure })

  if (!mediaEntry && !figureEntry) return null

  // MediaText is positioned after the Figure so if it is present we have to set figureEntry
  // and if FigureEntry is present then we have to check for mediaEntry.

  if (figureEntry) {
    const [next, path] = Editor.next(editor, { at: figureEntry[1] }) || []
    if (next && path && MediaText.isMediaText(next)) {
      mediaEntry = [next, path]
    }
  } else if (mediaEntry) {
    const [prev, path] = Editor.previous(editor, { at: mediaEntry[1] }) || []
    if (prev && path && Figure.isFigure(prev)) {
      figureEntry = [prev, path]
    }
  }

  if (!figureEntry) return null

  const [figure, figurePath] = figureEntry
  const [mediaText, mediaPath] = mediaEntry || []
  const hasCaption = Caption.isCaption(figure.children[figure.children.length-1])

  const toggleMediaText = () => {
    if (mediaPath) {
      Transforms.removeNodes(editor, { at: mediaPath })
    } else {
      const figureIndex = [...figurePath].pop()!
      const pathAfterFigure = [...figurePath, figureIndex + 1]
      Transforms.insertNodes(
        editor,
        { type: 'media_text', children: [{ text: '' }] },
        { at: pathAfterFigure },
      )
    }
  }

  return (
    <ToolGroup
      title="editor-tools-figure-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('figureTools')}
    >
      <Button
        id="editor-tools-figure-add-caption"
        clickHandler={() => CnxTransforms.insertCaption(editor)}
        isDisabled={hasCaption}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="comment" />
        <Localized id="editor-tools-figure-add-caption">
          Add caption
        </Localized>
      </Button>
      <Classes node={figureEntry} />
      <div>
        <Button
          l10nId={
            mediaText
              ? "editor-tools-figure-remove-media-text"
              : "editor-tools-figure-add-media-text"
          }
          clickHandler={toggleMediaText}
        />
      </div>
    </ToolGroup>
  )
}

export default FigureTools
