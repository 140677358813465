import * as React from 'react'
import { Transforms as CnxTransform, Definition, DefinitionTerm } from 'cnx-designer'
import { Localized } from '@fluent/react'
import { Editor, Node, Transforms } from 'slate'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import Input from '../../../../../../../components/ui/Input'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

import './index.css'

interface DefinitionToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const DefinitionTools = ({ editor, ...props }: DefinitionToolsProps) => {
  const selection = useCurrentSelection()

  const nodeDefinition = Editor.above(editor, { at: selection, match: Definition.isDefinition })
  if (!nodeDefinition) return null

  const [, definitionPath] = nodeDefinition
  const nodeTerm = Editor.above(editor, { match: DefinitionTerm.isDefinitionTerm })
  const [term, termPath] = nodeTerm ? nodeTerm : []
  const reference = term && term.reference

  const changeReference = (val: string | null) => {
    Transforms.setNodes(editor, { reference: val }, { at: termPath })
  }

  return (
    <ToolGroup
      title="editor-tools-definition-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('definitionTools')}
    >
      <Button
        id="editor-tools-definition-insert-definition-before"
        clickHandler={() => CnxTransform.addGlossaryDefinition(editor, { mode: 'before', select: true })}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="plus" />
        <Localized id="editor-tools-definition-insert-definition-before">
          Insert definition before
        </Localized>
      </Button>
      <Button
        id="editor-tools-definition-insert-definition-after"
        clickHandler={() => CnxTransform.addGlossaryDefinition(editor, { mode: 'after', select: true })}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="plus" />
        <Localized id="editor-tools-definition-insert-definition-after">
          Insert definition after
        </Localized>
      </Button>
      <Button
        id="editor-tools-definition-remove-definition"
        clickHandler={() => Transforms.removeNodes(editor, { at: definitionPath })}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="close" />
        <Localized id="editor-tools-definition-remove-definition">
          Remove definition
        </Localized>
      </Button>
      <Button
        id="editor-tools-definition-insert-meaning"
        clickHandler={() => CnxTransform.insertMeaning(editor)}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="plus" />
        <Localized id="editor-tools-definition-insert-meaning">
          Add meaning
        </Localized>
      </Button>
      <Button
        id="editor-tools-definition-insert-seealso"
        clickHandler={() => CnxTransform.insertSeeAlso(editor)}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="plus" />
        <Localized id="editor-tools-definition-insert-seealso">
          Add see also
        </Localized>
      </Button>
      {
        term && <label className="definition__label">
          <span className="definition__title">
            <Localized
              id="editor-tools-definition-term-label"
              vars={{ text: Node.string(term) }}
              elems={{ strong: <strong/> }}
            >
              {/* Required to properly render anchors as html elements. */}
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <></>
            </Localized>
          </span>
          <Input
            type="text"
            value={reference}
            onChange={changeReference}
          />
        </label>
      }
    </ToolGroup>
  )
}

export default DefinitionTools
