import * as React from 'react'
import { Meaning, Transforms } from 'cnx-designer'
import { Localized } from '@fluent/react'
import { Editor } from 'slate'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

interface MeaningToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const MeaningTools = ({ editor, ...props }: MeaningToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Meaning.isMeaning })
  if (!node) return null

  return (
    <ToolGroup
      title="editor-tools-meaning-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('meaningTools')}
    >
      <Button
        id="editor-tools-meaning-insert-example"
        clickHandler={() => Transforms.insertDefinitionExample(editor)}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="plus" />
        <Localized id="editor-tools-meaning-insert-example">
          Add example
        </Localized>
      </Button>
    </ToolGroup>
  )
}

export default MeaningTools
