import * as React from 'react'
import { Link } from 'react-router-dom'
import Notification, { Data } from '../../api/notification'
import { dateISOtoLocal, getLinkToNotification } from '../../helpers'
import DateDiff from '../../components/DateDiff'
import NotificationText from './components/NotificationText'
import './index.css'

interface NotificationCompProps {
  notification: Notification<Data>
  avatarSize?: 'small' | 'medium' | 'big'
  disableLink?: boolean
  deleteOnClick?: boolean
}

const NotificationComp = ({
  notification,
  disableLink,
  deleteOnClick,
}: NotificationCompProps) => {
  const linkToNotification = getLinkToNotification(notification)

  const onNotificationClick = () => {
    // TODO: Fire this action only on unread notifications
    if (deleteOnClick) {
      notification.delete()
        .then(() => Notification.pagination().delete(notification))
    }
  }

  const body = (
    <div id="notification" className="notification" onClick={onNotificationClick}>
      <span className="notification__content">
        <span className="notification__text">
          <NotificationText notification={notification} />
          <span className="notification__date">
            <DateDiff from={dateISOtoLocal(notification.data.timestamp)} now={new Date()} />
          </span>
        </span>
      </span>
    </div>
  )

  return disableLink || !linkToNotification
    ? body
    : <Link to={linkToNotification} className="notification__link">
      {body}
    </Link>
}

export default NotificationComp
