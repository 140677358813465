import * as React from 'react'
import { useHistory } from 'react-router'
import Pagination, { Page } from '../../api/pagination'
import { useQuery } from '../../hooks'
import Spinner from '../../components/Spinner'
import Button from '../../components/ui/Button'
import Icon from '../../components/ui/Icon'
import PageNavigationList from './components/PageNavigationList'
import './index.css'

interface ComponentProps<T> {
  items: T[]
  onAdd: (item: T) => void
  onDelete: (item: T) => void, [key: string]: any
  onUpdate: (item: T, data: Partial<T>) => void
}

interface PageNavigationProps<T> {
  pagination: Pagination<T>
  usePageParam?: boolean
  Component: (props: ComponentProps<T>) => JSX.Element
}

const PageNavigation = <T extends any>({
  pagination, usePageParam = false, Component,
}: PageNavigationProps<T>) => {
  const { page: pageParam } = useQuery<{ page?: string }>()
  const [activePage, setActivePage] = React.useState(
    usePageParam && Number(pageParam) ? Number(pageParam) : 1)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [page, setPage] = React.useState<Page<T> | null | undefined>(null)
  const [reloadPage, setReloadPage] = React.useState(0)
  const history = useHistory()

  React.useEffect(() => {
    // Reset pagination if pagination has changed
    setActivePage(1)
  }, [pagination])

  React.useEffect(() => {
    if (usePageParam) {
      const param = pageParam && Number(pageParam) ? Number(pageParam) : 1
      setActivePage(param)
    }
  }, [pageParam, usePageParam])

  React.useEffect(() => {
    setIsLoading(true)
    let mounted = true

    pagination.loadPage(activePage)
      .then(page => {
        if (mounted) setPage(page)
      })
      .catch(e => {
        if (mounted) setError(e)
      })
      .finally(() => setIsLoading(false))

    return () => {
      mounted = false
      setPage(null)
      setError(null)
      setIsLoading(false)
    }
  }, [pagination, activePage, reloadPage])

  React.useEffect(() => {
    if (!isLoading && page?.items.length === 0 && page.number !== 1) {
      const prev = pagination.page(page.has_prev_page ? page.number - 1 : 1)
      const pushTo = prev && prev.items.length ? prev.number : 1
      if (usePageParam) {
        history.push(`?page=${pushTo}`)
      } else {
        setActivePage(pushTo)
      }
    }
  }, [pagination, usePageParam, page, isLoading, history])

  const onAdd = (item: T) => {
    pagination.tryToAdd(item)
    setReloadPage(s => s + 1)
  }

  const onDelete = (item: T) => {
    pagination.delete(item)
    setReloadPage(s => s + 1)
  }

  const onUpdate = (item: T, data: Partial<T>) => {
    pagination.update(item, data)
  }

  const hasMore = React.useMemo(
    () => {
      const last = pagination.last()
      return Boolean(!last || !last[1] || last[1].has_next_page)
    },
    // rerun on page change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, page, reloadPage])

  const cachedPageNumbers = React.useMemo(
    () => pagination.pages().map(([number]) => number),
    // rerun this when page changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, page, reloadPage])

  return <div className="page-navigation__wrapper">
    <div className="page-navigation__component">
      {
        error
          ? error.message
          : isLoading
            ? <Spinner />
            : (
              <Component
                items={page?.items || []}
                onAdd={onAdd}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />
            )
      }
    </div>
    <div className="page-navigation">
      <Button
        className="page-navigation__button page-navigation__button--prev"
        isDisabled={!page?.has_prev_page}
        to={`${page?.has_prev_page ? `?page=${page.number - 1}` : '/'}`}
        clickHandler={e => {
          if (usePageParam) return
          e.preventDefault()
          if (page?.has_prev_page) setActivePage(page.number - 1)
        }}
      >
        <Icon name="arrow-left" />
      </Button>
      <PageNavigationList
        activePage={activePage}
        cachedPageNumbers={cachedPageNumbers}
        useRouterLinks={usePageParam}
        onPageClick={number => setActivePage(number)}
        hasMore={hasMore}
      />
      <Button
        className="page-navigation__button page-navigation__button--next"
        isDisabled={!page?.has_next_page}
        to={`${page?.has_next_page ? `?page=${page.number + 1}` : '/'}`}
        clickHandler={e => {
          if (usePageParam) return
          e.preventDefault()
          if (page?.has_next_page) setActivePage(page.number + 1)
        }}
      >
        <Icon name="arrow-right" />
      </Button>
    </div>
  </div>
}

export default PageNavigation
