import * as React from 'react'
import * as Counters from 'react-counters'
import { useSelector } from 'react-redux'
import { Admonition, CrossReference, Title } from 'cnx-designer'
import { Node } from 'slate'
import { RenderElementProps, useSlate } from 'slate-react'
import { Link, useHistory } from 'react-router-dom'
import { State } from '../../../../../store/reducers'
import { INVALID_REFERENCE_CLASS, LOCALIZATION } from '../../constants'
import {
  findNodeEntryById, findTarget,
  getValueForCounter, useGetDomNode,
} from './utils'
import { createL10nKeyForXrefLabel } from '.'
import {
  ADMONITION,
  EXERCISE,
  EXERCISE_COMMENTARY,
  EXERCISE_PROBLEM,
  EXERCISE_SOLUTION,
  FIGURE,
  RULE,
  RULE_EXAMPLE,
  RULE_PROOF,
  RULE_STATEMENT,
  TABLE,
} from '../../counters'
import { getNodeAtSelection } from '../../utils'

interface XrefProps extends RenderElementProps {
  element: CrossReference
}

const Xref = (props: XrefProps) => {
  return props.element.document
    ? <RemoteXref {...props}/>
    : <LocalXref {...props} />
}

export default Xref

interface LocalXrefProps extends RenderElementProps {
  element: CrossReference
}

const LocalXref = ({ element, attributes, children }: LocalXrefProps) => {
  const editor = useSlate()
  const history = useHistory()
  const checkNode = getNodeAtSelection(editor, Admonition.isAdmonition, editor.selection ?? undefined)
  const target: any = React.useMemo(() => findNodeEntryById(editor.children, element.target),
    [checkNode, editor.children, element.target]) // eslint-disable-line
  const domNode = useGetDomNode(editor, target ? target[0] : null)
  const { document, ui } = React.useContext(LOCALIZATION)

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (ev.ctrlKey) {
      history.replace({ ...history.location, hash: element.target })
    }
  }

  const counters = Counters.useCounters(domNode!)

  const args = {
    case: element.case || 'nominative',
    note: getValueForCounter(counters, ADMONITION),
    exercise: getValueForCounter(counters, EXERCISE),
    exercise_commentary: getValueForCounter(counters, EXERCISE_COMMENTARY),
    exercise_problem: getValueForCounter(counters, EXERCISE_PROBLEM),
    exercise_solution: getValueForCounter(counters, EXERCISE_SOLUTION),
    figure: getValueForCounter(counters, FIGURE),
    rule: getValueForCounter(counters, RULE),
    rule_example: getValueForCounter(counters, RULE_EXAMPLE),
    rule_proof: getValueForCounter(counters, RULE_PROOF),
    rule_statement: getValueForCounter(counters, RULE_STATEMENT),
    table: getValueForCounter(counters, TABLE),
  }

  const title = ui.getString('editor-tools-xref-hover-tooltip')
    || 'Control-click to go to destination'

  const l10nKey = target
    ? createL10nKeyForXrefLabel(target[0])
    : 'xref-label-unknown'

  const text = target && Title.isTitle(target[0].children[0])
    ? document.getString(l10nKey, args) + ": " + Node.string(target[0].children[0])
    : document.getString(l10nKey, args)

  // const suggestion = getSuggestionRefForVoidElement(editor, element)
  // const suggestionKind = suggestion?.type
  const isValidReference = Boolean(domNode)
  const classes = [
    // suggestionKind ? `suggestion suggestion--${suggestionKind}` : '',
    isValidReference ? '' : INVALID_REFERENCE_CLASS,
  ]

  return (
    <span {...attributes}>
      <span contentEditable={false}>
        <Link
          to={`#${element.target}`}
          onClick={onClick}
          title={title}
          className={classes.join(' ')}
        >
          {text}
        </Link>
      </span>
      {children}
    </span>
  )
}

interface RemoteXrefProps extends RenderElementProps {
  element: CrossReference
}

const RemoteXref = ({ element, attributes, children }: RemoteXrefProps) => {
  // const editor = useSlate()
  const referenceTargets = useSelector((state: State) => state.modules.referenceTargets)
  const { document, ui } = React.useContext(LOCALIZATION)

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    if (!ev.ctrlKey) {
      ev.preventDefault()
    }
  }

  const title = document.getString('editor-tools-xref-hover-tooltip')
    || 'Control-click to go to destination'

  const target = findTarget(element, referenceTargets.get(element.document!))

  let l10nKey
  const args = { case: element.case || 'nominative' }
  let text = ''
  let isValidReference = true

  if (!referenceTargets) {
    text = ui.getString('editor-tools-xref-label-remote-loading', args)
      || '(loading reference data)'
  } else if (target) {
    l10nKey = createL10nKeyForXrefLabel(target.type)
    target.counters.forEach(([type, counter]) => {
      args[type] = counter
    })
    text = document.getString(l10nKey, args) || 'Unknown reference'
  } else {
    isValidReference = false
    console.warn(
      `Undefined target for element ${element.target} from document: ${element.document}`)
    text = ui.getString('editor-tools-xref-label-remote-reference-missing', args)
      || '(reference target missing)'
  }

  // const suggestion = getSuggestionRefForVoidElement(editor, element)
  // const suggestionKind = suggestion?.type
  const classes = [
    // suggestionKind ? `suggestion suggestion--${suggestionKind}` : '',
    isValidReference ? '' : INVALID_REFERENCE_CLASS,
  ]

  return (
    <span {...attributes}>
      <span contentEditable={false}>
        <Link
          to={`/modules/${element.document}#${element.target}`}
          onClick={onClick}
          title={title}
          className={classes.join(' ')}
        >
          {text}
        </Link>
      </span>
      {children}
    </span>
  )
}
