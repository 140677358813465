import * as React from 'react'
import { Audio } from 'cnx-designer'
import { Editor } from 'slate'
import ToolGroup from '../ToolGroup'
import MediaAttribute from '../MediaAttribute'
import { OnToggle } from '../../index'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'

interface AudioToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const AudioTools = ({ toggleState, editor, onToggle }: AudioToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Audio.isAudio })
  if (!node) return null

  return (
    <ToolGroup
      title="editor-tools-audio-title"
      toggleState={toggleState}
      className="audio-tools"
      onToggle={() => onToggle('audioTools')}
    >
      <MediaAttribute node={node} attribute="intendedUse" />
    </ToolGroup>
  )
}

export default AudioTools
