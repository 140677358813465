import * as React from 'react'
import { Localized } from '@fluent/react'
import { DraftFile } from '../../api/draft'
import Button from '../../components/ui/Button'
import Dialog from '../../components/ui/Dialog'

import './index.css'

interface DraftFilePreviewProps {
  draftId: string
  file: DraftFile
  onClose: () => void
  showRemove: boolean
  onFileRemove: (file: DraftFile) => void
  showReplace: boolean
  onFileReplace: (file: DraftFile) => void
}

const DraftFilePreview = ({
  draftId, file, onClose, showRemove, onFileRemove, showReplace, onFileReplace,
}: DraftFilePreviewProps) => (
  <Dialog
    size="big"
    l10nId="draft-file-preview"
    vars={{ file: file.name }}
    placeholder="Preview of file"
    onClose={onClose}
  >
    <div className="draft-file-preview">
      <div className="draft-file-preview__buttons">
        <a href={`/api/v1/drafts/${draftId}/files/${file.name}`} target="_blank" rel="noopener noreferrer">
          <Localized id="draft-file-preview-open-in-new-window">
            Open in new window
          </Localized>
        </a>
        {
          showRemove
            && <Button id="draft-file-preview-button-remove" type="danger" clickHandler={() => onFileRemove(file)}>
              <Localized id="draft-file-preview-button-remove">
                Remove
              </Localized>
            </Button>
        }
        {
          showReplace && <Button id="draft-file-preview-button-replace" clickHandler={() => onFileReplace(file)}>
            <Localized id="draft-file-preview-button-replace">
              Replace
            </Localized>
          </Button>
        }
      </div>
      <div className="draft-file-preview__element">
        <DraftFileComp draftId={draftId} file={file} />
      </div>
    </div>
  </Dialog>
)

export default DraftFilePreview

const DraftFileComp = ({ draftId, file }: { draftId: string, file: DraftFile }) => {
  const url = `/api/v1/drafts/${draftId}/files/${file.name}`

  if (/^image/.test(file.mime)) {
    return <img src={url} alt={file.name} />
  } else if (/^audio/.test(file.mime)) {
    return <audio src={url} />
  } else if (/^video/.test(file.mime)) {
    return <video src={url} />
  }

  return <Localized id="draft-file-preview-unknown">
    We could not display preview for this file.
  </Localized>
}
