import * as React from 'react'
import { Quotation } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'

interface QuotationProps extends RenderElementProps {
  element: Quotation
}

const QuotationComp = ({ children, attributes }: QuotationProps) => {
  return <blockquote {...attributes} className="quotation">
    {children}
  </blockquote>
}

export default QuotationComp
