import * as React from 'react'
import Button from '../../components/ui/Button'
import Dialog from '../../components/ui/Dialog'

import './index.css'

interface ValidationErrorsDialogProps {
  errors: string | null
  title?: string
  onClear?: () => void
  clearValidationErrors?: () => void
}

const ValidationErrorsDialog = (props: ValidationErrorsDialogProps) => {
  const [errors, setErrors] = React.useState<string | null>(null)
  const [showDetails, setShowDetails] = React.useState(false)

  const clear = () => {
    if (props.clearValidationErrors) props.clearValidationErrors()
    setErrors(null)
    if (props.onClear) props.onClear()
  }

  const toggleDetails = () => {
    setShowDetails(state => !state)
  }

  React.useEffect(() => {
    if (props.errors) {
      setErrors(props.errors.replace('\n', '<br/>'))
    }
  }, [props.errors])

  if (!errors) return null

  return (
    <Dialog
      size="medium"
      l10nId={props.title || 'validation-errors-title'}
      placeholder="Validation errors were found"
      onClose={clear}
    >
      <div className="dialog__buttons">
        <Button l10nId="validation-errors-button-ok" clickHandler={clear} data-testid='button-ok' >
          OK
        </Button>
        <Button l10nId="validation-errors-button-details" clickHandler={toggleDetails} data-testid='button-details' >
          Details
        </Button>
      </div>
      {
        showDetails ?
          <div className="validation-details" dangerouslySetInnerHTML={{ __html: errors }} data-testid='details'/>
          : null
      }
    </Dialog>
  )
}

export default ValidationErrorsDialog
