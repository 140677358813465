import * as React from 'react'
import Select from 'react-select'
import { NameTerm, Term } from 'cnx-designer'
import { Editor, Node, NodeEntry, Path, Text, Transforms } from 'slate'
import { Suggestion } from 'slate-suggestions'
import { Localized } from '@fluent/react'
import Input from '../../../../../../../components/ui/Input'
import Button from '../../../../../../../components/ui/Button'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'
import NameIndex from './components/NameIndex'
import './index.css'

interface TermToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const INDEX_KINDS = ['default', 'name', 'foreign']

const TermTools = ({ editor, ...props }: TermToolsProps) => {
  const selection = useCurrentSelection()
  const [indexSelection, setIndexSelection] = React.useState()

  const onSelectIndex = (select: any) => {
    setIndexSelection(select)
    Transforms.setNodes(editor, { index: select.value }, { at: path })
  }
  const node = Editor.above(editor, { at: selection, match: Term.isTerm })

  React.useEffect(() => {
    if (node) {
      setIndexSelection({
        value: node[0].index,
        label:
        <Localized id={"editor-tools-term-choose-index-"+ node[0].index}>
          {node[0].index}
        </Localized>,
      } as any)
    }
    // eslint-disable-next-line
  }, [selection])

  if (!node) return null
  const path = node[1]

  return (
    <ToolGroup
      title="editor-tools-term-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('termTools')}
    >
      <label className="terms__label">
        <span className="terms__title">
          <Localized id="editor-tools-term-index-form">
            Index form
          </Localized>
        </span>
        <Input
          type="text"
          value={
            'reference' in node[0] ? node[0].reference : deriveReferenceForm(editor, path)
          }
          onChange={(val: string | null) => Transforms.setNodes(editor, { reference: val }, { at: path })}
        />
      </label>
      <label className="terms__label">
        <span className="terms__title">
          <Localized id="editor-tools-term-index">
            Index in which this term should appear
          </Localized>
        </span>
        <Select
          className="toolbox__select react-select"
          value={indexSelection}
          placeholder={
            <Localized id="editor-tools-term-choose-index-palceholder">
              Select
            </Localized>
          }
          onChange={onSelectIndex}
          options={INDEX_KINDS.map(ind => ({
            value: ind,
            label:
            <Localized id={"editor-tools-term-choose-index-"+ind}>
              {ind}
            </Localized>,
          }))}
        />
      </label>
      {Term.isNameTerm(node[0]) ? <NameIndex node={node as NodeEntry<NameTerm>} editor={editor} /> : null}
      <Button
        l10nId="editor-tools-term-remove"
        className="terms__button"
        clickHandler={() => Transforms.unwrapNodes(editor, { at: path })}
      >
        Remove term
      </Button>
    </ToolGroup>
  )
}

export default TermTools

function deriveReferenceForm(editor: Editor, path: Path): string {
  const nodes = Node.nodes(editor, {
    from: Editor.path(editor, path, { edge: 'start' }),
    to: Editor.path(editor, path, { edge: 'end' }),
    pass: ([node]) => Suggestion.isRemove(node),
  })

  let text = ""

  for (const [node] of nodes) {
    if (Text.isText(node) && !Suggestion.isRemove(node)) text += node.text
  }

  return text
}
