import * as React from 'react'
import { Exercise, Transforms } from 'cnx-designer'
import { Localized } from '@fluent/react'
import { Editor } from 'slate'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import Classes from '../Classes'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

interface ExerciseToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const ExerciseTools = ({ editor, ...props }: ExerciseToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Exercise.isExercise })
  if (!node) return null

  const [exercise] = node
  const hasCommentary = exercise.children[exercise.children.length - 1]
    .type === 'exercise_commentary'

  return (
    <ToolGroup
      title="editor-tools-exercise-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('exerciseTools')}
    >
      <Button
        id="editor-tools-exercise-insert-solution"
        clickHandler={() => Transforms.insertSolution(editor)}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="check" />
        <Localized id="editor-tools-exercise-insert-solution">
          Add solution
        </Localized>
      </Button>
      <Button
        id="editor-tools-exercise-insert-commentary"
        clickHandler={() => Transforms.insertCommentary(editor)}
        isDisabled={hasCommentary}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="comment" />
        <Localized id="editor-tools-exercise-insert-commentary">
          Add commentary
        </Localized>
      </Button>
      <Classes node={node} />
    </ToolGroup>
  )
}

export default ExerciseTools
