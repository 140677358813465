import * as React from 'react'
import { Localized } from '@fluent/react'
import { Team } from '../../../../../../api'
import { ProcessSlot } from '../../../../../../api/process'
import Button from '../../../../../../components/ui/Button'
import Input from '../../../../../../components/ui/Input'
import { useLoadAllTeamRoles } from '../../../../../../api/hooks'
import './index.css'

interface SlotProps {
  team: Team
  slot: ProcessSlot
  onChange: (slot: ProcessSlot) => any
  remove: (slot: ProcessSlot) => any
}

const Slot = ({ team, slot, onChange, remove }: SlotProps) => {
  const [name, setName] = React.useState(slot.name)
  const [autofill, setAutofill] = React.useState(slot.autofill)
  const [selectedRoles, setSelectedRoles] = React.useState(new Set(slot.roles))
  const [roles] = useLoadAllTeamRoles(team)

  React.useEffect(() => {
    setName(slot.name)
    setAutofill(slot.autofill)
    setSelectedRoles(new Set(slot.roles))
  }, [slot])

  const removeSlot = () => {
    remove(slot)
  }

  const handleNameChange = (name: string) => {
    setName(name)
    onChange({ ...slot, name })
  }

  const handleAutofillChange = (autofill: boolean) => {
    setAutofill(autofill)
    onChange({ ...slot, autofill })
  }

  const handleRoleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    const roleId = Number(e.currentTarget.dataset.id)
    if (!roleId) return

    setSelectedRoles(prevState => {
      const newSelectedRoles = new Set(prevState)
      if (newSelectedRoles.has(roleId)) {
        newSelectedRoles.delete(roleId)
      } else {
        newSelectedRoles.add(roleId)
      }

      onChange({
        ...slot,
        roles: Array.from(newSelectedRoles),
      })

      return newSelectedRoles
    })
  }


  return (
    <div className="process-slot">
      <div className="process-form__title">
        <h3>
          <Localized id="process-form-slot-name">
            Slot name:
          </Localized>
        </h3>
        <div className="process-form__title-controls">
          <Button l10nId="process-form-remove" type="danger" clickHandler={removeSlot}>
            Remove
          </Button>
        </div>
      </div>
      <label>
        <Input
          type="text"
          value={name}
          onChange={handleNameChange}
          minLength={1}
          trim={true}
          required
        />
      </label>
      <label>
        <span>
          <Localized id="process-form-slot-autofill">
            Automatically assign users:
          </Localized>
        </span>
        <Input
          type="checkbox"
          value={autofill}
          onChange={handleAutofillChange}
        />
      </label>
      <label>
        <span>
          <Localized id="process-form-slot-role">
            Roles:
          </Localized>
        </span>
        <div className="process-slot__roles">
          {
            roles.map(r => (
              <Button
                key={r.id}
                id="process-form-slot-role"
                clickHandler={handleRoleClick}
                dataId={r.id.toString()}
                className={`process-slot__role ${selectedRoles.has(r.id) ? 'selected' : ''}`}
              >
                {r.name}
              </Button>
            ))
          }
        </div>
      </label>
    </div>
  )
}

export default Slot
