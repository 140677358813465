import * as React from 'react'
import * as Counters from 'react-counters'
import { Problem } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { EXERCISE, EXERCISE_PROBLEM } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface ExerciseProblemProps extends RenderElementProps {
  element: Problem
}

const ExerciseProblemComp = ({ children, attributes }: ExerciseProblemProps) => {
  const { document } = React.useContext(LOCALIZATION)
  Counters.useCounter(attributes.ref, { increment: [EXERCISE_PROBLEM] })
  const exercise = Counters.useCounterValue(attributes.ref, EXERCISE)
  const problem = Counters.useCounterValue(attributes.ref, EXERCISE_PROBLEM)
  const message = document.getString('exercise-problem-label', { exercise, problem })

  return <div {...attributes} className="exercise-problem" data-label={message}>
    {children}
  </div>
}

export default ExerciseProblemComp
