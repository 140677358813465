import * as React from 'react'
import { FilesError } from 'react-files'
import { useDispatch } from 'react-redux'
import { Team } from '../../../../../api'
import Resource, { ResourceKind } from '../../../../../api/resource'
import { addAlert } from '../../../../../store/actions/alerts'
import Button from '../../../../../components/ui/Button'
import Dialog from '../../../../../components/ui/Dialog'
import Spinner from '../../../../../components/Spinner'
import TeamSelector from '../../../../../components/TeamSelector'
import Input from '../../../../../components/ui/Input'
import FileUploader from '../../../../../containers/FilesUploader'

export const ACCEPTED_FILE_TYPES = [
  'application/*',
  'audio/*',
  'image/*',
  'text/*',
  'video/*',
]

interface AddResourceDialogProps {
  show: boolean
  directory?: Resource
  onClose: () => void
  afterCreate: (resource: Resource) => void
}

const AddResourceDialog = (props: AddResourceDialogProps) => {
  const [resourceType, setResourceType] = React.useState<ResourceKind | null>(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [resourceName, setResourceName] = React.useState('')
  const [files, setFiles] = React.useState<File[]>([])
  const [team, setTeam] = React.useState<Team | null>(null)
  const dispatch = useDispatch()

  const onFilesError = (error: FilesError, _: File) => {
    dispatch(addAlert('error', 'file-upload-error', { code: error.code }))
  }

  const addResource = async () => {
    setIsUploading(true)

    if (!team) return

    const data: { name: string, team: number, parent?: string, file?: File } = {
      name: resourceName,
      team: team.id,
    }

    if (props.directory && props.directory.kind === 'directory') {
      data.parent = props.directory.id
    }

    if (resourceType === 'file' && files[0]) {
      data.file = files[0]
    }

    await Resource.create(data)
      .then(resource => {
        dispatch(addAlert('success', 'resources-add-success'))
        props.afterCreate(resource)
      })

    props.onClose()
  }

  React.useEffect(() => {
    if (!props.show) {
      setResourceName('')
      setResourceType(null)
      setFiles([])
      setIsUploading(false)
      setTeam(null)
    }
  }, [props.show])

  if (!props.show) return null

  return (
    <Dialog
      size="medium"
      l10nId="resources-add-title"
      placeholder="Add new resource"
      onClose={props.onClose}
    >
      {
        !resourceType ?
          <div className="dialog__buttons">
            <Button l10nId="resources-add-folder" clickHandler={() => setResourceType('directory')}>
              Add folder
            </Button>
            <Button l10nId="resources-add-file" clickHandler={() => setResourceType('file')}>
              Add file
            </Button>
          </div>
          : isUploading ?
            <Spinner />
            :
            <div className="resources__dialog-content">
              <Input
                type="text"
                l10nId="resources-name-placeholder"
                value={resourceName}
                onChange={val => setResourceName(val)}
                minLength={3}
                required
              />
              <TeamSelector
                permission="resources:manage"
                onChange={setTeam}
              />
              {
                resourceType === 'file' ?
                  <FileUploader
                    onFilesChange={setFiles}
                    onFilesError={onFilesError}
                    multiple={false}
                    accepts={ACCEPTED_FILE_TYPES}
                    optional={false}
                  /> :
                  null
              }
              <div className="dialog__buttons">
                <Button l10nId="resources-add-cancel" clickHandler={props.onClose}>
                  Cancel
                </Button>
                <Button
                  l10nId="resources-add-confirm"
                  clickHandler={addResource}
                  isDisabled={
                    resourceName.length < 3 ||
                    (resourceType === 'file' && !files[0]) || !team
                  }
                >
                  Confirm
                </Button>
              </div>
            </div>
      }
    </Dialog>
  )
}

export default AddResourceDialog
