import * as React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { Localized } from '@fluent/react'
import { FilesError } from 'react-files'
import { Book, Module, Team } from '../../api'
import store from '../../store'
import { archiveModule } from '../../store/actions/modules'
import { State } from '../../store/reducers'
import { addAlert } from '../../store/actions/alerts'
import { confirmDialog, getCurrentLng } from '../../helpers'
import BookSelector from '../../components/BookSelector'
import LimitedUI from '../../components/LimitedUI'
import ModulesList from '../../components/ModulesList'
import PageNavigation from '../../components/PageNavigation'
import Input from '../../components/ui/Input'
import FilesUploader from '../../containers/FilesUploader'

import './index.css'

interface ModulesPickerProps {
  // Team in which new module will be created.
  team: Team | number
  // Show only modules from specific team.
  filterByTeam?: Team | number
  filterByBook?: boolean
  filterByBookInitialValue?: Book
  onModuleClick: (mod: Module) => void
  onModuleAdd: (mod: Module) => void
}

type SelectOption = { value: string, label: string }

// value is an ISO tag for given language
const LANGUAGES: SelectOption[] = [
  { value: 'en', label: 'English' },
  { value: 'pl', label: 'Polski' },
]

const mapStateToProps = ({ modules }: State) => ({
  modules,
})

interface ModulesPickerState {
  moduleTitleValue: string
  moduleLanguage: SelectOption
  filterInput: string
  book: Book | null
}

class ModulesPicker extends React.Component<ModulesPickerProps> {
  state: ModulesPickerState = {
    moduleTitleValue: '',
    moduleLanguage: LANGUAGES.find(lng => lng.value === getCurrentLng('iso')) || LANGUAGES[0],
    filterInput: '',
    book: this.props.filterByBookInitialValue || null,
  }

  private handleModuleClick = (mod: Module) => {
    this.props.onModuleClick(mod)
  }

  private updateModuleTitleValue = (val: string) => {
    this.setState({ moduleTitleValue: val })
  }

  private updateModuleLanguage = (lang: SelectOption) => {
    this.setState({ moduleLanguage: lang })
  }

  private addNewModule = (e: React.FormEvent) => {
    e.preventDefault()

    const { moduleTitleValue: title, moduleLanguage: lang } = this.state
    const { team } = this.props
    const teamId = team instanceof Team ? team.id : team

    Module.create(title, lang.value, teamId)
      .then(mod => {
        this.props.onModuleAdd(mod)
        Module.pagination().tryToAdd(mod)
        store.dispatch(addAlert('success', 'module-list-add-module-alert-success', { title }))
      })
      .catch(e => {
        const err = e?.response?.data?.error.replace(/:/g, '-')
        switch (err) {
        case 'import-zip-index-missing':
        case 'import-invalid-xml':
        case 'import-zip-invalid':
          store.dispatch(addAlert('error', err))
          break
        default:
          store.dispatch(addAlert('error', 'Unkown error'))
        }
      })
  }

  private onFilesError = (error: FilesError, _file: File) => {
    store.dispatch(addAlert('error', 'file-upload-error', { code: error.code }))
  }

  private showRemoveModuleDialog = async (mod: Module) => {
    const res = await confirmDialog({
      title: 'module-list-delete-module-title',
      vars: { module: mod.title },
      buttons: {
        cancel: 'module-list-delete-module-cancel',
        confirm: 'module-list-delete-module-confirm',
      },
      showCloseButton: false,
    })

    if (res === 'confirm') {
      this.removeModule(mod)
    }
  }

  private removeModule = (mod: Module) => {
    store.dispatch(
      archiveModule(
        mod,
        {
          success: {
            l10nId: 'module-list-delete-module-alert-success',
            args: { title: mod.title },
          },
        },
      ),
    )
  }

  private handleFilterInput = (val: string | null) => {
    if (val !== this.state.filterInput) {
      this.setState({ filterInput: val })
    }
  }

  private handleBookChange = (option: { value: Book | null } | null) => {
    this.setState({ book: option ? option.value : null })
  }

  public render() {
    const { book, moduleTitleValue, moduleLanguage } = this.state
    console.log('moduleLanguage', moduleLanguage)
    return (
      <div className="modulesList">
        <LimitedUI permissions="module:edit">
          <div className="modulesList__new">
            <form onSubmit={this.addNewModule}>
              <div className="modulesList__top-bar">
                <Input
                  type="text"
                  l10nId="module-list-add-module-title"
                  value={moduleTitleValue}
                  onChange={this.updateModuleTitleValue}
                  minLength={3}
                  required
                />
                <Select
                  className="react-select"
                  value={moduleLanguage}
                  options={LANGUAGES}
                  onChange={this.updateModuleLanguage as any}
                />
                <Localized id="module-list-add-module-submit" attrs={{ value: true }}>
                  <input
                    type="submit"
                    value="Add new"
                    disabled={moduleTitleValue.length < 3 || !moduleLanguage}
                  />
                </Localized>
              </div>
            </form>
          </div>
        </LimitedUI>
        <div className="modulesList__filter">
          <Input
            l10nId="module-list-search-box"
            onChange={this.handleFilterInput}
          />
        </div>
        {
          this.props.filterByBook &&
            <BookSelector
              team={this.props.team}
              value={book ? { value: book, label: book.title } : null}
              onChange={this.handleBookChange}
            />
        }
        <PageNavigation
          pagination={Module.pagination()}
          usePageParam={false}
          Component={({ items }) => (
            <ModulesList
              modules={items}
              team={this.props.team}
              filter={this.state.filterInput}
              book={book || undefined}
              onModuleClick={this.handleModuleClick}
              onModuleRemoveClick={this.showRemoveModuleDialog}
            />
          )}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(ModulesPicker)

const FilesUploaderText = <Localized id="modules-list-files-uploader">
  Drop .zip file here or click to upload (optional).
  File has to contain index.cnxml and may contains other files like images.
</Localized>
