import * as React from 'react'
import {
  Admonition,
  AltText,
  Code,
  CrossReference,
  Foreign,
  Image,
  Link,
  List,
  ListItem,
  Problem,
  Quotation,
  Section,
  Solution,
  Term,
} from 'cnx-designer'
import { useSelector } from 'react-redux'
import { Editor, Range, Text, Transforms } from 'slate'
import { SuggestionsEditor } from 'slate-suggestions'
import { State } from '../../../../../../../store/reducers'
import Button, { ButtonHandler } from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import Tooltip from '../../../../../../../components/ui/Tooltip'
import { Mark, MarkKind } from '../../../../../../../screens/app/Draft/plugins/Marks'
import { Highlight } from '../../../../../../../screens/app/Draft/plugins/Highlights'
import { SourceElement } from '../../../../../../../screens/app/Draft/plugins/SourceElements'
import { validateParents, Validator } from '../../../../../../../screens/app/Draft/utils'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import { CurrentDraftContext } from '../../../../../../../screens/app/Draft'
import './index.css'

type Format = MarkKind
  | 'code'
  | 'term'
  | 'foreign'

const FORMATS: [Format, string][] = [
  ['strong', 'editor-tools-format-button-strong'],
  ['emphasis', 'editor-tools-format-button-emphasis'],
  ['underline', 'editor-tools-format-button-underline'],
  ['superscript', 'editor-tools-format-button-superscript'],
  ['subscript', 'editor-tools-format-button-subscript'],
  ['code', 'editor-tools-format-button-code'],
  ['term', 'editor-tools-format-button-term'],
  ['foreign', 'editor-tools-format-button-foreign'],
]

// List of valid validators for direct parents above current selection.
// Paragraphs are ignored during this process.
const VALID_LIST_PARENTS: Validator[] = [
  Editor.isEditor,
  Admonition.isAdmonition,
  Problem.isProblem,
  Solution.isSolution,
  Section.isSection,
  List.isList,
  ListItem.isListItem,
  Highlight.isHighlight,
]

const VALID_PARENTS_SOURCE_ELEMENT = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Problem.isProblem,
  Solution.isSolution,
  Quotation.isQuotation,
  Highlight.isHighlight,
]

// List of invalid validators for direct parents above current selection.
// Paragraphs are ignored during this process.
const INVALID_HIGHLIGHT_PARENTS: Validator[] = [
  Image.isImage,
  Link.isLink,
  CrossReference.isCrossReference,
]

// Code is an invalid partent too, but it is added later in the validation process
const INVALID_FORMAT_TOOLS_PARENTS: Validator[] = [
  AltText.isAltText,
  SourceElement.isSourceElement,
]

const FormatTools = ({ editor }: { editor: Editor }) => {
  const selection = useCurrentSelection()
  const currentDraftContext = React.useContext(CurrentDraftContext)
  const user = useSelector((state: State) => state.user.user)

  const isActive = (format: Format) => {
    switch (format) {
    case 'code':
      return Editor.above(editor, { at: selection, match: Code.isCode })
    case 'foreign':
      return Editor.above(editor, { at: selection, match: Foreign.isForeign })
    case 'term':
      return Editor.above(editor, { at: selection, match: Term.isTerm })
    default:
      return Mark.isActive(editor, selection, format)
    }
  }

  const applyFormat: ButtonHandler = ev => {
    ev.preventDefault()

    const format = (ev.currentTarget as HTMLButtonElement).dataset.id
    if (!format) return

    let matcher: typeof Code.isCode | typeof Foreign.isForeign | typeof Term.isTerm | undefined
    let node: Code | Foreign | Term

    switch (format) {
    case 'code':
      matcher = Code.isCode
      node = { type: 'code', placement: 'line', children: [] }
      break
    case 'foreign':
      matcher = Foreign.isForeign
      node = { type: 'foreign', children: [] }
      break
    case 'term':
      matcher = Term.isTerm
      node = { type: 'term', children: [] }
      break
    default:
      Mark.toggleMark(editor, selection, format as MarkKind)
      return
    }

    if (isActive(format)) {
      Transforms.unwrapNodes(editor, { at: selection, match: matcher })
    } else if (selection && Range.isExpanded(selection)) {
      Transforms.wrapNodes(editor, node, { match: Text.isText, split: true })
    } else {
      Transforms.insertNodes(editor, { ...node, children: [{ text: ' ' }] }, { select: true })
      Transforms.move(editor, { distance: 1, reverse: true })
    }
  }

  const clear: ButtonHandler = ev => {
    ev.preventDefault()
    Mark.removeMarks(editor)
  }

  const formatList = () => {
    if (Editor.above(editor, { match: List.isList }) || List.isList(Editor.node(editor, selection!)[0])) {
      Transforms.unwrapNodes(editor, { at: selection, match: List.isList })
      return
    }

    Transforms.wrapNodes(editor, { type: 'list', style: 'bulleted', bullet: 'bulleted', children: [] })
  }

  const selectionContainsNode = (type: string) => {
    if (!selection || Range.isCollapsed(selection)) return false
    return Boolean(Editor.fragment(editor, selection).find(n => n.type === type))
  }

  const toggleHighlight = () => {
    if (!user) return
    if (SuggestionsEditor.isSuggestionsEditor(editor)) {
      SuggestionsEditor.withoutSuggesting(editor, () => {
        Highlight.toggleHighlight(editor, selection, user)
      })
    } else {
      Highlight.toggleHighlight(editor, selection, user)
    }
  }

  const toggleSource = () => {
    const codeLine = Editor.above(editor, { match: Code.isCodeLine })
    if (codeLine && codeLine[0].language === 'cnxml-fragment') {
      Transforms.unwrapNodes(editor, { at: selection, match: Code.isCodeLine })
      return
    }

    const source: SourceElement = {
      type: 'code',
      placement: 'line',
      language: 'cnxml-fragment',
      children: [{ text: ' ' }],
    }

    if (selection && Range.isExpanded(selection)) {
      Transforms.wrapNodes(editor, source, { match: Text.isText, split: true })
    } else {
      Transforms.insertNodes(editor, source, { select: true })
      Transforms.move(editor, { distance: 1, reverse: true })
    }
  }

  return (
    <div className="toolbox-format">
      {FORMATS.map(([format, l10nId]) => (
        <Tooltip
          l10nId={l10nId}
          direction="up"
          className="toolbox__button--with-tooltip"
          key={format}
        >
          <Button
            id={l10nId}
            className={`toolbox__button--only-icon ${isActive(format) ? 'active' : ''}`}
            isDisabled={validateParents(
              editor,
              format === 'code' ? INVALID_FORMAT_TOOLS_PARENTS : [...INVALID_FORMAT_TOOLS_PARENTS, Code.isCode],
              selection)}
            dataId={format}
            clickHandler={applyFormat}
          >
            <Icon size="small" name={format} />
          </Button>
        </Tooltip>
      ))}
      <Tooltip
        l10nId="editor-tools-format-button-list"
        direction="up"
        className="toolbox__button--with-tooltip"
      >
        <Button
          id="editor-tools-format-button-list"
          className="toolbox__button--only-icon"
          isDisabled={!validateParents(editor, VALID_LIST_PARENTS, selection)}
          clickHandler={formatList}
          dataId="list"
        >
          <Icon size="small" name="list-ul" />
        </Button>
      </Tooltip>
      <Tooltip
        l10nId="editor-tools-format-button-highlight"
        direction="up"
        className="toolbox__button--with-tooltip"
      >
        <Button
          id="editor-tools-format-button-highlight"
          className="toolbox__button--only-icon"
          isDisabled={
            validateParents(editor, INVALID_HIGHLIGHT_PARENTS, selection)
            || selectionContainsNode('highlight')
          }
          clickHandler={toggleHighlight}
          dataId="highlight"
        >
          <Icon size="small" name="highlight" />
        </Button>
      </Tooltip>
      <Tooltip
        l10nId="editor-tools-format-button-source-element"
        direction="up"
        className="toolbox__button--with-tooltip"
      >
        <Button
          id="editor-tools-format-button-source-element"
          className="toolbox__button--only-icon"
          isDisabled={
            currentDraftContext.state.currentDraftPermissions.includes('propose-changes')
            || !validateParents(editor, VALID_PARENTS_SOURCE_ELEMENT, selection)
          }
          clickHandler={toggleSource}
          dataId="source"
        >
          <Icon size="small" name="file-code" />
        </Button>
      </Tooltip>
      <Tooltip
        l10nId="editor-tools-format-button-clear"
        direction="up"
        className="toolbox__button--with-tooltip"
      >
        <Button
          id="editor-tools-format-button-clear"
          className="toolbox__button--only-icon"
          isDisabled={Editor.marks(editor) === null}
          clickHandler={clear}
          dataId="clear"
        >
          <Icon size="small" name="close" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default FormatTools
