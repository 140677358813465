import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import store from '../store'
import APICache from './apicache'
import Pagination from './pagination'
import Role, { RoleData } from './role'
import TeamMember, { NewTeamMemberData } from './teammember'
import { elevated, extend } from './utils'
import { addTask } from '../store/actions/app'

export type TeamID = number

export type TeamPermission =
'member:add'
| 'member:remove'
| 'member:assign-role'
| 'member:edit-permissions'
| 'role:edit'
| 'book:edit'
| 'module:edit'
| 'editing-process:edit'
| 'editing-process:manage'
| 'resources:manage'
| 'content:export'

export type TeamStatus = 'archived'

/**
 * Team data.
 */
export type TeamData = {
  id: number
  name: string
  roles: RoleData[]
  repository?: string | null
  status?: TeamStatus
  ssh_key?: string
}

export type NewRoleData = {
  name: string
  permissions?: TeamPermission[]
}

export interface TeamUpdate {
  name?: string,
  repository?: string | null,
}

export default class Team {
  /**
   * Fetch specific team from the server.
   */
  static load(id: TeamID, fromCache = true): Promise<Team> {
    return APICache.getOrSetNested(
      ['Teams', id.toString()],
      fromCache,
      async () => new Team((await axios.get(`teams/${id}`)).data),
    )
  }

  static pagination(fromCache = true): Pagination<Team> {
    return APICache.getOrSetNestedSync(
      ['Pagination', 'teams'],
      fromCache,
      new Pagination(
        'teams',
        (data: TeamData) => new Team(data),
        teams => APICache.update('Teams', {
          ...teams.reduce((obj, team) => ({ ...obj, [team.id.toString()]: team }), {}),
        }),
      ),
    )
  }

  /**
   * Create a new team.
   *
   * This function requires team:manage permission.
   *
   * @param name
   */
  static async create(name: string): Promise<Team> {
    const team = new Team((await elevated(() => axios.post('teams', { name }))).data)
    APICache.update('Teams', { [team.id.toString()]: team })
    return team
  }

  /**
   * Team's ID.
   */
  id!: TeamID

  /**
   * Team's name.
   */
  name!: string

  /**
   * Roles in this team.
   */
  roles: Pagination<Role>

  /**
   * URL of a Git repository used by this team, `null` if this team uses no
   * repository, `undefined` if current user is not allowed to see
   * the repository settings
   */
  repository?: string | null

  /**
   * Team status.
   */
  status?: TeamStatus

  ssh_key?: string

  constructor(data: TeamData) {
    extend(this, data)

    this.roles = Role.pagination(this.id)
  }

  /**
   * Update a team.
   *
   * This function requires team:manage permission.
   *
   * @param data - object with data to update
   */
  async update(data: TeamUpdate): Promise<Team> {
    if (data.name != null) {
      this.name = data.name
    }
    if (data.repository != null) {
      this.repository = data.repository
    }

    const rsp = await elevated(() => axios.put(`teams/${this.id}`, data))

    if ('__task' in rsp.data) {
      store.dispatch(addTask(rsp.data.__task))
      delete rsp.data.__task
    }

    APICache.updateNested(['Teams', this.id.toString()], data)
    return new Team(rsp.data)
  }

  /**
   * Get data for specific role in team.
   */
  getRole(id: number): Promise<Role> {
    return Role.load(id, this.id)
  }

  /**
   * Create role in team.
   */
  async createRole({ name, permissions = [] }: NewRoleData): Promise<Role> {
    const role = await Role.create(this.id, name, permissions)
    return role
  }

  /**
   * Get team members.
   */
  members(): Pagination<TeamMember> {
    return TeamMember.pagination(this.id)
  }

  /**
   * Get data for specific member in team.
   */
  member(id: number, fromCache = true): Promise<TeamMember> {
    return TeamMember.load(id, this.id, fromCache)
  }

  /**
   * Add member to team.
   */
  addMember(data: NewTeamMemberData): Promise<AxiosResponse> {
    return TeamMember.add(this.id, data)
  }

  /**
   * Archive this team.
   *
   * This method requires team:manage permission and elevated session.
   */
  archive(): Promise<AxiosResponse> {
    this.status = 'archived'
    return elevated(() => axios.post(`teams/${this.id}/archive`, { archive: true }))
  }

  /**
   * Restore this team from archive.
   *
   * This method requires team:manage permission and elevated session.
   */
  restore(): Promise<AxiosResponse> {
    this.status = undefined
    return elevated(() => axios.post(`teams/${this.id}/archive`, { archive: false }))
  }
}
