import { FluentVariable } from '@fluent/bundle'
import Notification, { Data } from '../../api/notification'
import {
  PUSH_ALERT,
  REMOVE_ALERT,
  REMOVE_ALERT_TIMEOUT,
  REMOVE_NOTIFICATION_TIMEOUT,
} from '../../store/constants'
import { Alert, AlertDataKind } from '../../store/types'
import { createUUID } from '../../helpers'

export interface AddAlert {
  (dispatch: any): void
}

export interface AddNotification {
  (dispatch: any): void
}

export interface PushAlert {
  type: PUSH_ALERT,
  data: Alert,
}

export interface RemoveAlert {
  type: REMOVE_ALERT,
  data: Alert,
}

export type AlertsAction = PushAlert | RemoveAlert

export const addAlert = (
  kind: AlertDataKind,
  message: string,
  args: Record<string, FluentVariable> = {},
): AddAlert => (dispatch: React.Dispatch<AlertsAction>) => {
  const alert: Alert = {
    id: createUUID(),
    kind: 'alert',
    data: {
      kind,
      message,
      arguments: args,
    },
  }

  dispatch(pushAlert(alert))

  // Do not hide alert with error messages.
  if (kind !== 'error') {
    setTimeout(() => {
      dispatch(removeAlert(alert))
    }, REMOVE_ALERT_TIMEOUT)
  }
}

export const addNotification = (
  data: Notification<Data>,
): AddNotification => (dispatch: React.Dispatch<AlertsAction>) => {
  const alert: Alert = {
    id: createUUID(),
    kind: 'notification',
    data,
  }

  dispatch(pushAlert(alert))
  setTimeout(() => {
    dispatch(removeAlert(alert))
  }, REMOVE_NOTIFICATION_TIMEOUT)
}

export const pushAlert = (alert: Alert): PushAlert => ({
  type: PUSH_ALERT,
  data: alert,
})

export const removeAlert = (alert: Alert): RemoveAlert => ({
  type: REMOVE_ALERT,
  data: alert,
})
