import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, AlertInfo } from '../../store/types'
import { removeAlert as removeAlertAction } from '../../store/actions/alerts'
import AlertComp from './Alert'

import './index.css'

const Alerts = () => {
  const alerts = useSelector(state => state.alerts.alerts)
  const dispatch = useDispatch()

  const removeAlert = (alert: Alert) => {
    dispatch(removeAlertAction(alert))
  }

  const renderedAlerts: Alert[] = []
  const renderedErrorAlerts: AlertInfo[] = []

  for (const alert of alerts) {
    if (alert.kind === 'alert' && alert.data.kind === 'error') {
      renderedErrorAlerts.push(alert)
    } else {
      renderedAlerts.push(alert)
    }
  }

  return (
    <>
      <div className="alerts">
        {
          renderedAlerts.length > 0 ?
            <ul className="alerts__list">
              {
                renderedAlerts.map(
                  alert => <AlertComp key={alert.id} alert={alert} onClick={removeAlert} />,
                )
              }
            </ul>
            : null
        }
      </div>
      <div className="alerts--error">
        <ul className="alerts__list">
          {
            renderedErrorAlerts.map(
              alert => <AlertComp key={alert.id} alert={alert} onClick={removeAlert} />,
            )
          }
        </ul>
      </div>
    </>
  )
}

export default Alerts
