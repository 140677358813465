import * as React from 'react'
import { FluentVariable } from '@fluent/bundle'
import { Localized } from '@fluent/react'
import Icon from '../../../../../../../components/ui/Icon'

import './index.css'

interface ToolGroupProps {
  children: React.ReactNode
  title: string
  toggleState: boolean
  className?: string
  onToggle: () => any
  vars?: Record<string, FluentVariable>
  elems?: Record<string, React.ReactElement>
}

const ToolGroup = (
  { children, title, onToggle, toggleState: open, className, vars, elems }: ToolGroupProps,
) => {
  const toggle = (ev: React.MouseEvent) => {
    ev.preventDefault()
    onToggle()
  }

  return <div className={"toolgroup" + (open ? ' open' : '')}>
    <div className="toolgroup--header" onClick={toggle}>
      <Icon size='small' name={open ? 'arrow-down' : 'arrow-right'} />
      <Localized id={title} vars={vars} elems={elems}>{title}</Localized>
    </div>
    <div className={`toolgroup--content ${className ? className :''}`}>
      {children}
    </div>
  </div>
}

export default ToolGroup
