import * as React from 'react'
import { RenderElementProps, RenderLeafProps } from 'slate-react'
import MentionComp, { MentionProps } from '../Mention'
import { Hyperlink, Mention } from './plugins'

export const renderLeaf = ({ attributes, leaf, children }: RenderLeafProps) => {
  if (leaf.emphasis) {
    children = <em {...attributes}>{children}</em>
  }

  if (leaf.strong) {
    children = <strong {...attributes}>{children}</strong>
  }

  return <span {...attributes}>{children}</span>
}

export const renderElement = (props: RenderElementProps) => {
  if (Hyperlink.isHyperlink(props.element)) {
    return (
      <a
        {...props.attributes}
        href={props.element.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    )
  } else if (Mention.isMention(props.element)) {
    return <MentionComp {...props as MentionProps} />
  }

  return <p {...props.attributes}>{props.children}</p>
}
