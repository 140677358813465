import * as Sentry from '@sentry/react'
import * as React from 'react'
import { User } from '../api'

export default function useIsInSuperMode(user?: User | null) {
  const [state, setState] = React.useState(false)

  React.useEffect(() => {
    let mounted = true
    if (user) {
      user.isInSuperMode()
        .then(val => {
          if (mounted) setState(val)
        })
        .catch(() => {
          Sentry.captureMessage(
            `Couldn't check if user is in super mode, user id: ${user.id}`, 'warning')
          if (mounted) setState(false)
        })
    }
    return () => { setState(false); mounted = false }
  }, [user])

  return state
}
