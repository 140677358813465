import * as React from 'react'
import Select from 'react-select'
import { Bullet, BulletedList, List, NumberStyle } from 'cnx-designer'
import { Editor, NodeEntry, Transforms } from 'slate'
import { Localized } from '@fluent/react'
import { Transforms as ListTransforms } from 'slate-lists'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import Classes from '../Classes'
import { OnToggle } from '../../index'
import { getNodeAtSelection } from '../../../../utils'
import './index.css'

interface ListToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const ListTools = ({ editor, ...props }: ListToolsProps) => {
  const selection = useCurrentSelection()

  const node = getNodeAtSelection(editor, List.isList, selection) as NodeEntry<List> | undefined

  if (!node) return null

  const [list, path] = node

  const changeListStyle = (option: StyleOption | null) => {
    // A reasonable default
    if (option == null) {
      Transforms.setNodes(editor, { style: 'enumerated', numberStyle: 'arabic' })
      return
    }

    const props = 'bullet' in option
      ? { style: 'bulleted', bullet: option.bullet }
      : { style: 'enumerated', numberStyle: option.style }

    Transforms.setNodes(editor, props, { at: path })
  }

  const indentList = () => {
    Editor.withoutNormalizing(editor, () => {
      ListTransforms.increaseDepth(editor)
      selectNextStyle(editor, list)
    })
  }

  const styleKey: StyleOption = BulletedList.isBulletedList(list)
    ? { value: `bullet/${list.bullet}`, bullet: list.bullet } as BulletStyleOption
    : { value: `number/${list.numberStyle}`, style: list.numberStyle } as NumberStyleOption

  return (
    <ToolGroup
      title="editor-tools-list-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('listTools')}
    >
      <div className="toolbox-list__nesting">
        <Button
          id="editor-tools-list-decrease-level"
          clickHandler={() => ListTransforms.decreaseDepth(editor)}
          className="toolbox__button--insert"
        >
          <Icon size="small" name="outdent" />
          <Localized id="editor-tools-list-decrease-level">
            Decrease item level
          </Localized>
        </Button>
        <Button
          id="editor-tools-list-increase-level"
          clickHandler={indentList}
          className="toolbox__button--insert"
        >
          <Icon size="small" name="indent" />
          <Localized id="editor-tools-list-increase-level">
            Increase item level
          </Localized>
        </Button>
      </div>
      <Select<BulletStyleOption | NumberStyleOption, false, GroupedOption>
        className="toolbox__select react-select toolbox-list__style"
        classNamePrefix="react-select"
        options={LIST_STYLES}
        value={styleKey}
        formatGroupLabel={formatGroupLabel}
        formatOptionLabel={OptionLabel}
        onChange={changeListStyle}
      />
      <Classes node={node} />
    </ToolGroup>
  )
}

export default ListTools

interface BulletStyleOption {
  value: string
  bullet: Bullet
  example: string
}

interface NumberStyleOption {
  value: string
  style: NumberStyle
  example: string
}

type StyleOption = BulletStyleOption | NumberStyleOption

interface GroupedOption {
  label: string
  options: BulletStyleOption[] | NumberStyleOption[]
}

const BULLET_STYLES: BulletStyleOption[] = [
  { value: 'bullet/bullet', bullet: 'bullet', example: '\u2022' },
  { value: 'bullet/open-circle', bullet: 'open-circle', example: '\u25E6' },
  { value: 'bullet/pilcrow', bullet: 'pilcrow', example: '\u00b6' },
  { value: 'bullet/rpilcrow', bullet: 'rpilcrow', example: '\u204b' },
  { value: 'bullet/asterisk', bullet: 'asterisk', example: '*' }, // TODO: probably not * exactly
  { value: 'bullet/dash', bullet: 'dash', example: '-' }, // TODO: probably not - exactly
]

const NUMBER_STYLES: NumberStyleOption[] = [
  { value: 'number/arabic', style: 'arabic', example: '1, 2, 3, ..., 98, 99, 100' },
  { value: 'number/upper-alpha', style: 'upper-alpha', example: 'a, b, c, ..., z, aa, ab' },
  { value: 'number/lower-alpha', style: 'lower-alpha', example: 'A, B, C, ..., Z, AA, AB' },
  { value: 'number/lower-roman', style: 'lower-roman', example: 'i, ii, iii, ..., xcviii, xcix, c' },
  { value: 'number/upper-roman', style: 'upper-roman', example: 'I, II, III, ..., XCVIII, XCIX, C' },
]

const LIST_STYLES: GroupedOption[] = [
  {
    label: 'editor-tools-list-itemized',
    options: BULLET_STYLES,
  },
  {
    label: 'editor-tools-list-enumerated',
    options: NUMBER_STYLES,
  },
]

const formatGroupLabel = (group: GroupedOption) => (
  <div>
    <Localized id={group.label} />
  </div>
)

function OptionLabel(option: StyleOption) {
  if ('bullet' in option) {
    const { bullet, example } = option
    return <>
      <Localized id="editor-tools-list-style" vars={{ style: bullet }}>{bullet}</Localized>
      <span className="toolbox-list__bullet-example">{example}</span>
    </>
  }
  const { style, example } = option
  return <>
    <div>
      <Localized id="editor-tools-list-style" vars={{ style }}>{style}</Localized>
    </div>
    <div className="toolbox-list__numbering-example">{example}</div>
  </>
}

const STYLE_CYCLE_ITEMIZED = ['bullet', 'open-circle', 'dash']
const STYLE_CYCLE_ENUMERATED = ['arabic', 'lower-alpha', 'lower-roman', 'upper-alpha', 'upper-roman']

function selectNextStyle(editor: Editor, node: List) {
  const [[, path]] = Editor.levels(editor, { match: List.isList, reverse: true })

  if (BulletedList.isBulletedList(node)) {
    const style = STYLE_CYCLE_ITEMIZED[STYLE_CYCLE_ITEMIZED.indexOf(node.bullet) + 1]
    Transforms.setNodes(editor, { style: 'bulleted', bullet: style }, { at: path })
  } else {
    const style = STYLE_CYCLE_ENUMERATED[STYLE_CYCLE_ENUMERATED.indexOf(node.numberStyle as any) + 1]
    Transforms.setNodes(editor, { style: 'enumerated', numberStyle: style }, { at: path })
  }
}
