import * as React from 'react'
import * as Counters from 'react-counters'
import { Commentary } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { EXERCISE_COMMENTARY } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface CommentaryProps extends RenderElementProps {
  element: Commentary
}

const CommentaryComp = ({ children, attributes }: CommentaryProps) => {
  const { document } = React.useContext(LOCALIZATION)
  Counters.useCounter(attributes.ref, { increment: [EXERCISE_COMMENTARY] })
  const message = document.getString('exercise-commentary-label')

  return (
    <div
      className="exercise-commentary"
      data-label={message}
      {...attributes}
    >
      {children}
    </div>
  )
}

export default CommentaryComp
