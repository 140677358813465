import * as React from 'react'
import { Redirect, Route, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as api from './api'
import { TeamPermission } from './api/team'
import ErrorBoundary from './components/ErrorBoundary'
import Alerts from './components/Alerts'
import Navigation from './components/Navigation'
import Spinner from './components/Spinner'
import ConfirmDialog from './components/ConfirmDialog'
import ConversationTabs from './components/ConversationTabs'
import Admin from './screens/app/Admin'
import Tasks from './containers/Tasks'
import Dashboard from './screens/app/Dashboard'
import NotificationsCentre from './screens/app/NotificationsCentre'
import Books from './screens/app/Books'
import Book from './screens/app/Book'
import Module from './screens/app/Module'
import ModuleLabels from './screens/app/ModuleLabels'
import Draft from './screens/app/Draft'
import DraftDetais from './screens/app/DraftDetails'
import Resources from './screens/app/Resources'
import Profile from './screens/app/Profile'
import Settings from './screens/app/Settings'
import Helpdesk from './screens/app/Helpdesk'
import Invitations from './screens/app/Invitations'
import Processes from './screens/app/Processes'
import Error404 from './screens/app/Error404'
import Teams from './screens/app/Teams'
import Modules from './screens/app/Modules'
import BookDetails from './screens/app/Book/BookDetails/BookDetails'
import Import from './screens/app/Import'
import * as userActions from './store/actions/user'
import * as appActions from './store/actions/app'
import * as modulesActions from './store/actions/modules'
import { State } from './store/reducers/index'
import './assets/styles/shared.css'
import './assets/styles/print.css'
import { addAlert } from './store/actions/alerts'

/**
 * One of those permissions is required to go to the /teams route since in this screen
 * user can add, remove and manage users in teams.
 */
export const ROUTE_TEAMS_PERMISSIONS: TeamPermission[] = [
  'member:add',
  'member:assign-role',
  'member:edit-permissions',
  'member:remove',
]

const App = () => {
  const { isLoadingUser, user } = useSelector((state: State) => state.user)
  const dispatch = useDispatch()

  const InvitationsGuard = () => {
    const isSuper = Boolean(user?.is_super)
    // Temporarily allow inviting external users only to super admins
    return isSuper // || user.allPermissions.has('member:add')
  }

  const TeamsGuard = () => {
    const isSuper = Boolean(user?.is_super)
    return isSuper || ROUTE_TEAMS_PERMISSIONS.some(p => user?.allPermissions.has(p))
  }

  const ProcessesGuard = () => {
    const isSuper = Boolean(user?.is_super)
    return Boolean(isSuper || user?.allPermissions.has('editing-process:edit'))
  }

  const AdminGuard = () => Boolean(user?.is_super)

  React.useEffect(() => {
    window.onunhandledrejection = (error : any) => {
      if (error.reason.response.status >= 400 && error.reason.response.status < 500) {
        dispatch(addAlert('error', error.reason.response.data.message))
      }
    }
    dispatch(userActions.fetchUser())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (user) {
      dispatch(appActions.syncExpandedGroups())
      dispatch(modulesActions.syncModulesLabels())
      api.Events.create()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Router>
      <ErrorBoundary>
        {
          !isLoadingUser && user ?
            <div className="container container--main">
              <Navigation />
              <main>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route path="/notifications" component={NotificationsCentre} />
                  <Route exact path="/books" component={Books} />
                  <Route exact path="/books/details/:id" component={BookDetails} />
                  <Route path="/books/:id" component={Book} />
                  <Route path="/modules/labels" component={ModuleLabels} />
                  <Route exact path="/modules/:id" component={Module} />
                  <Route exact path="/drafts/:id" component={DraftDetais} />
                  <Route path="/drafts/:id/:action" component={Draft} />
                  <Route exact path="/resources" component={Resources} />
                  <Route path="/resources/:id" component={Resources} />
                  <Route path="/users/:id" component={Profile} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/helpdesk/:id?" component={Helpdesk} />
                  <Route path="/modules/:id?/:action?" component={Modules} />
                  <SecureRoute
                    path="/invitations"
                    component={Invitations}
                    routeGuard={InvitationsGuard}
                    redirectToPathWhenFail="/"
                  />
                  <SecureRoute
                    path="/teams"
                    component={Teams}
                    routeGuard={TeamsGuard}
                    redirectToPathWhenFail="/"
                  />
                  <SecureRoute
                    path="/processes/:id?/:action?"
                    component={Processes}
                    routeGuard={ProcessesGuard}
                    redirectToPathWhenFail="/"
                  />
                  <Route path="/login" component={() => <ReplaceURL to="/account/login" />} />
                  <Route path="/elevate" component={() => <ReplaceURL to="/account/elevate" />} />
                  <SecureRoute
                    path="/admin"
                    component={Admin}
                    routeGuard={AdminGuard}
                    redirectToPathWhenFail="/"
                  />
                  <Route path="/import/:id" component={Import} />
                  <Route component={Error404} />
                </Switch>
                <ConversationTabs />
              </main>
              <Tasks />
              <Alerts />
              <ConfirmDialog />
            </div>
            : <Spinner />
        }
      </ErrorBoundary>
    </Router>
  )
}

export default App

interface SecureRouteProps extends RouteProps {
  redirectToPathWhenFail: string
  routeGuard: () => boolean
}

const SecureRoute = ({ routeGuard, redirectToPathWhenFail, ...routeProps }: SecureRouteProps) => {
  const [loading, setLoading] = React.useState(true)
  const [passedGuard, setPassedGuard] = React.useState(false)

  const checkRouteGuard = () => {
    setPassedGuard(routeGuard())
    setLoading(false)
  }

  React.useEffect(() => {
    checkRouteGuard()
  })

  if (loading) return null

  if (passedGuard) return <Route {...routeProps} />

  return <Redirect to={redirectToPathWhenFail} />
}

const ReplaceURL = ({ to }: { to: string }) => {
  window.location.replace(to)
  return null
}
