import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import APICache from './apicache'
import { TeamID } from './team'
import { elevated, extend } from './utils'
import Pagination from './pagination'

export type ResourceStatus = 'archived'

export type ResourceData = {
  id: string
  name: string
  parent: string | null
  kind: ResourceKind
  team: TeamID
  status?: ResourceStatus
}

export type ResourceKind = 'directory' | 'file'

export type NewResourceData = {
  name: string
  team: TeamID
  parent?: string
  file?: File
}

export default class Resource {
  /**
   * Load a resource by ID.
   */
  static load(id: string, fromCache = true): Promise<Resource> {
    return APICache.getOrSetNested(
      ['Resources', id],
      fromCache,
      async () => new Resource((await axios.get(`resources/${id}`)).data),
    )
  }

  static pagination(id?: string, fromCache = true): Pagination<Resource> {
    return APICache.getOrSetNestedSync(
      ['Pagination', `resources${id ? `-${id}-content` : ''}`],
      fromCache,
      new Pagination(
        `resources${id ? `/${id}/content` : ''}`,
        (data: ResourceData) => new Resource(data),
        resources => APICache.update(
          'Resources',
          resources.reduce((obj, resource) => ({ ...obj, [resource.id]: resource }), {}),
        ),
      ),
    )
  }

  /**
   * Create a new resource.
   *
   * This function requires elevated permissions: 'resources:manage' in targeted team.
   *
   * @param name   name of the resource.
   * @param team   id of the team in which to create the resource.
   * @param parent optional parent id.
   * @param file optional file, if omitted "folder" will be created.
   */
  static async create({ name, team, parent, file }: NewResourceData): Promise<Resource> {
    const data: FormData = new FormData()

    data.append('name', name)
    data.append('team', team.toString())
    if (parent) {
      data.append('parent', parent)
    }
    if (file) {
      data.append('file', file)
    }

    const resource = new Resource((await axios.post('resources', data)).data)
    return APICache.setNested(['Resources', resource.id], resource)
  }

  /**
   * Resurce's ID.
   */
  id!: string

  /**
   * Resource's name.
   */
  name!: string

  /**
   * Resource's parent.
   */
  parent?: string

  /**
   * Resource's kind.
   */
  kind!: ResourceKind

  /**
   * ID of team for which this resource belongs.
   */
  team!: TeamID

  /**
   * Resource status.
   */
  status?: ResourceStatus

  constructor(data: ResourceData) {
    extend(this, data)
  }

  /**
   * Fetch this resource's content.
   *
   * This method is supported only for resource of kind file.
   */
  async content(): Promise<File> {
    if (this.kind === 'file') {
      // Do not cache this
      const rsp = await axios.get(`resources/${this.id}/content`)
      return rsp.data
    }
    throw new Error(`Resource.content() method is supported only for resources of kind 'file'`)
  }

  /**
   * Fetch this resource's children.
   *
   * This method is supported only for resource of kind directory.
   */
  children(fromCache = true): Pagination<Resource> {
    if (this.kind === 'directory') {
      return Resource.pagination(this.id, fromCache)
    }
    throw new Error(
      `Resource.children() method is supported only for resources of kind 'directory'`)
  }

  /**
   * Update name of this resource.
   *
   * This method requires elevated permissions: 'resources:manage'
   */
  async changeName(name: string): Promise<void> {
    await elevated(() => axios.put(`resources/${this.id}`, { name }))
    APICache.updateNested(['Resources', this.id], { name })
  }

  /**
   * Replace contents of this resource.
   *
   * This method requires permissions: 'resources:manage'
   */
  async replaceContent(file: File): Promise<void> {
    await axios.put(`resources/${this.id}/content`, file, {
      headers: {
        'Content-Type': 'multipart',
      },
    })
  }

  /**
   * Archive this resource.
   *
   * This method requires resources:manage permission.
   */
  archive(): Promise<AxiosResponse> {
    this.status = 'archived'
    return axios.post(`resources/${this.id}/archive`, { archive: true })
  }

  /**
   * Restore this resource from archive.
   *
   * This method requires resources:manage permission.
   */
  restore(): Promise<AxiosResponse> {
    this.status = undefined
    return axios.post(`resources/${this.id}/archive`, { archive: false })
  }
}
