import * as React from 'react'
import Header from '../../../components/Header'
import GeneralSettings from './components/GeneralSettings'
import LoginMethods from './components/LoginMethods'

import './index.css'

const Settings = () => <section className="section--wrapper">
  <Header l10nId="settings-view-title" title="Settings" />
  <div className="section__content">
    <div className="settings">
      <GeneralSettings />
      <LoginMethods />
    </div>
  </div>
</section>

export default Settings
