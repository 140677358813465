import * as React from 'react'
import { Book } from '../../../../api'
import { useLoadSpecificBookPart } from '../../../../api/hooks'

import './index.css'

interface RemoteSourceProps {
  book: Book | string,
  module: string,
  onClick: (ev: React.MouseEvent) => void,
}

const RemoteSource = ({ book, module, onClick }: RemoteSourceProps) => {
  const [part] = useLoadSpecificBookPart(book, module)

  return part
    ? <div className="target-source" data-id={part.id} onClick={onClick}>
      <span className="target-source__title">{part.title}</span>
    </div>
    : null
}

export default RemoteSource
