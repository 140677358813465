export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN'
export type FETCH_USER_BEGIN = typeof FETCH_USER_BEGIN

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export type FETCH_USER_SUCCESS = typeof FETCH_USER_SUCCESS

export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export type FETCH_USER_FAILURE = typeof FETCH_USER_FAILURE

export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export type CLEAR_USER_DATA = typeof CLEAR_USER_DATA

export const UPDATE_USER = 'UPDATE_USER'
export type UPDATE_USER = typeof UPDATE_USER


export const SET_REFERENCE_TARGETS = 'SET_REFERENCE_TARGETS'
export type SET_REFERENCE_TARGETS = typeof SET_REFERENCE_TARGETS

export const CREATE_LABEL = 'CREATE_LABEL'
export type CREATE_LABEL = typeof CREATE_LABEL

export const REMOVE_LABEL = 'REMOVE_LABEL'
export type REMOVE_LABEL = typeof REMOVE_LABEL

export const SET_LABELS = 'SET_LABELS'
export type SET_LABELS = typeof SET_LABELS

export const UPDATE_LABEL = 'UPDATE_LABEL'
export type UPDATE_LABEL = typeof UPDATE_LABEL

export const SET_MODULES_WITH_LABELS = 'SET_MODULES_WITH_LABELS'
export type SET_MODULES_WITH_LABELS = typeof SET_MODULES_WITH_LABELS

export const ADD_LABEL_TO_MODULE = 'ADD_LABEL_TO_MODULE'
export type ADD_LABEL_TO_MODULE = typeof ADD_LABEL_TO_MODULE

export const REMOVE_LABEL_FROM_MODULE = 'REMOVE_LABEL_FROM_MODULE'
export type REMOVE_LABEL_FROM_MODULE = typeof REMOVE_LABEL_FROM_MODULE


export const PUSH_ALERT = 'PUSH_ALERT'
export type PUSH_ALERT = typeof PUSH_ALERT

export const REMOVE_ALERT = 'REMOVE_ALERT'
export type REMOVE_ALERT = typeof REMOVE_ALERT

export const REMOVE_ALERT_TIMEOUT = 7000
export const REMOVE_NOTIFICATION_TIMEOUT = 5000

export const SET_LOCALE = 'SET_LOCALE'
export type SET_LOCALE = typeof SET_LOCALE

export const SET_AVAILABLE_LOCALES = 'SET_AVAILABLE_LOCALES'
export type SET_AVAILABLE_LOCALES = typeof SET_AVAILABLE_LOCALES

export const SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG'
export type SHOW_CONFIRM_DIALOG = typeof SHOW_CONFIRM_DIALOG

export const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG'
export type CLOSE_CONFIRM_DIALOG = typeof CLOSE_CONFIRM_DIALOG

export const SET_SELECTED_TEAMS = 'SET_SELECTED_TEAMS'
export type SET_SELECTED_TEAMS = typeof SET_SELECTED_TEAMS

export const SET_EXPANDED_GROUPS = 'SET_EXPANDED_GROUPS'
export type SET_EXPANDED_GROUPS = typeof SET_EXPANDED_GROUPS

export const ADD_TASK = 'ADD_TASK'
export type ADD_TASK = typeof ADD_TASK

export const REMOVE_TASK = 'REMOVE_TASK'
export type REMOVE_TASK = typeof REMOVE_TASK

export const SET_ACTIVE_TASK = 'SET_ACTIVE_TASK'
export type SET_ACTIVE_TASK = typeof SET_ACTIVE_TASK

export const SET_TASK_STATE = 'SET_TASK_STATE'
export type SET_TASK_STATE = typeof SET_TASK_STATE

export const SET_TASK_PROGRESS = 'SET_TASK_PROGRESS'
export type SET_TASK_PROGRESS = typeof SET_TASK_PROGRESS

export const ADD_TASK_ERROR = 'ADD_TASK_ERROR'
export type ADD_TASK_ERROR = typeof ADD_TASK_ERROR


export const ADD_CONVERSATION = 'ADD_CONVERSATION'
export type ADD_CONVERSATION = typeof ADD_CONVERSATION

export const ADD_MESSAGE = 'ADD_MESSAGE'
export type ADD_MESSAGE = typeof ADD_MESSAGE

export const ADD_MESSAGES = 'ADD_MESSAGES'
export type ADD_MESSAGES = typeof ADD_MESSAGES

export const REPLACE_LOADING_MESSAGE = 'REPLACE_LOADING_MESSAGE'
export type REPLACE_LOADING_MESSAGE = typeof REPLACE_LOADING_MESSAGE

export const ADD_CONVERSATION_TAB = 'ADD_CONVERSATION_TAB'
export type ADD_CONVERSATION_TAB = typeof ADD_CONVERSATION_TAB

export const REMOVE_CONVERSATION_TAB = 'REMOVE_CONVERSATION_TAB'
export type REMOVE_CONVERSATION_TAB = typeof REMOVE_CONVERSATION_TAB

export const JOIN_CONVERSATION = 'JOIN_CONVERSATION'
export type JOIN_CONVERSATION = typeof JOIN_CONVERSATION
