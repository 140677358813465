import * as React from 'react'

import './index.css'

const HighlightText = ({ text, highlight }: { text: string, highlight: string }) => {
  // Required because of typescript error:
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
  if (!highlight) return text as any as JSX.Element

  const rgx = new RegExp(highlight, 'gi')
  const textWithHighlights = text.replace(
    rgx,
    match => `<span class="highlight-text">${match}</span>`,
  )

  return <span dangerouslySetInnerHTML={{ __html: textWithHighlights }} />
}

export default HighlightText
