/** @jsxImportSource slate-cnxml */

import * as CNXML from 'slate-cnxml'
import { Editor, NodeEntry, Path, Range, Element as SlateElement, Text, Transforms } from 'slate'
import { User } from '../../../../../api'

import './index.css'

export type HighlightColor =
  'lightblue' |
  'green' |
  'red' |
  'yellow'

export const HIGHLIGHT_COLORS: HighlightColor[] = [
  'lightblue',
  'green',
  'red',
  'yellow',
]

export interface Highlight extends SlateElement {
  type: 'highlight'
  color: HighlightColor
  textContent: string
  user: number
}

export const Highlight = {
  isHighlight: (value: any): value is Highlight => {
    return SlateElement.isElement(value) && value.type === 'highlight'
  },
  toggleHighlight: (editor: Editor, selection: Range | undefined, user: User) => {
    if (!selection) return

    let highlight = Editor.above(editor, { at: selection.anchor, match: Highlight.isHighlight })
    if (!highlight && Range.isExpanded(selection)) {
      highlight = Editor.above(editor, { at: selection.focus, match: Highlight.isHighlight })
    }

    if (highlight) {
      Transforms.unwrapNodes(editor, { at: selection, match: Highlight.isHighlight })
      return
    }

    if (Range.isCollapsed(selection)) return

    Transforms.wrapNodes(
      editor,
      { type: 'highlight', user: user.id, children: [] },
      { match: Text.isText, split: true })
  },
}

export const normalizeHighlight = (editor: Editor, entry: NodeEntry): boolean => {
  const [element, path] = entry
  let normalizations = 0
  if (Highlight.isHighlight(element)) {
    if (element.textContent === undefined) {
      Transforms.setNodes(editor, { textContent: '' }, { at: path })
      normalizations++
    }
    if (Editor.isEmpty(editor, element)) {
      Transforms.removeNodes(editor, { at: path })
      return true
    }
    if (!HIGHLIGHT_COLORS.includes(element.color)) {
      Transforms.setNodes(editor, { color: HIGHLIGHT_COLORS[0] }, { at: path })
      normalizations++
    }
    if (typeof element.user !== 'number' && Number(element.user)) {
      Transforms.setNodes(editor, { user: Number(element.user) }, { at: path })
      normalizations++
    } else if (!element.user) {
      Transforms.removeNodes(editor, { at: path })
      return true
    }
    for (const [i, child] of element.children.entries()) {
      if (Highlight.isHighlight(child)) {
        Transforms.unwrapNodes(editor, { at: [...path, i] })
        return true
      }
    }
  }
  return normalizations !== 0
}

/**
 * Deserialize Highlight element. Return true if it was dserialized and false otherwise.
 */
export const deserializeHighlight = (
  editor: CNXML.DeserializingEditor,
  el: Element,
  at: Path,
): boolean => {
  if (el.namespaceURI === CNXML.EDITING_NAMESPACE && el.localName === 'highlight') {
    let user: string | number | null = Number(el.getAttribute('user'))
    if (!user) user = el.getAttribute('user')

    CNXML.buildElement(editor, el, at, {
      type: 'highlight',
      color: el.getAttribute('color'),
      user,
      textContent: el.getAttribute('text'),
    }, { ...CNXML.INLINE })
    CNXML.normalizeLine(editor, at)
    return true
  }
  return false
}

/**
 * Serialize Highlight element to xml element.
 */
export const serializeHighlight: CNXML.PartialSerializer<Text, any> = (node, attrs, children) => {
  if (Highlight.isHighlight(node)) {
    return (
      <highlight
        {...attrs}
        xmlns={CNXML.EDITING_NAMESPACE}
        color={node.color}
        user={node.user}
        text={node.textContent}
      >
        {children}
      </highlight>
    )
  }
  return null
}
