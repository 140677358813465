import * as Sentry from '@sentry/react'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Draft from '../../api/draft'
import { addAlert } from '../../store/actions/alerts'
import EditableText from '../../components/EditableText'

import './index.css'

interface TitleProps {
  draft: Draft
}

const Title = ({ draft }: TitleProps) => {
  const user = useSelector(state => state.user.user)
  const dispatch = useDispatch()
  const [titleInput, setTitleInput] = React.useState(draft.title)

  const changeTitle = async (newTitle: string) => {
    setTitleInput(newTitle)

    try {
      await draft.update({ title: newTitle })
      dispatch(addAlert('success', 'editor-document-title-save-alert-success'))
    } catch (e) {
      dispatch(addAlert('error', 'editor-document-title-save-alert-error'))
      Sentry.captureMessage(
        `Couldn't update title for draft: ${draft.module}. Error ${e}`,
        'warning',
      )
    }
  }

  React.useEffect(() => {
    setTitleInput(draft.title)
  }, [draft])

  const permissions = draft.permissions || []
  const editPermission = permissions.some(p => p === 'edit')
  const userIsProcessManager = user && user.hasPermissionsInTeam(
    'editing-process:manage',
    draft.team)

  return (
    <div className="draft-title">
      {
        editPermission || userIsProcessManager
          ? (
            <EditableText
              text={titleInput}
              onAccept={changeTitle}
              placeholder="editor-document-title-placeholder"
            />
          )
          : titleInput
      }
    </div>
  )
}

export default Title
