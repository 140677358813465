import * as React from 'react'
import { FluentBundle, FluentResource } from '@fluent/bundle'
import { negotiateLanguages } from '@fluent/langneg'
import { ReactLocalization, useLocalization } from '@fluent/react'
import { MANIFEST } from '../../../../../l10n'
import Load from '../../../../../components/Load'
import Spinner from '../../../../../components/Spinner'
import { LOCALIZATION } from '../../constants'

interface LocalizationLoaderProps {
  locale: string,
  l10n: ReactLocalization,
  children: React.ReactNode,
}

async function loader(
  { locale }: { locale: string },
): Promise<{ l10n: ReactLocalization }> {
  const manifest = await MANIFEST

  const languages = negotiateLanguages(
    [locale],
    manifest.available.application,
    { defaultLocale: manifest.default },
  )

  const bundles = await Promise.all(languages.map(async language => {
    const rsp = await fetch(`/locale/${language}/document.ftl`)
    const bundle = new FluentBundle(language, { useIsolating: false })
    bundle.addResource(new FluentResource(await rsp.text()))
    return bundle
  }))

  return { l10n: new ReactLocalization(bundles) }
}

function LocalizationLoader({ children, l10n: document }: LocalizationLoaderProps) {
  const { l10n: ui } = useLocalization()

  return <LOCALIZATION.Provider value={{ document, ui }}>
    {children}
  </LOCALIZATION.Provider>
}

export default Load(loader, ['locale'], undefined, undefined, EditorLoader)<{
  locale: string, children: React.ReactNode
}>(LocalizationLoader)

function EditorLoader() {
  return (
    <div className="editor">
      <Spinner />
    </div>
  )
}
