import axios from '../config/axios'
import APICache from './apicache'
import Pagination from './pagination'
import { ProcessID } from './process'
import { VersionID } from './processversion'

// Interfaces are matching data from https://github.com/openstax-poland/adaptarr-server/blob/master/doc/api/events.md

export interface Document {
  id: string
  title: string
}

interface Slot {
  id: number
  name: string
}

interface Step {
  id: number
  name: string
}

interface User {
  id: number
  name: string
}

interface NotificationData {
  id: number
  timestamp: string
}

export interface ConversationNewMessage extends NotificationData {
  kind: 'conversation:new-message'
  conversation: number
  author: User
  message: {
    id: number
  }
}

export const isConversationNewMessage = (data: Data): data is ConversationNewMessage => {
  return data.kind === 'conversation:new-message'
}

export interface ProcessCanceled extends NotificationData {
  kind: 'process-end:cancelled'
  module: Document
}

export const isProcessCanceled = (data: Data): data is ProcessCanceled => {
  return data.kind === 'process-end:cancelled'
}

export interface ProcessFinished extends NotificationData {
  kind: 'process-end:finished'
  module: Document
}

export const isProcessFinished = (data: Data): data is ProcessFinished => {
  return data.kind === 'process-end:finished'
}

export interface ProcessChangeAdvanced extends NotificationData {
  kind: 'process-change:advanced'
  draft: Document
  step: Step
}

export const isProcessChangeAdvanced = (data: Data): data is ProcessChangeAdvanced => {
  return data.kind === 'process-change:advanced'
}

export interface ProcessChangeChangedProcess extends NotificationData {
  kind: 'process-change:changed-process'
  draft: Document
  process: {
    id: [ProcessID, VersionID]
    name: string
  }
  step: Step
  slots: Slot[]
}

export const isProcessChangeChangedProcess = (data: Data): data is ProcessChangeChangedProcess => {
  return data.kind === 'process-change:changed-process'
}

export interface SlotAssignmentFilled extends NotificationData {
  kind: 'slot-assignment:filled'
  slot: Slot
  draft: Document
}

export const isSlotAssignmentFilled = (data: Data): data is SlotAssignmentFilled => {
  return data.kind === 'slot-assignment:filled'
}

export interface SlotAssignmentVacated extends NotificationData {
  kind: 'slot-assignment:vacated'
  slot: Slot
  draft: Document
}

export const isSlotAssignmentVacated = (data: Data): data is SlotAssignmentVacated => {
  return data.kind === 'slot-assignment:vacated'
}

export interface SupportNewTicket extends NotificationData {
  kind: 'support:new-ticket'
  author: User
  ticket: {
    id: number
    title: string
  }
}

export const isSupportNewTicket = (data: Data): data is SupportNewTicket => {
  return data.kind === 'support:new-ticket'
}

export type Data = ConversationNewMessage
  | ProcessCanceled
  | ProcessFinished
  | ProcessChangeAdvanced
  | ProcessChangeChangedProcess
  | SlotAssignmentFilled
  | SlotAssignmentVacated
  | SupportNewTicket

export default class Notification<T extends Data> {
  static pagination(fromCache = true): Pagination<Notification<Data>> {
    return APICache.getOrSetNestedSync(
      ['Pagination', 'notifications'],
      fromCache,
      new Pagination(
        'notifications',
        (data: Data) => new Notification(data),
        notis => APICache.set(
          'Notifications',
          notis.reduce((obj, noti) => ({ ...obj, [noti.data.id.toString()]: noti }), {})),
      ),
    )
  }

  data: T

  constructor(data: T) {
    this.data = data
  }

  /**
   * Delete this notification.
   */
  async delete(): Promise<Notification<T>> {
    await axios.delete(`notifications/${this.data.id}`)
    APICache.invalidate(['Notifications', this.data.id.toString()])
    return this
  }
}
