const parser = new DOMParser()

export const getXmlError = (xml: string): null | string => {
  const xmlDoc = parser.parseFromString(xml.trim(), 'text/xml')
  const error = xmlDoc.getElementsByTagName('parsererror')
  if (error.length) {
    return error[0].textContent
  }
  return null
}
