import * as React from 'react'
import { Team, TeamMember } from '../../../../../api'
import LimitedUI from '../../../../../components/LimitedUI'
import AddMember from './AddMember'
import Member from './Member'
import './index.css'

interface MembersManagerProps {
  members: TeamMember[]
  team: Team
  onDelete: (member: TeamMember) => void
  onUpdate: (member: TeamMember, data: Partial<TeamMember>) => void
}

const MembersManager = ({ members, team, onDelete, onUpdate }: MembersManagerProps) => <>
  <LimitedUI team={team} permissions="member:add">
    <AddMember team={team} />
  </LimitedUI>
  <ul className="teams__rolesList">
    {
      members.map(m => (
        <Member
          key={m.user}
          member={m}
          team={team}
          afterMemberRemove={onDelete}
          afterRoleChange={updated => onUpdate(m, { role: updated.role })}
          afterMemberPermissionsChange={
            updated => onUpdate(m, { permissions: updated.permissions })
          }
        />
      ))
    }
  </ul>
</>

export default MembersManager
