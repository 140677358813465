import { Editor, NodeEntry, Transforms } from 'slate'
import { Equation, Math } from './interfaces'

export function normalize(editor: Editor, entry: NodeEntry): boolean {
  const [node, path] = entry

  if (Math.isMath(node) && node.placement === 'block') {
    const [parent] = Editor.parent(editor, path)

    if (!Equation.isEquation(parent)) {
      Transforms.wrapNodes(
        editor,
        { type: 'equation', children: [] },
        { at: path },
      )
      return true
    }
  }

  return false
}
