/** LaTeX snippet */
export interface Snippet {
  /**
   * The snippet itself
   */
  snippet: string
  /** Value to display in snippet selector instead of {@code snippet} */
  preview?: string
}

/** LaTeX snippet with variants */
export interface Variant extends Snippet {
  /** Variants of this snippet */
  variants?: Snippet[]
}

/** Group of related snippets */
export interface SnippetGroup {
  /** Fluent key for group's name */
  name: string
  /** Snippets in this group */
  snippets: Variant[]
  /** Uncommon snippets in this group */
  uncommon?: Variant[]
}

export const SNIPPETS = [
  {
    name: 'math-editor-snippet-group-style',
    snippets: [
      // (openstax-poland/adaptarr-backlog#84): Disable text styling until we
      // know it is needed.
      // { snippet: '\\mathbb{#0}', preview: '\\mathbb{Abc}' },
      // { snippet: '\\mathbf{#0}', preview: '\\mathbf{Abc}' },
      // { snippet: '\\mathcal{#0}', preview: '\\mathcal{Abc}' },
      // { snippet: '\\mathfrak{#0}', preview: '\\mathfrak{Abc}' },
      // { snippet: '\\mathit{#0}', preview: '\\mathit{Abc}' },
      // { snippet: '\\mathrm{#0}', preview: '\\mathrm{Abc}' },
      // { snippet: '\\mathscr{#0}', preview: '\\mathscr{Abc}' },
      // { snippet: '\\mathsf{#0}', preview: '\\mathsf{Abc}' },
      // { snippet: '\\mathtt{#0}', preview: '\\mathtt{Abc}' },
      // { snippet: '\\boldsymbol{#0}', preview: '\\boldsymbol{\\alpha}\\boldsymbol{\\beta}\\boldsymbol{\\gamma}' },
      // { snippet: '\\text{#0}', preview: '\\text{text}' },
      // { snippet: '\\textbf{#0}', preview: '\\textbf{text}' },
      // { snippet: '\\textit{#0}', preview: '\\textit{text}' },
      // { snippet: '\\textmd{#0}', preview: '\\textmd{text}' },
      // { snippet: '\\textrm{#0}', preview: '\\textrm{text}' },
      // { snippet: '\\textsc{#0}', preview: '\\textsc{text}' },
      // { snippet: '\\textsf{#0}', preview: '\\textsf{text}' },
      // { snippet: '\\textsl{#0}', preview: '\\textsl{text}' },
      // { snippet: '\\texttt{#0}', preview: '\\texttt{text}' },
      // { snippet: '\\textup{#0}', preview: '\\textup{text}' },
      { snippet: '\\vec{#0}', preview: '\\vec{a}' },
      { snippet: '\\acute{#0}', preview: '\\acute{a}' },
      { snippet: '\\bar{#0}', preview: '\\bar{a}' },
      { snippet: '\\check{#0}', preview: '\\check{a}' },
      { snippet: '\\ddot{#0}', preview: '\\ddot{a}' },
      { snippet: '\\tilde{#0}', preview: '\\tilde{a}' },
      { snippet: '\\breve{#0}', preview: '\\breve{a}' },
      { snippet: '\\dot{#0}', preview: '\\dot{a}' },
      { snippet: '\\grave{#0}', preview: '\\grave{a}' },
      { snippet: '\\hat{#0}', preview: '\\hat{a}' },
      { snippet: '\\mathring{#0}', preview: '\\mathring{a}' },
    ],
    uncommon: [
      { snippet: '\\overline{#0}', preview: '\\overline{aaa}' },
      { snippet: '\\underline{#0}', preview: '\\underline{aaa}' },
      { snippet: '\\overbrace{#0}', preview: '\\overbrace{AAA}' },
      { snippet: '\\overgroup{#0}', preview: '\\overgroup{AAA}' },
      { snippet: '\\overleftarrow{#0}', preview: '\\overleftarrow{AAA}' },
      { snippet: '\\overleftharpoon{#0}', preview: '\\overleftharpoon{AAA}' },
      { snippet: '\\overleftrightarrow{#0}', preview: '\\overleftrightarrow{AAA}' },
      { snippet: '\\overlinesegment{#0}', preview: '\\overlinesegment{AAA}' },
      { snippet: '\\overrightarrow{#0}', preview: '\\overrightarrow{AAA}' },
      { snippet: '\\Overrightarrow{#0}', preview: '\\Overrightarrow{AAA}' },
      { snippet: '\\overrightharpoon{#0}', preview: '\\overrightharpoon{AAA}' },
      { snippet: '\\underbrace{#0}', preview: '\\underbrace{AAA}' },
      { snippet: '\\undergroup{#0}', preview: '\\undergroup{AAA}' },
      { snippet: '\\underleftarrow{#0}', preview: '\\underleftarrow{AAA}' },
      { snippet: '\\underleftrightarrow{#0}', preview: '\\underleftrightarrow{AAA}' },
      { snippet: '\\underlinesegment{#0}', preview: '\\underlinesegment{AAA}' },
      { snippet: '\\underrightarrow{#0}', preview: '\\underrightarrow{AAA}' },
      { snippet: '\\utilde{#0}', preview: '\\utilde{AAA}' },
      { snippet: '\\widecheck{#0}', preview: '\\widecheck{AAA}' },
      { snippet: '\\widehat{#0}', preview: '\\widehat{AAA}' },
      { snippet: '\\widetilde{#0}', preview: '\\widetilde{AAA}' },
      { snippet: '\\xhookleftarrow[#0]{#1}', preview: '\\xhookleftarrow[under]{over}' },
      { snippet: '\\xhookrightarrow[#0]{#1}', preview: '\\xhookrightarrow[under]{over}' },
      { snippet: '\\xleftarrow[#0]{#1}', preview: '\\xleftarrow[under]{over}' },
      { snippet: '\\xLeftarrow[#0]{#1}', preview: '\\xLeftarrow[under]{over}' },
      { snippet: '\\xleftequilibrium[#0]{#1}', preview: '\\xleftequilibrium[under]{over}' },
      { snippet: '\\xleftharpoondown[#0]{#1}', preview: '\\xleftharpoondown[under]{over}' },
      { snippet: '\\xleftharpoonup[#0]{#1}', preview: '\\xleftharpoonup[under]{over}' },
      { snippet: '\\xleftrightarrow[#0]{#1}', preview: '\\xleftrightarrow[under]{over}' },
      { snippet: '\\xLeftrightarrow[#0]{#1}', preview: '\\xLeftrightarrow[under]{over}' },
      { snippet: '\\xleftrightharpoons[#0]{#1}', preview: '\\xleftrightharpoons[under]{over}' },
      { snippet: '\\xlongequal[#0]{#1}', preview: '\\xlongequal[under]{over}' },
      { snippet: '\\xmapsto[#0]{#1}', preview: '\\xmapsto[under]{over}' },
      { snippet: '\\xrightarrow[#0]{#1}', preview: '\\xrightarrow[under]{over}' },
      { snippet: '\\xRightarrow[#0]{#1}', preview: '\\xRightarrow[under]{over}' },
      { snippet: '\\xrightequilibrium[#0]{#1}', preview: '\\xrightequilibrium[under]{over}' },
      { snippet: '\\xrightharpoondown[#0]{#1}', preview: '\\xrightharpoondown[under]{over}' },
      { snippet: '\\xrightharpoonup[#0]{#1}', preview: '\\xrightharpoonup[under]{over}' },
      { snippet: '\\xrightleftarrows[#0]{#1}', preview: '\\xrightleftarrows[under]{over}' },
      { snippet: '\\xrightleftharpoons[#0]{#1}', preview: '\\xrightleftharpoons[under]{over}' },
      { snippet: '\\xtofrom[#0]{#1}', preview: '\\xtofrom[under]{over}' },
      { snippet: '\\xtwoheadleftarrow[#0]{#1}', preview: '\\xtwoheadleftarrow[under]{over}' },
      { snippet: '\\xtwoheadrightarrow[#0]{#1}', preview: '\\xtwoheadrightarrow[under]{over}' },
    ],
  },
  {
    name: 'math-editor-snippet-group-operators',
    snippets: [
      {
        snippet: '+',
        variants: [
          { snippet: '\\dotplus' }, // ∔
          { snippet: '\\oplus' }, // ⊕
          { snippet: '\\pm' }, // ±
          { snippet: '\\mp' }, // ∓,
        ],
      },
      {
        snippet: '-',
        variants: [
          { snippet: '\\circleddash' }, // ⊝
          { snippet: '\\ominus' }, // ⊖
        ],
      },
      {
        snippet: '\\cdot', // ⋅
        variants: [
          { snippet: '\\odot' }, // ⊙
          { snippet: '\\times' }, // ×
          { snippet: '\\otimes' }, // ⊗
          { snippet: '\\ast' }, // ∗
          { snippet: '\\circledast' }, // ⊛
        ],
      },
      {
        snippet: '\\div', // ÷
        variants: [
          { snippet: '\\divideontimes' }, // ⋇
          { snippet: '\\oslash' }, // ⊘
        ],
      },
      {
        snippet: '\\circ', // ∘
        variants: [
          { snippet: '\\circledcirc' }, // ⊚
        ],
      },
      {
        snippet: '\\sqrt{#0}', preview: '\\sqrt{x}',
        variants: [
          { snippet: '\\sqrt[#1]{#0}', preview: '\\sqrt[y]{x}' },
        ],
      },
      {
        snippet: '\\frac{#0}{#1}', preview: '\\frac{x}{y}',
        variants: [
          { snippet: '\\binom{#0}{#1}', preview: '\\binom{x}{y}' },
          { snippet: '\\cfrac{#0}{#1}', preview: '\\cfrac{1}{1+\\cfrac{1}{1 + \\mathellipsis}}' },
        ],
      },
      { snippet: '\\sum_{#1}^{#2} #0', preview: '\\sum_{n=1}^N' },
      {
        snippet: '\\prod_{#1}^{#2} #0', preview: '\\prod_{n=1}^N',
        variants: [
          { snippet: '\\coprod_{#1}^{#2} #0', preview: '\\coprod_{n=1}^N' },
        ],
      },
      {
        snippet: '\\int\\limits_{#1}^{#2} #0', preview: '\\int\\limits_a^b',
        variants: [
          { snippet: '\\iint\\limits_{#1}^{#2} #0', preview: '\\iint\\limits_a^b' },
          { snippet: '\\oint\\limits_{#1}^{#2} #0', preview: '\\oint\\limits_a^b' },
          { snippet: '\\oiint\\limits_{#1}^{#2} #0', preview: '\\oiint\\limits_a^b' },
          { snippet: '\\oiiint\\limits_{#1}^{#2} #0', preview: '\\oiiint\\limits_a^b' },
          { snippet: '\\intclockwise\\limits_{#1}^{#2} #0', preview: '\\intclockwise\\limits_a^b' },
          { snippet: '\\intctrclockwise\\limits_{#1}^{#2} #0', preview: '\\intctrclockwise\\limits_a^b' },
          { snippet: '\\varointclockwise\\limits_{#1}^{#2} #0', preview: '\\varointclockwise\\limits_a^b' },
        ],
      },
      {
        snippet: '\\cap', // ∩
        variants: [
          { snippet: '\\doublecap' },
          { snippet: '\\sqcap' },
        ],
      },
      {
        snippet: '\\cup', // ∪
        variants: [
          { snippet: '\\uplus' },
          { snippet: '\\doublecup' },
          { snippet: '\\sqcup' },
        ],
      },
      { snippet: '\\setminus' }, // ∖
      // \\mod itself is rendered with a large space in front
      { snippet: '\\mod', preview: '\\mathrm{mod}' },
      {
        snippet: '\\lim',
        variants: [
          { snippet: '\\liminf' },
          { snippet: '\\limsup' },
        ],
      },
      {
        snippet: '\\rd',
        variants: [
          { snippet: '\\rD' },
          { snippet: '\\partial' }, // ∂
          { snippet: '\\eth' }, // ð
          { snippet: '\\differencedelta' }, // ∆
        ],
      },
      { snippet: '\\nabla' }, // ∇
      { snippet: '\\pdiff{#0}{#1}', preview: '\\pdiff{x}{y}' },
      { snippet: '#0^#1', preview: 'x^y' },
      { snippet: '#0_#1', preview: 'x_y' },
    ],
    uncommon: [
      { snippet: '\\bigcap_{#1}^{#2} #0', preview: '\\bigcap_{n=1}^N' },
      {
        snippet: '\\bigcup_{#1}^{#2} #0', preview: '\\bigcup_{n=1}^N',
        variants: [
          { snippet: '\\biguplus_{#1}^{#2} #0', preview: '\\biguplus_{n=1}^N' },
          { snippet: '\\bigsqcup_{#1}^{#2} #0', preview: '\\bigsqcup_{n=1}^N' },
        ],
      },
      { snippet: '\\bigoplus_{#1}^{#2} #0', preview: '\\bigoplus_{n=1}^N' },
      { snippet: '\\bigodot_{#1}^{#2} #0', preview: '\\bigodot_{n=1}^N' },
      { snippet: '\\bigotimes_{#1}^{#2} #0', preview: '\\bigotimes_{n=1}^N' },
      { snippet: '\\amalg' },
      { snippet: '\\wr' },
      {
        snippet: '\\bowtie', // ⋈
        variants: [
          { snippet: '\\ltimes' }, // ⋉
          { snippet: '\\rightthreetimes' }, // ⋌
          { snippet: '\\rtimes' }, // ⋊
          { snippet: '\\leftthreetimes' }, // ⋋
        ],
      },
      { snippet: '\\overset{#1}{#2}', preview: '\\overset{?}{=}' },
      { snippet: '\\underset{#1}{#2}', preview: '\\underset{?}{=}' },
    ],
  },
  {
    name: 'math-editor-snippet-group-relations',
    snippets: [
      {
        snippet: '\\lt', // <
        variants: [
          { snippet: '\\le' }, // ≤
          { snippet: '\\nleq' }, // ≰
          { snippet: '\\leqq' }, // ≦
          { snippet: '\\leqslant' }, // ⩽
          { snippet: '\\eqslantless' }, // ⪕
          { snippet: '\\ll' }, // ≪
          { snippet: '\\lll' }, // ⋘
          { snippet: '\\lesssim' }, // ≲
          { snippet: '\\lessapprox' }, // ⪅
          { snippet: '\\lessdot' }, // ⋖
          { snippet: '\\nless' }, // ≮
          { snippet: '\\nleqslant' }, // 
          { snippet: '\\lneq' }, // ⪇
          { snippet: '\\lneqq' }, // ≨
          { snippet: '\\nleqq' }, // 
          { snippet: '\\lvertneqq' }, // 
          { snippet: '\\lnsim' }, // ⋦
          { snippet: '\\lnapprox' }, // ⪉
        ],
      },
      {
        snippet: '=',
        variants: [
          { snippet: '\\ne' },
          { snippet: '\\approx' }, // ≈
          { snippet: '\\approxeq' }, // ≊
          { snippet: '\\sim' }, // ∼
          { snippet: '\\nsim' }, // ≁
          { snippet: '\\simeq' }, // ≃
          { snippet: '\\backsimeq' }, // ⋍
          { snippet: '\\bumpeq' }, // ≏
          { snippet: '\\Bumpeq' }, // ≎
          { snippet: '\\circeq' }, // ≗
          { snippet: '\\coloneq' }, // ≔
          { snippet: '\\doteq' }, // ≐
          { snippet: '\\doteqdot' }, // ≑
          { snippet: '\\fallingdotseq' }, // ≒
          { snippet: '\\risingdotseq' }, // ≓
          { snippet: '\\eqcirc' }, // ≖
          { snippet: '\\eqsim' }, // ≂
          { snippet: '\\thickapprox' }, // ≈
          { snippet: '\\thicksim' }, // ∼
          { snippet: '\\eqdef' }, // ≞
          { snippet: '\\measeq' }, // ≝
          { snippet: '\\questeq' }, // ≟
        ],
      },
      {
        snippet: '\\equiv', // ≡
        variants: [
          { snippet: '\\cong' }, // ≅
          { snippet: '\\ncong' }, // ≆
          { snippet: '\\asymp' }, // ≍
        ],
      },
      {
        snippet: '\\gtreqqless', // ⪌
        variants: [
          { snippet: '\\gtreqless' }, // ⋛
          { snippet: '\\lessgtr' }, // ≶
          { snippet: '\\gtrless' }, // ≷
          { snippet: '\\lesseqgtr' }, // ⋚
          { snippet: '\\lesseqqgtr' }, // ⪋
        ],
      },
      {
        snippet: '\\gt', // >
        variants: [
          { snippet: '\\ge' }, // ≥
          { snippet: '\\ngeq' }, // ≱
          { snippet: '\\geqq' }, // ≧
          { snippet: '\\geqslant' }, // ⩾
          { snippet: '\\eqslantgtr' }, // ⪖
          { snippet: '\\gg' }, // ≫
          { snippet: '\\ggg' }, // ⋙
          { snippet: '\\gtrsim' }, // ≳
          { snippet: '\\gtrapprox' }, // ⪆
          { snippet: '\\gtrdot' }, // ⋗
          { snippet: '\\ngtr' }, // ≯
          { snippet: '\\ngeqslant' }, // 
          { snippet: '\\ngeqq' }, // 
          { snippet: '\\gneq' }, // ⪈
          { snippet: '\\gneqq' }, // ≩
          { snippet: '\\gvertneqq' }, // 
          { snippet: '\\gnsim' }, // ⋧
          { snippet: '\\gnapprox' }, // ⪊
        ],
      },
      { snippet: '\\propto' }, // ∝
      {
        snippet: '\\prec', // ≺
        variants: [
          { snippet: '\\preceq' }, // ⪯
          { snippet: '\\curlyeqprec' }, // ⋞
          { snippet: '\\precapprox' }, // ⪷
          { snippet: '\\preccurlyeq' }, // ≼
          { snippet: '\\precsim' }, // ≾
          { snippet: '\\nprec' }, // ⊀
          { snippet: '\\npreceq' }, // ⋠
          { snippet: '\\precnsim' }, // ⋨
          { snippet: '\\precnapprox' }, // ⪹
          { snippet: '\\precneqq' }, // ⪵
        ],
      },
      {
        snippet: '\\succ', // ≻
        variants: [
          { snippet: '\\curlyeqsucc' }, // ⋟
          { snippet: '\\succapprox' }, // ⪸
          { snippet: '\\succcurlyeq' }, // ≽
          { snippet: '\\succeq' }, // ⪰
          { snippet: '\\succsim' }, // ≿
          { snippet: '\\nsucc' }, // ⊁
          { snippet: '\\nsucceq' }, // ⋡
          { snippet: '\\succnsim' }, // ⋩
          { snippet: '\\succnapprox' }, // ⪺
          { snippet: '\\succneqq' }, // ⪶
        ],
      },
      {
        snippet: '\\parallel', // ∥
        variants: [
          { snippet: '\\nparallel' }, // ∦
        ],
      },
      { snippet: '\\perp' }, // ⊥
      {
        snippet: '\\divides', // ∣
        variants: [
          { snippet: '\\nmid' }, // ∤
        ],
      },
      {
        snippet: '\\in', // ∈
        variants: [
          { snippet: '\\ni' }, // ∋
          { snippet: '\\notin' }, // ∉
        ],
      },
      {
        snippet: '\\subset', // ⊂
        variants: [
          { snippet: '\\subseteq' }, // ⊆
          { snippet: '\\subseteqq' }, // ⫅
          { snippet: '\\varsubsetneq' }, // 
          { snippet: '\\nsubseteqq' }, // 
          { snippet: '\\nsubset' }, // ⊄
          { snippet: '\\nsubseteq' }, // ⊈
          { snippet: '\\subsetneq' }, // ⊊
          { snippet: '\\subsetneqq' }, // ⫋
          { snippet: '\\varsubsetneqq' }, // 
          { snippet: '\\Subset' }, // ⋐
          { snippet: '\\sqsubset' }, // ⊏
          { snippet: '\\sqsubseteq' }, // ⊑
        ],
      },
      {
        snippet: '\\supset', // ⊃
        variants: [
          { snippet: '\\supseteq' }, // ⊇
          { snippet: '\\supseteqq' }, // ⫆
          { snippet: '\\supsetneq' }, // ⊋
          { snippet: '\\supsetneqq' }, // ⫌
          { snippet: '\\varsupsetneq' }, // 
          { snippet: '\\varsupsetneqq' }, // 
          { snippet: '\\nsupset' }, // ⊅
          { snippet: '\\nsupseteq' }, // ⊉
          { snippet: '\\nsupseteqq' }, // 
          { snippet: '\\Supset' }, // ⋑
          { snippet: '\\sqsupset' }, // ⊐
          { snippet: '\\sqsupseteq' }, // ⊒
        ],
      },
    ],
    uncommon: [
      { snippet: '\\lhd' }, // ⊲
      { snippet: '\\rhd' }, // ⊳
      { snippet: '\\originalof' }, // ⊶
      { snippet: '\\imageof' }, // ⊷
    ],
  },
  {
    name: 'math-editor-snippet-group-fences',
    snippets: [
      { snippet: '\\left(#0\\right)' }, // ( )
      { snippet: '\\left[#0\\right]' }, // [ ]
      { snippet: '\\left\\{#0\\right\\}' }, // { }
      { snippet: '\\left\\langle#0\\right\\rangle' }, // ⟨ ⟩
      { snippet: '\\left\\lceil#0\\right\\rceil' }, // ⌈ ⌉
      { snippet: '\\left\\lfloor#0\\right\\rfloor' }, // ⌊ ⌋
      { snippet: '\\left\\lvert#0\\right\\rvert' }, // ∣ ∣
      { snippet: '\\left\\lVert#0\\right\\rVert' }, // ∥ ∥
      { snippet: '\\middle\\mid', preview: '\\left.\\middle\\mid' },
    ],
    uncommon: [
      { snippet: '\\left\\lgroup#0\\right\\rgroup' }, // ⟮ ⟯
      { snippet: '\\left\\llcorner#0\\right\\lrcorner' }, // └
      { snippet: '\\left\\lmoustache#0\\right\\rmoustache' }, // ⎰ ⎱
      { snippet: '\\left\\ulcorner#0\\right\\urcorner' }, // ┌ ┐
      { snippet: '\\left\\{#0\\middle\\mid#1\\right\\}', preview: '\\left\\{x\\middle\\mid x>0\\right\\}' },
      { snippet: '\\left\\{#0:#1\\right\\}', preview: '\\left\\{x: x>0\\right\\}' },
    ],
  },
  {
    name: 'math-editor-snippet-group-functions',
    snippets: [
      { snippet: '\\exp' },
      {
        snippet: '\\log',
        variants: [
          { snippet: '\\ln' },
          { snippet: '\\lb' },
          { snippet: '\\lg' },
        ],
      },
      { snippet: '\\max' },
      { snippet: '\\min' },
      {
        snippet: '\\sin',
        variants: [
          { snippet: '\\arcsin' },
        ],
      },
      {
        snippet: '\\sh',
        variants: [
          { snippet: '\\sinh' },
        ],
      },
      {
        snippet: '\\cos',
        variants: [
          { snippet: '\\arccos' },
        ],
      },
      {
        snippet: '\\ch',
        variants: [
          { snippet: '\\cosh' },
        ],
      },
      {
        snippet: '\\tg',
        variants: [
          { snippet: '\\arctg' },
          { snippet: '\\tan' },
          { snippet: '\\arctan' },
        ],
      },
      {
        snippet: '\\th',
        variants: [
          { snippet: '\\tanh' },
        ],
      },
      {
        snippet: '\\ctg',
        variants: [
          { snippet: '\\arcctg' },
        ],
      },
      { snippet: '\\cth' },
      { snippet: '\\deg' },
      { snippet: '\\det' },
      { snippet: '\\dim' },
      { snippet: '\\hom' },
      { snippet: '\\ker' },
      { snippet: '\\arg' },
      { snippet: '\\Re' },
      { snippet: '\\Im' },
    ],
    uncommon: [
      { snippet: '\\sec' },
      { snippet: '\\cosec' },
      { snippet: '\\cot' },
      { snippet: '\\coth' },
      { snippet: '\\cotg' },
      { snippet: '\\csc' },
      { snippet: '\\wp' }, // ℘
    ],
  },
  {
    name: 'math-editor-snippet-group-logic',
    snippets: [
      {
        snippet: '\\exists', // ∃
        variants: [
          { snippet: '\\nexists' }, // ∄
        ],
      },
      { snippet: '\\forall' }, // ∀
      { snippet: '\\neg' }, // ¬
      {
        snippet: '\\land', // ∧
        variants: [
          { snippet: '\\barwedge' }, // ⊼
          { snippet: '\\doublebarwedge' }, // ⩞
          { snippet: '\\curlywedge' }, // ⋏
        ],
      },
      {
        snippet: '\\lor', // ∨
        variants: [
          { snippet: '\\curlyvee' }, // ⋎
        ],
      },
      { snippet: '\\nor' }, // ⊻
      {
        snippet: '\\to', // →
        variants: [
          { snippet: '\\leadsto' }, // ⇝
          { snippet: '\\gets' }, // ←
        ],
      },
      {
        snippet: '\\mapsto', // ↦
        variants: [
          { snippet: '\\longmapsto' }, // ⟼
          { snippet: '\\multimap' }, // ⊸
        ],
      },
      { snippet: '\\impliedby' }, // ⟸
      {
        snippet: '\\implies', // ⟹
        variants: [
          { snippet: '\\roundimplies' }, // ⥰
        ],
      },
    ],
    uncommon: [
      { snippet: '\\because' }, // ∵
      { snippet: '\\therefore' }, // ∴
      { snippet: '\\biconditional' }, // ⟷
      {
        snippet: '\\vdash', // ⊢
        variants: [
          { snippet: '\\nvdash' }, // ⊬
          { snippet: '\\models' }, // ⊨
          { snippet: '\\nvDash' }, // ⊭
          { snippet: '\\Vdash' }, // ⊩
          { snippet: '\\nVdash' }, // ⊮
          { snippet: '\\Vvdash' }, // ⊪
          { snippet: '\\nVDash' }, // ⊯
          { snippet: '\\dashv' }, // ⊣
        ],
      },
      { snippet: '\\bigvee_{#1}^{#2} #0', preview: '\\bigvee_{n=1}^N' },
      { snippet: '\\bigwedge_{#1}^{#2} #0', preview: '\\bigwedge_{n=1}^N' },
    ],
  },
  {
    name: 'math-editor-snippet-group-arrows',
    snippets: [
      {
        snippet: '\\leftarrow', // ←
        variants: [
          { snippet: '\\nleftarrow' }, // ↚
          { snippet: '\\twoheadleftarrow' }, // ↞
          { snippet: '\\leftarrowtail' }, // ↢
          { snippet: '\\dashleftarrow' }, // ⇠
          { snippet: '\\leftharpoonup' }, // ↼
          { snippet: '\\leftharpoondown' }, // ↽
          { snippet: '\\leftleftarrows' }, // ⇇
          { snippet: '\\Leftarrow' }, // ⇐
          { snippet: '\\nLeftarrow' }, // ⇍
          { snippet: '\\Lleftarrow' }, // ⇚
          { snippet: '\\hookleftarrow' }, // ↩
          { snippet: '\\longleftarrow' }, // ⟵
          { snippet: '\\Longleftarrow' }, // ⟸
          { snippet: '\\circlearrowleft' }, // ↺
          { snippet: '\\curvearrowleft' }, // ↶
          { snippet: '\\looparrowleft' }, // ↫
          { snippet: '\\Lsh' }, // ↰
        ],
      },
      {
        snippet: '\\leftrightarrow', // ↔
        variants: [
          { snippet: '\\nleftrightarrow' }, // ↮
          { snippet: '\\Leftrightarrow' }, // ⇔
          { snippet: '\\nLeftrightarrow' }, // ⇎
          { snippet: '\\longleftrightarrow' }, // ⟷
          { snippet: '\\Longleftrightarrow' }, // ⟺
          { snippet: '\\leftrightarrows' }, // ⇆
          { snippet: '\\rightleftarrows' }, // ⇄
          { snippet: '\\leftrightharpoons' }, // ⇋
          { snippet: '\\rightleftharpoons' }, // ⇌
          { snippet: '\\leftrightsquigarrow' }, // ↭
        ],
      },
      {
        snippet: '\\rightarrow', // →
        variants: [
          { snippet: '\\nrightarrow' }, // ↛
          { snippet: '\\twoheadrightarrow' }, // ↠
          { snippet: '\\rightarrowtail' }, // ↣
          { snippet: '\\dashrightarrow' }, // ⇢
          { snippet: '\\rightsquigarrow' }, // ⇝
          { snippet: '\\rightharpoonup' }, // ⇀
          { snippet: '\\rightharpoondown' }, // ⇁
          { snippet: '\\rightrightarrows' }, // ⇉
          { snippet: '\\Rightarrow' }, // ⇒
          { snippet: '\\nRightarrow' }, // ⇏
          { snippet: '\\Rrightarrow' }, // ⇛
          { snippet: '\\hookrightarrow' }, // ↪
          { snippet: '\\longrightarrow' }, // ⟶
          { snippet: '\\Longrightarrow' }, // ⟹
          { snippet: '\\circlearrowright' }, // ↻
          { snippet: '\\curvearrowright' }, // ↷
          { snippet: '\\looparrowright' }, // ↬
          { snippet: '\\Rsh' }, // ↱
        ],
      },
      {
        snippet: '\\uparrow', // ↑
        variants: [
          { snippet: '\\upharpoonleft' }, // ↿
          { snippet: '\\restriction' }, // ↾
          { snippet: '\\upuparrows' }, // ⇈
          { snippet: '\\Uparrow' }, // ⇑
        ],
      },
      {
        snippet: '\\updownarrow', // ↕
        variants: [
          { snippet: '\\Updownarrow' }, // ⇕
          { snippet: '\\upharpoonright' }, // ↾
        ],
      },
      {
        snippet: '\\downarrow', // ↓
        variants: [
          { snippet: '\\downharpoonleft' }, // ⇃
          { snippet: '\\downharpoonright' }, // ⇂
          { snippet: '\\downdownarrows' }, // ⇊
          { snippet: '\\Downarrow' }, // ⇓
        ],
      },
      { snippet: '\\nearrow' }, // ↗
      { snippet: '\\swarrow' }, // ↙
      { snippet: '\\nwarrow' }, // ↖
      { snippet: '\\searrow' }, // ↘
    ],
  },
  {
    name: 'math-editor-snippet-group-greek-letters',
    snippets: [
      { snippet: '\\alpha' },
      { snippet: '\\beta' },
      {
        snippet: '\\gamma',
        variants: [
          { snippet: '\\Gamma' },
          { snippet: '\\digamma' },
        ],
      },
      {
        snippet: '\\delta',
        variants: [
          { snippet: '\\Delta' },
        ],
      },
      {
        snippet: '\\epsilon',
        variants: [
          { snippet: '\\varepsilon' },
        ],
      },
      { snippet: '\\zeta' },
      { snippet: '\\eta' },
      {
        snippet: '\\theta',
        variants: [
          { snippet: '\\Theta' },
          { snippet: '\\vartheta' },
        ],
      },
      { snippet: '\\iota' },
      {
        snippet: '\\kappa',
        variants: [
          { snippet: '\\varkappa' },
        ],
      },
      {
        snippet: '\\lambda',
        variants: [
          { snippet: '\\Lambda' },
        ],
      },
      { snippet: '\\mu' },
      { snippet: '\\nu' },
      {
        snippet: '\\xi',
        variants: [
          { snippet: '\\Xi' },
        ],
      },
      { snippet: '\\omicron' },
      {
        snippet: '\\pi',
        variants: [
          { snippet: '\\Pi' },
          { snippet: '\\varpi' },
        ],
      },
      {
        snippet: '\\rho',
        variants: [
          { snippet: '\\varrho' },
        ],
      },
      {
        snippet: '\\sigma',
        variants: [
          { snippet: '\\Sigma' },
          { snippet: '\\varsigma' },
        ],
      },
      { snippet: '\\tau' },
      {
        snippet: '\\phi',
        variants: [
          { snippet: '\\Phi' },
          { snippet: '\\varphi' },
        ],
      },
      {
        snippet: '\\upsilon',
        variants: [
          { snippet: '\\Upsilon' },
        ],
      },
      { snippet: '\\chi' },
      {
        snippet: '\\psi',
        variants: [
          { snippet: '\\Psi' },
        ],
      },
      {
        snippet: '\\omega',
        variants: [
          { snippet: '\\Omega' },
        ],
      },
    ],
  },
  {
    name: 'math-editor-snippet-group-letters-and-symbols',
    snippets: [
      { snippet: '\\infty' }, // ∞
      { snippet: '\\exponentialE' },
      { snippet: '\\imaginaryI' },
      { snippet: '\\imaginaryJ' },
      {
        snippet: '\\varnothing', // ∅
        variants: [
          { snippet: '\\emptyset' }, // ∅
        ],
      },
      { snippet: '\\C' },
      { snippet: '\\N' },
      { snippet: '\\Q' },
      { snippet: '\\R' },
      { snippet: '\\Z' },
      { snippet: '\\P' },
      { snippet: '\\mathellipsis' }, // …
      {
        snippet: '\\cdots', // ⋯
        variants: [
          { snippet: '\\ddots' }, // ⋱
          { snippet: '\\vdots' }, // ⋮
          { snippet: '\\cdotp' }, // ⋅
          { snippet: '\\ldotp' }, // .
        ],
      },
    ],
    uncommon: [
      { snippet: '\\Game' }, // ⅁
      { snippet: '\\complement' }, // ∁
      { snippet: '\\aleph' }, // ℵ
      { snippet: '\\beth' }, // ℶ
      { snippet: '\\daleth' }, // ℸ
      { snippet: '\\gimel' }, // ℷ
      { snippet: '\\gothicCapitalC' },
      { snippet: '\\gothicCapitalH' },
      { snippet: '\\gothicCapitalI' },
      { snippet: '\\gothicCapitalR' },
      { snippet: '\\scriptCapitalE' },
      { snippet: '\\scriptCapitalH' },
      { snippet: '\\scriptCapitalL' },
      { snippet: '\\intercal' }, // ⊺
      { snippet: '\\Bbbk' }, // k
      { snippet: '\\top' }, // ⊤
      { snippet: '\\bot' }, // ⊥
      { snippet: '\\ell' }, // ℓ
      { snippet: '\\hbar' }, // ℏ
      { snippet: '\\hslash' }, // ℏ
      { snippet: '\\Colon' }, // ∷
      { snippet: '\\diagup' }, // ╱
      { snippet: '\\degree' }, // °
      {
        snippet: '\\measuredangle', // ∡
        variants: [
          { snippet: '\\angle' }, // ∠
          { snippet: '\\sphericalangle' }, // ∢
        ],
      },
    ],
  },
  {
    name: 'math-editor-snippet-group-shapes',
    snippets: [
      {
        snippet: '\\square', // □
        variants: [
          { snippet: '\\blacksquare' }, // ■
          { snippet: '\\boxminus' }, // ⊟
          { snippet: '\\boxplus' }, // ⊞
          { snippet: '\\boxtimes' }, // ⊠
          { snippet: '\\boxdot' }, // ⊡
        ],
      },
      {
        snippet: '\\triangle', // △
        variants: [
          { snippet: '\\bigtriangleup' }, // △
          { snippet: '\\triangleq' }, // ≜
          { snippet: '\\blacktriangle' }, // ▲
        ],
      },
      {
        snippet: '\\triangledown', // ▽
        variants: [
          { snippet: '\\bigtriangledown' }, // ▽
          { snippet: '\\blacktriangledown' }, // ▼
        ],
      },
      {
        snippet: '\\triangleleft', // ◃
        variants: [
          { snippet: '\\vartriangleleft' }, // ⊲
          { snippet: '\\ntriangleleft' }, // ⋪
          { snippet: '\\unlhd' }, // ⊴
          { snippet: '\\ntrianglelefteq' }, // ⋬
          { snippet: '\\blacktriangleleft' }, // ◀
        ],
      },
      {
        snippet: '\\triangleright', // ▹
        variants: [
          { snippet: '\\vartriangleright' }, // ⊳
          { snippet: '\\ntriangleright' }, // ⋫
          { snippet: '\\unrhd' }, // ⊵
          { snippet: '\\ntrianglerighteq' }, // ⋭
          { snippet: '\\blacktriangleright' }, // ▶
        ],
      },
      { snippet: '\\bullet' }, // ∙
      {
        snippet: '\\bigcirc', // ◯
        variants: [
          { snippet: '\\circledR' }, // ®
          { snippet: '\\circledS' }, // Ⓢ
        ],
      },
      {
        snippet: '\\star', // ⋆
        variants: [
          { snippet: '\\bigstar' }, // ★
        ],
      },
      { snippet: '\\diamond' }, // ⋄
      {
        snippet: '\\lozenge', // ◊
        variants: [
          { snippet: '\\blacklozenge' }, // ⧫
        ],
      },
      { snippet: '\\clubsuit' }, // ♣
      { snippet: '\\heartsuit' }, // ♡
      { snippet: '\\spadesuit' }, // ♠
      { snippet: '\\diamondsuit' }, // ♢
      { snippet: '\\checkmark' }, // ✓
      { snippet: '\\sharp' }, // ♯
      { snippet: '\\flat' }, // ♭
      { snippet: '\\natural' }, // ♮
      {
        snippet: '\\dagger', // †
        variants: [
          { snippet: '\\ddagger' }, // ‡
        ],
      },
      { snippet: '\\maltese' }, // ✠
    ],
  },
]
