import * as React from 'react'
import { useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import store from '../../store'
import { createLabel } from '../../store/actions/modules'
import { ModuleLabelProperites } from '../../store/types'
import { createIdFromName, escapeStringRegExp, getRandomColor } from '../../helpers'
import ModuleLabelCreator from './ModuleLabelCreator'
import MLEItem from './MLEItem'
import Button from '../../components/ui/Button'
import Input from '../../components/ui/Input'

import './index.css'

const ModuleLabelsEditor = () => {
  const labels = useSelector(state => state.modules.labels)
  const [search, setSearch] = React.useState('')
  const [newLabel, setNewLabel] = React.useState('')

  const regExp = new RegExp(escapeStringRegExp(search), 'i')
  const filteredLabels = Object.values(labels).filter(l => l.name.match(regExp))
  const [showCreateForm, setShowCreateForm] = React.useState(false)

  const onChange = (text: string | null) => {
    setSearch(text ?? '')
  }

  const toggleCreateForm = () => {
    setShowCreateForm(state => !state)
  }

  const createNewLabel = (label?: ModuleLabelProperites) => {
    if (search.length || label) {
      store.dispatch(
        createLabel({
          name: label?.name || search,
          color: label?.color || getRandomColor(),
        }),
      )
      if (showCreateForm) {
        setShowCreateForm(false)
      }
      setNewLabel(createIdFromName(label?.name || search))
      setSearch('')
    }
  }

  const isLabelNotExisting = React.useMemo(
    () => !filteredLabels.find(l => l.name.toLowerCase() === search.toLowerCase()),
    [search, filteredLabels])

  return (
    <div className="module-labels-editor">
      <div className="module-labels-editor__header">
        <Input l10nId="module-labels-editor-search" type="text" onChange={onChange} value={search} />
        <Button
          l10nId="module-labels-editor-new-label"
          clickHandler={toggleCreateForm}
          isDisabled={!isLabelNotExisting}
        >
          Add new label
        </Button>
      </div>
      {
        showCreateForm
          ?
          <ModuleLabelCreator
            onCancel={toggleCreateForm}
            createHandler={createNewLabel}
            initialPhrase={search}
          />
          : null
      }
      <ul className="module-labels-editor__list">
        {
          filteredLabels.length
            ? filteredLabels.map(label => (
              <li key={label.id}>
                <MLEItem label={label} currentlyEditingId={newLabel} />
              </li>
            ))
            :
            <li className="module-labels-editor__no-labels">
              <Localized id="module-labels-editor-no-labels">
                No labels found
              </Localized>
            </li>
        }
        {
          isLabelNotExisting && search.length > 0
            ?
            <span
              id="module-labels-list-create-label"
              className="module-labels-list__item module-labels-list__item--center"
              onClick={() => createNewLabel()}
            >
              <Localized id="module-labels-list-create-label" vars={{ name: search }}>
                {`Create new label: ${search}`}
              </Localized>
            </span>
            : null
        }
      </ul>
    </div>
  )
}
export default ModuleLabelsEditor
