import * as React from 'react'
import * as api from '../../api'
import { ReferenceTarget } from '../../store/types'
import { LOCALIZATION } from '../../screens/app/Draft/constants'
import RefTarget from '../../components/ReferenceTarget'
import { XrefTargetOnSelect } from '../XrefTargetSelector'

import './index.css'

interface ReferenceTargetsProps {
  /**
   * Module in which reference targets are located.
   */
  module: api.Module | null,
  /**
   * List of reference targets to display.
   */
  targets: ReferenceTarget[],
  /**
   * Function to call when user selects a resource target.
   */
  onSelect: XrefTargetOnSelect
}

/**
 * A list of reference targets, all of which are in a single module.
 */
export default function ReferenceTargets({ module, targets, onSelect }: ReferenceTargetsProps) {
  const { document } = React.useContext(LOCALIZATION)
  const sorted = new Map<string, ReferenceTarget[]>()

  for (const target of targets) {
    if (!sorted.has(target.type)) {
      sorted.set(target.type, [])
    }

    sorted.get(target.type)!.push(target)
  }

  return (
    <ul className="targets">
      {Array.from(sorted.entries(), ([type, targets]) => (
        <li key={type} className="targets__category">
          <span className="targets__title">
            {document.getString('reference-targets-category', { type })}
          </span>
          {targets.map(target => (
            <RefTarget
              key={target.id}
              module={module}
              target={target}
              onSelect={onSelect}
            />
          ))}
        </li>
      ))}
    </ul>
  )
}
