import * as React from 'react'
import Select, { Props } from 'react-select'
import { Team } from '../../api'
import { useLoadAllTeamRoles, useLoadTeam } from '../../api/hooks'

interface RoleSelectorProps extends Omit<Props, 'options'> {
  team?: Team | number
}

const RoleSelector = (props: RoleSelectorProps) => {
  const [team] = useLoadTeam(typeof props.team === 'number' ? props.team : props.team?.id)
  const [roles] = useLoadAllTeamRoles(team)

  return (
    <Select
      {...props}
      options={roles.map(r => ({ value: r, label: r.name }))}
    />
  )
}

export default RoleSelector
