import { Node, NodeEntry } from 'slate'
import { ProcessSingleStep, SlotPermission } from '../../../../../api/process'
import { Highlight } from '../../../../../screens/app/Draft/plugins/Highlights'
import { MediaText } from '../../../../../screens/app/Draft/plugins/MediaText'

export const isFinishStep = (step: ProcessSingleStep) => {
  if (step.links.length === 0 && step.slots.length === 0) return true
  return false
}

export const stepHasPermission = (
  step: ProcessSingleStep, permission: SlotPermission,
) => step.slots.some(sl => sl.permissions.includes(permission))

const rootNode = (nodes: Node[]) => ({ children: nodes })

const countIterables = (iterable: Iterable<NodeEntry<Node>>): [number, number] => {
  let countHighligths = 0
  let countMediaTexts = 0
  for (const [node] of iterable) {
    if (Highlight.isHighlight(node)) {
      countHighligths++
    } else if (MediaText.isMediaText(node)) {
      countMediaTexts++
    }
  }
  return [countHighligths, countMediaTexts]
}

export const countHighlightsAndMediaTexts = (document: Node[]) => {
  return countIterables(Node.nodes(rootNode(document)))
}
