import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { initialState, reducer, State } from '../store/reducers'

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore<State, any, any, any>(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk)),
)

export default store

export const createTestStore = () => createStore<State, any, any, any>(
  reducer,
  initialState,
  applyMiddleware(thunk),
)
