import * as React from 'react'
import * as Counters from 'react-counters'
import { ADMONITION, EXERCISE, FIGURE, RULE, TABLE } from '../../counters'

import './index.css'

const CountersRoot = ({ children }: { children: React.ReactNode }) => {
  const ref = React.useRef(null)
  Counters.useCounter(ref, {
    reset: [ADMONITION, EXERCISE, FIGURE, RULE, TABLE],
  })
  Counters.useCounterRoot(ref)

  return <div ref={ref} className="counters-root">
    {children}
  </div>
}

export default CountersRoot
