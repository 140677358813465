import * as React from 'react'
import Select from 'react-select'
import { Localized, withLocalization, WithLocalizationProps } from '@fluent/react'
import { Role, Team, User } from '../../../../../../api'
import { useLoadAllUsers } from '../../../../../../api/hooks'
import store from '../../../../../../store'
import { addAlert } from '../../../../../../store/actions/alerts'
import LimitedUI from '../../../../../../components/LimitedUI'
import RoleSelector from '../../../../../../components/RoleSelector'

import './index.css'

interface AddMemberProps extends WithLocalizationProps {
  team: Team
}

const AddMember = ({ team, getString }: AddMemberProps) => {
  const [users] = useLoadAllUsers()
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null)
  const [selectedRole, setSelectedRole] = React.useState<Role | null>(null)

  const handleUserChange = (option: { value: User, label: string } | null) => {
    setSelectedUser(option ? option.value : option)
  }

  const handleRoleChange = (option: { value: Role, label: string } | null) => {
    setSelectedRole(option ? option.value : option)
  }

  const addMember = (ev: React.FormEvent) => {
    ev.preventDefault()
    if (!selectedUser) return

    team.addMember({
      user: selectedUser.id,
      role: selectedRole ? selectedRole.id : undefined,
      permissions: [],
    })
      .then(() => {
        setSelectedUser(null)
        store.dispatch(addAlert('success', 'teams-member-add-success'))
      })
  }

  const usersToAdd = React.useMemo(() => users
    .filter(u => !u.teams.find(t => t.id === team.id))
    .map(user => ({ value: user, label: user.name }))
  , [users, team.id])

  return (
    <form
      className="teams__member-add"
      onSubmit={addMember}
    >
      <Select
        className="react-select"
        isClearable={true}
        placeholder={getString('teams-select-user')}
        value={selectedUser ? { value: selectedUser, label: selectedUser.name } : null}
        options={usersToAdd}
        onChange={handleUserChange as any}
      />
      <LimitedUI team={team} permissions="member:assign-role">
        <RoleSelector
          team={team}
          className="react-select"
          isClearable={true}
          placeholder={getString('teams-select-role')}
          value={selectedRole ? { value: selectedRole, label: selectedRole.name } : null}
          onChange={handleRoleChange as any}
        />
      </LimitedUI>
      <Localized id="teams-member-add" attrs={{ value: true }}>
        <input type="submit" value="Invite user" disabled={!selectedUser}/>
      </Localized>
    </form>
  )
}

export default withLocalization(AddMember)
