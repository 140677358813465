import * as React from 'react'
import { Footnote } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import Button from '../../../../../../../components/ui/Button'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'
import Classes from '../Classes'

interface FootnoteToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const FootnoteTools = ({ editor, ...props }: FootnoteToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Footnote.isFootnote })
  if (!node) return null

  const [footnote, path] = node

  return (
    <ToolGroup
      title="editor-tools-footnote-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('footnoteTools')}
    >
      <Classes node={node} />
      {
        footnote.collapse ?
          <Button
            l10nId="editor-tools-footnote-show"
            clickHandler={() => Transforms.setNodes(editor, { collapse: false }, { at: path })}
          >
            Show content
          </Button>
          :
          <Button
            l10nId="editor-tools-footnote-hide"
            clickHandler={() => Transforms.setNodes(editor, { collapse: true }, { at: path })}
          >
            Hide content
          </Button>
      }
    </ToolGroup>
  )
}

export default FootnoteTools
