import { Instances } from 'react-counters/dist/interfaces'
import * as React from 'react'
import { Counter } from 'react-counters'
import * as Sentry from '@sentry/react'
import { Editor, Element, Node } from 'slate'
import { ReactEditor } from 'slate-react'
import { SuggestionsEditor } from 'slate-suggestions'
import { ReferenceTarget } from '../../../../../store/types'

export const findNodeEntryById = (document: Node[], id: string) => {
  for (const node of Node.descendants({ children: document })) {
    if (node[0].id === id) return node
  }

  return undefined
}

export const findNodeEntryByElement = (editor: Editor, element: Element) => {
  for (const node of Node.descendants({ children: editor.children })) {
    if (Element.isElement(node[0]) && node[0] === element) return node
  }

  return undefined
}

export const getSuggestionRefForVoidElement = (editor: Editor, element: Element) => {
  if (SuggestionsEditor.isSuggestionsEditor(editor)) {
    const [, path] = findNodeEntryByElement(editor, element) || []
    return path /* && editor.suggestions.find(sugg => {
      if (
        Path.isDescendant(path, sugg.range.focus.path)
        || Path.isParent(path, sugg.range.focus.path)
      ) return true
      return false
    }) */
  }
  return undefined
}

export const findTarget = (
  element: { target: string },
  refs: ReferenceTarget[] = [],
): ReferenceTarget | undefined => {
  return refs.find(target => {
    if (target.id === element.target) {
      return true
    }
    if (target.children.length) {
      return findTarget(element, target.children)
    }
    return false
  })
}

const getDomNode = (editor: ReactEditor, target: Node): HTMLElement | null => {
  try {
    return ReactEditor.toDOMNode(editor, target)
  } catch (e) {
    Sentry.captureMessage(e, 'error')
    return null
  }
}

export const useGetDomNode = (editor: ReactEditor, target: Node | null | undefined) => {
  const [domNode, setDomNode] = React.useState<HTMLElement | null>(null)

  React.useEffect(() => {
    if (target) {
      setDomNode(getDomNode(editor, target))
    } else {
      setDomNode(null)
    }
  }, [editor, target])

  return domNode
}

export const getValueForCounter = (counters: Instances, counter: Counter): number => {
  const instances = counters.get(counter)
  return instances
    ? instances[instances.length - 1].value
    : 0
}
