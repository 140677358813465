
import { SlotPermission } from '../../../../api/process'
import * as constants from './constants'

export interface SetCurrentDraftLang {
  type: constants.SET_CURRENT_DRAFT_LANG,
  data: string,
}

export interface SetCurrentDraftPermissions {
  type: constants.SET_CURRENT_DRAFT_PERMISSIONS,
  data: SlotPermission[],
}

export interface SetReadOnly {
  type: constants.SET_READ_ONLY,
  data: boolean,
}

export type CurrentDraftAction = SetCurrentDraftLang
  | SetCurrentDraftPermissions
  | SetReadOnly

export const setCurrentDraftLang = (code: string): SetCurrentDraftLang => ({
  type: constants.SET_CURRENT_DRAFT_LANG,
  data: code,
})

export const setCurrentDraftPermissions = (
  permissions: SlotPermission[],
): SetCurrentDraftPermissions => ({
  type: constants.SET_CURRENT_DRAFT_PERMISSIONS,
  data: permissions,
})

export const setReadOnly = (payload: boolean): SetReadOnly => ({
  type: constants.SET_READ_ONLY,
  data: payload,
})
