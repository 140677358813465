import * as React from 'react'
import { FluentVariable } from '@fluent/bundle'
import { Localized } from '@fluent/react'

import './index.css'

interface HeaderProps {
  l10nId?: string
  title?: string
  children?: any
  fixed?: boolean
  vars?: Record<string, FluentVariable>
  elems?: Record<string, React.ReactElement>
}

const Header = ({ l10nId, title, children, fixed, vars = {}, elems }: HeaderProps) => {
  let titleContent = null

  if (l10nId != null && title != null) {
    titleContent = <Localized id={l10nId} vars={{ title, ...vars }} elems={elems}>
      {title}
    </Localized>
  } else if (title != null) {
    titleContent = title
  }

  return <div className={`header ${fixed ? 'fixed' : ''}`}>
    {
      titleContent != null ?
        <h2 className="header__title">
          {titleContent}
        </h2>
        : null
    }
    {children}
  </div>
}

export default Header
