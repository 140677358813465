import * as React from 'react'
import { useDispatch } from 'react-redux'
import { TaskData } from '../../../../store/reducers/tasks'
import { setActiveTask } from '../../../../store/actions/tasks'

export interface TaskPreviewProps {
  id: string
  data: TaskData
}

export default function TaskPreview({ id, data }: TaskPreviewProps) {
  // eslint-disable-next-line
  const { message, progress } = data
  const dispatch = useDispatch()

  const onClick = React.useCallback(() => {
    dispatch(setActiveTask(id))
  // eslint-disable-next-line
  }, [])

  return (
    <div id="task-preview" className="task-preview" onClick={onClick}>
      <span className="task-preview__message">
        {message}
      </span>
    </div>
  )
}
