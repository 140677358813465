import { Editor, Range, Transforms } from 'slate'
import { isKeyHotkey } from 'is-hotkey'
import { Code, CodeLine, Term } from 'cnx-designer'
import { Mark, MarkKind } from '../Marks'

const isStrongShortcut = isKeyHotkey('mod+b')
const isEmphasisShortcut = isKeyHotkey('mod+i')
const isUnderlineShortcut = isKeyHotkey('mod+u')
const isCodeShortcut = isKeyHotkey('mod+`')
const isTermShortcut = isKeyHotkey('mod+k')

export default function onKeyDownShortcut(editor: Editor, event: KeyboardEvent) {
  let mark: MarkKind

  if (isStrongShortcut(event)) {
    mark = 'strong'
  } else if (isEmphasisShortcut(event)) {
    mark = 'emphasis'
  } else if (isUnderlineShortcut(event)) {
    mark = 'underline'
  } else if (isCodeShortcut(event)) {
    event.preventDefault()
    const code = Editor.above(editor, { match: Code.isCode })
    if (!code) {
      if (!editor.selection || Range.isCollapsed(editor.selection)) {
        Transforms.insertNodes(
          editor,
          { type: 'code', placement: 'line', children: [{ text: ' ' }] } as CodeLine)
      } else {
        Transforms.wrapNodes(
          editor,
          { type: 'code', placement: 'line', children: [] } as CodeLine,
          { split: true })
      }
    } else {
      Transforms.unwrapNodes(editor, { at: code[1] })
    }
    return true
  } else if (isTermShortcut(event)) {
    event.preventDefault()
    const term = Editor.above(editor, { match: Term.isTerm })
    if (!term) {
      if (!editor.selection || Range.isCollapsed(editor.selection)) {
        Transforms.insertNodes(editor, { type: 'term', children: [{ text: ' ' }] } as Term)
      } else {
        Transforms.wrapNodes(editor, { type: 'term', children: [] } as Term, { split: true })
      }
    } else {
      Transforms.unwrapNodes(editor, { at: term[1] })
    }
    return true
  } else {
    return false
  }

  event.preventDefault()
  if (editor.selection) {
    Mark.toggleMark(editor, editor.selection, mark)
  }
  return true
}
