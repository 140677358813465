import * as React from 'react'
import { Audio, Transforms as CnxTransforms, Image, Media, Video } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import { FileDescription, StorageContext } from '../../../../../../../api/storage'
import ToolGroup from '../ToolGroup'
import AssetPreview from '../../../../../../../components/AssetPreview'
import Modal from '../../../../../../../components/Modal'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import AssetList from '../../../../../../../containers/AssetList'
import { OnToggle } from '../../index'

import './index.css'

interface MediaToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

type Action = 'add' | 'replace'

const MediaTools = ({ toggleState, editor, onToggle }: MediaToolsProps) => {
  const selection = useCurrentSelection()
  const storage = React.useContext(StorageContext)
  const modal = React.useRef<Modal>(null)
  const [action, setAction] = React.useState<Action | null>(null)
  const [nodeToReplace, setNodeToReplace] = React.useState<Audio | Image | Video | null>(null)

  const node = Editor.above(editor, { at: selection, match: Media.isMedia })
  if (!node) return null

  const [media, path] = node

  const onAssetRemove = (node: Audio | Image | Video) => {
    Transforms.removeNodes(editor, { at: [...path, media.children.findIndex(n => n === node)] })
  }

  const onAssetClick = (node: Audio | Image | Video) => {
    setNodeToReplace(node)
    setAction('replace')
    modal.current!.open()
  }

  const onAddMediaNode = () => {
    setAction('add')
    modal.current!.open()
  }

  const createMediaItem = (asset: FileDescription): Omit<Audio | Image | Video, 'children'> => ({
    type: 'media_' + asset.mime.split('/')[0] as 'media_image' | 'media_audio' | 'media_video',
    src: asset.name,
    intendedUse: 'all',
  })

  const addMediaNode = (asset: FileDescription) => {
    const item = createMediaItem(asset)
    CnxTransforms.addMediaItem(editor, item, { at: [...path, media.children.length - 1] })
    modal.current!.close()
  }

  const replaceMediaNode = (asset: FileDescription) => {
    if (!nodeToReplace) return
    Transforms.setNodes(
      editor,
      createMediaItem(asset),
      { at: [...path, media.children.findIndex(n => n === nodeToReplace)] })
    modal.current!.close()
  }

  const renderModal = () => (
    <AssetList
      onSelect={onSelect!}
    />
  )

  const onSelect = (asset: FileDescription) => {
    if (action === 'add') {
      addMediaNode(asset)
    } else if (action === 'replace') {
      replaceMediaNode(asset)
    }
  }

  return media && (
    <ToolGroup
      title="editor-tools-media-title"
      toggleState={toggleState}
      className="media-tools"
      onToggle={() => onToggle('mediaTools')}
    >
      {
        media.children.map(node => {
          if (
            !Image.isImage(node)
            && !Audio.isAudio(node)
            && !Video.isVideo(node)
          ) return null

          const onClick = () => {
            onAssetRemove(node)
          }
          const onReplaceAsset = () => {
            onAssetClick(node)
          }

          return (
            <div key={node.src} className="media-tools__asset">
              <Button
                id="editor-tools-media-remove"
                className="media-tools__remove"
                clickHandler={onClick}
              >
                <Icon name="close" />
              </Button>
              <AssetPreview
                asset={{
                  mime: storage.mimeFromName(node.src),
                  name: node.src,
                }}
                onClick={onReplaceAsset}
              />
            </div>
          )
        })
      }
      <Button l10nId="editor-tools-media-add" clickHandler={onAddMediaNode}>
        Add media file
      </Button>
      <Modal
        ref={modal}
        content={renderModal}
      />
    </ToolGroup>
  )
}

export default MediaTools
