import * as React from 'react'
import { History } from 'history'
import { Ticket } from '../../api'
import TicketManager from './components/TicketManager'
import TicketCreator from './components/TicketCreator'
import TicketsList from './components/TicketsList'
import Button from '../../components/ui/Button'
import PageNavigation from '../../components/PageNavigation'
import './index.css'

interface TicketsProps {
  match: { params: { id?: string } }
  history: History
}

const Tickets = ({ match: { params: { id } }, history }: TicketsProps) => {
  const [openTicketError, setOpenTicketError] = React.useState<Error | null>(null)
  const [selectedTicket, setSelectedTicket] = React.useState<Ticket | null>(null)
  const [showTicketCreator, setShowTicketCreator] = React.useState(false)

  const addTicket = (ticket: Ticket) => {
    setShowTicketCreator(false)
    setSelectedTicket(ticket)
    history.replace(`/helpdesk/${ticket.id}`)
  }

  const closeAddTicket = () => {
    history.push('/helpdesk')
  }

  const onTicketClick = (ticket: Ticket) => {
    history.push(`/helpdesk/${ticket.id}`)
  }

  const openTicket = async (id: string) => {
    try {
      const ticket = await Ticket.load(id)
      setSelectedTicket(ticket)
    } catch (e) {
      setOpenTicketError(e)
    }
  }

  React.useEffect(() => {
    if (!id && (selectedTicket || showTicketCreator || openTicketError)) {
      setSelectedTicket(null)
      setShowTicketCreator(false)
      setOpenTicketError(null)
      return
    }

    if (id === 'new') {
      setShowTicketCreator(true)
    } else if (id) {
      openTicket(id!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (openTicketError) {
    return (
      <div className="tickets tickets--error">
        <h3 className="tickets__error-msg">{openTicketError.message}</h3>
        <Button l10nId="tickets-go-to-list" to="/helpdesk">
          Go to tickets list
        </Button>
      </div>
    )
  }

  if (showTicketCreator) {
    return (
      <div className="tickets">
        <TicketCreator onCreate={addTicket} onClose={closeAddTicket} />
      </div>
    )
  }

  if (selectedTicket) {
    return (
      <div className="tickets">
        <TicketManager ticket={selectedTicket} />
      </div>
    )
  }

  return (
    <div className="tickets">
      <div className="tickets__filter">
        <Button l10nId="tickets-create-new" to="/helpdesk/new">
          Create new ticket
        </Button>
      </div>
      <PageNavigation
        pagination={Ticket.pagination()}
        usePageParam={true}
        Component={({ items }) => (
          <TicketsList tickets={items} onTicketClick={onTicketClick} />
        )}
      />
    </div>
  )
}

export default Tickets
