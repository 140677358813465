import * as React from 'react'
import { Link } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import { isValidUrl } from '../../../../../../../helpers'
import Input from '../../../../../../../components/ui/Input'
import Button from '../../../../../../../components/ui/Button'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

import './index.css'

interface LinkToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const LinkTools = ({ editor, ...props }: LinkToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Link.isLink })
  if (!node) return null

  const [link, path] = node

  const onChange = (val: string) => {
    if (!isValidUrl(val)) return
    Transforms.setNodes(editor, { url: val }, { at: path })
  }

  return (
    <ToolGroup
      title="editor-tools-link-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('linkTools')}
    >
      <Input
        type="text"
        l10nId="editor-tools-link-url"
        value={link.url}
        onChange={onChange}
        validator={isValidUrl}
        required
      />
      <Button
        l10nId="editor-tools-link-remove"
        className="link__button"
        clickHandler={() => Transforms.unwrapNodes(editor, { at: path })}
      >
        Remove link
      </Button>
    </ToolGroup>
  )
}

export default LinkTools
