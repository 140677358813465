import React from 'react'
import AletrnativeIdListElement from './AletrnativeIdListElement'
import Button from '../../../components/ui/Button'

export interface ExistingIdProps {
  repoName: string
  repoID: string
  setID: (repo: string, ID: string) => void
  removeID: (repo: string) => void
}

export default function ExistingId({ repoName, repoID: initialRepoID, setID, removeID }: ExistingIdProps) {
  const [repoID, setRepoID] = React.useState(initialRepoID)
  const onSetId = React.useCallback(setID.bind(null, repoName, repoID), [setID, repoName, repoID])
  const onRemoveId = React.useCallback(removeID.bind(null, repoName), [removeID, repoName])

  return <AletrnativeIdListElement repoName={repoName} repoID={repoID} setRepoID={setRepoID}>
    <Button
      l10nId="alternative-id-element-setID-button"
      type="default"
      clickHandler={onSetId}
    />
    <Button
      l10nId="alternative-id-remove-button"
      type="danger"
      clickHandler={onRemoveId}
    />
  </AletrnativeIdListElement>
}
