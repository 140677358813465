import User from '../../api/user'
import { UserDataAction } from '../../store/actions/user'
import { IsLoading, ReduxStateError } from '../../store/types'
import {
  FETCH_USER_BEGIN,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  UPDATE_USER,
} from '../../store/constants'

export interface UserReduxState {
  isLoadingUser: IsLoading
  user: User | null
  error?: ReduxStateError
}

export const initialState = {
  isLoadingUser: true,
  user: null,
}

// eslint-disable-next-line default-param-last
export function reducer(state: UserReduxState = initialState, action: UserDataAction) {
  switch (action.type) {
  case FETCH_USER_BEGIN:
    return {
      ...state,
      isLoadingUser: true,
      user: state.user,
      error: undefined,
    }

  case FETCH_USER_SUCCESS:
    return {
      ...state,
      isLoadingUser: false,
      user: action.data,
      error: undefined,
    }

  case FETCH_USER_FAILURE:
    return {
      ...state,
      isLoadingUser: false,
      error: action.error,
      user: null,
    }

  case UPDATE_USER:
    return {
      ...state,
      user: state.user?.id === action.userId
        ? new User({ ...state.user, ...action.data }, state.user.apiId)
        : state.user,
    }

  default:
    return state
  }
}
