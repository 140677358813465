/* eslint-disable max-len */
import Book from './book'
import BookPart from './bookpart'
import Draft, { DraftAndBookPart, DraftFile, ProcessDetails } from './draft'
import { ConversationData } from './conversation'
import Module, { AlternativeIds, RefTarget } from './module'
import Pagination from './pagination'
import Process, { Link, ProcessSingleStep, ProcessSlot, ProcessStructure, Slot } from './process'
import ProcessVersion from './processversion'
import Resource from './resource'
import Role from './role'
import Team from './team'
import TeamMember from './teammember'
import Ticket from './ticket'
import User, { LoginMethod } from './user'
import Notification, { Data } from './notification'

export interface APICacheMap {
  'Books': { [bookId: string]: Book }
  'Books/Parts': { [bookId: string]: BookPart }
  'Book/AlternativeIds': { [bookId: string]: AlternativeIds }
  'Conversations': { [convId: string]: ConversationData }
  'Drafts': { [draftId: string]: Draft }
  'Drafts/Processes': { [draftId: string]: ProcessDetails }
  'Drafts/Files': { [draftId: string]: DraftFile[] }
  'LoginMethods': { [method: string]: LoginMethod }
  'Modules': { [moduleId: string]: Module }
  'Modules/AlternativeIds': { [moduleId: string]: AlternativeIds }
  'Modules/Books': { [moduleId: string]: string[] }
  'Modules/Files': { [moduleId: string]: string[] }
  'Modules/XrefTargets': { [moduleId: string]: RefTarget[]}
  'Notifications': { [notiId: string]: Notification<Data> }
  'Processes': { [processId: string]: Process }
  'Pagination': { [endpoint: string]: Pagination<any> }
  'Processes/Slots': { [processId: string]: Slot[] }
  'Processes/Steps': { [processId: string]: ProcessSingleStep[] }
  'Processes/Structures': { [processId: string]: ProcessStructure }
  'Processes/Versions': { [processId: string]: { [versionId: string]: ProcessVersion } }
  'Processes/Versions/Structures': { [processId: string]: { [versionId: string]: ProcessStructure } }
  'Processes/Versions/Links': { [processId: string]: { [versionId: string]: { [stepId: string]: Link[] } } }
  'Processes/Versions/Slots': { [processId: string]: { [versionId: string]: ProcessSlot[] } }
  'Processes/Versions/Steps': { [processId: string]: { [versionId: string]: ProcessSingleStep[] } }
  'Resources': { [resourceId: string]: Resource }
  'Roles': { [teamId: string]: { [roleId: string]: Role } }
  'Teams': { [teamId: string]: Team }
  'TeamMembers': { [teamId: string]: { [memberId: string]: TeamMember } }
  'Tickets': { [ticketId: string]: Ticket }
  'Users': { [userId: string]: User }
  'Users/Drafts': { [userId: string]: { [draftId: string]: DraftAndBookPart } }
}

class APICache {
  private cache: Partial<APICacheMap> = {}

  set<K extends keyof APICacheMap>(key: K, value: APICacheMap[typeof key]) {
    this.cache[key] = value
    return value
  }

  setNested<K extends [keyof APICacheMap, string]>(
    key: K,
    value: APICacheMap[typeof key[0]][typeof key[1]],
  ) {
    const [cacheId, nestedId] = key
    if (typeof this.cache[cacheId] === 'object') {
      this.cache[cacheId]![nestedId] = value
    } else {
      this.cache[cacheId] = {
        [nestedId]: value,
      } as any // TODO: Find a way to handle this typechecking properly
    }
    return value
  }

  get<K extends keyof APICacheMap>(key: K) {
    return this.cache[key]
  }

  getNested<K extends [keyof APICacheMap, string]>(key: K) {
    const [cacheId, nestedId] = key
    if (this.cache[cacheId]) {
      return this.cache[cacheId]![nestedId] as APICacheMap[typeof key[0]][typeof key[1]] | undefined
    }
    return undefined
  }

  update<K extends keyof APICacheMap>(key: K, data: APICacheMap[typeof key]) {
    this.cache[key] = {
      ...this.cache[key],
      ...data,
    }
  }

  updateNested<K extends [keyof APICacheMap, string]>(
    key: K,
    obj: Partial<APICacheMap[typeof key[0]][typeof key[1]]>,
  ) {
    const [cacheId, nestedId] = key

    if (!this.cache[cacheId]) {
      this.cache[cacheId] = {}
    }

    if (!this.cache[cacheId]![nestedId]) {
      this.cache[cacheId]![nestedId] = {}
    }

    for (const [key, val] of Object.entries(obj)) {
      this.cache[cacheId]![nestedId][key] = val
    }
  }

  updateNestedWithUpdater<K extends [keyof APICacheMap, string]>(
    key: K,
    updater: (item: APICacheMap[typeof key[0]][typeof key[1]]) => APICacheMap[typeof key[0]][typeof key[1]],
  ) {
    const [cacheId, nestedId] = key

    if (!this.cache[cacheId]) {
      this.cache[cacheId] = {}
    }

    if (!this.cache[cacheId]![nestedId]) {
      this.cache[cacheId]![nestedId] = {}
    }

    this.cache[cacheId]![nestedId] = updater(this.cache[cacheId]![nestedId] as APICacheMap[typeof key[0]][typeof key[1]])
  }

  async getOrSet(
    key: keyof APICacheMap,
    fromCache: boolean,
    func: () => Promise<APICacheMap[typeof key]>,
  ) {
    if (fromCache) {
      const cached = this.get(key)
      if (cached) return cached
    }

    const data = await func()
    return this.set(key, data)
  }

  async getOrSetNested<K extends [keyof APICacheMap, string]>(
    key: K,
    fromCache: boolean,
    func: () => Promise<APICacheMap[typeof key[0]][typeof key[1]]>,
  ) {
    if (fromCache) {
      const cached = this.getNested(key)
      if (cached) return cached
    }

    const data = await func()
    return this.setNested(key, data)
  }

  getOrSetNestedSync<K extends [keyof APICacheMap, string]>(
    key: K,
    fromCache: boolean,
    data: APICacheMap[typeof key[0]][typeof key[1]],
  ) {
    if (fromCache) {
      const cached = this.getNested(key)
      if (cached) return cached
    }

    return this.setNested(key, data)
  }

  invalidate(...keys: (keyof APICacheMap | [keyof APICacheMap, string])[]) {
    for (const key of keys) {
      if (typeof key === 'string') {
        delete this.cache[key]
        continue
      }

      const [cacheId, nestedId] = key
      if (this.cache[cacheId] && this.cache[cacheId]![nestedId]) {
        delete this.cache[cacheId]![nestedId]
      }
    }
  }

  clear() {
    this.cache = {}
  }
}

export default new APICache()
