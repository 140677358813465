import * as React from 'react'
import { useHistory } from 'react-router'
import { History } from 'history'
import { Draft, Module } from '../../../api'
import store from '../../../store'
import { archiveModule } from '../../../store/actions/modules'
import { confirmDialog } from '../../../helpers'
import Header from '../../../components/Header'
import Load from '../../../components/Load'
import Section from '../../../components/Section'
import LockToggle from '../../../components/LockToggle'
import LimitedUI from '../../../components/LimitedUI'
import Button from '../../../components/ui/Button'
import ModulePreview from '../../../containers/ModulePreview'

interface ModuleProps {
  history: History
  mod: Module
  isDraftExisting: boolean
}

async function loader({ match }: { match: { params: { id: string } } }) {
  const [module, draft] = await Promise.all([
    Module.load(match.params.id),
    Draft.load(match.params.id).catch(() => null),
  ])

  return {
    mod: module,
    isDraftExisting: draft !== null,
  }
}

const _Module = ({ mod, isDraftExisting }: ModuleProps) => {
  const [editing, setEditing] = React.useState(false)

  const toggleEditing = (status: boolean) => {
    setEditing(status)
  }

  const history = useHistory()

  const removeModule = async () => {
    const res = await confirmDialog({
      title: 'module-remove-title',
      vars: { module: mod.title },
      buttons: {
        cancel: 'module-remove-cancel',
        confirm: 'module-remove',
      },
    })
    if (res === 'confirm') {
      store.dispatch(archiveModule(mod))
      history.push('/')
    }
  }

  const exportModule = () => {
    mod.export()
  }

  return (
    <Section>
      <Header l10nId="module-view-title" title={mod.title}>
        {
          isDraftExisting ?
            <Button
              l10nId="module-open-draft"
              withBorder={true}
              to={`/drafts/${mod.id}/edit`}
            >
              Open draft
            </Button>
            : null
        }
        <LimitedUI team={mod.team} permissions="module:edit">
          <LockToggle onToggle={toggleEditing} />
          {
            editing &&
              <Button l10nId="module-remove" type="danger" clickHandler={removeModule}>
                Remove
              </Button>
          }
        </LimitedUI>
        <LimitedUI team={mod.team} permissions="content:export">
          <Button l10nId="module-export" clickHandler={exportModule} withBorder={true}>
            Export
          </Button>
        </LimitedUI>
      </Header>
      <div className="section__content">
        <ModulePreview moduleId={mod.id}/>
      </div>
    </Section>
  )
}

export default Load(loader)(_Module)
