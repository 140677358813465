import * as React from 'react'
import * as Counters from 'react-counters'
import { Rule } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { RULE, RULE_EXAMPLE, RULE_PROOF, RULE_STATEMENT } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface RuleProps extends RenderElementProps {
  element: Rule
}

const RuleComp = ({ children, attributes, element }: RuleProps) => {
  const { document } = React.useContext(LOCALIZATION)
  Counters.useCounter(attributes.ref, {
    increment: [RULE],
    reset: [RULE_EXAMPLE, RULE_PROOF, RULE_STATEMENT],
  })
  const counter = Counters.useCounterValue(attributes.ref, RULE)
  const message = document.getString('rule-label', { rule: counter, type: element.kind })

  return <div {...attributes} className="rule" data-label={message}>
    {children}
  </div>
}

export default RuleComp
