import * as React from 'react'
import { Localized } from '@fluent/react'
import Notification, { Data } from '../../../../../api/notification'
import Button from '../../../../../components/ui/Button'
import NotificationListItem from '../NotificationListItem'

interface NotificationsListProps {
  items: Notification<Data>[]
  onDelete: (notification: Notification<Data>) => void
}

const NotificationsList = ({ items, onDelete }: NotificationsListProps) => {
  const deleteAll = () => {
    items.forEach(noti => noti.delete().then(onDelete))
  }

  if (items.length === 0) {
    return <Localized id="notification-centre-empty">
      No notifications found.
    </Localized>
  }

  return <>
    <Button
      l10nId="notification-centre-delete-all"
      type="danger"
      className="notifications__delete-all"
      clickHandler={deleteAll}
    >
      Delete all
    </Button>
    <ul className="notificationsList">
      {items.map(noti => (
        <NotificationListItem key={noti.data.id} notification={noti} afterDelete={onDelete} />
      ))}
    </ul>
  </>
}

export default NotificationsList
