import * as React from 'react'
import { useSelector } from 'react-redux'
import Team, { TeamPermission } from '../../api/team'
import { useIsInSuperMode } from '../../hooks'

import './index.css'

/**
 * permissions or onePermissionFrom prop is required if onlySuper is not set to true.
 *
 * We've tried to require those by creating separate interfaces but typescript
 * wasn't cooperating.
 */
interface LimitedUIProps {
  children: React.ReactNode
  // User has to have all of passed permissions.
  permissions?: TeamPermission | TeamPermission[]
  // User has to have one permission from given array.
  onePermissionFrom?: TeamPermission[]
  // Component will show only for users in super session.
  onlySuperElevated?: boolean
  // Component will show only for users with is_super flag
  onlySuper?: boolean
  // Component will show only for users in support team.a1
  onlySupport?: boolean
  // If specified then user has to have permissions in this team.
  team?: Team | number
}

const LimitedUI = (props: LimitedUIProps) => {
  const user = useSelector(state => state.user.user)
  const [userHasPermissions, setUserHasPermissions] = React.useState(false)
  const isInSuperMode = useIsInSuperMode(user)

  /**
   * Check whether user has proper permissions.
   */
  const checkUserPermissions = () => {
    if (!user) {
      setUserHasPermissions(false)
      return
    }

    if (user.is_super) {
      setUserHasPermissions(true)
      return
    }

    const {
      permissions,
      onePermissionFrom,
      onlySuper,
      onlySuperElevated,
      onlySupport,
      team,
    } = props

    if (onlySuper) {
      setUserHasPermissions(user.is_super)
      return
    }

    if (onlySuperElevated) {
      setUserHasPermissions(isInSuperMode)
      return
    }

    if (onlySupport) {
      setUserHasPermissions(user.is_support)
      return
    }

    if (!permissions && !onePermissionFrom) {
      throw new Error('LimitedUI component require permissions or onePermissionFrom prop.')
    }

    if (isInSuperMode) {
      setUserHasPermissions(isInSuperMode)
      return
    }

    if (team) {
      const teamId = typeof team === 'number' ? team : team.id
      setUserHasPermissions(
        user.hasPermissionsInTeam(
          (permissions || onePermissionFrom) as TeamPermission | TeamPermission[],
          teamId,
        ),
      )
      return
    }

    if (permissions) {
      if (typeof permissions === 'string') {
        setUserHasPermissions(user.allPermissions.has(permissions))
      } else {
        setUserHasPermissions(permissions.every(p => user.allPermissions.has(p)))
      }
      return
    } else if (onePermissionFrom) {
      setUserHasPermissions(onePermissionFrom.some(p => user.allPermissions.has(p)))
      return
    }

    setUserHasPermissions(false)
  }

  React.useEffect(() => {
    checkUserPermissions()
  })

  if (!userHasPermissions) return null

  return (
    <div className="limitedui">
      {props.children}
    </div>
  )
}

export default LimitedUI
