/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://74245313f86c1ebe3683306585762fad@sentry.naukosfera.com/4'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = '006474131b98c1400c74649fbf667ee4f6b1032d'
