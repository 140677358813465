import * as React from 'react'
import { Localized } from '@fluent/react'
import { useSelector } from 'react-redux'
import Process from '../../../../../api/process'
import ProcessInfo from './ProcessInfo'

import './index.css'

interface ProcessesListProps {
  isEditingUnlocked?: boolean
  processes: Process[]
  onDelete: (process: Process) => void
  onUpdate: (process: Process, data: Partial<Process>) => void
}

const ProcessesList = ({
  processes, isEditingUnlocked = false, onDelete, onUpdate,
}: ProcessesListProps) => {
  const selectedTeams = useSelector(state => state.app.selectedTeams)

  const filtered = React.useMemo(
    () => processes.filter(p => selectedTeams.includes(p.team)),
    [processes, selectedTeams])


  if (filtered.length === 0) {
    return <div className="processes__list-no-results">
      <Localized id="processes-list-no-results">
        There are no results for matching criteria
      </Localized>
    </div>
  }

  return (
    <ul className="processes__list">
      {
        filtered.map(p => (
          <ProcessListItem
            key={p.id}
            isEditingUnlocked={isEditingUnlocked}
            process={p}
            afterDelete={onDelete}
            afterUpdate={onUpdate}
          />
        ))
      }
    </ul>
  )
}

export default ProcessesList

interface ProcessesListItemProps {
  isEditingUnlocked: boolean
  process: Process
  afterDelete: (process: Process) => void
  afterUpdate: (process: Process, data: Partial<Process>) => void
}

const ProcessListItem = ({
  isEditingUnlocked,
  process,
  afterDelete,
  afterUpdate,
}: ProcessesListItemProps) => (
  <li className="processes__item">
    <ProcessInfo
      isEditingUnlocked={isEditingUnlocked}
      process={process}
      afterDelete={afterDelete}
      afterUpdate={afterUpdate}
    />
  </li>
)
