import * as React from 'react'
import { useSelector } from 'react-redux'
import Conversation from '../../api/conversation'
import { State } from '../../store/reducers'
import InputEditor from './components/InputEditor'
import MessagesStream from './components/MessagesStream'
import Spinner from '../../components/Spinner'

import './index.css'

interface ChatProps {
  conversation: Conversation
  disableInput?: boolean
}

const Chat = ({ conversation, disableInput }: ChatProps) => {
  const { conversations, messages } = useSelector((state: State) => state.conversations)
  const messagesStreamRef = React.useRef<MessagesStream>(null)

  React.useEffect(() => {
    if (messagesStreamRef && messagesStreamRef.current) {
      messagesStreamRef.current.scrollToBottom()
    }
  }, [disableInput])

  React.useEffect(() => {
    if (messagesStreamRef && messagesStreamRef.current) {
      messagesStreamRef.current.checkForLoadingMessagesInView()
    }
  }, [conversation])

  const convData = conversations.get(conversation.id)
  const chatMessages = messages.get(conversation.id) || []

  if (!convData) return <Spinner />

  return (
    <div className="chat">
      <MessagesStream
        ref={messagesStreamRef}
        conversation={conversation}
        convData={convData}
        messages={chatMessages}
      />
      { !disableInput && convData.write && <InputEditor socket={conversation} /> }
    </div>
  )
}

export default Chat
