import * as React from 'react'
import { Role, Team } from '../../../../../api'
import AddRole from '../AddRole'
import RoleManager from '../RoleManager'

import './index.css'

interface RolesManagerProps {
  roles: Role[]
  team: Team
  onAdd: (item: Role) => void
  onDelete: (item: Role) => void
}

const RolesManager = ({ roles, team, onAdd, onDelete }: RolesManagerProps) => <>
  <AddRole team={team} onSuccess={onAdd} />
  <ul className="teams__rolesList">
    {
      roles.map(r => (
        <li key={r.id} className="teams__role">
          <RoleManager
            role={r}
            afterDelete={onDelete}
          />
        </li>
      ))
    }
  </ul>
</>

export default RolesManager
