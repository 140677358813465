import * as React from 'react'
import Dialog from '../../../../../../../components/ui/Dialog'
import Button from '../../../../../../../components/ui/Button'

interface AfterExportDialogProps {
  show: boolean
  onClose: () => void
}

const AfterExportDialog = (props: AfterExportDialogProps) => {
  if (!props.show) return null

  return (
    <Dialog
      size="medium"
      l10nId="editor-tools-save-export-title"
      placeholder={`Please send downloaded file to the administrator so
      he can fix your problem.`}
      onClose={props.onClose}
    >
      <div className="dialog__buttons dialog__buttons--center">
        <Button l10nId="editor-tools-save-export-ok" clickHandler={props.onClose}>
          Ok
        </Button>
      </div>
    </Dialog>
  )
}

export default AfterExportDialog
