import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { User } from '../../../api'
import Section from '../../../components/Section'
import Spinner from '../../../components/Spinner'
import Header from '../../../components/Header'
import UserProfile from '../../../containers/UserProfile'
import UserTeamsAndRoles from './components/UserTeamsAndRoles'
import './index.css'

interface ProfileProps {
  match: {
    params: {
      id: string
    }
  }
}

const Profile = ({ match }: ProfileProps) => {
  const loggedUser = useSelector(state => state.user.user)
  const [user, setUser] = React.useState<User | null>(null)
  const history = useHistory()

  React.useEffect(() => {
    let mounted = true

    User.load(match.params.id)
      .then(usr => {
        if (mounted) setUser(usr)
      })
      .catch(() => {
        history.push('/404')
      })

    return () => {
      mounted = false
      setUser(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id])

  return <div className="container container--splitted">
    <Section>
      {
        loggedUser?.id === user?.id
          ? <Header l10nId="user-profile-view-title-your" title="Your profile" />
          :
          <Header
            l10nId="user-profile-view-title-named"
            title={user ? user.name + "'s profile" : '...'}
            vars={{
              name: user ? user.name : '...',
            }}
          />
      }
      <div className="section__content">
        {user ? <UserProfile user={user} onUpdate={setUser} /> : <Spinner />}
      </div>
    </Section>
    <Section>
      <Header l10nId="user-profile-section-teams-and-roles" title="User's teams and roles" />
      <div className="section__content">
        {user ? <UserTeamsAndRoles user={user} /> : <Spinner />}
      </div>
    </Section>
  </div>
}

export default Profile
