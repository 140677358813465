import * as React from 'react'
import { Localized } from '@fluent/react'
import Nestable, { RenderItem } from 'react-nestable'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Draft } from '../../api'
import BookPart, { PartData } from '../../api/bookpart'
import { DraftAndBookPart } from '../../api/draft'
import { useLoadDraftsGroupedInBooks } from '../../api/hooks'
import store from '../../store'
import { removeExpandedGroup, setExpandedGroup } from '../../store/actions/app'
import ModuleLabels from '../../components/ModuleLabels'
import Spinner from '../../components/Spinner'
import Button from '../../components/ui/Button'
import Icon from '../../components/ui/Icon'
import Tooltip from '../../components/ui/Tooltip'

import './index.css'

interface DraftsListProps {
  drafts: DraftAndBookPart[]
}

const DraftsList = ({ drafts }: DraftsListProps) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [draftsMap, setDraftsMap] = React.useState<Map<string, Draft>>(new Map())
  const selectedTeams = useSelector(state => state.app.selectedTeams)
  const [groupedDrafts] = useLoadDraftsGroupedInBooks(drafts)
  const [booksWithFetchedData, setBooksWitchFetchedData] = React.useState<Set<string>>(new Set())

  const getUnfetchedIds = () => {
    if (!groupedDrafts) return null

    const bookIds: Set<string> = new Set()
    const unfechedIds: string[] = []

    bookLoop:
    for (const { book, draftIds } of groupedDrafts.data) {
      for (const ids of draftIds) {
        for (const id of ids) {
          if (!draftsMap.has(id)) {
            if (unfechedIds.length === 32) break bookLoop
            bookIds.add(book?.id || 'empty')
            unfechedIds.push(id)
          }
        }
      }
    }

    return unfechedIds.length ? { bookIds, unfechedIds } : null
  }

  const fetchMoreDrafts = async () => {
    if (isLoading) return
    setIsLoading(true)
    const data = getUnfetchedIds()
    if (data) {
      const drafts = await Draft.loadMultiple(data.unfechedIds)
      setDraftsMap(map => {
        const newMap = new Map(map)
        drafts.forEach(d => newMap.set(d.module, d))
        return newMap
      })
      setBooksWitchFetchedData(set => new Set([...set, ...data.bookIds]))
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    // fetch data initially
    if (!isLoading && groupedDrafts?.data.length && draftsMap.size === 0) {
      fetchMoreDrafts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, groupedDrafts, draftsMap])

  // Fetch more drafts when scrolled 50px from the bottom
  const onScroll = React.useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target as HTMLDivElement
    if (!isLoading && scrollTop + offsetHeight >= scrollHeight - 50) {
      fetchMoreDrafts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className="draftsList" onScroll={onScroll}>
      {
        groupedDrafts?.totalDrafts ?
          <>
            <ul className="list">
              {
                groupedDrafts.data.map(data => {
                  if (
                    !booksWithFetchedData.has(data.book?.id || 'empty')
                    || (data.book && !selectedTeams.includes(data.book.team))
                  ) return null

                  return (
                    <li key={data.book?.id || 'empty'} className="list__item draftsList__book">
                      {
                        data.book ?
                          <>
                            <div className="draftsList__book-title">
                              {data.book.title}
                            </div>
                            <ul className="list">
                              <NestableCustomized parts={data.parts} draftsMap={draftsMap} />
                            </ul>
                          </>
                          :
                          <>
                            <div className="draftsList__book-title">
                              <Localized id="dashboard-drafts-section-not-assigned">
                                Not assigned to any book
                              </Localized>
                            </div>
                            <ul className="list">
                              {
                                data.draftIds.flatMap(d => d).map(d => {
                                  if (
                                    !draftsMap.has(d)
                                    || !selectedTeams.includes(draftsMap.get(d)!.team)
                                  ) return null

                                  return (
                                    <li key={d} className="list__item">
                                      <DraftItemContent draft={draftsMap.get(d)!} />
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </>
                      }
                    </li>
                  )
                })
              }
            </ul>
            {
              groupedDrafts.totalDrafts !== draftsMap.size &&
                <Button id="draft-list-load-more" isDisabled={isLoading} clickHandler={fetchMoreDrafts}>
                  Load more { isLoading && <Spinner />}
                </Button>
            }
          </>
          :
          <Localized id="dashboard-drafts-empty">
            There are no drafts to display
          </Localized>
      }
    </div>
  )
}

export default DraftsList

const hasFetchedDraft = (
  parts: PartData[],
  draftsMap: Map<string, Draft>,
): boolean => parts.some(p => {
  if (p.kind === 'module' && draftsMap.has(p.id)) return true
  if (p.kind === 'group' && hasFetchedDraft(p.parts, draftsMap)) return true
  return false
})

const NestableCustomized = ({
  parts,
  draftsMap,
}: { parts: BookPart[], draftsMap: Map<string, Draft> }) => {
  const url = window.location.pathname + '#DraftsList'
  const expandedGroups = useSelector(state => state.app.expandedGroups)

  const renderItem = ({ item, collapseIcon }: RenderItem<PartData>) => {
    const toggleGroup = () => {
      nestable.current!.toggleCollapseGroup(item.number)
    }

    if (!hasFetchedDraft(item.kind === 'module' ? [item] : item.parts, draftsMap)) return null

    return (
      <div className={`bookpart__item bookpart__item--${item.kind}`}>
        {
          item.kind === 'group' ?
            <>
              <span className="bookpart__icon">
                {collapseIcon}
              </span>
              <div
                className="bookpart__title"
                onClick={toggleGroup}
              >
                {item.title}
              </div>
            </>
            : <DraftItemContent draft={draftsMap.get(item.id)!} />
        }
      </div>
    )
  }

  const renderCollapseIcon = ({ isCollapsed }: {isCollapsed: boolean}) => {
    if (isCollapsed) {
      return <Icon name="arrow-right"/>
    }
    return <Icon name="arrow-down" />
  }

  const onToggleCollapse = (num: number, newState: boolean) => {
    if (newState) {
      store.dispatch(setExpandedGroup(url, num))
    } else {
      store.dispatch(removeExpandedGroup(url, num))
    }
  }

  const nestable = React.createRef<Nestable>()

  return (
    <Nestable
      ref={nestable}
      isDisabled={true}
      items={parts}
      className="book-collection"
      childrenProp="parts"
      renderItem={renderItem}
      renderCollapseIcon={renderCollapseIcon}
      collapsedGroups={expandedGroups[url] || []}
      onToggleCollapse={onToggleCollapse}
    />
  )
}

const DraftItemContent = ({ draft }: { draft: Draft}) => <>
  <Link
    to={`/drafts/${draft.module}/edit`}
    className="draftsList__draft-title"
  >
    {draft?.title || draft.title}
  </Link>
  <ModuleLabels module={draft.module} />
  <Tooltip l10nId="dashboard-drafts-details">
    <Link to={`/drafts/${draft.module}`} className="draftsList__details-icon">
      <Icon size="small" name="details"/>
    </Link>
  </Tooltip>
</>
