import { AppAction, ConfirmDialogOptions } from '../../store/actions/app'
import {
  CLOSE_CONFIRM_DIALOG,
  SET_AVAILABLE_LOCALES,
  SET_EXPANDED_GROUPS,
  SET_LOCALE,
  SET_SELECTED_TEAMS,
  SHOW_CONFIRM_DIALOG,
} from '../../store/constants'
import { ExpandedGroups } from '../../store/types'

export interface AppReduxState {
  locale: string[]
  availableLocales: string[]
  showConfirmDialog: boolean
  confirmDialogOptions: ConfirmDialogOptions
  selectedTeams: number[]
  expandedGroups: ExpandedGroups
}

const DEFAULT_CONFIRM_DIALOG_OPTIONS: ConfirmDialogOptions = {
  title: 'confirm-dialog-title',
  content: '',
  buttons: {
    ok: 'confirm-dialog-button-ok',
    cancel: 'confirm-dialog-button-cancel',
  },
  callback: (key: string) => key,
}

export const initialState: AppReduxState = {
  locale: Array.from(navigator.languages),
  availableLocales: [],
  showConfirmDialog: false,
  confirmDialogOptions: DEFAULT_CONFIRM_DIALOG_OPTIONS,
  selectedTeams: [],
  expandedGroups: {},
}

// eslint-disable-next-line default-param-last
export function reducer(state: AppReduxState = initialState, action: AppAction) {
  switch (action.type) {
  case SET_LOCALE:
    // console.log( action, state.locale)
    return {
      ...state,
      locale: action.data,
    }

  case SET_AVAILABLE_LOCALES:
    return {
      ...state,
      availableLocales: action.data,
    }

  case SHOW_CONFIRM_DIALOG:
    return {
      ...state,
      showConfirmDialog: true,
      confirmDialogOptions: action.data,
    }

  case CLOSE_CONFIRM_DIALOG:
    return {
      ...state,
      showConfirmDialog: false,
      confirmDialogOptions: DEFAULT_CONFIRM_DIALOG_OPTIONS,
    }

  case SET_SELECTED_TEAMS:
    return {
      ...state,
      selectedTeams: action.data,
    }

  case SET_EXPANDED_GROUPS:
    return {
      ...state,
      expandedGroups: action.data,
    }

  default:
    return state
  }
}
