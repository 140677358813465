import * as React from 'react'
import { Localized } from '@fluent/react'
import { useDispatch, useSelector } from 'react-redux'
import { Team } from '../../api'
import { TeamID } from '../../api/team'
import { useLoadAllTeams } from '../../api/hooks'
import { setSelectedTeams as setSelectedTeamsAction } from '../../store/actions/app'
import SelectList from '../../components/ui/SelectList'
import Icon from '../../components/ui/Icon'

import './index.css'

const TeamSwitcher = () => {
  const [teamsMap, setTeamsMap] = React.useState<Map<TeamID, Team>>(new Map())
  const [teams] = useLoadAllTeams()
  const { user, selectedTeams } = useSelector(state => ({
    user: state.user.user,
    selectedTeams: state.app.selectedTeams,
  }))
  const dispatch = useDispatch()
  const selectList = React.useRef<SelectList>(null)
  const teamSwitcher = React.useRef<HTMLDivElement>(null)

  const onSelectListChange = (selected: Set<number>) => {
    const teamsIds = [...selected]
    dispatch(setSelectedTeamsAction(teamsIds))
  }

  const toggleList = () => {
    const isListOpen = selectList.current!.state.isListOpen
    if (isListOpen) {
      selectList.current!.close()
    } else {
      selectList.current!.open()
    }
  }

  const setSelectedTeams = (teams: Team[]) => {
    const teamsMap = new Map(teams.map(t => [t.id, t]))
    setTeamsMap(teamsMap)

    const selTeamsFromLS = localStorage.getItem('selectedTeams')
    // Filter value saved in localStorage since it can change when user will log
    // to another account.
    let selectedTeams: number[] = JSON.parse(selTeamsFromLS || '[]')
      .filter((tId: number) => teamsMap.has(tId)
        && teamsMap.get(tId)!.status !== 'archived'
        && user?.teams.find(t => t.id === tId || user.is_super))

    if (selectedTeams.length === 0 && user && user.teams.length > 0) {
      selectedTeams = user.teams
        .filter(t => teamsMap.has(t.id) && teamsMap.get(t.id)!.status !== 'archived')
        .map(t => t.id)
    }

    dispatch(setSelectedTeamsAction(selectedTeams))
  }

  const onKeyDown = React.useCallback((ev: KeyboardEvent) => {
    if (ev.key === 'Escape' && selectList.current) {
      if (selectList.current.state.isListOpen) {
        selectList.current!.close()
      }
    }
  }, [selectList])

  const clickOutside = React.useCallback((ev: MouseEvent) => {
    if (
      teamSwitcher.current &&
      !teamSwitcher.current.contains(ev.target as HTMLElement)
    ) {
      if (selectList.current!.state.isListOpen) {
        selectList.current!.close()
      }
    }
  }, [teamSwitcher, selectList])

  React.useEffect(() => {
    setSelectedTeams(teams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams])

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('click', clickOutside)
    }
  }, [clickOutside, onKeyDown])

  if (!user || user.teams.length === 0) return null

  const options = teams.filter(t => t.status !== 'archived')

  return (
    <div ref={teamSwitcher} className="team-switcher">
      <span id="team-switcher-toggle" className="team-switcher__box" onClick={toggleList}>
        <span className="team-switcher__value">
          <span className="team-switcher__name">
            {
              selectedTeams.length && teamsMap.has(selectedTeams[0]) ?
              teamsMap.get(selectedTeams[0])!.name
                :
                <Localized id="team-switcher-select-placeholder">
                  Select teams
                </Localized>
            }
          </span>
          {
            selectedTeams.length > 1 ?
              <span className="team-switcher__more">
                + {selectedTeams.length - 1}
              </span>
              : null
          }
        </span>
        <span className="team-switcher__trigger">
          <Icon size="small" name="arrow-down" />
        </span>
      </span>
      <SelectList
        ref={selectList}
        showSelectAllOption={options.length > 1}
        isListOpen={false}
        options={options}
        onChange={onSelectListChange}
        formatOption={formatSelectOption}
        selected={selectedTeams}
        closeAfterSelect={false}
      />
    </div>
  )
}

export default TeamSwitcher

function formatSelectOption(option: any): JSX.Element {
  return option.name
}
