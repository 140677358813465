import * as React from 'react'
import { Localized } from '@fluent/react'
import { Draft, Module, Process, Team, User } from '../../api'
import { ProcessDetails } from '../../api/draft'
import { ProcessSlot } from '../../api/process'
import { useLoadDrafts, useLoadDraftsDetails, useLoadStructure, useLoadTeam } from '../../api/hooks'
import { SlotId, UserId } from '../BeginProcess'
import Dialog from '../../components/ui/Dialog'
import Spinner from '../../components/Spinner'
import UsersList from '../../containers/UsersList'
import SlotInfo from './SlotInfo'

import './index.css'

interface UpdateSlotsProps {
  process: Process
  modules: (Module | string)[]
  team: Team | number
}

const UpdateSlots = (props: UpdateSlotsProps) => {
  const [drafts, isLoadingDrafts] = useLoadDrafts(props.modules.map(m => m instanceof Module ? m.id : m))
  const [draftsWithProcessDetails, isLoadingDraftsDetails] = useLoadDraftsDetails(drafts || [])
  const [structure, isLoadingStructure] = useLoadStructure(props.process)
  const [team] = useLoadTeam(props.team instanceof Team ? props.team.id : props.team)
  const [showAssignUser, setShowAssignUser] = React.useState(false)
  const [currentSlot, setCurrentSlot] = React.useState<ProcessSlot | null>(null)
  const [slots, setSlots] = React.useState<Map<SlotId, UserId[]>>(new Map())

  const draftsInProcess = React.useMemo(() => {
    const map = new Map<Draft, ProcessDetails>()

    if (!draftsWithProcessDetails) return map

    for (const [draft, processDetails] of draftsWithProcessDetails) {
      if (processDetails.id[0] === props.process.id) {
        map.set(draft, processDetails)
      }
    }

    return map
  }, [draftsWithProcessDetails, props.process])

  React.useEffect(() => {
    // set initial users in slots
    const slots = new Map<SlotId, UserId[]>()
    for (const details of draftsInProcess.values()) {
      for (const slot of details.slots) {
        if (slot.user && (!slots.has(slot.id) || !slots.get(slot.id)!.includes(slot.user.id))) {
          slots.set(slot.id, [...slots.get(slot.id) || [], slot.user.id])
        }
      }
    }
    setSlots(slots)
  }, [draftsInProcess])

  const toggleUserForSlot = async (slot: ProcessSlot, user: User | null) => {
    const promises = []

    for (const draft of draftsInProcess.keys()) {
      promises.push(() => user
        ? Draft.assignUser(draft.module, slot.id, user.id)
        : Draft.unassignUser(draft.module, slot.id))
    }

    await Promise.all(promises.map(f => f()))

    setSlots(state => {
      const slots = new Map(state)
      if (user) {
        slots.set(slot.id, [user.id])
      } else {
        slots.delete(slot.id)
      }
      return slots
    })
    setShowAssignUser(false)
  }

  const showAssignUserDialog = (slot: ProcessSlot) => {
    setCurrentSlot(slot)
    setShowAssignUser(true)
  }

  if (isLoadingStructure || isLoadingDrafts || isLoadingDraftsDetails) {
    return (
      <div className="update-slots">
        <Spinner />
      </div>
    )
  }

  if (!drafts || !draftsWithProcessDetails || !structure) {
    return (
      <div className="update-slots">
        <Localized id="update-slots-fetching-error">
          Could not fetch details about slots in this process for given modules.
          Please try again later.
        </Localized>
      </div>
    )
  }

  return (
    <div className="update-slots">
      {
        showAssignUser && currentSlot && team ?
          <Dialog
            l10nId="update-slots-assign-user-title"
            vars={{
              slot: currentSlot.name,
              roles: currentSlot.roles.length ? currentSlot.roles.join(', ') : 'undefined',
            }}
            placeholder="Select user with [roles] for current slot."
            size="medium"
            onClose={() => setShowAssignUser(false)}
          >
            <UsersList
              allowedRoles={currentSlot.roles}
              teams={[team]}
              onUserClick={user => toggleUserForSlot(currentSlot, user)}
            />
          </Dialog>
          : null
      }
      <h3>
        <Localized id="update-slots-title">
          Manage slots assignments for drafts:
        </Localized>
      </h3>
      <ul>
        {Array.from(draftsInProcess.keys()).map(d => <li key={d.module}>{d.title}</li>)}
      </ul>
      {
        structure.slots.map(s => (
          <SlotInfo
            key={s.id}
            team={props.process.team}
            slot={s}
            slots={slots}
            onAssignUser={showAssignUserDialog}
            onUnassignUser={slot => toggleUserForSlot(slot, null)}
          />
        ))
      }
    </div>
  )
}

export default UpdateSlots
