import * as React from 'react'
import { Localized } from '@fluent/react'
import Team, { TeamUpdate } from '../../../../../api/team'
import { addAlert } from '../../../../../store/actions/alerts'
import store from '../../../../../store'
import EditableText from '../../../../../components/EditableText'

import './index.css'

export interface TeamSettingsProps {
  team: Team
  onUpdate?: (team: Team, update: Partial<Team>) => void
}

export default function TeamSettings({ team, onUpdate }: TeamSettingsProps) {
  // Since team.update modifies that object directly, EditableText will not, on
  // its own, notice that changes have been accepted. Instead we use a key to
  // force react to re-create these components.
  const [key, setKey] = React.useState(0)

  const updateTeam = React.useCallback((data: TeamUpdate) => {
    team.update(data)
      .then(() => {
        store.dispatch(addAlert('success', 'teams-update-name-success'))
        setKey(key => key + 1)
        if (onUpdate != null) {
          onUpdate(team, data)
        }
      })
      .catch(() => {
        store.dispatch(addAlert('error', 'teams-update-name-error'))
      })
  }, [team, onUpdate])

  const updateTeamName = React.useCallback((name: string) => updateTeam({ name }), [updateTeam])

  const updateTeamRepository = React.useCallback(
    (repository: string) => updateTeam({ repository: repository.length > 0 ? repository : null }),
    [updateTeam],
  )

  return <div className="team__settings">
    <span className="team__settings--label">
      <Localized id="team-settings-name">
        Name:
      </Localized>
    </span>
    <EditableText
      key={key}
      text={team.name}
      minLength={1}
      onAccept={updateTeamName}
      placeholder="team-settings-name-placeholder"
    />

    {team.repository !== undefined && <>
      <span className="team__settings--label">
        <Localized id="team-settings-repository-url">
          Git repository URL:
        </Localized>
      </span>
      <EditableText
        key={key}
        text={team.repository ?? ''}
        onAccept={updateTeamRepository}
        placeholder="team-settings-repository-url-placeholder"
      />
    </>}

    {team.ssh_key != null && <>
      <span className="team__settings--label">
        <Localized id="team-settings-ssh-key">
          SSH public key:
        </Localized>
      </span>
      <span>
        {team.ssh_key}
      </span>
    </>}
  </div>
}
