import * as React from 'react'
import { match } from 'react-router'
import { FilesError } from 'react-files'
import { Localized } from '@fluent/react'
import Creatable from 'react-select/creatable'
import Draft from '../../../api/draft'
import store from '../../../store'
import { addAlert } from '../../../store/actions/alerts'
import { saveAsFile as saveAs } from '../../../helpers'
import Load from '../../../components/Load'
import Header from '../../../components/Header'
import Section from '../../../components/Section'
import Spinner from '../../../components/Spinner'
import DraftInfo from '../../../components/DraftInfo'
import DraftFiles from '../../../components/DraftFiles'
import DraftTitle from '../../../components/DraftTitle'
import ValidationErrorsDialog from '../../../components/ValidationErrorsDialog'
import Button from '../../../components/ui/Button'
import Dialog from '../../../components/ui/Dialog'
import FilesUploader from '../../../containers/FilesUploader'
import AlternativeIdList from '../../../containers/AlternativeIdList/AlternativeIdList'
import LicenseSelector from '../../../containers/LicenseSelector'

import './index.css'

interface DraftDetailsProps {
  draft: Draft
}

async function loader({ match: { params: { id } } }: { match: match<{ id: string }> }) {
  const draft = await Draft.load(id)
  return {
    draft,
  }
}

interface DraftDetailsState {
  isDownloading: boolean
  isImporting: boolean
  showImportDialog: boolean
  files: File[]
  validationErrors: string | null
}

class DraftDetais extends React.Component<DraftDetailsProps> {
  state: DraftDetailsState = {
    isDownloading: false,
    isImporting: false,
    showImportDialog: false,
    files: [],
    validationErrors: null,
  }

  private downloadCNXML = async () => {
    this.setState({ isDownloading: true })
    const data = await this.props.draft.read('index.cnxml')
    saveAs(this.props.draft.title + '.cnxml', data)
    this.setState({ isDownloading: false })
  }

  private showImportDialog = () => {
    this.setState({ showImportDialog: true })
  }

  private closeImportDialog = () => {
    this.setState({ showImportDialog: false, files: [] })
  }

  private importCNXML = async () => {
    this.setState({ isImporting: true })
    const file = this.state.files[0]
    if (!file) return
    const text = await new Response(file).text()
    this.props.draft.writeCNXML(text)
      .then(res => {
        store.dispatch(addAlert('success', 'draft-details-import-success'))
        if (res.data.validation) {
          this.setState({ validationErrors: res.data.validation })
        }
      })
      .catch(e => {
        store.dispatch(addAlert('error', 'draft-details-import-error', { details: e.toString() }))
      })
    this.setState({ showImportDialog: false, isImporting: false })
  }

  private onFilesChange = (files: File[]) => {
    this.setState({ files })
  }

  private onFilesError = (error: FilesError, _: File) => {
    store.dispatch(addAlert('error', 'file-upload-error', { code: error.code }))
  }

  private resetValidationErrors = () => {
    this.setState({ validationErrors: null })
  }

  private onLicenseChange = (license: string | null) => {
    this.props.draft.update({ license })
    this.forceUpdate()
  }

  public render() {
    const { draft } = this.props
    const { showImportDialog, isDownloading, isImporting, files, validationErrors } = this.state

    return (
      <Section className="draft-details">
        <Header
          l10nId="draft-details-view-title"
          title="Draft details"
        >
          <DraftTitle draft={draft} />
          <DraftInfo draft={draft} />
          <div className="draft-details__controls">
            <Button l10nId="draft-details-go-to-draft" to={`/drafts/${draft.module}/edit`} withBorder={true}>
              Go to draft
            </Button>
          </div>
        </Header>
        <div className="section__content">
          <div className="section__half">
            <div className="draft-details__buttons">
              <Button
                l10nId={
                  isDownloading
                    ? "draft-details-button-downloading"
                    : "draft-details-button-download"
                }
                clickHandler={this.downloadCNXML}
                isDisabled={isDownloading}
              />
              {
                draft.hasPermission(['edit', 'accept-changes', 'propose-changes'])
                  ? (
                    <Button
                      l10nId={
                        isImporting
                          ? "draft-details-button-importing"
                          : "draft-details-button-import"
                      }
                      clickHandler={this.showImportDialog}
                      isDisabled={isImporting}
                    />
                  )
                  : null
              }
            </div>
            <DraftFiles draft={draft} />
          </div>
          <div className="section__half">
            <LicenseSelector license={draft.license} onChange={this.onLicenseChange} />
            <AlternativeIdList element={this.props.draft}/>
          </div>
        </div>
        {
          showImportDialog ?
            <Dialog
              size="medium"
              l10nId="draft-details-import-title"
              placeholder="Upload CNXML file to import"
              onClose={this.closeImportDialog}
            >
              {
                isImporting ?
                  <Spinner />
                  :
                  <>
                    <FilesUploader
                      onFilesChange={this.onFilesChange}
                      onFilesError={this.onFilesError}
                      accepts={['.cnxml']}
                      multiple={false}
                      optional={false}
                    />
                    <div className="dialog__buttons">
                      <Button l10nId="draft-details-button-cancel" clickHandler={this.closeImportDialog}>
                        Cancel
                      </Button>
                      <Button
                        l10nId="draft-details-button-confirm"
                        clickHandler={this.importCNXML}
                        isDisabled={!files.length}
                      >
                        Confirm
                      </Button>
                    </div>
                  </>
              }
            </Dialog>
            : null
        }
        <ValidationErrorsDialog
          title="draft-details-validation-errors"
          errors={validationErrors}
          onClear={this.resetValidationErrors}
        />
      </Section>
    )
  }
}

export default Load(loader)(DraftDetais)
