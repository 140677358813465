const chunk = <T>(arr: T[], max: number): T[][] => {
  if (arr.length === 0) return []

  const result = []
  for (let start = 0; start < arr.length; start += max) {
    result.push(arr.slice(start, start + max))
  }

  return result
}

export default chunk
