import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Localized } from '@fluent/react'
import { Book, BookPart, Module, Process } from '../../api'
import { addAlert } from '../../store/actions/alerts'
import confirmDialog from '../../helpers/confirmDialog'
import LimitedUI from '../../components/LimitedUI'
import EditableText from '../../components/EditableText'
import Button from '../../components/ui/Button'
import Dialog from '../../components/ui/Dialog'
import BeginProcess from '../../containers/BeginProcess'
import ProcessPreview from '../../containers/ProcessPreview'
import UpdateSlots from '../../containers/UpdateSlots'

export interface GroupProps {
  book: Book
  item: BookPart
  collapseIcon: any
  isEditingUnlocked: boolean
  showStatsFor: Process | null
  afterAction: () => void
  onTitleClick: (num: number) => void
  onModuleAdd: (parent: BookPart) => void
  onGroupAdd: (parent: BookPart) => void
}

const Group = (props: GroupProps) => {
  const [showBeginProcess, setShowBeginProcess] = React.useState(false)
  const [showUpdateSlots, setShowUpdateSlots] = React.useState(false)
  const [
    nestedModulesWithoutProcess,
    setNestedModulesWithoutProcess,
  ] = React.useState<Module[] | undefined>(undefined)
  const [
    nestedModulesInSelectedProcess,
    setNestedModulesInSelectedProcess,
  ] = React.useState<Module[] | undefined>(undefined)
  const dispatch = useDispatch()

  const { isEditingUnlocked, collapseIcon, item, showStatsFor } = props
  const hasPartsNotInProcess = React.useMemo(
    () => item.parts!.some(p => p.kind === 'module' && !p.process),
    [item.parts])
  const hasPartsInSelectedProcess = React.useMemo(
    () => showStatsFor && item.parts!.some(
      p => p.kind === 'module'
      && p.process?.process === showStatsFor.id),
    [item.parts, showStatsFor])
  const modStatuses = React.useMemo(
    () => showStatsFor ? item.getModuleStatuses(showStatsFor.id) : new Map(),
    [item, showStatsFor])

  const showBeginProcessDialog = async () => {
    const modules: Module[] = []

    for (const part of props.item.parts!) {
      if (part.kind === 'module') {
        const mod = await part.module()
        if (mod && !mod.process) {
          modules.push(mod)
        }
      }
    }

    if (modules.length) {
      setShowBeginProcess(true)
      setNestedModulesWithoutProcess(modules)
    } else {
      setNestedModulesWithoutProcess(undefined)
      await confirmDialog({
        title: 'book-begin-process-no-modules',
        buttons: {
          ok: 'book-begin-process-no-modules-ok',
        },
        buttonsPosition: 'center',
      })
    }
  }

  const closeBeginProcessDialog = () => {
    setShowBeginProcess(false)
    setNestedModulesWithoutProcess(undefined)
  }

  const showUpdateSlotsDialog = async () => {
    if (!showStatsFor) return

    const modules: Module[] = []

    for (const part of props.item.parts!) {
      if (part.kind === 'module') {
        const mod = await part.module()
        if (mod && mod.process?.process === showStatsFor.id) {
          modules.push(mod)
        }
      }
    }

    if (modules.length) {
      setShowUpdateSlots(true)
      setNestedModulesInSelectedProcess(modules)
    } else {
      setNestedModulesInSelectedProcess(undefined)
      await confirmDialog({
        title: 'book-update-slots-no-modules',
        buttons: {
          ok: 'book-update-slots-no-modules-ok',
        },
        buttonsPosition: 'center',
      })
    }
  }

  const updateBook = () => {
    props.afterAction()
  }

  const updateGroupName = (name: string) => {
    if (!name.length || name === props.item.title) return

    props.item.update({ title: name })
      .then(() => {
        updateBook()
        dispatch(
          addAlert(
            'success',
            'book-group-change-title-alert-success',
            {
              from: props.item.title,
              to: name,
            },
          ),
        )
      })
      .catch(e => {
        dispatch(addAlert('error', e.message))
      })
  }

  const handleAddGroup = () => {
    props.onGroupAdd(props.item)
  }

  const removeGroup = () => {
    const { item } = props

    item.delete()
      .then(() => {
        updateBook()
        dispatch(
          addAlert(
            'success',
            'book-remove-group-alert-success',
            { title: item.title },
          ),
        )
      })
      .catch(e => {
        dispatch(addAlert('error', e.message))
      })
  }

  const showRemoveGroupDialog = async () => {
    const res = await confirmDialog({
      title: 'book-remove-group-dialog-title',
      buttons: {
        cancel: 'book-remove-group-cancel',
        confirm: 'book-remove-group-confirm',
      },
      showCloseButton: false,
    })
    if (res === 'confirm') {
      removeGroup()
    }
  }

  const handleAddModule = () => {
    props.onModuleAdd(props.item)
  }

  const afterBeginProcess = () => {
    props.afterAction()
  }

  const onTitleClick = () => {
    if (!props.isEditingUnlocked) {
      props.onTitleClick(props.item.number)
    }
  }

  return (
    <>
      <span className="bookpart__icon">
        {collapseIcon}
      </span>
      <span className="bookpart__title" onClick={onTitleClick} data-testid="bookpartgroup-title">
        {
          isEditingUnlocked ?
            <EditableText
              text={item.title}
              onAccept={updateGroupName}
              placeholder="book-part-title-placeholder"
            />
            : item.title
        }
      </span>
      <span className="bookpart__info">
        {
          isEditingUnlocked ?
            <LimitedUI permissions="book:edit">
              <Button l10nId="book-button-add-module" clickHandler={handleAddModule} data-testid="add-module">
                Add module
              </Button>
              <Button l10nId="book-button-add-group" clickHandler={handleAddGroup} data-testid="add-group">
                Add group
              </Button>
              <Button
                l10nId="book-button-remove"
                type="danger"
                clickHandler={showRemoveGroupDialog}
                data-testid="remove"
              >
                Remove
              </Button>
            </LimitedUI>
            : null
        }
        {
          showStatsFor
            ? <>
              {
                modStatuses.size
                  ? <ModStatuses statuses={modStatuses} />
                  : null
              }
              {
                hasPartsInSelectedProcess
                  ? <LimitedUI permissions="editing-process:manage">
                    <Button l10nId="book-update-slots" clickHandler={showUpdateSlotsDialog}>
                      Update slots
                    </Button>
                  </LimitedUI>
                  : null
              }
            </>
            : hasPartsNotInProcess
              ? <LimitedUI permissions="editing-process:manage">
                <Button
                  l10nId="book-begin-process"
                  clickHandler={showBeginProcessDialog}
                  data-testid="show-begin-process"
                >
                  Begin process
                </Button>
              </LimitedUI>
              : null
        }
      </span>
      {
        showBeginProcess && nestedModulesWithoutProcess ?
          <Dialog
            l10nId="book-begin-process-title"
            placeholder="Configure and begin process"
            size="medium"
            className="bookpart__item--module begin-process-dialog"
            onClose={closeBeginProcessDialog}
          >
            <BeginProcess
              modules={nestedModulesWithoutProcess}
              onClose={closeBeginProcessDialog}
              afterUpdate={afterBeginProcess}
            />
          </Dialog>
          : null
      }
      {
        showUpdateSlots && nestedModulesInSelectedProcess && showStatsFor ?
          <Dialog
            l10nId="book-process-preview-title"
            placeholder="Process details:"
            size="big"
            onClose={() => setShowUpdateSlots(false)}
          >
            <UpdateSlots
              process={showStatsFor}
              modules={nestedModulesInSelectedProcess}
              team={props.book.team}
            />
            <ProcessPreview process={showStatsFor} />
          </Dialog>
          : null
      }
    </>
  )
}

export default Group

const ModStatuses = ({ statuses }: { statuses: Map<string, number> }) => {
  const sts = Array.from(statuses.entries())
  return (
    <>
      {
        sts.map(([stepName, counter]) => (
          <span key={stepName} className="bookpart__step">
            <Localized id="book-part-step-statistic" vars={{ step: stepName, counter }}>
              {`{ $step }: { $counter }`}
            </Localized>
          </span>
        ))
      }
    </>
  )
}
