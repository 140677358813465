import * as React from 'react'
import Select from 'react-select'
import { CrossReference, DocumentReference, Link } from 'cnx-designer'
import { Localized } from '@fluent/react'
import { Editor, Transforms } from 'slate'
import Modal from '../../../../../../../components/Modal'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import XrefTargetSelector, { XrefTargetData } from '../../../../../../../containers/XrefTargetSelector'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

import './index.css'

const CASES: string[] = [
  'nominative',
  'genitive',
  'dative',
  'accusative',
  'instrumental',
  'locative',
  'vocative',
]

interface XrefToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const XrefTools = ({ editor, ...props }: XrefToolsProps) => {
  const selection = useCurrentSelection()

  const xrefModal = React.useRef<Modal>(null)
  const node = Editor.above(editor, { at: selection, match: isLinkLike })
  if (!node) return null

  const [xref, path] = node
  const xrefCase = (xref as Pick<CrossReference, 'case'>).case || CASES[0]

  const changeCase = ({ value }: { value: string, label: string }) => {
    const attr: Partial<Editor> | Partial<Element> | Partial<Text> = {
      document: xref.document,
      case: value !== 'none' ? value : null,
    }
    if (xref.type === 'xref') attr.target = xref.target
    Transforms.setNodes(
      editor,
      attr,
      { at: path },
    )
  }

  const renderXrefModal = () => (
    <XrefTargetSelector onSelect={changeReference} />
  )

  const changeReference = (data: XrefTargetData) => {
    xrefModal.current!.close()

    const newRef: Partial<CrossReference | DocumentReference | Link> = {
      type: data.type,
    }

    switch (data.type) {
    case 'link':
      newRef.url = data.url
      break

    case 'docref':
      newRef.document = data.source.id
      break

    case 'xref':
      newRef!.target = data.target.id
      if (data.source) {
        newRef!.document = data.source.id
      }
      break

    default:
      console.error(`Unknown type on ${data}`)
      return
    }

    // Update xref properties
    Transforms.setNodes(editor, newRef, { at: path })
    if (data.type === 'link' || data.type === 'docref') {
      // Insert new text for link or docref. Do not insert it for xref because it is a void element.
      const text = data.type === 'link' ? data.text : data.source.title
      Transforms.insertText(editor, text, { at: path })
    }
  }

  const title = xref.type === 'docref' ? 'editor-tools-docref-title' : 'editor-tools-xref-title'

  return (
    <ToolGroup
      title={title}
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('xrefTools')}
    >
      {
        (xref.type === 'xref' || xref.type === 'docref')
        && <label className="toolbox__label">
          <span className="xref__title">
            <Localized id="editor-tools-xref-case">
              Select case
            </Localized>
          </span>
          <Select
            className="toolbox__select react-select"
            onChange={changeCase as any}
            options={CASES.map(c => ({ value: c, label: c }))}
            value={{ value: xrefCase, label: xrefCase }}
            formatOptionLabel={OptionLabel}
          />
        </label>
      }
      <Button
        id="editor-tools-xref-change"
        clickHandler={() => xrefModal.current!.open()}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="pencil" />
        <Localized id="editor-tools-xref-change">
          Change target
        </Localized>
      </Button>
      <Modal
        ref={xrefModal}
        content={renderXrefModal}
      />
    </ToolGroup>
  )
}

export default XrefTools

function OptionLabel({ value: case_ }: {value: string, label: string}) {
  return (
    <Localized
      id="editor-tools-xref-grammatical-case"
      vars={{ case: case_ }}
    >
      {case_}
    </Localized>
  )
}

function isLinkLike(value: unknown): value is CrossReference | DocumentReference | Link {
  return CrossReference.isCrossReference(value)
    || DocumentReference.isDocumentReference(value)
    || Link.isLink(value)
}
