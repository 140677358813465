import * as React from 'react'
import { Localized } from '@fluent/react'
import { MathfieldElement } from 'mathlive'
import { Snippet, SnippetGroup, SNIPPETS, Variant } from '../../assets/latex-symbols'
import Math from '../../components/Math'
import Button from '../../components/ui/Button'
import './index.css'

interface MathEditorProps {
  equation: string
  onCancel: () => void,
  onFinish: (latex: string) => void,
}

export default function MathEditor(props: MathEditorProps) {
  const { equation, onFinish } = props

  const ref = React.useRef<HTMLDivElement>(null)
  const editor = React.useRef<MathfieldElement>()

  if (editor.current == null) {
    const ml = new MathfieldElement()
    ml.value = equation

    editor.current = ml
  }

  React.useLayoutEffect(() => {
    ref.current!.prepend(editor.current!)
  }, [])

  React.useEffect(() => {
    editor.current!.focus()
  }, [])

  const onFinishHandler = React.useCallback(() => {
    onFinish(editor.current!.value)
  }, [onFinish])

  return <div ref={ref} className="math-editor">
    <div className="math-editor__tools">
      <div className="math-editor__toolbar">
        <Button
          id="math-editor-cancel"
          clickHandler={props.onCancel}
        >
          <Localized id="math-editor-cancel">Cancel and close</Localized>
        </Button>
        <Button
          id="math-editor-finish"
          clickHandler={onFinishHandler}
        >
          <Localized id="math-editor-finish">Finish and close</Localized>
        </Button>
      </div>
      <Snippets editor={editor.current} />
    </div>
  </div>
}

function Snippets({ editor }: { editor: MathfieldElement }) {
  const onSelect = React.useCallback((snippet: Snippet) => {
    editor.insert(snippet.snippet, {
      focus: true,
      format: 'latex',
      insertionMode: 'replaceSelection',
      mode: 'latex',
      resetStyle: true,
      selectionMode: 'placeholder',
      smartFence: false,
    })
  }, [editor])

  return <div className="math-editor__snippets">
    {SNIPPETS.map(group => <SnippetGroupComp key={group.name} group={group} onSelect={onSelect} />)}
  </div>
}

interface SnippetGroupProps {
  group: SnippetGroup
  onSelect: (snippet: Snippet) => void
}

function SnippetGroupComp({ group, onSelect }: SnippetGroupProps) {
  return <div className="snippet-group">
    <div className="snippet-group__header">
      <Localized id={group.name}>{group.name}</Localized>
    </div>
    <div className="snippet-group__content">
      {group.snippets.map(snippet => (
        <SnippetComp key={snippet.snippet} snippet={snippet} onSelect={onSelect} />
      ))}
    </div>
  </div>
}

interface SnippetButtonProps {
  snippet: Snippet
  onSelect: (snippet: Snippet) => void
}

function SnippetButton({ snippet, onSelect }: SnippetButtonProps) {
  const onClick = React.useCallback(() => onSelect(snippet), [snippet, onSelect])
  const preview = snippet.preview ?? snippet.snippet

  return <Button id="math-editor-snippet" clickHandler={onClick} className="snippet toolbox__button--only-icon">
    <Math latex={preview} placement="block" />
  </Button>
}

interface SnippetButtonWithVariantsProps {
  snippet: Snippet
  variants: Snippet[],
  onSelect: (snippet: Snippet) => void
}

function SnippetButtonWithVariants({ snippet, variants, onSelect }: SnippetButtonWithVariantsProps) {
  return <button id="math-editor-snippet" className="snippet has-variants toolbox__button--only-icon">
    <Math latex={snippet.preview ?? snippet.snippet} placement="block" />
    <SnippetPalette
      main={snippet}
      variants={variants}
      onSelect={onSelect}
    />
  </button>
}

interface SnippetProps {
  snippet: Variant
  onSelect: (snippet: Snippet) => void
}

function SnippetComp({ snippet, onSelect }: SnippetProps) {
  if (snippet.variants == null) {
    return <SnippetButton snippet={snippet} onSelect={onSelect} />
  }
  return <SnippetButtonWithVariants snippet={snippet} variants={snippet.variants} onSelect={onSelect} />
}

interface SnippetPaletteProps {
  main: Snippet,
  variants: Snippet[]
  onSelect: (snippet: Snippet) => void
}

function SnippetPalette({ main, variants, onSelect }: SnippetPaletteProps) {
  return <div className="snippet__palette">
    <SnippetButton snippet={main} onSelect={onSelect} />
    {variants.map(variant => (
      <SnippetButton key={variant.snippet} snippet={variant} onSelect={onSelect} />
    ))}
  </div>
}
