import * as React from 'react'
import { useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import { Conversation, User } from '../../api'
import store from '../../store'
import { removeConversationTab } from '../../store/actions/conversations'
import Icon from '../../components/ui/Icon'
import Chat from '../../containers/Chat'

import './index.css'

interface ConversationTabProps {
  conversation: Conversation
  openAtStart?: boolean
  title?: string
}

const ConversationTab = ({ conversation, openAtStart = true, title }: ConversationTabProps) => {
  const [members, setMembers] = React.useState<User[]>([])
  const [isOpen, setIsOpen] = React.useState(openAtStart)
  const {
    conversations,
    loggedUser,
  } = useSelector(state => ({
    conversations: state.conversations.conversations,
    loggedUser: state.user.user,
  }))
  const convInfo = conversations.get(conversation.id)

  const toggleIsOpen = () => {
    setIsOpen(open => !open)
  }

  const closeConversation = () => {
    store.dispatch(removeConversationTab(conversation.id))
  }

  React.useEffect(() => {
    // Members of conversation without author (if there is more than one member)
    const fetchMembersData = async () => {
      const members: User[] = []

      if (convInfo?.members) {
        for (const memberId of convInfo.members) {
          members.push(await User.load(memberId))
        }
      }

      if (members.length > 1) {
        members.filter(user => user.id !== loggedUser?.id)
      }

      setMembers(members)
    }
    fetchMembersData()
  }, [convInfo, loggedUser])

  if (!convInfo) return null

  return (
    <div className={`conversation-tab ${isOpen ? 'conversation-tab--open' : ''}`}>
      <div className="conversation-tab__info">
        <div id="conversation-visibility-toggle" className="conversation-tab__title" onClick={toggleIsOpen}>
          {
            title
              ? <Localized id={title}>{ title }</Localized>
              : members.map(user => user.name)
          }
        </div>
        <div id="conversation-tab-close" className="conversation-tab__close" onClick={closeConversation}>
          <Icon name="close" />
        </div>
      </div>
      <div className="conversation-tab__content">
        <Chat conversation={conversation} />
      </div>
    </div>
  )
}

export default ConversationTab
