import * as React from 'react'
import { SeeAlso } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { LOCALIZATION } from '../../constants'

interface DefinitionSeeAlsoProps extends RenderElementProps {
  element: SeeAlso
}

const DefinitionSeeAlsoComp = ({ children, attributes }: DefinitionSeeAlsoProps) => {
  const { document } = React.useContext(LOCALIZATION)
  const message = document.getString('definition-seealso-label')

  return <div {...attributes} className="definition-seealso" data-label={message}>
    {children}
  </div>
}

export default DefinitionSeeAlsoComp
