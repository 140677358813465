import * as React from 'react'
import { Localized } from '@fluent/react'
import Dialog from '../../../../../../../components/ui/Dialog'
import Button from '../../../../../../../components/ui/Button'

interface SuggestionsDialogProps {
  show: boolean
  suggestions: number
  onClose: () => void
}

const SuggestionsDialog = (props: SuggestionsDialogProps) => {
  if (!props.show) return null

  return (
    <Dialog
      size="medium"
      l10nId="step-changer-dialog-suggestions"
      placeholder="Please resolve all suggestions."
      onClose={props.onClose}
    >
      <div>
        <Localized
          id="step-changer-dialog-suggestions-info"
          vars={{ suggestions: props.suggestions }}
        >
          You have unresolved suggestions in the document.
          Please resolve all of them before changing step.
        </Localized>
      </div>
      <div className="step-changer__dialog-controls">
        <Button l10nId="step-changer-dialog-suggestions-ok" clickHandler={props.onClose}>
          Ok
        </Button>
      </div>
    </Dialog>
  )
}

export default SuggestionsDialog
