import * as React from 'react'
import { renderMathInElement } from 'mathlive'

interface MathProps {
  latex: string
  placement: 'line' | 'block'
}

export default function Math({ latex, placement }: MathProps) {
  const ref = React.useRef(null)

  React.useLayoutEffect(() => {
    renderMathInElement(ref.current!, {
      namespace: 'mathlive',
    })
  }, [latex])

  const Tag = placement === 'line' ? 'span' : 'div'
  const type = placement === 'line'
    ? 'math/text; mode=text'
    : 'math/text; mode=display'

  return <Tag ref={ref}>
    <script type={type}>{latex}</script>
  </Tag>
}
