import Process from '../../api/process'
import { AddAlert, addAlert } from './alerts'

export const updateProcessName = (process: Process, name: string) => (
  dispatch: React.Dispatch<AddAlert>,
) => process.updateName(name)
  .then(() => {
    dispatch(addAlert('success', 'process-update-name-success'))
  })
  .catch(e => {
    dispatch(
      addAlert(
        'error',
        'process-update-name-error',
        { details: e?.response?.data?.raw },
      ),
    )
  })
