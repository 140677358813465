import * as React from 'react'
import { Route } from 'react-router'
import TeamEditor from './components/TeamEditor'
import TeamList from './components/TeamList'
import Section from '../../../components/Section'

import './index.css'

const Teams = () => {
  return (
    <Section className="teams">
      <Route exact path="/teams" component={TeamList} />
      <Route exact path="/teams/:id" component={TeamEditor} />
    </Section>
  )
}

export default Teams
