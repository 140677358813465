import * as React from 'react'
import { NameTerm } from 'cnx-designer'
import { Editor, NodeEntry, Transforms } from 'slate'
import { Localized, ReactLocalization, useLocalization } from '@fluent/react'
import Input from '../../../../../../../../../components/ui/Input'

interface NameIndexProps {
  node: NodeEntry<NameTerm>
  editor: Editor
}

const NameIndex = ({ node, editor }: NameIndexProps) => {
  const [term, path] = node
  const [reference, setReference] = React.useState(term.text as string)
  const { l10n } = useLocalization()

  React.useEffect(() => {
    const { name, born, died } = term
    setReference(formatNameReference(l10n, name ?? '', born ?? 0, died ?? null))
  }, [l10n, term])

  return <>
    <label className="terms__label">
      <span className="terms__title">
        <Localized id="editor-tools-term-name">
          Name
        </Localized>
      </span>
      <Input
        type="text"
        value={term.name || ''}
        onChange={(val: string | null) => Transforms.setNodes(editor, { name: val }, { at: path })}
      />
    </label>
    <label className="terms__label">
      <span className="terms__title">
        <Localized id="editor-tools-term-born">
          Year of birth
        </Localized>
      </span>
      <Input
        type="number"
        value={term.born}
        onChange={(val: number | null) => Transforms.setNodes(editor, { born: val }, { at: path })}
      />
    </label>
    <label className="terms__label">
      <span className="terms__title">
        <Localized id="editor-tools-term-died">
          Year of death
        </Localized>
      </span>
      <Input
        type="number"
        value={term.died}
        onChange={(val: number | null) => Transforms.setNodes(editor, { died: val }, { at: path })}
      />
    </label>
    <div>
      <Localized
        id="editor-tools-term-name-index-form"
        vars={{ reference }}
        elems={{ wrap: <strong/> }}
      >
        {/* Required to properly render anchors as html elements. */}
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <></>
      </Localized>
    </div>
  </>
}

export default NameIndex

function formatNameReference(
  l10n: ReactLocalization,
  name: string,
  born: number,
  died: number | null,
): string {
  let yob, eob, yod, eod

  if (born <= 0) {
    yob = 1 - born
    eob = 'bce'
  } else {
    yob = born
    eob = 'ce'
  }

  if (died == null) {
    yod = ''
    eod = 'none'
  } else if (died <= 0) {
    yod = 1 - died
    eod = 'bce'
  } else {
    yod = died
    eod = 'ce'
  }

  return l10n.getString('term-name-reference-form', { name, yob: Number(yob), eob, yod: Number(yod), eod })
}
