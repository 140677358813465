import * as React from 'react'
import { useSelector } from 'react-redux'
import ActiveTask from './components/ActiveTask'
import TaskPreview from './components/TaskPreview'

import './index.css'

export default function Tasks() {
  const { tasks } = useSelector(state => state.tasks)

  return <>
    <div className="tasks">
      {Array.from(tasks, ([key, data]) => (
        <TaskPreview
          key={key}
          id={key}
          data={data}
        />
      ))}
    </div>

    <ActiveTask />
  </>
}
