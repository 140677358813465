import * as React from 'react'
import Select from 'react-select'
import { Foreign } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import { Localized, withLocalization, WithLocalizationProps } from '@fluent/react'
import data from '../../../../../../../locale/data'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

interface ForeignToolsProps extends WithLocalizationProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const ForeignTools = ({
  toggleState, editor, onToggle, getString,
}: ForeignToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Foreign.isForeign })
  if (!node) return null

  const [foreign, path] = node

  const selectLanguage = (lang: { value: string } | null) => {
    const language = lang && data.allLanguages.find(l => l.code === lang.value)
    Transforms.setNodes(
      editor,
      { language: language?.code } as Partial<Foreign>,
      { at: path })
  }

  const language = foreign.language ? data.allLanguages.find(l => l.code === foreign.language) : undefined

  return (
    <ToolGroup
      title="editor-tools-foreign-title"
      toggleState={toggleState}
      onToggle={() => onToggle('foreignTools')}
    >
      <label className="toolbox__label">
        <span className="toolbox__title">
          <Localized id="editor-tools-foreign-select-language">
            Select language of foreign word
          </Localized>
        </span>
        <Select
          className="toolbox__select react-select"
          placeholder={getString('editor-tools-foreign-select-placeholder')}
          value={language ? { value: language.code, label: language.name } : null}
          onChange={selectLanguage as any}
          options={data.allLanguages.map(l => ({ value: l.code, label: l.name }))}
          formatOptionLabel={formatSelectOption}
        />
      </label>
    </ToolGroup>
  )
}

export default withLocalization(ForeignTools)

function formatSelectOption(option: any): JSX.Element {
  return option.label
}
