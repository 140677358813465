/** @jsxImportSource slate-cnxml */

import * as CNXML from 'slate-cnxml'
import { Editor, Node, Path, Transforms } from 'slate'
import MathML2Latex from 'mathml-to-latex'
import { createUUID } from '../../../../../helpers'
import '../cnxml'
import { Equation, Math } from './interfaces'

const MATHML_NAMESPACE = "http://www.w3.org/1998/Math/MathML"

export function deserialize(editor: CNXML.DeserializingEditor, el: Element, at: Path): boolean {
  if (el.namespaceURI === MATHML_NAMESPACE || el.localName === "math") {
    Transforms.insertNodes(
      editor,
      {
        type: 'math',
        id: el.getAttribute('id') ?? createUUID(),
        placement: getMathPlacement(editor, el, at),
        mathml: normalizeMathMLNamespaces(el).outerHTML,
        latex: MathML2Latex.convert(el.outerHTML),
        children: [{ text: '' }],
      },
      { at },
    )

    return true
  }

  if (el.localName === "equation") {
    CNXML.buildElement(editor, el, at, {
      type: 'equation',
      id: el.getAttribute('id') ?? createUUID(),
    }, { ...CNXML.BLOCK, ...CNXML.INLINE })

    return true
  }

  function getMathPlacement(editor: CNXML.DeserializingEditor, el: Element, at: Path): 'line' | 'block' {
    const display = el.getAttribute('display')

    switch (display) {
    case 'inline': return 'line'
    case 'block': return 'block'
    }

    if (at[at.length - 1] <= 0) return 'block'

    const [previous] = Editor.previous(editor, { at: Path.previous(at) }) ?? []

    return previous && Editor.isInline(editor, previous)
      ? 'line'
      : 'block'
  }

  return false
}

export function serialize(node: Node, attrs: CNXML.CommonAttrs, children: CNXML.Node) {
  if (Equation.isEquation(node)) {
    return (
      <equation {...attrs}>
        {children}
      </equation>
    )
  }

  if (Math.isMath(node)) {
    return new DOMParser().parseFromString(node.mathml as string, 'application/xml').childNodes[0]
  }

  return null
}

function normalizeMathMLNamespaces(el: Element): Element {
  const newEl = el.ownerDocument.createElementNS(el.namespaceURI, el.localName)
  for (let child of el.childNodes) { // eslint-disable-line
    switch (child.nodeType) {
    case window.Node.ELEMENT_NODE:
      newEl.appendChild(normalizeMathMLNamespaces(child as Element))
      break

    case window.Node.ATTRIBUTE_NODE:
      throw new Error("shouldn't happen")

    default:
      newEl.appendChild(newEl.ownerDocument.importNode(child, true))
      break
    }
  }

  for (let attr of el.attributes) { // eslint-disable-line
    newEl.setAttributeNS(attr.namespaceURI, attr.localName, attr.value)
  }

  return newEl
}
