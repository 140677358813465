import * as React from 'react'
import { Admin } from '../../../api'
import Button from '../../../components/ui/Button'
import Section from '../../../components/Section'
import Header from '../../../components/Header'

import './index.css'

const AdminScreen = () => {
  const [backupEnabled, setBackupEnabled] = React.useState(true)

  const onCreateBackup = React.useCallback(() => {
    Admin.createBackup()
    setBackupEnabled(false)
  }, [setBackupEnabled])

  return (
    <div className="container admin">
      <Section>
        <Header l10nId="admin-view-title" title="Administration" />
        <div className="section__content">
          <div className="admin__tools">
            <Button l10nId="admin-create-backup" clickHandler={onCreateBackup} isDisabled={!backupEnabled}>
              Create backup
            </Button>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default AdminScreen
