import * as React from 'react'
import Select from 'react-select'
import { FluentVariable } from '@fluent/bundle'
import { Localized } from '@fluent/react'
import Team, { TeamID } from '../../../../../api/team'
import Process, {
  ProcessSlot,
  ProcessStep,
  ProcessStructure,
  SlotPermission,
} from '../../../../../api/process'
import ProcessSlots from '../ProcessSlots'
import ProcessSteps from '../ProcessSteps'
import TeamSelector from '../../../../../components/TeamSelector'
import Button from '../../../../../components/ui/Button'
import Input from '../../../../../components/ui/Input'

import './index.css'

interface ProcessFormProps {
  structure?: ProcessStructure | null
  process?: Process
  onSubmit: (structure: ProcessStructure, team: TeamID) => any
}

type ErrorsMap = Map<string, Record<string, FluentVariable>> // Map<l10nId, l10nArgs>

interface ProcessFormState {
  name: string
  team: Team | null
  startingStep: number
  slots: ProcessSlot[]
  steps: ProcessStep[]
  errors: ErrorsMap
}

class ProcessForm extends React.Component<ProcessFormProps> {
  state: ProcessFormState = {
    name: '',
    team: null,
    startingStep: 0,
    slots: [],
    steps: [],
    errors: new Map(),
  }

  private handleNameChange = (name: string) => {
    this.setState({ name }, () => {
      if (this.state.errors.size) {
        // We validate form every each change only if validation failed before.
        // This way user is able to see that errors, are updating when he make actions.
        this.validateForm()
      }
    })
  }

  private handleTeamChange = (team: Team) => {
    this.setState({ team }, () => {
      if (this.state.errors.size) {
        this.validateForm()
      }
    })
  }

  private handleStartingStepChange = ({ value }: { value: number, label: string }) => {
    this.setState({ startingStep: value }, () => {
      if (this.state.errors.size) {
        this.validateForm()
      }
    })
  }

  private handleSlotsChange = (slots: ProcessSlot[]) => {
    this.setState({ slots }, () => {
      if (this.state.errors.size) {
        this.validateForm()
      }
    })
  }

  private handleStepsChange = (steps: ProcessStep[]) => {
    this.setState({ steps }, () => {
      if (this.state.errors.size) {
        this.validateForm()
      }
    })
  }

  private onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!this.validateForm()) return

    const { name, startingStep, slots, steps, team } = this.state

    if (!team) return

    this.props.onSubmit({
      start: startingStep,
      name,
      slots,
      steps,
    }, team.id)
  }

  private updateStructure = async () => {
    const { structure: s, process } = this.props
    if (s) {
      this.setState({
        name: s.name,
        startingStep: s.start,
        slots: s.slots,
        steps: s.steps,
      })
    }
    if (!compareTeams(process ? process.team : null, this.state.team)) {
      if (!process) {
        this.setState({ team: null })
      } else {
        this.setState({ team: await Team.load(process.team) })
      }
    }
  }

  componentDidUpdate(prevProps: ProcessFormProps) {
    const prevTeam = prevProps.process ? prevProps.process.team : null
    const currTeam = this.props.process ? this.props.process.team : null
    if (
      !compareTeams(prevTeam, currTeam) ||
      !compareStructures(prevProps.structure, this.props.structure)
    ) {
      this.updateStructure()
    }
  }

  componentDidMount() {
    this.updateStructure()
  }

  public render() {
    const { startingStep, slots, steps, errors, name, team } = this.state
    const { process } = this.props

    return (
      <form
        className="process-form"
        onSubmit={this.onSubmit}
      >
        <div className="controls">
          <Button
            l10nId={
              !this.props.structure ? "process-form-create" : "process-form-save-changes"
            }
            clickHandler={this.onSubmit}
            isDisabled={errors.size > 0}
          />
          <Button l10nId="process-form-cancel" to="/processes">
            Cancel
          </Button>
        </div>
        {
          errors.size ?
            <ul className="process-form__errors">
              {
                [...errors].map(([l10nId, args]) => <li key={l10nId}>
                  <Localized id={l10nId} vars={args}>{l10nId}</Localized>
                </li>)
              }
            </ul>
            : null
        }
        <label>
          <h3>
            <Localized id="process-form-process-name">
              Process name
            </Localized>
          </h3>
          <Input
            type="text"
            value={name}
            onChange={this.handleNameChange}
            minLength={1}
            trim={true}
            required
          />
        </label>
        <label>
          <h3>
            <Localized id="process-form-process-team">
              Team
            </Localized>
          </h3>
          <TeamSelector
            isDisabled={process !== undefined}
            team={process ? process.team : undefined}
            permission="editing-process:edit"
            onChange={this.handleTeamChange}
          />
        </label>
        <label>
          <h3>
            <Localized id="process-form-process-starting-step">
              Starting step
            </Localized>
          </h3>
          <Select
            className="react-select"
            value={
              startingStep >= 0 && steps[startingStep]
                ? { value: startingStep, label: steps[startingStep].name }
                : null
            }
            options={steps.map((s, i) => ({
              value: i,
              label: s.name,
            }))}
            onChange={this.handleStartingStepChange as any}
          />
        </label>
        {
          team
            ? <div className="process-form__split">
              <ProcessSlots
                slots={slots}
                team={team}
                onChange={this.handleSlotsChange}
              />
              <ProcessSteps
                steps={steps}
                slots={this.state.slots}
                onChange={this.handleStepsChange}
              />
            </div>
            : <div className="process-form__select-team-message">
              <Localized id="process-form-select-team">
                Select team to continue
              </Localized>
            </div>
        }
      </form>
    )
  }

  private validateForm = (): boolean => {
    // List with l10n ids for error messages.
    const errors: ErrorsMap = new Map()

    const { name, startingStep, slots, steps, team } = this.state

    if (!team) {
      errors.set('process-form-error-team', {})
    }

    // Validate that name for process exists.
    if (!name.length) {
      errors.set('process-form-error-name', {})
    }

    // Verify there are no empty or duplicate names for slots.
    const slotNames: Set<string> = new Set()
    for (const slot of slots) {
      if (!slot.name) {
        errors.set('process-form-error-slot-name', {})
      }
      if (slotNames.has(slot.name)) {
        errors.set('process-form-error-slot-name-duplicate', {})
      }
      slotNames.add(slot.name)
    }

    // Validate that starting step exists and have links.
    if (!steps[startingStep]) {
      errors.set('process-form-error-starting-step', {})
    } else if (!steps[startingStep].links.length) {
      errors.set('process-form-error-starting-step-no-links', {})
    }

    // Validate that slots and steps have minimum length
    if (!slots.length) {
      errors.set('process-form-error-slots-min', {})
    }

    if (steps.length < 2) {
      errors.set('process-form-error-steps-min', {})
    }

    // Validate steps.
    let hasStepsWithoutLinks = false // check if there are any finishing steps (steps without links)
    const stepNames: Set<string> = new Set() // hold all step names to find duplicates
    steps.forEach((s, i) => {
      // Verify there are no empty or duplicate names.
      if (!s.name) {
        errors.set('process-form-error-step-name', {})
      }
      if (stepNames.has(s.name)) {
        errors.set('process-form-error-step-name-duplicate', {
          step: s.name,
        })
      }
      stepNames.add(s.name)

      // If there is propose-changes or accept-changes slot then the second one
      // is also required. Edit permission cannot exists with them.
      const permissions: Set<SlotPermission> = new Set()
      s.slots.forEach(sl => {
        // there can be only one edit or propose-changes permission in step
        if (sl.permission === 'edit' || sl.permission === 'propose-changes') {
          if (permissions.has(sl.permission)) {
            errors.set('process-form-error-step-slot-permission-duplicate', {
              step: s.name,
            })
          }
        }

        permissions.add(sl.permission)

        // Slots have to be filled.
        if (!sl.permission || typeof sl.slot !== 'number') {
          errors.set('process-form-error-step-slot-permission-or-slot', {})
        }
      })

      // Verify that each propose-changes step is followed by an accept-changes
      // step.
      if (permissions.has('propose-changes')) {
        let isLinkingToAcceptChanged = false
        for (const link of s.links) {
          if (link.to === null) continue
          const step = steps[link.to]
          if (step.slots.some(s => s.permission === 'accept-changes')) {
            isLinkingToAcceptChanged = true
          }
        }
        if (!isLinkingToAcceptChanged) {
          errors.set('process-form-error-propose-and-no-accept', {
            step: s.name,
          })
        }
      }

      // Verify that each accept-changes step is preceded only by propose-changes
      // steps.
      if (permissions.has('accept-changes')) {
        const stepsWhichAreLinkingToCurrentStep = steps.filter(st => {
          if (st.links.some(l => l.to === i)) return true
          return false
        })
        if (stepsWhichAreLinkingToCurrentStep.length > 0 &&
          stepsWhichAreLinkingToCurrentStep.every(
            st => st.slots.some(sl => sl.permission === 'propose-changes'))
        ) return

        errors.set('process-form-error-accept-and-no-propose', {
          step: s.name,
        })
      }

      if (
        permissions.has('edit') &&
        (permissions.has('propose-changes') ||
        permissions.has('accept-changes'))
      ) {
        errors.set('process-form-error-edit-and-changes', {
          step: s.name,
        })
      }

      // Validate if there is finish step.
      if (s.links.length === 0) {
        hasStepsWithoutLinks = true
      }

      // Verify there are no empty or duplicate names.
      const stepLinks: Set<string> = new Set()
      s.links.forEach(l => {
        if (stepLinks.has(l.name)) {
          errors.set('process-form-error-step-link-name-duplicate', {
            step: s.name,
          })
        }
        stepLinks.add(l.name)

        if (!l.name.length) {
          errors.set('process-form-error-step-link-name', {})
        }
        if (typeof l.to !== 'number' || typeof l.slot !== 'number') {
          errors.set('process-form-error-step-link-to-or-slot', {})
        }
      })
    })

    // Validate if there is finish step.
    if (!hasStepsWithoutLinks) {
      errors.set('process-form-error-no-finish', {})
    }

    // Verify all steps are reachable from the initial step.
    const stepIndexes: Set<number> = new Set(steps.map((_, i) => i))
    stepIndexes.delete(startingStep)
    const processedStepIndexs: Set<number> = new Set()
    const removeReachableStepIndexes = (stepIndex: number) => {
      if (processedStepIndexs.has(stepIndex) || stepIndexes.size === 0) return
      processedStepIndexs.add(stepIndex)
      for (const link of steps[stepIndex].links) {
        if (link.to === null) continue
        stepIndexes.delete(link.to)
        removeReachableStepIndexes(link.to)
      }
    }
    removeReachableStepIndexes(startingStep)
    if (stepIndexes.size > 0) {
      errors.set('process-form-error-steps-not-reachable', {
        // eslint-disable-next-line newline-per-chained-call
        names: steps.filter((_, i) => stepIndexes.has(i)).map(s => s.name).join(', '),
      })
    }

    // Verify there's a path from every step to a final step.
    const finalStepIndex = steps.findIndex(s => !s.links.length && !s.slots.length)

    const checkedLinks: Map<number, Map<number, boolean>> = new Map()
    const checkedSteps: Map<number, boolean> = new Map()
    const checkIfStepHavePathToFinal = (stepIndex: number): boolean => {
      if (stepIndex === finalStepIndex) { return true }
      let have_path_to_final = false
      for (const link of steps[stepIndex].links) {
        if (checkedSteps.has(stepIndex)) {
          have_path_to_final = true
          break
        }
        if (link.to === null || checkedLinks.get(stepIndex)?.has(link.to)) continue
        if (!checkedLinks.has(stepIndex)) {
          checkedLinks.set(stepIndex, new Map())
        }
        checkedLinks.get(stepIndex)?.set(link.to, true)
        if (finalStepIndex === link.to) {
          have_path_to_final = true
        } else {
          have_path_to_final = checkIfStepHavePathToFinal(link.to)
        }
        if (have_path_to_final) {
          checkedSteps.set(stepIndex, true)
          break
        }
      }

      return have_path_to_final
    }

    const stepsWhichDoesNotLeadToFinal = steps.filter((_, i) => !checkIfStepHavePathToFinal(i))
    if (stepsWhichDoesNotLeadToFinal.length > 0) {
      errors.set('process-form-error-steps-no-path-to-final', {
        names: stepsWhichDoesNotLeadToFinal.map(s => s.name).join(', '),
      })
    }

    // Update state
    this.setState({ errors })

    return errors.size === 0
  }
}

export default ProcessForm

/**
 * Return true if teams are the same.
 */
function compareTeams(team1?: Team | number | null, team2?: Team | number | null): boolean {
  if (team1 instanceof Team && team2 instanceof Team) {
    if (team1.id === team2.id) return true
    return false
  }
  if (team1 === team2) return true
  return false
}

/**
 * Return true if strucutres are the same.
 */
function compareStructures(s1?: ProcessStructure | null, s2?: ProcessStructure | null): boolean {
  if (!s1 && !s2) return true
  if (typeof s1 !== typeof s2) return false
  if (
    s1!.name !== s2!.name ||
    s1!.start !== s2!.start ||
    !compareSlots(s1!.slots, s2!.slots) ||
    !compareSteps(s1!.steps, s2!.steps)
  ) return false
  return true
}

function compareSlots(s1: ProcessSlot[], s2: ProcessSlot[]): boolean {
  if (s1.length !== s2.length) return false
  return s1.every((el, i) => {
    const el2 = s2[i]
    if (
      el.id !== el2.id ||
      el.name !== el2.name ||
      el.autofill !== el2.autofill ||
      !el.roles.every((r1, ri) => {
        if (r1 !== el2.roles[ri]) return false
        return true
      })
    ) return false
    return true
  })
}

function compareSteps(s1: ProcessStep[], s2: ProcessStep[]): boolean {
  if (s1.length !== s2.length) return false
  return s1.every((el, i) => {
    const el2 = s2[i]
    if (
      el.id !== el2.id ||
      el.name !== el2.name ||
      !el.links.every((l1, li) => {
        if (
          l1.name !== el2.links[li].name ||
          l1.to !== el2.links[li].to ||
          l1.slot !== el2.links[li].slot
        ) return false
        return true
      }) ||
      !el.slots.every((s1, si) => {
        if (
          s1.permission !== el2.slots[si].permission ||
          s1.slot !== el2.slots[si].slot
        ) return false
        return true
      })
    ) return false
    return true
  })
}
