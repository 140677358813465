import * as React from 'react'
import { Localized } from '@fluent/react'
import { Book } from '../../../api'
import { useLoadTeam } from '../../../api/hooks'

const CardTitle = ({ book }: { book: Book }) => {
  const [team] = useLoadTeam(book.team)

  return (
    <h2 className="card__title">
      <span className="card__book-name">
        {book.title}
      </span>
      {
        team
          ?
          <span className="card__book-team">
            <Localized
              id="book-card-team"
              vars={{ team: team.name }}
            >
              Team: ...
            </Localized>
          </span>
          : null
      }
    </h2>
  )
}

export default CardTitle
