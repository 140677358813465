import * as React from 'react'
import { match } from 'react-router'
import { History } from 'history'
import { Localized } from '@fluent/react'
import Team, { TeamID } from '../../../api/team'
import Process, { ProcessStructure } from '../../../api/process'
import store from '../../../store'
import { addAlert } from '../../../store/actions/alerts'
import { updateProcessName } from '../../../store/actions/processes'
import { assertDefined, confirmDialog } from '../../../helpers'
import ProcessForm from './components/ProcessForm'
import ProcessesList from './components/ProcessesList'
import PageNavigation from '../../../components/PageNavigation'
import Section from '../../../components/Section'
import Spinner from '../../../components/Spinner'
import Header from '../../../components/Header'
import LockToggle from '../../../components/LockToggle'
import LimitedUI from '../../../components/LimitedUI'
import Button from '../../../components/ui/Button'
import Icon from '../../../components/ui/Icon'
import ProcessPreview from '../../../containers/ProcessPreview'

import './index.css'

interface ProcessesProps {
  match: match<{ id?: string, action?: string }>
  history: History
}

interface ProcessesState {
  isEditingUnlocked: boolean
  showForm: boolean
  structure: ProcessStructure | null
  process: Process | null
  team: Team | null
  showPreview: boolean
  error: Error | null
}

class Processes extends React.Component<ProcessesProps> {
  state: ProcessesState = {
    isEditingUnlocked: false,
    showForm: false,
    structure: null,
    process: null,
    team: null,
    showPreview: false,
    error: null,
  }

  private toggleEditing = (status: boolean) => {
    this.setState({ isEditingUnlocked: status })
  }

  private closeForm = () => {
    this.props.history.push('/processes')
  }

  private createProcess = async (structure: ProcessStructure, team: TeamID) => {
    // Create new process if we are not editing existing one.
    if (!this.state.process) {
      Process.create(structure, team)
        .then(process => {
          this.closeForm()
          store.dispatch(addAlert('success', 'process-create-success', { name: structure.name }))
          Process.pagination().tryToAdd(process)
        })
        .catch(e => {
          store.dispatch(
            addAlert(
              'error',
              'process-create-error',
              { details: e.response.data.raw },
            ),
          )
        })
    } else {
      // Check if changes require creating new version.
      // If not then just update changed fields.
      const oldStructure = await this.state.process.structure()

      const {
        newVersionRequired,
        updates,
      } = this.compareOldWithNew(oldStructure, structure)

      if (!newVersionRequired) {
        try {
          await Promise.all(updates.map(u => u()))
          Process.pagination().clear()
          this.closeForm()
          store.dispatch(addAlert('success', 'process-update-success'))
        } catch (e) {
          console.error(e)
          store.dispatch(addAlert('error', 'process-update-error'))
        }
      } else {
        // Show confirmation dialog
        this.showConfirmNewVersionCreation(structure)
      }
    }
  }

  private showConfirmNewVersionCreation = async (structure: ProcessStructure) => {
    const res = await confirmDialog({
      title: 'process-update-warning-new-version',
      content: <Localized id="process-update-warning-new-version-content" elems={{ p: <p/> }}>
        <div className="process__dialog-content" />
      </Localized>,
      buttons: {
        cancel: 'process-update-warning-new-version-cancel',
        confirm: 'process-update-warning-new-version-confirm',
      },
    })

    if (res === 'confirm') {
      this.createNewProcessVersion(structure)
    }
  }

  private createNewProcessVersion = (structure: ProcessStructure) => {
    const { process } = this.state
    if (!process) return

    process.createVersion(structure)
      .then(() => {
        this.closeForm()
        store.dispatch(
          addAlert(
            'success',
            'process-create-version-success',
            { name: structure.name },
          ),
        )
        store.dispatch(updateProcessName(process, structure.name))
      })
      .catch(e => {
        store.dispatch(
          addAlert(
            'error',
            'process-create-version-error',
            { details: e.response.data.raw },
          ),
        )
      })
  }

  private compareOldWithNew = (oldStructure: ProcessStructure, newStructure: ProcessStructure): {
    newVersionRequired: boolean,
    updates: (() => Promise<any>)[],
  } => {
    const { process } = this.state

    if (!process) {
      throw new Error("Couldn't establish process to update.")
    }

    const res: {
      newVersionRequired: boolean
      updates: (() => Promise<any>)[]
    } = {
      newVersionRequired: false,
      updates: [],
    }

    if (
      oldStructure.slots.length !== newStructure.slots.length
      || oldStructure.steps.length !== newStructure.steps.length
      || oldStructure.start !== newStructure.start
    ) {
      return {
        newVersionRequired: true,
        updates: [],
      }
    }

    if (oldStructure.name !== newStructure.name) {
      res.updates.push(() => store.dispatch(updateProcessName(process, newStructure.name)))
    }

    // Slots and steps have unique id's, but those are present only on oldStructure,
    // so we are comparing slots, steps, links by index and we are using id from oldStructre
    // to update them. We have to check all fields, because only few of them are editable.

    let slotIndex = 0
    for (const slot of oldStructure.slots) {
      const newSlot = newStructure.slots[slotIndex]
      if (slot.autofill !== newSlot.autofill) {
        return {
          newVersionRequired: true,
          updates: [],
        }
      }
      const slotUpdate: {name?: string, roles?: number[]} = {}
      if (slot.name !== newSlot.name) {
        slotUpdate.name = newSlot.name
      }
      if (numberArrayDiff(slot.roles, newSlot.roles).length) {
        slotUpdate.roles = newSlot.roles
      }
      if (slotUpdate.name || slotUpdate.roles) {
        res.updates.push(() => process.updateSlot(slot.id, slotUpdate))
      }

      slotIndex++
    }

    let stepIndex = 0
    for (const step of oldStructure.steps) {
      const newStep = newStructure.steps[stepIndex]
      if (
        step.links.length !== newStep.links.length
        || step.slots.length !== newStep.slots.length
      ) {
        return {
          newVersionRequired: true,
          updates: [],
        }
      }

      if (step.name !== newStep.name) {
        res.updates.push(() => process.updateStepName(step.id, newStep.name))
      }

      let linkIndex = 0
      for (const link of step.links) {
        const newLink = newStep.links[linkIndex]
        // link.slot/to are based on indexes so we can easly compare them
        if (
          link.slot !== newLink.slot
          || link.to !== newLink.to
        ) {
          return {
            newVersionRequired: true,
            updates: [],
          }
        }

        if (link.name !== newLink.name) {
          const slotId = oldStructure.slots[link.slot].id
          const stepId = oldStructure.steps[link.to].id
          res.updates.push(() => process.updateLinkName(step.id, slotId, stepId, newLink.name))
        }

        linkIndex++
      }

      let slotIndex = 0
      for (const slot of step.slots) {
        const newSlot = step.slots[slotIndex]
        // slot.slot are based on indexes so we can easly compare them
        if (
          slot.slot !== newSlot.slot
          || slot.permission !== newSlot.permission
        ) {
          return {
            newVersionRequired: true,
            updates: [],
          }
        }

        slotIndex++
      }

      stepIndex++
    }

    return res
  }

  private updateScreenDependOnParams = async () => {
    const { match: { params: { id, action } } } = this.props
    if (!id || !action) {
      this.setState({
        showForm: false,
        showPreview: false,
        process: null,
        structure: null,
        team: null,
        error: null,
      })
      return
    }

    if (id === 'new' && action === 'create') {
      this.setState({
        showForm: true,
        process: null,
        structure: null,
        error: null,
      })
      return
    }

    try {
      const process = await Process.load(Number(id))

      if (process && action === 'edit') {
        const structure = await process.structure()
        this.setState({
          process,
          showForm: true,
          showPreview: false,
          structure,
          error: null,
        })
      } else if (process && action === 'preview') {
        const team = assertDefined(
          await Team.load(process.team),
          `Couldn't find team with id: ${process.team}`,
        )
        this.setState({
          showForm: false,
          showPreview: true,
          process,
          team,
          error: null,
        })
      }
    } catch (error) {
      this.setState({ error })
    }
  }

  componentDidUpdate(prevProps: ProcessesProps) {
    const { id, action } = prevProps.match.params
    const { id: newId, action: newAction } = this.props.match.params

    if (id !== newId || action !== newAction) {
      this.updateScreenDependOnParams()
    }
  }

  componentDidMount() {
    this.updateScreenDependOnParams()
  }

  public render() {
    const {
      isEditingUnlocked,
      showForm,
      structure,
      showPreview,
      process,
      team,
      error,
    } = this.state

    if (error) {
      return (
        <div className="contianer">
          <Section>
            <Header l10nId="processes-view-title" title="Manage processes" />
            <div className="section__content processes processes--error">
              <span className="processes__error-msg">
                {error.message}
              </span>
              <Button l10nId="processes-go-to-list" to="/processes">
                Go to processes list
              </Button>
            </div>
          </Section>
        </div>
      )
    }

    if (showPreview) {
      return <div className="container">
        <Section>
          <Header
            l10nId="processes-view-preview"
            title="Process preview"
          >
            <Button id="processes-view-preview-close" to="/processes" className="close-button">
              <Icon name="close" />
            </Button>
          </Header>
          <div className="section__content">
            {
              team && process
                ? <ProcessPreview process={process} />
                : <Spinner />
            }
          </div>
        </Section>
      </div>
    }

    return (
      <div className="container">
        <Section>
          <Header l10nId="processes-view-title" title="Manage processes">
            <LimitedUI permissions="editing-process:edit">
              <LockToggle onToggle={this.toggleEditing} />
            </LimitedUI>
          </Header>
          <div className="section__content processes">
            {
              showForm ?
                <ProcessForm
                  structure={structure}
                  process={process ? process : undefined}
                  onSubmit={this.createProcess}
                />
                :
                <>
                  <div className="processes__create-new">
                    <Button l10nId="processes-view-add" to="/processes/new/create">
                      Add new process
                    </Button>
                  </div>
                  <PageNavigation
                    pagination={Process.pagination()}
                    usePageParam={true}
                    Component={
                      ({ items, onDelete, onUpdate }) => (
                        <ProcessesList
                          processes={items}
                          isEditingUnlocked={isEditingUnlocked}
                          onDelete={onDelete}
                          onUpdate={onUpdate}
                        />
                      )
                    }
                  />
                </>
            }
          </div>
        </Section>
      </div>
    )
  }
}

export default Processes

function numberArrayDiff(a: number[], b: number[]) {
  if (a.length > b.length) {
    return a.filter(item => b.indexOf(item) < 0)
  }
  return b.filter(item => a.indexOf(item) < 0)
}
