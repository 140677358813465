import * as React from 'react'
import { Localized } from '@fluent/react'
import { PersistDB } from 'slate-persistence'
import store from '../../../../../store'
import { addAlert } from '../../../../../store/actions/alerts'
import Spinner from '../../../../../components/Spinner'
import Button from '../../../../../components/ui/Button'
import Dialog from '../../../../../components/ui/Dialog'
import DocumentItem from '../DocumentItem'

import './index.css'

const ClearDatabaseDialog = ({ onClose }: { onClose: () => void }) => {
  const [isClearing, setIsClearing] = React.useState(false)
  const [selectedIds, setSelectedIds] = React.useState<Set<string>>(new Set())

  const onSelectDocuments = (selIds: Set<string>) => {
    setSelectedIds(selIds)
  }

  const clearDatabase = async () => {
    setIsClearing(true)
    try {
      await Promise.all(
        Array.from(selectedIds).map(id => PersistDB.load(id).then(db => db.discard())))
    } catch (e) {
      console.error(e)
      store.dispatch(addAlert('error', 'helpdesk-clear-local-database-error'))
    }
    onClose()
  }

  return (
    <Dialog
      size="medium"
      l10nId="helpdesk-clear-local-database-title"
      placeholder="Select drafts to remove from local database"
      onClose={onClose}
      showCloseButton={false}
    >
      {
        isClearing ?
          <Spinner />
          :
          <div className="helpdesk__dialog-content">
            <LocalDatabaseDocuments
              selected={selectedIds}
              onSelect={onSelectDocuments}
            />
            <div className="dialog__buttons">
              <Button l10nId="helpdesk-clear-local-database-cancel" clickHandler={onClose}>
                Cancel
              </Button>
              <Button
                id="helpdesk-clear-local-database-confirm"
                clickHandler={clearDatabase}
                isDisabled={!selectedIds.size}
              >
                <Localized
                  id="helpdesk-clear-local-database-confirm"
                  vars={{ drafts: selectedIds.size }}
                >
                  {`Clear database for ${selectedIds.size} selected drafts.`}
                </Localized>
              </Button>
            </div>
          </div>
      }
    </Dialog>
  )
}

export default ClearDatabaseDialog

interface LocalDatabaseDocumentsProps {
  selected: Set<string>
  onSelect: (selectedIds: Set<string>) => void
}

const LocalDatabaseDocuments = ({ selected, onSelect }: LocalDatabaseDocumentsProps) => {
  const [documents, setDocuments] = React.useState<Set<string>>(new Set())
  const [documentsSelected, setDocumentsSelected] = React.useState(selected)

  React.useEffect(() => {
    fetchDocuments()
  }, [])

  React.useEffect(() => {
    setDocumentsSelected(selected)
  }, [selected])

  const fetchDocuments = async () => {
    const db = await PersistDB.open()
    const documents = (await db.dirty()).map(({ id }) => id)
    setDocuments(new Set(documents))
  }

  const onDocumentSelect = (id: string) => {
    const newSelected = new Set(documentsSelected)
    if (newSelected.has(id)) {
      newSelected.delete(id)
    } else {
      newSelected.add(id)
    }
    setDocumentsSelected(newSelected)
    onSelect(newSelected)
  }

  return (
    <ul className="local-database-documents">
      {
        !documents.size
          ? <span className="local-database-documents">
            <Localized id="helpdesk-clear-local-database-no-documents">
              There are no documents saved in your local database
            </Localized>
          </span>
          : Array.from(documents).map(id => (
            <DocumentItem
              key={id}
              isSelected={selected.has(id)}
              moduleId={id}
              onDocumentSelect={onDocumentSelect}
            />
          ))
      }
    </ul>
  )
}
