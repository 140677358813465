import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Draft } from '../../../api'
import { DraftAndBookPart } from '../../../api/draft'
import { addAlert } from '../../../store/actions/alerts'
import Section from '../../../components/Section'
import Header from '../../../components/Header'
import Spinner from '../../../components/Spinner'
import DraftsList from '../../../components/DraftsList'
import ContentTools from '../../../components/ContentTools'

import './index.css'

const Dashboard = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [drafts, setDrafts] = React.useState<DraftAndBookPart[]>([])
  const dispatch = useDispatch()

  React.useEffect(() => {
    let mounted = true

    setIsLoading(true)

    Draft.all()
      .then(drafts => {
        if (mounted) setDrafts(drafts)
      })
      .catch(e => {
        dispatch(addAlert('error', 'dashboard-drafts-fetch-error', { error: e.toString() }))
      })
      .finally(() => setIsLoading(false))

    return () => {
      mounted = false
      setIsLoading(false)
      setDrafts([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container container--splitted dashboard">
      <Section>
        <Header l10nId="dashboard-section-drafts" title="Your drafts" />
        <div className="section__content">
          {isLoading ? <Spinner /> : <DraftsList drafts={drafts} />}
        </div>
      </Section>
      {/* TODO: only if has permissions */}
      <Section>
        <Header l10nId="dashboard-section-tools" title="Tools" />
        <div className="section__content">
          <ContentTools />
        </div>
      </Section>
    </div>
  )
}

export default Dashboard
