import * as React from 'react'
import Checkbox from '../../../../../components/ui/Checkbox'
import { useLoadModule } from '../../../../../api/hooks'

interface DocumentItemProps {
  isSelected: boolean
  moduleId: string
  onDocumentSelect: (id: string) => void
}

const DocumentItem = ({ isSelected, moduleId, onDocumentSelect }: DocumentItemProps) => {
  const [mod] = useLoadModule(moduleId)
  const documentTitle = mod?.title || 'undefined'

  return (
    <li className="local-database-documents__item">
      <Checkbox
        value={isSelected}
        label={documentTitle}
        onChange={() => onDocumentSelect(moduleId)}
      />
    </li>
  )
}

export default DocumentItem
