import * as React from 'react'
import Icon from '../../components/ui/Icon'
import Button from '../../components/ui/Button'

interface LockToggleProps {
  isOpen?: boolean
  onToggle: (locked: boolean) => void
}

const LockToggle = ({ isOpen = false, onToggle }: LockToggleProps) => {
  const [status, setStatus] = React.useState(isOpen)

  const onClick = () => {
    const newStatus = !status
    setStatus(newStatus)
    onToggle(newStatus)
  }

  return (
    <Button id="lock-toggle" clickHandler={onClick} data-testid="toggler">
      {
        status ?
          <Icon size="medium" name="unlock" />
          : <Icon size="medium" name="lock" />
      }
    </Button>
  )
}

export default LockToggle
