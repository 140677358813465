import * as React from 'react'
import { useSelector } from 'react-redux'
import { FluentVariable } from '@fluent/bundle'
import { Localized } from '@fluent/react'
import { Link } from 'react-router-dom'
import store from '../../store'
import { createLabel } from '../../store/actions/modules'
import { ModuleLabel as ModuleLabelType } from '../../store/types'
import { escapeStringRegExp, getRandomColor } from '../../helpers'
import Icon from '../../components/ui/Icon'
import Input from '../../components/ui/Input'

import './index.css'

interface ModuleLabelsListProps {
  active?: ModuleLabelType[]
  title?: string
  onLabelClick: (label: ModuleLabelType) => void
  onClose?: () => void
  onLabelCreate: (label: string) => void
  vars?: Record<string, FluentVariable>
  elems?: Record<string, React.ReactElement>
}

const ModuleLabelsList = (
  {
    active = [],
    title,
    onLabelClick,
    onClose,
    onLabelCreate,
    vars,
    elems,
  }: ModuleLabelsListProps,
) => {
  const labels = useSelector(state => state.modules.labels)
  const [filter, setFilter] = React.useState('')
  const regExp = new RegExp(escapeStringRegExp(filter), 'i')
  const filteredLabels = Object.values(labels).filter(label => label.name.match(regExp))
  const moduleLabelsList = React.useRef<HTMLDivElement>(null)

  const onChange = (text: string | null) => {
    setFilter(text ?? '')
  }

  const createNewLabel = () => {
    if (filter.length) {
      store.dispatch(createLabel({
        name: filter,
        color: getRandomColor(),
      }))
      onLabelCreate(filter)
    }
  }

  const onKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape' && moduleLabelsList.current) {
      if (onClose) {
        onClose()
      }
    }
  }

  const clickOutside = (ev: MouseEvent) => {
    if (
      moduleLabelsList.current &&
      !moduleLabelsList.current.contains(ev.target as HTMLElement)
    ) {
      if (onClose) {
        onClose()
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('click', clickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={moduleLabelsList} className="module-labels-list">
      <div className="module-labels-list__content">
        {
          title
            ? <span className="module-labels-list__title">
              <Localized id={title} vars={vars} elems={elems}>{title}</Localized>
            </span>
            : null
        }
        <Input
          type="text"
          onChange={onChange}
          autoFocus={true}
          l10nId="module-labels-list-filter"
        />
        {
          filteredLabels.length
            ? filteredLabels.map(label => (
              <LabelPresentation
                key={label.id}
                label={label}
                isActive={Boolean(active.find(l => l.id === label.id))}
                onClick={onLabelClick}
              />
            ))
            : filter.length === 0
              ?
              <span
                className="module-labels-list__item module-labels-list__item--no-hover"
              >
                <Localized id="module-labels-list-no-labels-found">
                  No labels found
                </Localized>
              </span>
              : null

        }
        {
          filter.length && !filteredLabels.find(l => l.name.toLowerCase() === filter.toLowerCase())
            ?
            <span
              id="module-labels-list-create-label"
              className="module-labels-list__item module-labels-list__item--center"
              onClick={createNewLabel}
            >
              <Localized id="module-labels-list-create-label" vars={{ name: filter }}>
                {`Create new label: ${filter}`}
              </Localized>
            </span>
            : null
        }
      </div>
      <Link
        className="module-labels-list__item module-labels-list__item--edit"
        to="/modules/labels"
      >
        <Icon size="small" name="pencil"/>
        <Localized id="module-labels-list-edit-labels">
          Edit labels
        </Localized>
      </Link>
    </div>
  )
}

export default ModuleLabelsList

interface LabelPresentationProps {
  label: ModuleLabelType
  isActive: boolean
  onClick: (label: ModuleLabelType) => void
}

const LabelPresentation = ({ label, isActive, onClick }: LabelPresentationProps) => {
  const handleClick = () => {
    onClick(label)
  }

  return (
    <span
      id="module-labels-list__color"
      className="module-labels-list__item"
      onClick={handleClick}
    >
      { isActive ? <Icon size="small" name="check-simple" /> : <span className="icon" /> }
      <span
        className="module-labels-list__color"
        style={{ backgroundColor: label.color }}
      />
      <span className="module-labels-list__name">
        {label.name}
      </span>
    </span>
  )
}
