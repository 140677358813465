import * as React from 'react'
import { Localized } from '@fluent/react'
import { State } from '../../../../api/task'

export interface TaskStateProps {
  state: State | 'fetch-error'
  /**
   * Current progress
   *
   * Ignored if {@code state} is not {@code 'running'}.
   */
  progress: number | null
  /**
   * Current progress message
   *
   * Ignored if {@code state} is not {@code 'running'}.
   */
  message: string
}

/** Component showing current state of a task */
export default function TaskState({ state, progress, message }: TaskStateProps) {
  if (state !== 'running') {
    return <div>
      <Localized id={`task-state-${state}`} />
    </div>
  }

  const props: { value?: number } = {}

  if (progress != null) {
    props.value = progress
  }

  return <div className="active-task__state">
    <label htmlFor="progress">{message}</label>
    <progress id="progress" {...props} />
  </div>
}
