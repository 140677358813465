import * as React from 'react'
import Select, { Props } from 'react-select'
import { Localized } from '@fluent/react'
import { Process, Team } from '../../api'
import { useLoadAllProcesses } from '../../api/hooks'

interface ProcessSelectorProps extends Omit<Props, 'options'> {
  title?: string
  team?: Team | number
  filter?: (process: Process) => boolean
}

const ProcessSelector = ({
  title, isClearable = true, filter = selectAll, ...props
}: ProcessSelectorProps) => {
  const teamId = typeof props.team === 'number' ? props.team : props.team?.id
  const [processes] = useLoadAllProcesses()

  const options = React.useMemo(
    () => processes
      .filter(p => !teamId || p.team === teamId)
      .filter(filter)
      .map(p => ({ value: p, label: p.name })),
    [teamId, processes, filter])

  return (
    <div className="process-selector">
      <span className="process-selector__title">
        <Localized id={title ? title : 'process-selector-title'}>
          Select process:
        </Localized>
      </span>
      <Localized id="process-selector-placeholder" attrs={{ placeholder: true }}>
        <Select
          className="react-select"
          classNamePrefix="react-select"
          placeholder="Select..."
          options={options}
          {...props}
          isClearable={isClearable}
        />
      </Localized>
    </div>
  )
}

export default ProcessSelector

function selectAll(): boolean {
  return true
}
