import * as React from 'react'
import Select from 'react-select'
import { Admonition, ADMONITION_KINDS, AdmonitionKind } from 'cnx-designer'
import { Editor, Transforms } from 'slate'
import { Localized } from '@fluent/react'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import Button from '../../../../../../../components/ui/Button'
import ToolGroup from '../ToolGroup'
import Classes from '../Classes'
import { OnToggle } from '../../index'
import { unwrapChildrenFromNode } from '../../../../utils'

interface AdmonitionToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

function AdmonitionTools({ toggleState, editor, onToggle }: AdmonitionToolsProps) {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Admonition.isAdmonition })
  if (!node) return null

  const [admonition, path] = node

  const onChange = ({ value }: {value: AdmonitionKind, label: AdmonitionKind}) => {
    Transforms.setNodes(editor, { kind: value }, { at: path })
  }

  const onClickToggle = () => {
    onToggle('admonitionTools')
  }

  return (
    <ToolGroup
      title="editor-tools-admonition-title"
      toggleState={toggleState}
      onToggle={onClickToggle}
    >
      <Select
        className="toolbox__select react-select"
        value={{ value: admonition.kind, label: admonition.kind }}
        onChange={onChange as any}
        options={ADMONITION_KINDS.map(t => ({ value: t, label: t }))}
        formatOptionLabel={OptionLabel}
      />
      <Classes node={node} />
      <Button l10nId="editor-tools-admonition-unwrap" clickHandler={() => unwrapChildrenFromNode(editor, node)}>
        Unwrap admonition
      </Button>
    </ToolGroup>
  )
}

export default AdmonitionTools

function OptionLabel({ value: type }: {value: AdmonitionKind, label: AdmonitionKind}) {
  return <Localized id="editor-tools-admonition-type" vars={{ type }}>{type}</Localized>
}
