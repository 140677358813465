import * as React from 'react'
import Select from 'react-select'
import { Transforms as CnxTransforms, Rule, RULE_KINDS, RuleKind } from 'cnx-designer'
import { Localized } from '@fluent/react'
import { Editor, Transforms } from 'slate'
import Button from '../../../../../../../components/ui/Button'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import Classes from '../Classes'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'

import './index.css'

interface RuleToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const RuleTools = ({ toggleState, editor, onToggle }: RuleToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Rule.isRule })
  if (!node) return null

  const [rule, path] = node

  const insertStatement = () => CnxTransforms.insertStatement(editor, { select: true })

  const insertProof = () => CnxTransforms.insertProof(editor, { select: true })

  const insertRuleExample = () => CnxTransforms.insertRuleExample(editor, { select: true })

  const onTypeChange = (data: {value: RuleKind, label: RuleKind} | undefined) => {
    Transforms.setNodes(editor, { kind: data?.value }, { at: path })
  }

  return (
    <ToolGroup
      title="editor-tools-rule-title"
      toggleState={toggleState}
      className="rule-tools"
      onToggle={() => onToggle('ruleTools')}
    >
      <Button l10nId="editor-tools-rule-insert-statement" clickHandler={insertStatement}>
        Add statement
      </Button>
      <Button l10nId="editor-tools-rule-insert-proof" clickHandler={insertProof}>
        Add proof
      </Button>
      <Button l10nId="editor-tools-rule-insert-example" clickHandler={insertRuleExample}>
        Add example
      </Button>
      <Select
        className="toolbox__select react-select"
        value={{ value: rule.kind, label: rule.kind }}
        onChange={onTypeChange as any}
        options={RULE_KINDS.map(t => ({ value: t, label: t }))}
        formatOptionLabel={OptionLabel}
      />
      <Classes node={node} />
    </ToolGroup>
  )
}

export default RuleTools

function OptionLabel({ value: type }: {value: RuleKind, label: RuleKind}) {
  return <Localized id="editor-tools-rule-type" vars={{ type }}>{type}</Localized>
}
