import axios from '../config/axios'
import { extend } from './utils'

/**
 * Interface that matches data returned by the server.
 */
export interface Cursor<T> {
  offset: number
  limit: number
  items: T[]
}

export type Page<T> = {
  number: number
  items: T[]
  has_prev_page: boolean
  // If it is set to null then we don't know yet if this page has next page
  has_next_page: boolean | null
}

class Pagination<T> {
  /**
   * Limit of items per page. It is null initialy
   * and it is set to a number returned by the server after first call to loadPage.
   */
  private limit: number | null = null

  /**
   * Store fetched pages. Value will be null if we know that specific page exists but
   * we don't know what data it is containing (for example because we've deleted it from the cache).
   */
  private _pages = new Map<number, Page<T> | null>()

  /**
   * We are prefetching next page and hold it here until user ask for it.
   */
  private _nextPage: Page<T> | null = null

  /**
   * Fetch next page if there is one and modify value of `next` for passed page
   * to undefined if there are no results on the next page.
   */
  private async prefetchNextPage(page: Page<T>): Promise<void> {
    const nextPage = page.has_next_page ? await this.loadPage(page.number + 1, false) : null
    if (nextPage && nextPage.items.length === 0) {
      page.has_next_page = false
    }
    this._nextPage = nextPage?.items.length ? nextPage : null
  }

  constructor(
    public endpoint: string,
    private transformer?: (item: any) => T,
    private caching?: (items: T[]) => void,
    // eslint-disable-next-line no-empty-function
  ) {}

  public async loadPage(number: number, prefetchNext = true): Promise<Page<T>> {
    const cachedPage = this._pages.get(number)
    if (cachedPage) {
      if (prefetchNext) await this.prefetchNextPage(cachedPage)
      return cachedPage
    } else if (this._nextPage?.number === number) {
      const page = this._nextPage
      this._pages.set(page.number, page)
      if (prefetchNext) await this.prefetchNextPage(page)
      return page
    }

    if (number !== 1 && !this.limit) {
      // Fetch first page to set this.limit to the value returned by the server
      await this.loadPage(1, false)
    }

    // To calculate offset we have to
    // number - 1 because we need to have 0 offset for first page etc.
    const offset = this.limit ? Math.max(number - 1, 0) * this.limit : 0
    const params = { offset, limit: this.limit || undefined }
    const response: Cursor<T> = (await axios.get(this.endpoint, { params })).data
    const items = this.transformer ? response.items.map(this.transformer) : response.items

    this.limit = response.limit

    if (this.caching) {
      this.caching(items)
    }

    const page = {
      number,
      items,
      has_prev_page: number > 1,
      // Value of has_next_page might be changed
      // by this.prefetchNextPage(page) if next page has 0 items
      has_next_page: Boolean(items.length && items.length === response.limit),
    }

    if (prefetchNext && (items.length || number === 1)) {
      this._pages.set(number, page)
      await this.prefetchNextPage(page)
    }

    // TODO: Clear cached pages to not store more than ~5
    // We can't do this currently because we need to have acces to this.loadAll()
    // for components like ProcessSelector, etc.

    return page
  }

  public page(number: number): Page<T> | undefined | null {
    return this._pages.get(number)
  }

  /**
   * Return sorted array of pages which was fetched before.
   */
  public pages(): [number, Page<T> | null][] {
    return Array.from(this._pages.entries())
      .sort(([a], [b]) => {
        if (a > b) return 1
        if (b > a) return -1
        return 0
      })
  }

  /**
   * Return first from fetched pages.
   */
  public first(): [number, Page<T> | null] | undefined {
    let number = Infinity
    let page: Page<T> | null | undefined
    for (const [n, p] of this._pages) {
      if (n < number) {
        page = p
        number = n
      }
    }
    return page === undefined ? undefined : [number, page]
  }

  /**
   * Return last from fetched pages.
   */
  public last(): [number, Page<T> | null] | undefined {
    let number = -1
    let page: Page<T> | null | undefined
    for (const [n, p] of this._pages) {
      if (n > number) {
        number = n
        page = p
      }
    }
    return number === -1 ? undefined : [number, page || null]
  }

  /**
   * Check if we've already fetched all pages.
   */
  public hasAll(): boolean {
    if (this._pages.size === 0) return false
    const [lastNum, lastData] = this.last()!
    return Boolean(lastData && !lastData.has_next_page && this._pages.size === lastNum)
  }

  /**
   * Return array of all items from all fetched pages.
   */
  public items(): T[] {
    return this.pages().flatMap(([, page]) => page?.items || [])
  }

  /**
   * Load next page after the last fetched page.
   */
  public loadMore(): Promise<Page<T> | null> {
    const lastPage = this.last()
    if (!lastPage) return this.loadPage(1)
    if (lastPage && lastPage[1] === null) return this.loadPage(lastPage[0])
    if (lastPage[1] && lastPage[1].has_next_page) return this.loadPage(lastPage[1].number + 1)
    return Promise.resolve(null)
  }

  /**
   * Add item to the last page if we've already fetched that page or if last fetched page
   * was full then reset it's next value.
   */
  public tryToAdd(item: T) {
    const last = this.last()
    if (!this.limit || !last || !last[1] || last[1].has_next_page) return
    const lastPage = last[1]
    if (lastPage.items.length < this.limit) {
      this._pages.set(lastPage.number, {
        ...lastPage,
        items: [...lastPage.items, item],
      })
    } else if (lastPage.items.length === this.limit) {
      this._pages.set(lastPage.number, { ...lastPage, has_next_page: true })
    }
  }

  /**
   * Delete an item if it is in cached page and update all other pages behind that item.
   */
  public delete(item: T) {
    const pages = this.pages()
    const indexOfPageWithItem = pages
      .findIndex(([, page]) => page && page.items.find(i => i === item))
    const [pageNumber, page] = pages[indexOfPageWithItem]

    // Remove item from the page
    page!.items = page!.items.filter(i => i !== item)
    this._pages.set(pageNumber, page)

    if (indexOfPageWithItem >= 0) {
      let lastPageNumber = pageNumber
      const pagesToUpdate = pages.slice(indexOfPageWithItem)

      for (const [index, [number, page]] of pagesToUpdate.entries()) {
        if (!page) continue
        if (lastPageNumber !== pageNumber && lastPageNumber !== number - 1) {
          // If this page was not dirrectly after previous page then we can't update it.
          // So we have to remove cached data.
          this._pages.set(number, null)
          continue
        }
        if (index === pagesToUpdate.length - 1 && page.items.length === 0) {
          if (page.has_next_page) {
            this._pages.set(number, null)
          } else {
            // If we've moved element from this page
            // to the page before and this page was the last page
            // then just delete it from cache.
            this._pages.delete(number)
          }
          break
        }

        const nextPage: typeof pagesToUpdate[0] | undefined = pagesToUpdate[index + 1]
        const nextPageNumber = nextPage && nextPage[0]
        const nextPageItems = nextPage && nextPage[1] && nextPage[1].items

        // Move item from the next page to this page
        if (nextPageNumber === number + 1 && nextPageItems?.length) {
          const first = nextPageItems.splice(0, 1)[0]
          page.items.push(first)
          lastPageNumber = number
        }
      }
    }
  }

  public update(item: T, data: Partial<T>) {
    const pages = this.pages()
    const page = pages.find(([, pageData]) => pageData && pageData.items.find(i => i === item))
    if (page) {
      this._pages.set(page[0], {
        ...page[1]!,
        items: page[1]!.items.map(i => {
          if (i === item) {
            extend(i, data)
          }
          return i
        }),
      })
    }
  }

  /**
   * Clear fetched pages.
   */
  public clear() {
    this._pages = new Map()
  }

  /**
   * Load missing pages starting from the last fetched page and return all pages.
   */
  public async loadRest() {
    let hasMorePages = true
    while (hasMorePages) {
      const page = await this.loadMore()
      if (!page) {
        hasMorePages = false
        break
      }
    }
    return this.pages()
  }

  /**
   * Load all pages and return them.
   */
  public loadAll() {
    if (this.hasAll()) return Promise.resolve(this.pages())
    this.clear()
    return this.loadRest()
  }
}

export default Pagination
