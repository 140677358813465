import { CnxEditor } from 'cnx-designer'
import { normalize as normalizeMath } from './normalize'
import { Math } from './interfaces'

export * from './cnxml'
export * from './interfaces'
export * from './normalize'

export const withMath = <T extends CnxEditor>(editor: T) => {
  const { normalizeNode, isEquationContent } = editor

  editor.normalizeNode = entry => normalizeMath(editor, entry) || normalizeNode(entry)
  editor.isEquationContent = node => Math.isMath(node) || isEquationContent(node)

  return editor
}
