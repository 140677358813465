import * as React from 'react'
import { Transforms as CnxTransforms, Section } from 'cnx-designer'
import { Localized } from '@fluent/react'
import { Editor } from 'slate'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import Classes from '../Classes'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'

interface SectionToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const SectionTools = ({ editor, ...props }: SectionToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Section.isSection })
  if (!node) return null

  return (
    <ToolGroup
      title="editor-tools-sections-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('sectionTools')}
    >
      <Button
        id="editor-tools-sections-decrease-depth"
        clickHandler={() => CnxTransforms.decreaseSectionDepth(editor)}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="outdent" />
        <Localized id="editor-tools-sections-decrease-depth">
          Decrease level
        </Localized>
      </Button>
      <Button
        id="editor-tools-sections-increase-depth"
        clickHandler={() => CnxTransforms.increaseSectionDepth(editor)}
        className="toolbox__button--insert"
      >
        <Icon size="small" name="indent" />
        <Localized id="editor-tools-sections-increase-depth">
          Increase level
        </Localized>
      </Button>
      <Classes node={node} />
    </ToolGroup>
  )
}

export default SectionTools
