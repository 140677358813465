import * as React from 'react'
import { Editor, Range, Transforms } from 'slate'
import { useSlate } from 'slate-react'
import { useCurrentSelection, withCurrentSelection } from '../../../../screens/app/Draft/withCurrentSelection'
import User from '../../../../api/user'
import { useLoadAllUsers } from '../../../../api/hooks'
import Avatar from '../../../../components/ui/Avatar'
import { Mention } from '../InputEditor/plugins'

import './index.css'

const Suggestions = () => {
  const editor = useSlate()
  const [users] = useLoadAllUsers()
  const [index, setIndex] = React.useState(0)
  const selection = useCurrentSelection()
  const [search, target] = getSearchAndTarget(editor, selection)
  const filteredUsers = React.useMemo(
    () => users.filter(u => u.name.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10),
    [users, search],
  )

  const insertMention = React.useCallback((user: User) => {
    if (target) {
      Transforms.select(editor, target)
    }
    Mention.insertMention(editor, user.id)
  }, [editor, target])

  const onKeyDown = React.useCallback((ev: KeyboardEvent) => {
    if (!search) return false

    switch (ev.key) {
    case 'ArrowDown': {
      ev.preventDefault()
      const prevIndex = index >= filteredUsers.length - 1 ? 0 : index + 1
      setIndex(prevIndex)
      return true
    }
    case 'ArrowUp': {
      ev.preventDefault()
      const nextIndex = index <= 0 ? filteredUsers.length - 1 : index - 1
      setIndex(nextIndex)
      return true
    }
    case 'Tab':
    case 'Enter':
      ev.preventDefault()
      ev.stopPropagation()
      insertMention(filteredUsers[index])
      return true
    }

    return false
  }, [search, filteredUsers, index, insertMention])

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true)
    return () => document.removeEventListener('keydown', onKeyDown, true)
  }, [onKeyDown])

  if (!search || users.length === 0) return null

  return (
    <ul className="mentions__list">
      {
        filteredUsers.map((user, i) => (
          <li
            key={user.id}
            id="chat-suggestion-select"
            className={
              `mentions__item ${index === i ? 'selected' : '' }`
            }
            data-id={user.id}
            onClick={() => insertMention(user)}
          >
            <Avatar
              size="small"
              withName={true}
              user={user}
              disableLink={true}
            />
          </li>
        ))
      }
    </ul>
  )
}

export default withCurrentSelection(Suggestions)

const getSearchAndTarget = (editor: Editor, selection: Range | undefined): [string, Range | null | undefined] => {
  const res: [string, Range | null | undefined] = ['', null]
  if (!selection) return res

  const point = Editor.start(editor, selection)
  const [leaf] = Editor.leaf(editor, point)
  const before = leaf.text.slice(0, point.offset).match(/@(\w+)$/)
  const after = leaf.text.slice(point.offset).match(/^\s?/)

  if (before && after) {
    const search = before[1]
    return [
      search,
      {
        anchor: { path: point.path, offset: point.offset - search.length - 1 },
        focus: point,
      },
    ]
  }

  return ['', null]
}
