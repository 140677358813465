import * as React from 'react'
import { useSelector } from 'react-redux'
import { Module } from '../../../../../api'
import { ModuleLabel } from '../../../../../store/types'
import ModuleInfo from './ModuleInfo'
import { SearchQueries } from '../../../../../components/SearchInput'

import './index.css'

interface ModulesListProps {
  modules: Module[]
  filter: SearchQueries
  activePreviewId: string
  onModuleLabelClick: (label: ModuleLabel) => void
  isEditingUnlocked: boolean
  afterDelete: (module: Module) => void
}

const ModulesList = ({
  modules,
  activePreviewId,
  filter,
  isEditingUnlocked,
  onModuleLabelClick,
  afterDelete,
}: ModulesListProps) => {
  const {
    selectedTeams,
    modulesWithLabels,
    user,
  } = useSelector(state => ({
    modulesWithLabels: state.modules.modulesWithLabels,
    selectedTeams: state.app.selectedTeams,
    user: state.user.user,
  }))

  const filtered = React.useMemo(() => {
    const filterRegExp = new RegExp(filter.text, 'i')
    return modules.filter(m => {
      if (!selectedTeams.includes(m.team)) return false
      if (!m.title.match(filterRegExp)) return false
      if (
        filter.label
        && (!modulesWithLabels[m.id] || !modulesWithLabels[m.id].includes(filter.label.id))
      ) return false
      return true
    })
  }, [modules, filter, selectedTeams, modulesWithLabels])

  return (
    <ul className="modules__list">
      {
        filtered.map(m => (
          <ModuleInfo
            key={m.id}
            module={m}
            activePreviewId={activePreviewId}
            highlight={filter.text}
            isEditingUnlocked={isEditingUnlocked}
            user={user}
            onModuleLabelClick={onModuleLabelClick}
            afterDelete={afterDelete}
          />
        ))
      }
    </ul>
  )
}

export default ModulesList

