import * as React from 'react'
import { Localized } from '@fluent/react'
import { Editor, Range } from 'slate'
import { useSlate } from 'slate-react'
import * as api from '../../api'
import { ReferenceTarget } from '../../store/types'
import LocalReferenceTargets from '../../containers/LocalReferenceTargets'
import RemoteReferenceTargets from '../../containers/RemoteReferenceTargets'
import LinkBox from '../../containers/LinkBox'
import './index.css'

export type XrefTargetLink = {
  type: 'link'
  url: string
  text: string
}

export type XrefTargetReference = {
  type: 'xref'
  target: ReferenceTarget
  source?: api.Module
}

export type XrefTargetDocument = {
  type: 'docref'
  source: api.Module
}

export type XrefTargetData = XrefTargetLink | XrefTargetReference | XrefTargetDocument

export type XrefTargetOnSelect = (data: XrefTargetData) => void

interface XrefTargetSelectorProps {
  /**
   * Function to call when user selects a reference target.
   */
  onSelect: XrefTargetOnSelect
}

const XrefTargetSelector = ({ onSelect }: XrefTargetSelectorProps) => {
  const editor = useSlate()
  const [tab, setTab] = React.useState<'link' | 'local' | 'remote'>('link')

  const selectTabLink = () => setTab('link')
  const selectTabLocal = () => setTab('local')
  const selectTabRemote = () => setTab('remote')

  const text = editor.selection && Range.isExpanded(editor.selection)
    ? Editor.string(editor, editor.selection)
    : ''

  return (
    <div className="reference-targets">
      <div className="tabs">
        <span
          id="reference-target-list-tab-link"
          className={`tabs__tab ${tab === 'link' ? 'selected' : ''}`}
          onClick={selectTabLink}
        >
          <Localized id="reference-target-list-tab-link">
            Link
          </Localized>
        </span>
        <span
          id="reference-target-list-tab-local"
          className={`tabs__tab ${tab === 'local' ? 'selected' : ''}`}
          onClick={selectTabLocal}
        >
          <Localized id="reference-target-list-tab-local">
            This document
          </Localized>
        </span>
        <span
          id="reference-target-list-tab-remote"
          className={`tabs__tab ${tab === 'remote' ? 'selected' : ''}`}
          onClick={selectTabRemote}
        >
          <Localized id="reference-target-list-tab-remote">
            Other documents
          </Localized>
        </span>
      </div>
      <div className={tab === 'link' ? 'visible' : 'hidden'}>
        <LinkBox text={text} onSelect={onSelect} />
      </div>
      <div className={tab === 'local' ? 'visible' : 'hidden'}>
        <LocalReferenceTargets editor={editor} onSelect={onSelect} />
      </div>
      <div className={tab === 'remote' ? 'visible' : 'hidden'}>
        <RemoteReferenceTargets onSelect={onSelect} />
      </div>
    </div>
  )
}

export default XrefTargetSelector
