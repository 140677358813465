import { Editor, Transforms } from 'slate'

export default function onKeyDownQuotationMark(editor: Editor, ev: KeyboardEvent): void {
  if (ev.key === '"') {
    ev.preventDefault()
    const { selection } = editor

    if (selection == null) {
      return
    }
    const before = Editor.before(editor, selection, { distance: 1, unit: 'character' })
    if (!before) {
      Transforms.insertText(editor, '„', { at: selection })
      return
    }
    const beforeChar = Editor.leaf(editor, before!)[0].text[before!.offset]
    if (beforeChar === undefined) Transforms.insertText(editor, '„', { at: selection })
    else if (beforeChar === " ") Transforms.insertText(editor, '„', { at: selection })
    else Transforms.insertText(editor, '”', { at: selection })
  }
}
