import * as React from 'react'
import { Notification } from '../../../api'
import Section from '../../../components/Section'
import Header from '../../../components/Header'
import PageNavigation from '../../../components/PageNavigation'
import NotificationsList from './components/NotificationsList'
import './index.css'

const NotificationsCentre = () => (
  <div className="container">
    <Section>
      <Header l10nId="notification-centre-view-title" title="Notifications" />
      <div className="section__content notifications">
        <PageNavigation
          pagination={Notification.pagination()}
          usePageParam={true}
          Component={NotificationsList}
        />
      </div>
    </Section>
  </div>
)

export default NotificationsCentre
