import * as React from 'react'
import { match } from 'react-router'
import Load from '../../../../components/Load'
import AlternativeIdList from '../../../../containers/AlternativeIdList/AlternativeIdList'
import LicenseSelector from '../../../../containers/LicenseSelector'
import Header from '../../../../components/Header'
import Section from '../../../../components/Section'
import ReportButton from '../../../../components/ui/ReportButton'
import { Book } from '../../../../../src/api'

import './BookDetails.css'

async function loader({ match: { params: { id } } }: { match: match<{ id: string }> }) {
  const book = await Book.load(id, false)
  return {
    book,
  }
}

function BookDetails({ book }: { book: Book }) {
  const onLicenseChanged = React.useCallback(license => book.update({ license }), [book])

  return (
    <div className="container container--splitted">
      <Section className="details">
        <Header
          l10nId="book-details-title"
          title='Book details'
        />
        <LicenseSelector license={book.license} onChange={onLicenseChanged} />
        <AlternativeIdList element={book}/>
      </Section>
      <Section className="book-reports">
        <Header
          l10nId="book-reports"
          title="Reports"
        />
        <ReportButton l10nId="book-reports-progress" type="book-progress" book={book.id} />
      </Section>
    </div>
  )
}

export default Load(loader)(BookDetails)
