import DocumentOutline, { RenderOutlineElementProps } from 'document-outline'
import * as React from 'react'
import { IdEditor } from 'cnx-designer'
import { useHistory } from 'react-router'
import { Node } from 'slate'
import { Editable, RenderElementProps, Slate } from 'slate-react'
import { DocumentDB, PersistingEditor } from 'slate-persistence'
import { Suggestion } from 'slate-suggestions'
import { useDispatch } from 'react-redux'
import { Draft, Storage } from '../../../api'
import { StorageContext } from '../../../api/storage'
import Button from '../../../components/ui/Button'
import DraftInfo from '../../../components/DraftInfo'
import ErrorBoundary from '../../../components/ErrorBoundary'
import Header from '../../../components/Header'
import Icon from '../../../components/ui/Icon'
import Load from '../../../components/Load'
import Section from '../../../components/Section'
import Tooltip from '../../../components/ui/Tooltip'
import CountersRoot from './components/CountersRoot'
import LocalizationLoader from './components/LocalizationLoader'
import DraftTitle from '../../../components/DraftTitle'
import StepChanger from './components/StepChanger'
import SaveButton from './components/SaveButton'
import Toolbox from './components/Toolbox'
import reducer, { CurrentDraftState, initialState } from './store/reducer'
import {
  CurrentDraftAction,
  setCurrentDraftLang,
  setCurrentDraftPermissions,
  setReadOnly,
} from './store/actions'
import loader from './loader'
import {
  createEditor,
  onKeyDown as onKeyDownPlugin,
  renderElement as renderElementPlugin,
  renderLeaf,
  renderOutlineElement as renderOutlineElementPlugin,
} from './plugins'
import { useGoToReadOnly, useScrollIntoHash } from './utils'
import './index.css'
import { addAlert } from '../../../store/actions/alerts'
import Outline from './components/Outline'

interface DraftProps {
  documentDB: DocumentDB
  draft: Draft
  readOnly: boolean
  storage: Storage
  shouldBeRestored: boolean
  value: Node[]
  suggestions: Suggestion[]
  mediaUrl: (src: string) => string
  deserializationErrors: string[]
}

interface CurrentDraftContext {
  state: CurrentDraftState & {
    draft: Draft | null
    storage: Storage | null
  }
  dispatchCurrentDraftAction: React.Dispatch<CurrentDraftAction>
}

export const CurrentDraftContext = React.createContext<CurrentDraftContext>({
  state: {
    ...initialState,
    draft: null,
    storage: null,
  },
  dispatchCurrentDraftAction: (val: CurrentDraftAction) => val,
})

const DraftComp = ({ draft, documentDB, mediaUrl, storage, suggestions, ...props }: DraftProps) => {
  const [state, dispatchCurrentDraftAction] = React.useReducer(reducer, initialState)
  const dispatch = useDispatch()
  const currentDraftContextValue = React.useMemo(
    () => ({ state: { ...state, draft, storage }, dispatchCurrentDraftAction }),
    [state, dispatchCurrentDraftAction, draft, storage])
  const history = useHistory()
  const editor = React.useMemo(() => {
    const editor = createEditor(documentDB)
    editor.suggestions = suggestions
    return editor
  }, [documentDB, suggestions])
  const [value, setValue] = React.useState<Node[]>(props.value)
  const [hasChanges, setHasChanges] = React.useState(false)

  const [showSuggestions, setShowSuggestions] = React.useState(true)
  editor.showSuggestions = showSuggestions

  const renderElement = React.useCallback(
    (props: RenderElementProps) => renderElementPlugin(props, editor, { mediaUrl }),
    [editor, mediaUrl])
  const renderOutlineElement = React.useCallback(
    (props: RenderOutlineElementProps) => renderOutlineElementPlugin(props, editor),
    [editor])
  const onKeyDown = React.useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => onKeyDownPlugin(editor, ev as any as KeyboardEvent),
    [editor])

  const checkIfHasChanges = React.useCallback(() => {
    setHasChanges(PersistingEditor.hasChanges(editor))
  }, [editor])

  React.useEffect(() => {
    editor.onChangesPersisted = checkIfHasChanges
  }, [editor, checkIfHasChanges])

  React.useEffect(() => {
    if (props.shouldBeRestored) {
      editor.restore()
      IdEditor.invalidateIDs(editor)
    }
  }, [editor, props.shouldBeRestored])

  React.useEffect(() => {
    if (draft.step?.slots.some(s => s.permissions.includes('propose-changes'))) {
      editor.editingMode = 'suggesting'
    } else {
      editor.editingMode = 'normal'
    }
  }, [editor, draft.step])

  React.useEffect(
    () => dispatchCurrentDraftAction(setCurrentDraftPermissions(draft.permissions || [])),
    [draft.permissions])

  React.useEffect(() => {
    // It seems like without setTimeout LocalizationProvider is never rerender
    setTimeout(() => dispatchCurrentDraftAction(setCurrentDraftLang(storage.language)), 50)
  }, [storage])

  React.useEffect(() => dispatchCurrentDraftAction(setReadOnly(props.readOnly)), [props.readOnly])

  React.useEffect(() => {
    if (props.deserializationErrors.length) {
      dispatch(addAlert('error', 'deserialization-error', { error: props.deserializationErrors.join(', ') }))
    }
  }, [dispatch, props.deserializationErrors])

  useGoToReadOnly(history, draft.module, state.readOnly)

  useScrollIntoHash(history.location.hash.slice(1), [history.location.hash])

  // const [editingMode, setEditingMode] = React.useState(editor.editingMode)
  // const toggleSuggesting = React.useCallback((ev) => {
  //   ev.preventDefault()
  //   switch (editor.editingMode) {
  //   case 'normal':
  //     editor.editingMode = 'suggesting'
  //     setEditingMode('suggesting')
  //     break

  //   case 'suggesting':
  //     editor.editingMode = 'normal'
  //     setEditingMode('normal')
  //     break
  //   }
  // }, [editor, setEditingMode])

  return (
    <Section>
      <StorageContext.Provider value={storage}>
        <CurrentDraftContext.Provider value={currentDraftContextValue}>
          <Slate
            editor={editor}
            value={value}
            onChange={setValue}
          >
            <Header fixed={true}>
              <DraftInfo draft={draft} />
              <StepChanger
                draft={draft}
                document={value}
                hasChanges={hasChanges}
                onStepChange={() => history.push('/')}
              />
              {/* <Button
                clickHandler={toggleSuggesting}
              >
                Editing mode: {editingMode}
              </Button> */}
              <div className="draft__controls">
                <Tooltip
                  l10nId="editor-tools-button-undo"
                  direction="up"
                  className="toolbox__button--with-tooltip"
                >
                  <Button
                    id="editor-tools-button-undo"
                    clickHandler={editor.undo}
                    isDisabled={!editor.history.undos.length}
                    className="toolbox__button--only-icon"
                  >
                    <Icon size="small" name="undo" />
                  </Button>
                </Tooltip>
                <Tooltip
                  l10nId="editor-tools-button-redo"
                  direction="up"
                  className="toolbox__button--with-tooltip"
                >
                  <Button
                    id="editor-tools-button-redo"
                    clickHandler={editor.redo}
                    isDisabled={!editor.history.redos.length}
                    className="toolbox__button--only-icon"
                  >
                    <Icon size="small" name="redo" />
                  </Button>
                </Tooltip>
                <SaveButton
                  readOnly={state.readOnly}
                  document={value}
                  hasChanges={hasChanges}
                  afterSave={checkIfHasChanges}
                />
              </div>
            </Header>
            <div className="section__content draft">
              <div className="draft__editor">
                <div className={`document ${state.readOnly ? 'document--readonly' : ''}`}>
                  <div className="document__header">
                    <DraftTitle draft={draft} />
                  </div>
                  <ErrorBoundary
                    extraMessage="error-boundary-info-editors-error"
                    buttons={{
                      continueWorking: false,
                      goToDashboard: true,
                      reloadPage: true,
                    }}
                  >
                    <div className="document__editor document__editor--document">
                      <LocalizationLoader locale={state.currentDraftLang}>
                        <DocumentOutline
                          renderElement={renderOutlineElement}
                          as={Outline}
                        />
                        <CountersRoot>
                          <Editable
                            className={`editor editor--document ${showSuggestions ? 'show-suggestions' : ''}`}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onKeyDown={onKeyDown}
                            readOnly={state.readOnly}
                          />
                        </CountersRoot>
                      </LocalizationLoader>
                      { state.readOnly
                        ? null
                        : <Toolbox showSuggestions={showSuggestions} setShowSuggestions={setShowSuggestions} /> }
                    </div>
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </Slate>
        </CurrentDraftContext.Provider>
      </StorageContext.Provider>
    </Section>
  )
}

export default Load(loader, [], 'draft-loading-message')(DraftComp)
