import React from 'react'
import { Localized } from '@fluent/react'
import Input from '../../../components/ui/Input'
import Button from '../../../components/ui/Button'
import '../AlternativeIdList.css'

interface AletrnativeIdListElementProps {
  repoName: string
  repoID: string
  setRepoName?: (value: string) => void
  setRepoID: (value: string) => void
  children: React.ReactNode
}

export default function AletrnativeIdListElement({
  repoName,
  repoID,
  setRepoName,
  setRepoID,
  children,
}: AletrnativeIdListElementProps) {
  return <>
    <div className="alternative-ids__input">
      {setRepoName == null
        ?
        <Input
          type="text"
          value={repoName}
          onChange={never}
          disabled={true}
          required={true}
        />
        :
        <Input
          type="text"
          value={repoName}
          onChange={setRepoName}
          required={true}
          l10nId="alternative-id-add-reponame-placeholder"
        />}
    </div>
    <div className="alternative-ids__input">
      <Input
        type="text"
        value={repoID}
        onChange={setRepoID}
        required={true}
        l10nId="alternative-id-add-id-placeholder"
      />
    </div>
    <div className="alternative-ids__list-button">
      {children}
    </div>
  </>
}

function never(value: unknown) {}
