// import { CrossReference, DocumentReference } from 'cnx-designer'
// import { FluentVariable } from '@fluent/bundle'
// import { WithLocalizationProps } from '@fluent/react'
import { Editor, Node, Path, PathRef, Text } from 'slate'
import { Marker, Suggestion, SuggestionsEditor } from 'slate-suggestions'
// import { Module } from '../../../../../../../api'
// import {
//   ChangeSuggestion,
//   InsertSuggestion,
//   RemoveSuggestion,
//   SuggestionEx,
// } from './types'

// export const isInsertSuggestion = (suggestion: SuggestionEx): suggestion is InsertSuggestion => {
//   return suggestion.type === 'insert'
// }

// export const isRemoveSuggestion = (suggestion: SuggestionEx): suggestion is RemoveSuggestion => {
//   return suggestion.type === 'remove'
// }

// export const isChangeSuggestion = (suggestion: SuggestionEx): suggestion is ChangeSuggestion => {
//   return suggestion.type === 'change'
// }

// export const isSameSuggestion = (suggestion: SuggestionEx, suggestion2: SuggestionEx) => {
//   if (suggestion.type !== suggestion2.type) return false

//   if (
//     isChangeSuggestion(suggestion)
//     && isChangeSuggestion(suggestion2)
//     && suggestion.insert === suggestion2.insert
//     && suggestion.remove === suggestion2.remove
//   ) return true

//   return suggestion === suggestion2
// }

// /**
//  * Check if a suggestion is adjacent to another.
//  */
// const isAdjacent = (editor: Editor, prev: Suggestion, next: Suggestion): boolean => {
//   const endOfPrevious = Range.end(prev.range)
//   const afterEndOfPrevious = Editor.after(editor, endOfPrevious)
//   const startOfCurrent = Range.start(next.range)

//   const currentSuggIsDirectlyAfterPreviousText = Point.equals(endOfPrevious, startOfCurrent)
//   const currentSuggIsDirectlyAfterPreviousInline = afterEndOfPrevious != null
//     && !Path.equals(endOfPrevious.path, startOfCurrent.path)
//     && Point.equals(afterEndOfPrevious, startOfCurrent)

//   return currentSuggIsDirectlyAfterPreviousText || currentSuggIsDirectlyAfterPreviousInline
// }

// /**
//  * Return index of suggestion which is selected or -1 if it was not found.
//  */
// export const findActiveSuggestionIndexByRef = (
//   editor: SuggestionsEditor,
//   selection: Range | undefined,
//   suggestions: SuggestionEx[],
// ) => {
//   if (!selection) return -1

//   const [suggestion] = SuggestionsEditor.suggestions(editor, { at: selection })

//   for (let inx = 0 ; inx < suggestions.length ; ++inx) {
//     const s = suggestions[inx]

//     if (s.type === 'change' && (s.insert === suggestion || s.remove === suggestion)) {
//       return inx
//     }

//     if (s === suggestion) {
//       return inx
//     }
//   }

//   return -1
// }

// /**
//  * Derive current ‘extended suggestions’ (where adjacent insert and remove
//  * suggestions are combined into a {@link ChangeSuggestion}) from a suggestions
//  * editor.
//  */
// export const deriveSuggestions = (editor: SuggestionsEditor) => {
//   const suggestions: SuggestionEx[] = []

//   for (const suggestion of editor.suggestions) {
//     const prev = suggestions[suggestions.length - 1]

//     if (
//       prev != null
//       && prev.type !== 'change'
//       && prev.type !== suggestion.type
//       && isAdjacent(editor, prev, suggestion)
//     ) {
//       const [insert, remove] = isInsertSuggestion(prev)
//         ? [prev, suggestion] as [InsertSuggestion, RemoveSuggestion]
//         : [suggestion, prev] as [InsertSuggestion, RemoveSuggestion]

//       suggestions.pop()
//       suggestions.push({ type: 'change', insert, remove, start: prev, end: suggestion })
//     } else {
//       suggestions.push(suggestion)
//     }
//   }

//   return suggestions
// }

// const mapXrefsWithTheirTargets = async (
//   descendants: Descendant[],
//   getString: WithLocalizationProps["getString"],
// ): Promise<Descendant[]> => {
//   const transformed: Descendant[] = []
//   for (const d of descendants) {
//     if (CrossReference.isCrossReference(d)) {
//       const args: Record<string, FluentVariable> = {
//         target: d.target,
//       }
//       if (d.document) {
//         args.document = (await Module.load(d.document)).title
//       }
//       const l10nKey = d.document
//         ? 'editor-tools-suggestion-cross-ref-external-document'
//         : 'editor-tools-suggestion-cross-ref-internal-document'
//       transformed.push({ text: getString(l10nKey, args) })
//       continue
//     } else if (DocumentReference.isDocumentReference(d)) {
//       const args = { document: (await Module.load(d.document)).title }
//       const l10nKey = 'editor-tools-suggestion-document-ref'
//       transformed.push({ text: getString(l10nKey, args) })
//       continue
//     } else if (Element.isElement(d)) {
//       transformed.push({
//         ...d,
//         children: await mapXrefsWithTheirTargets(d.children, getString),
//       })
//       continue
//     }
//     transformed.push(d)
//   }
//   return transformed
// }

// const getTextContent = async (
//   editor: Editor,
//   suggestion: Suggestion,
//   getString: WithLocalizationProps["getString"],
// ): Promise<string> => {
//   const descendants = Editor.fragment(editor, suggestion.range)
//   const transformed = await mapXrefsWithTheirTargets(descendants, getString)
//   return transformed.map(d => Node.string(d)).join('')
// }

// export const getSuggestionContent = async (
//   editor: Editor,
//   suggestion: SuggestionEx,
//   getString: WithLocalizationProps["getString"],
// ): Promise<[string, string]> => {
//   let insertContent = ""
//   let deleteContent = ""
//   switch (suggestion.type) {
//   case 'change':
//     insertContent = await getTextContent(editor, suggestion.insert, getString)
//     deleteContent = await getTextContent(editor, suggestion.remove, getString)
//     break

//   case 'insert':
//     insertContent = await getTextContent(editor, suggestion, getString)
//     break

//   case 'remove':
//     deleteContent = await getTextContent(editor, suggestion, getString)
//     break

//   default:
//     console.error(`Incorrect suggestion type: ${suggestion}`)
//   }
//   return [insertContent, deleteContent]
// }

// export const acceptOrRejectSuggestion = (
//   editor: SuggestionsEditor,
//   action: 'accept' | 'reject',
//   suggestion: SuggestionEx,
// ) => {
//   if (isChangeSuggestion(suggestion)) {
//     editor[action](suggestion.end.range)
//     editor[action](suggestion.start.range)
//   } else {
//     editor[action](suggestion.range)
//   }
// }

// export const getPointBeforeSuggestion = (editor: Editor, suggestion: SuggestionEx) => {
//   if (isChangeSuggestion(suggestion)) {
//     return Editor.before(editor, suggestion.start.range)
//   }
//   return Editor.before(editor, suggestion.range)
// }

export function resolveFromMarker(
  editor: Editor,
  path: Path,
  marker: Suggestion | Marker,
): [Path, 'insert' | 'remove' | 'move' | 'split' | 'merge'] {
  if (!Marker.is(marker)) return [path, marker.suggestion]

  switch (marker.type) {
  case 'move-source': {
    const [[, p2]] = Editor.nodes(editor, {
      at: [],
      match: node => Suggestion.isMove(node) && node.movedFrom === marker.id,
    })
    return [p2, 'move']
  }

  case 'join':
    return [path, 'merge']

  case 'split-point': {
    const [[, p2]] = Editor.nodes(editor, {
      at: [],
      match: node => Suggestion.isSplit(node) && node.splitFrom === marker.id,
    })
    return [p2, 'split']
  }
  }

  // I would like this to be Error(`Unknown marker ${marker.type}`), but TSC
  // reports “Property 'type' does not exist on type 'never'”. Eslint on the
  // other hand complains if we leave this out...
  throw new Error(`unreachable`)
}

export function acceptOrRejectAll(editor: SuggestionsEditor, action: 'accept' | 'reject') {
  const actionf = action === 'accept' ? SuggestionsEditor.accept : SuggestionsEditor.reject
  let suggestions: [Suggestion | Marker, PathRef][] = Array.from(
    Editor.nodes(editor, {
      at: [],
      match: node => Suggestion.is(node) || Marker.is(node),
    }),
    ([node, path]) => [node as unknown as Suggestion | Marker, Editor.pathRef(editor, path)],
  )

  // There may be other nodes in between the halves, making it impossible
  // to reject the split until these other nodes are rejected.
  let delayed: [Suggestion | Marker, PathRef][] = []

  while (suggestions.length > 0) {
    for (const [node, pathref] of suggestions) {
      const path = pathref.unref()
      if (path == null) continue

      if (!isSplitRejectionPossible(editor, action, node, path)) {
        delayed.push([node, Editor.pathRef(editor, path)])
        continue
      }

      const [at, type] = resolveFromMarker(editor, path, node)
      actionf(editor, { at, type })
    }

    suggestions = delayed
    delayed = []
  }
}

function isSplitRejectionPossible(
  editor: Editor,
  action: 'accept' | 'reject',
  node: Suggestion | Marker,
  path: Path,
): boolean {
  // Not actually a rejection, we handle this here to make logic in
  // acceptOrRejectAll simpler.
  if (action === 'accept') return true

  if (Suggestion.isSplit(node)) {
    if (path[path.length - 1] === 0) return false

    const prev = Node.get(editor, Path.previous(path))
    if (Text.isText(prev)) return false

    const last = prev.children[prev.children.length - 1]
    const marker = Text.isText(last) && last.text === ''
      ? prev.children[prev.children.length - 2]
      : last

    return Marker.isSplit(marker) && marker.id === node.splitFrom
  }

  if (Marker.isSplit(node)) {
    const parentPath = Path.parent(path)
    const parent = Node.ancestor(editor, parentPath)
    const index = path[path.length - 1]

    if (parent.children.length === index + 2) {
      const last = parent.children[index + 1]
      if (!Text.isText(last) || last.text !== '') return false
    } else if (parent.children.length !== index + 1) {
      return false
    }

    const next = Node.get(editor, Path.next(parentPath))

    return Suggestion.isSplit(next) && next.splitFrom === node.id
  }

  return true
}
