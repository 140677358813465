import * as React from 'react'
import { Localized } from '@fluent/react'
import Process, { Slot } from '../../../api/process'
import { SlotDetails } from '../../../api/draft'
import SlotInfo, { SlotWithUser } from './SlotInfo'
import './index.css'

interface Props {
  slots: SlotDetails[]
  process: Process
  onChange: (mapping: Map<number, number | null>) => void
}

export default function ConfigureSlots(props: Props) {
  const { process, onChange } = props

  const sourceSlots = React.useMemo(
    () => props.slots.filter(slot => slot.user != null),
    [props.slots],
  ) as SlotWithUser[]

  const [targetSlots, setTargetSlots] = React.useState<Slot[]>([])

  const [mapping, setMappingState] = React.useState(new Map())

  const setMapping = React.useCallback(mapping => {
    setMappingState(mapping)
    onChange(mapping)
  }, [onChange])

  // Update targetSlots when process changes.
  React.useEffect(() => {
    process.slots().then(setTargetSlots)

    return () => setTargetSlots([])
  }, [process])

  // Update mapping and when source slots change.
  React.useEffect(() => {
    const newMapping = new Map(sourceSlots.map(slot => [slot.id, null]))
    setMapping(newMapping)

    return () => setMapping(new Map())
  }, [sourceSlots, onChange, setMapping])

  const onMappingChange = React.useCallback((from: number, to: number | null) => {
    const newMapping = new Map(mapping)
    newMapping.set(from, to)
    setMapping(newMapping)
  }, [setMapping, mapping])

  if (sourceSlots.length === 0) return null

  return (
    <div className="map-slots">
      <h3>
        <Localized id="change-process-slots-title">
          Choose slot mapping:
        </Localized>
      </h3>
      <div className="map-slots__slots">
        {
          sourceSlots.map(slot => (
            <SlotInfo
              key={slot.id}
              current={slot}
              team={process.team}
              userId={slot.user.id}
              mapping={mapping}
              slots={targetSlots}
              onChange={onMappingChange}
            />
          ))
        }
      </div>
    </div>
  )
}
