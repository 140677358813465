import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FilesError } from 'react-files'
import { Resource } from '../../../../../api'
import { useLoadTeam } from '../../../../../api/hooks'
import { addAlert } from '../../../../../store/actions/alerts'
import { confirmDialog } from '../../../../../helpers'
import Spinner from '../../../../../components/Spinner'
import Button from '../../../../../components/ui/Button'
import Dialog from '../../../../../components/ui/Dialog'
import Icon from '../../../../../components/ui/Icon'
import Input from '../../../../../components/ui/Input'
import FileUploader from '../../../../../containers/FilesUploader'
import { ACCEPTED_FILE_TYPES } from "../../index"
import EditingButtons from './components/EditingButtons'
import ResourceName from './components/ResourceName'
import './index.css'

interface ResourceCardProps {
  resource: Resource
  isEditingUnlocked: boolean
  afterResourceRemove: (resource: Resource) => void
}

const ResourceCard = ({ resource, isEditingUnlocked, afterResourceRemove }: ResourceCardProps) => {
  const [team] = useLoadTeam(resource.team)
  const [showEditResource, setShowEditResource] = React.useState(false)
  const [resourceName, setResourceName] = React.useState(resource.name)
  const [files, setFiles] = React.useState<File[]>([])
  const [isUploading, setIsUploading] = React.useState(false)
  const dispatch = useDispatch()

  const closeEditResource = () => {
    setShowEditResource(false)
  }

  const editResource = async () => {
    setIsUploading(true)

    const promises = []
    if (resourceName !== resource.name) {
      promises.push(resource.changeName(resourceName))
    }

    if (files.length) {
      promises.push(resource.replaceContent(files[0]))
    }

    try {
      await Promise.all(promises)
      resource.name = resourceName
      dispatch(addAlert('success', 'resources-edit-success'))
    } catch (e) {
      dispatch(addAlert('error', 'resources-edit-error'))
    }

    setShowEditResource(false)
    setResourceName(resource.name)
    setFiles([])
    setIsUploading(false)
  }

  const onFilesError = (error: FilesError, _: File) => {
    dispatch(addAlert('error', 'file-upload-error', { code: error.code }))
  }

  const removeResource = async () => {
    const res = await confirmDialog({
      title: 'resources-card-remove-title',
      vars: { resource: resource.name },
      buttons: {
        cancel: 'resources-card-cancel',
        confirm: 'resources-card-remove',
      },
    })
    if (res === 'confirm') {
      resource.archive()
        .then(() => {
          afterResourceRemove(resource)
          dispatch(addAlert('success', 'resources-card-remove-success'))
        })
    }
  }

  const to = React.useMemo(
    () => resource.kind === 'directory'
      ? `/resources/${resource.id}`
      : `/api/v1/resources/${resource.id}/content`,
    [resource])

  return (
    <>
      <Link
        to={to}
        target={resource.kind === 'file' ? '_blank' : undefined}
        className={`resource__card ${isEditingUnlocked ? 'resource__card--editing' : ''}`}
      >
        <div className="resource__content">
          <Icon size="big" name={resource.kind === 'file' ? 'file' : 'folder'} />
          <ResourceName resource={resource} team={team} />
          <EditingButtons
            show={isEditingUnlocked}
            onEdit={() => setShowEditResource(true)}
            onRemove={removeResource}
          />
        </div>
      </Link>
      {
        showEditResource ?
          <Dialog
            size="medium"
            l10nId="resources-card-edit-title"
            placeholder="Edit this resource"
            onClose={closeEditResource}
            showCloseButton={false}
          >
            {
              isUploading ?
                <Spinner />
                :
                <div className="resources__dialog-content">
                  <Input
                    type="text"
                    l10nId="resources-name-placeholder"
                    value={resourceName}
                    onChange={val => setResourceName(val)}
                    minLength={3}
                    required
                  />
                  {
                    resource.kind === 'file' ?
                      <FileUploader
                        onFilesChange={setFiles}
                        onFilesError={onFilesError}
                        multiple={false}
                        accepts={ACCEPTED_FILE_TYPES}
                        optional={true}
                      />
                      : null
                  }
                  <div className="dialog__buttons">
                    <Button l10nId="resources-edit-cancel" clickHandler={closeEditResource}>
                      Cancel
                    </Button>
                    <Button
                      l10nId="resources-edit-update"
                      clickHandler={editResource}
                      isDisabled={resourceName.length < 3}
                    >
                      Update
                    </Button>
                  </div>
                </div>
            }
          </Dialog>
          : null
      }
    </>
  )
}

export default ResourceCard
