import * as React from 'react'
import { RenderElementProps } from 'slate-react'
import { Glossary } from 'cnx-designer'
import { LOCALIZATION } from '../../constants'

interface GlossaryProps extends RenderElementProps{
  element: Glossary
}

const GlossaryComp = ({ children, element }: GlossaryProps) => {
  const { document } = React.useContext(LOCALIZATION)
  const headerText = document.getString('glossary-header-label')
  return (
    <section
      className="glossary"
      id={element.id as string | undefined}
    >
      <h2 className="title" contentEditable={false}>
        {headerText}
      </h2>
      {children}
    </section>
  )
}

export default GlossaryComp
