import * as React from 'react'
import { Localized } from '@fluent/react'
import { ModuleLabelProperites } from '../../../store/types'
import { useIsLabelExisting } from '../../../hooks'
import ColorGenerator from '../../../components/ColorGenerator'
import Input from '../../../components/ui/Input'
import Button from '../../../components/ui/Button'
import { getRandomColor, isColorDark } from '../../../helpers'

import './index.css'

interface ModuleLabelCreatorProps {
  onCancel: () => void
  createHandler: (label: ModuleLabelProperites) => void
  initialPhrase?: string
}

const ModuleLabelCreator = ({
  onCancel,
  createHandler,
  initialPhrase,
}: ModuleLabelCreatorProps) => {
  const [labelData, setLabelData] = React.useState<ModuleLabelProperites>({
    color: getRandomColor(),
    name: initialPhrase,
  })

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const updateLabelData = (data: { name?: string, color?: string }) => {
    setLabelData({
      ...labelData,
      ...data,
    })
  }

  const isLabelExisting = useIsLabelExisting(labelData.name || '')

  const onChange = (name: string) => {
    updateLabelData({ name })
  }

  const onColorChange = (color: string) => {
    updateLabelData({ color })
  }

  const handleCreateLabel = () => {
    if (labelData.name && labelData.color) {
      createHandler(labelData as ModuleLabelProperites)
    }
  }

  return (
    <div className="module-label-creator">
      <ModuleLabelPreview
        name={labelData.name}
        color={labelData.color!}
      />
      <div className="module-label-creator__controls">
        <Input
          type="text"
          l10nId="module-labels-editor-label-name-placeholder"
          onChange={onChange}
          validator={() => !isLabelExisting}
          errorMessage="module-labels-editor-label-name-taken"
          value={labelData.name}
          required
        />
        <ColorGenerator startColor={labelData.color} onChange={onColorChange} />
        <div className="module-label-creator__buttons">
          <Button l10nId="module-labels-editor-cancel" clickHandler={handleCancel}>
            Cancel
          </Button>
          <Button
            l10nId="module-labels-editor-create-label"
            clickHandler={handleCreateLabel}
            isDisabled={!labelData.name || !labelData.color || isLabelExisting}
          >
            Create label
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ModuleLabelCreator

interface ModuleLabelPreviewProps extends ModuleLabelProperites {
  color: string
}

const ModuleLabelPreview = ({ name, color }: ModuleLabelPreviewProps) => {
  const style: React.CSSProperties = {
    backgroundColor: color,
  }
  if (isColorDark(color)) {
    style.color = '#fff'
  }

  return (
    <span
      className="module-label"
      style={style}
    >
      {
        name
          ? name
          :
          <Localized id="module-label-editor-label-name">
            Label name
          </Localized>
      }
    </span>
  )
}
