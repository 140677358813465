import * as React from 'react'
import Button from '../../../../components/ui/Button'

import './index.css'

interface PageNavigationListProps {
  activePage: number
  cachedPageNumbers: number[]
  hasMore: boolean
  useRouterLinks?: boolean
  onPageClick?: (number: number) => void
}

const PageNavigationList = ({
  activePage,
  cachedPageNumbers,
  useRouterLinks = false,
  hasMore,
  onPageClick,
}: PageNavigationListProps) => {
  const beforeActivePage = React.useMemo(() => {
    if (activePage === 1) return []
    if (activePage === 2) return [1]
    if (activePage >= 3) return [1, 2]
    return []
  }, [activePage])
  const afterActivePage = React.useMemo(() => {
    const last2 = cachedPageNumbers.slice(cachedPageNumbers.length - 2)
    return last2.filter(n => n > activePage)
  }, [cachedPageNumbers, activePage])

  const handleClick = (n: number) => {
    if (!useRouterLinks && onPageClick) {
      onPageClick(n)
    }
  }

  return <div className="page-navigation__list">
    {beforeActivePage.map(n => (
      <Button
        key={n}
        id="page-navigation-select-by-index"
        className="page-navigation__page"
        to={useRouterLinks ? `?page=${n}` : undefined}
        clickHandler={e => {
          if (useRouterLinks) return
          e.preventDefault()
          handleClick(n)
        }}
      >
        {n}
      </Button>
    ))}
    {
      activePage === 4 ?
        <Button
          id="page-navigation-select-3"
          className="page-navigation__page"
          to={useRouterLinks ? `?page=${3}` : undefined}
          clickHandler={e => {
            if (useRouterLinks) return
            e.preventDefault()
            handleClick(3)
          }}
        >
          3
        </Button>
        : activePage > 4 ? '...' : null
    }
    {
      activePage > 4 ?
        <Button
          id="page-navigation-select-prev"
          className="page-navigation__page"
          to={useRouterLinks ? `?page=${activePage - 1}` : undefined}
          clickHandler={e => {
            if (useRouterLinks) return
            e.preventDefault()
            handleClick(activePage - 1)
          }}
        >
          {activePage - 1}
        </Button>
        : null
    }
    <span className="page-navigation__page page-navigation__active-page">
      {activePage}
    </span>
    {
      ((activePage + 1) < afterActivePage[0]) || (!afterActivePage[0] && hasMore) ?
        <Button
          id="page-navigation-select-next"
          className="page-navigation__page"
          to={useRouterLinks ? `?page=${activePage + 1}` : undefined}
          clickHandler={e => {
            if (useRouterLinks) return
            e.preventDefault()
            handleClick(activePage + 1)
          }}
        >
          {activePage + 1}
        </Button>
        : null
    }
    {(activePage + 2) < afterActivePage[0] ? '...' : null}
    {afterActivePage.map(n => (
      <Button
        key={n}
        id="page-navigation-select-by-index"
        className="page-navigation__page"
        to={useRouterLinks ? `?page=${n}` : undefined}
        clickHandler={e => {
          if (useRouterLinks) return
          e.preventDefault()
          handleClick(n)
        }}
      >
        {n}
      </Button>
    ))}
    {hasMore ? '...' : null}
  </div>
}

export default PageNavigationList
