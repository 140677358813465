import * as React from 'react'
import { Link } from 'react-router-dom'
import { User } from '../../../api'
import { useLoadUser } from '../../../api/hooks'
import Tooltip from '../../../components/ui/Tooltip'

import './index.css'

interface AvatarProps {
  user?: User | number
  disableLink?: boolean
  size?: 'small' | 'medium' | 'big'
  withName?: boolean
}

const Avatar = ({ user, disableLink, size, withName }: AvatarProps) => {
  const [userData] = useLoadUser(user instanceof User ? user.id : user)

  const mainClasses = ['avatar']
  if (size) mainClasses.push(`avatar--${size}`)

  const title = userData && userData.name ? userData.name : 'Unknow user'
  const linkToProfile = userData ? '/users/' + userData.id : '/settings'

  const avatarSrc = '/images/unknown-user.svg'

  const body = (
    <>
      <Tooltip l10nId={title}>
        <span className="avatar__image">
          <img src={avatarSrc} alt={title}/>
        </span>
      </Tooltip>
      {
        withName ?
          <span className="avatar__name">{title}</span>
          : null
      }
    </>
  )

  if (disableLink) {
    return (
      <span className={mainClasses.join(' ')}>
        {body}
      </span>
    )
  }

  return (
    <Link to={linkToProfile} className={mainClasses.join(' ')}>
      {body}
    </Link>
  )
}

export default Avatar
