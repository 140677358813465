import * as React from 'react'
import Select from 'react-select'
import { Audio, Image, MediaUse, Video } from 'cnx-designer'
import { NodeEntry, Transforms } from 'slate'
import { useSlate } from 'slate-react'
import { Localized } from '@fluent/react'
import { isValidUrl } from '../../../../../../../helpers'
import Checkbox from '../../../../../../../components/ui/Checkbox'
import Input from '../../../../../../../components/ui/Input'

import './index.css'

export type MediaBooleanAttr =
  'autoplay'
  | 'controller'
  | 'loop'

export type MediaStringAttr =
  'longdesc'
  | 'print-width'
  | 'standby'
  | 'thumbnail'

export type MediaNumberAttr = 'height' | 'volume' | 'width'

export type MediaSelectAttr = 'intendedUse'

interface MediaUseOption {
  value: MediaUse
  label: string
}

const FOR_ATTR_OPTIONS: MediaUseOption[] = [
  { value: 'all', label: 'media-attr-intended-use-all' },
  { value: 'online', label: 'media-attr-intended-use-online' },
  { value: 'pdf', label: 'media-attr-intended-use-pdf' },
]

export type MediaAttr =
  MediaBooleanAttr
  | MediaNumberAttr
  | MediaSelectAttr
  | MediaStringAttr

interface MediaAttributeProps {
  node: NodeEntry<Audio | Image | Video>
  attribute: MediaAttr
}

const MediaAttribute = ({ node, attribute }: MediaAttributeProps) => {
  const editor = useSlate()
  const [element, path] = node
  let body: JSX.Element

  const updateMediaAttr = (attr: MediaAttr, val: boolean | number | string | null) => {
    val !== null
      ? Transforms.setNodes(editor, { [attr]: val }, { at: path })
      : Transforms.unsetNodes(editor, attr, { at: path })
  }

  switch (attribute) {
  case 'autoplay': {
    const onChange = (val: boolean) => {
      updateMediaAttr('autoplay', val)
    }
    body = (
      <Checkbox
        value={element.autoplay as boolean | undefined}
        label="media-attr-autoplay"
        onChange={onChange}
      />
    )
    break
  }

  case 'controller': {
    const onChange = (val: boolean) => {
      updateMediaAttr('controller', val)
    }
    body = (
      <Checkbox
        value={element.controller as boolean | undefined}
        label="media-attr-controller"
        onChange={onChange}
      />
    )
    break
  }

  case 'intendedUse': {
    const forVal = element.intendedUse
    const onChange = ({ value }: MediaUseOption) => {
      updateMediaAttr('intendedUse', value)
    }
    body = (
      <label>
        <Localized id="media-attr-intended-use">
          For
        </Localized>
        <Select
          className="react-select"
          value={forVal ? { value: forVal, label: 'media-attr-intended-use-' + forVal } : undefined}
          options={FOR_ATTR_OPTIONS}
          formatOptionLabel={OptionLabel}
          onChange={onChange as any}
        />
      </label>
    )
    break
  }

  case 'height': {
    const onChange = (val: number | null) => {
      updateMediaAttr('height', val)
    }
    body = (
      <label>
        <Localized id="media-attr-height">
          Height
        </Localized>
        <Input
          type="number"
          value={element.height as number | undefined}
          onChange={onChange}
          validator={(val: number | null) => !val || Boolean(Number(val))}
          errorMessage="media-attr-height-error"
        />
      </label>
    )
    break
  }

  case 'longdesc': {
    const onChange = (val: string | null) => {
      if (val == null || !isValidUrl(val)) {
        updateMediaAttr('longdesc', null)
      } else {
        updateMediaAttr('longdesc', val)
      }
    }
    body = (
      <label>
        <Localized id="media-attr-longdesc">
          Longdesc
        </Localized>
        <Input
          type="text"
          value={element.longdesc as string | undefined}
          onChange={onChange}
          validator={(val: string | null) => !val || Boolean(Number(val))}
          errorMessage="media-attr-longdesc-error"
        />
      </label>
    )
    break
  }

  case 'loop': {
    const onChange = (val: boolean) => {
      updateMediaAttr('loop', val)
    }
    body = (
      <Checkbox
        value={element.loop as boolean | undefined}
        label="media-attr-loop"
        onChange={onChange}
      />
    )
    break
  }

  case 'print-width': {
    const onChange = (val: string | null) => {
      updateMediaAttr('print-width', val)
    }
    body = (
      <label>
        <Localized id="media-attr-print-width">
          Print width
        </Localized>
        <Input
          type="text"
          value={element['print-width'] as string | undefined}
          onChange={onChange}
          validator={(val: string | null) => !val || /[0-9]+[a-z]+/.test(val)}
          errorMessage="media-attr-print-width-error"
        />
      </label>
    )
    break
  }

  case 'standby': {
    const onChange = (val: string | null) => {
      updateMediaAttr('standby', val)
    }
    body = (
      <label>
        <Localized id="media-attr-standby">
          Standby
        </Localized>
        <Input type="text" value={element.standby as string | undefined} onChange={onChange} />
      </label>
    )
    break
  }

  case 'thumbnail': {
    const onChange = (val: string | null) => {
      if (val == null || !isValidUrl(val)) {
        updateMediaAttr('thumbnail', null)
      } else {
        updateMediaAttr('thumbnail', val)
      }
    }
    body = (
      <label>
        <Localized id="media-attr-thumbnail">
          Thumbnail
        </Localized>
        <Input
          type="text"
          value={element.thumbnail as string | undefined}
          onChange={onChange}
          validator={(val: string | null) => !val || isValidUrl(val)}
          errorMessage="media-attr-thumbnail-error"
        />
      </label>
    )
    break
  }

  case 'volume': {
    const onChange = (val: number | null) => {
      updateMediaAttr('volume', val)
    }
    body = (
      <label>
        <Localized id="media-attr-volume">
          Volume
        </Localized>
        <Input
          type="number"
          value={element.volume as number | undefined}
          onChange={onChange}
          validator={(val: number | null) => val == null || (0 < val && val <= 100)}
          errorMessage="media-attr-volume-error"
        />
      </label>
    )
    break
  }

  case 'width': {
    const onChange = (val: number | null) => {
      updateMediaAttr('width', val)
    }
    body = (
      <label>
        <Localized id="media-attr-width">
          Width
        </Localized>
        <Input
          type="number"
          value={element.width as number | undefined}
          onChange={onChange}
          validator={(val: number | null) => val == null || val > 0}
          errorMessage="media-attr-width-error"
        />
      </label>
    )
    break
  }

  default:
    return null
  }

  return (
    <div className="media-attr">
      {body}
    </div>
  )
}

export default MediaAttribute

function OptionLabel(op: { value: string, label: string } | undefined) {
  if (!op) return ''
  return <Localized id={op.label}>{op.label}</Localized> as unknown as string
}
