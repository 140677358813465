import * as React from 'react'
import { useSelector } from 'react-redux'
import { History } from 'history'
import { Team } from '../../../../../api'
import { useIsInSuperMode } from '../../../../../hooks'
import Header from '../../../../../components/Header'
import PageNavigation from '../../../../../components/PageNavigation'
import AddTeam from '../AddTeam'
import TeamListItem from '../TeamListItem'

export interface TeamListProps {
  history: History
}

export default function TeamList({ history }: TeamListProps) {
  const user = useSelector(state => state.user.user)
  const isInSuperMode = useIsInSuperMode(user)

  const onTeamClick = (team: Team) => {
    history.push(`/teams/${team.id}`)
  }

  return <>
    <Header l10nId="teams-section-manage-teams-title" title="Manage teams" />
    <div className="section__content">
      <PageNavigation
        pagination={Team.pagination()}
        usePageParam={true}
        Component={
          ({ items, onAdd, onDelete }) => <>
            <AddTeam onAdd={onAdd} />
            <ul className="teams__list">
              {
                items.map(t => (
                  <TeamListItem
                    key={t.id + t.name}
                    team={t}
                    isInSuperMode={isInSuperMode}
                    onTeamClick={onTeamClick}
                    afterTeamRemove={onDelete}
                  />
                ))
              }
            </ul>
          </>
        }
      />
    </div>
  </>
}
