import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import APICache from './apicache'
import Pagination from './pagination'
import { ConversationData } from './conversation'
import { extend } from './utils'

export type TicketID = string

export type TicketData = {
  id: TicketID
  title: string
  opened: string
  closed: string | null
  conversation: ConversationData
}

export default class Ticket {
  /**
   * Load information about Ticket with given id.
   */
  static load(id: string, fromCache = true): Promise<Ticket> {
    return APICache.getOrSetNested(
      ['Tickets', id.toString()],
      fromCache,
      async () => new Ticket((await axios.get(`support/tickets/${id}`)).data),
    )
  }

  static pagination(fromCache = true): Pagination<Ticket> {
    return APICache.getOrSetNestedSync(
      ['Pagination', 'tickets'],
      fromCache,
      new Pagination(
        'support/tickets',
        (data: TicketData) => new Ticket(data),
        tickets => APICache.update(
          'Tickets',
          tickets.reduce((obj, ticket) => ({ ...obj, [ticket.id]: ticket }), {}),
        ),
      ),
    )
  }

  /**
   * Create new ticket with specific title.
   */
  static async create(title: string): Promise<Ticket> {
    const ticket = new Ticket((await axios.post('support/tickets', { title })).data)
    APICache.invalidate('Tickets', 'Conversations')
    return ticket
  }

  /**
   * ID of this ticket.
   */
  id!: TicketID

  /**
   * Title of this ticket.
   */
  title!: string

  /**
   * Date and time when this ticked was opened.
   */
  opened: Date

  /**
   * Date and time when this ticked was closed or null if it's still open.
   */
  closed: Date | null

  /**
   * List of IDs of this ticket's authors,
   */
  authors!: number[]

  /**
   * Conversation data associated with this ticket.
   */
  conversation!: ConversationData

  constructor(data: TicketData) {
    extend(this, data)

    this.opened = new Date(data.opened)
    this.closed = data.closed ? new Date(data.closed) : null
  }

  /**
   * Update title of ticket.
   * This endpoint is available only for members of support team.
   */
  update(title: string): Promise<AxiosResponse<TicketData>> {
    this.title = title
    APICache.updateNested(['Tickets', this.id], { title })
    return axios.put(`support/tickets/${this.id}`, { title })
  }

  /**
   * Join the conversation associated with this ticket.
   * This endpoint is only available to members of the support team.
   */
  async join(): Promise<void> {
    await axios.post(`support/tickets/${this.id}/join`)
    APICache.invalidate(['Tickets', this.id], ['Conversations', this.conversation.id.toString()])
  }

  /**
   * Close ticket.
   * This endpoint is only available to members of the support team.
   */
  async close(): Promise<void> {
    await axios.post(`support/tickets/${this.id}/close`)
    const closedDate = new Date()
    this.closed = closedDate
    APICache.updateNested(
      ['Tickets', this.id],
      { closed: closedDate },
    )
  }

  /**
   * Reopen ticket.
   * This endpoint is only available to members of the support team.
   */
  async reopen(): Promise<void> {
    await axios.post(`support/tickets/${this.id}/reopen`)
    this.closed = null
    APICache.updateNested(
      ['Tickets', this.id],
      { closed: null },
    )
  }
}
