import * as React from 'react'
import { Localized } from '@fluent/react'
import Dialog from '../../../../../../../components/ui/Dialog'
import Button from '../../../../../../../components/ui/Button'

interface ErrorDialogProps {
  show: boolean
  error: string
  onClose: () => void
  onExport: () => void
}

const ErrorDialog = (props: ErrorDialogProps) => {
  if (!props.show) return null

  return (
    <Dialog
      size="medium"
      l10nId="editor-tools-save-error-title"
      placeholder="We couldn't save this document"
      onClose={props.onClose}
    >
      <Localized
        id="editor-tools-save-error-content"
        elems={{ p: <p/> }}
        vars={{ error: props.error }}
      >
        <div className="save-button__dialog-content" />
      </Localized>
      <div className="dialog__buttons dialog__buttons--center">
        <Button l10nId="editor-tools-save-error-export" clickHandler={props.onExport}>
          Export document
        </Button>
      </div>
    </Dialog>
  )
}

export default ErrorDialog
