import * as React from 'react'
import { Localized } from '@fluent/react'
import Icon from '../../../../../components/ui/Icon'
import Button, { ButtonHandler } from '../../../../../components/ui/Button'

import './index.css'

interface OutlineProps {
  children?: any
}

// eslint-disable-next-line react/display-name
const Outline = React.forwardRef((props: OutlineProps, ref) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const outlineLabel = <Localized id="outline-label">Outline</Localized>

  const toggleWrapper: ButtonHandler = event => {
    event.stopPropagation()
    setIsExpanded(prevState => !prevState)
  }
  const expandWrapper:React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()
    if (!isExpanded) setIsExpanded(true)
  }

  // add tabindex to outline elements
  let indexNumber = 1
  const outlineElements = Array.from(document.getElementsByClassName("outline-element"))
  outlineElements.forEach((elem: any) => {
    elem.setAttribute("tabindex", indexNumber.toString())
    indexNumber++
  })

  return (
    <div
      ref={ref as React.RefObject<HTMLDivElement>}
      className={isExpanded ? 'outline-wrapper' : 'outline-wrapper outline-wrapper--collapsed'}
      onClick={expandWrapper}
    >
      <Button
        id="outline-toggle"
        className="outline-wrapper--toggler"
        clickHandler={toggleWrapper}
        withBorder={false}
      >
        <Icon size='small' name={isExpanded ? 'arrow-down' : 'arrow-right'} />
      </Button>
      <div
        className={
          isExpanded ? 'outline-wrapper--label-no-display' : 'outline-wrapper--label'
        }
        contentEditable={false}
      >
        {outlineLabel}
      </div>
      {props.children}
    </div>
  )
})

export default Outline
