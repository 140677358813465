import { Editor, Range, Text } from 'slate'

export type MarkKind = 'emphasis' | 'strong' | 'underline' | 'subscript' | 'superscript'
const marks = ['emphasis', 'strong', 'underline', 'subscript', 'superscript']

export const Mark = {
  isActive: (editor: Editor, selection: Range | undefined, format: MarkKind): boolean => {
    const isActive = Boolean(Editor.nodes(editor, {
      at: selection,
      match: n => Text.isText(n) && (n[format] === true || n.position === format),
    }).next().value)

    return isActive
  },
  toggleMark: (editor: Editor, selection: Range | undefined, format: MarkKind) => {
    const isActive = Mark.isActive(editor, selection, format)

    if (isActive) {
      if (format === 'subscript' || format === 'superscript') {
        Editor.removeMark(editor, 'position')
      } else {
        Editor.removeMark(editor, format)
      }
    } else if (format === 'subscript' || format === 'superscript') {
      Editor.addMark(editor, 'position', format)
    } else {
      Editor.addMark(editor, format, true)
    }
  },
  removeMarks: (editor: Editor) => {
    marks.forEach(m => Editor.removeMark(editor, m))
  },
}
