import * as React from 'react'
import { Localized } from '@fluent/react'
import Icon from '../../components/ui/Icon'
import Input from '../../components/ui/Input'
import Button from '../../components/ui/Button'
import NewIdInput from './components/NewIdInput'
import ExistingId from './components/ExistingId'
import Module from '../../api/module'
import Book from '../../api/book'
import Draft from '../../api/draft'
import store from '../../store'
import { addAlert } from '../../store/actions/alerts'
import './AlternativeIdList.css'

interface AlternativeIdListProps {
  element: Draft | Book
}

const AlternativeIdList = ({ element }: AlternativeIdListProps) => {
  const [source, setSource] = React.useState<Module | Book>()
  const [repoName, setRepoName] = React.useState<string>()
  const [repoId, setRepoId] = React.useState<string>()
  const [alternativeIDs, setAlternativeIDs] = React.useState<{}>()

  const loadAltIDs = async (sourceTemp: Book | Module) => {
    const altIDS = await sourceTemp.alternativeIds()
    setAlternativeIDs(altIDS)
  }

  React.useEffect(() => {
    const loadSource = async () => {
      let tempSource: Module | Book
      if (element instanceof Draft) { tempSource = await Module.load(element.module) }
      else {
        tempSource = element
      }
      loadAltIDs(tempSource)
      return tempSource
    }

    loadSource()
      .then(data => setSource(data))
  }, [element])

  const RenderCollapseIcon = ({ isCollapsed }: { isCollapsed: boolean }) => {
    if (isCollapsed) {
      return <div className="alternative-id-icon-right"><Icon name="arrow-right"/></div>
    }
    return <Icon name="arrow-down" />
  }

  const setID = async (repo: string, ID: string) => {
    await source?.setAlternativeIdForRepository(repo, ID)
      .then(() => {
        setRepoName('')
        setRepoId('')
        store.dispatch(addAlert('success', 'alternative-id-alert-success'))
      })
      .catch(error => { store.dispatch(addAlert('error', 'alternative-id-alert-error')) })
      .then(() => setAlternativeIDs(prevState => {
        const tempPrev = { ...prevState }
        tempPrev[`${repo}`] = ID
        return tempPrev
      }))
  }

  const removeID = async (repoName: string) => {
    await source?.deleteAlternativeIdForRepository(repoName)
      .then(() => setAlternativeIDs(prevState => {
        const tempPrev = { ...prevState }
        delete tempPrev[repoName]
        return tempPrev
      }))
  }

  if (!source || !alternativeIDs) return null

  return (
    <div>
      <div className="alternative-ids__title">
        <h3>
          <Localized id="alternative-id-title">
            Alternative ID
          </Localized>
        </h3>
      </div>
      <div className="alternative-ids__list">
        <NewIdInput setID={setID} />

        {Object.keys(alternativeIDs).map((key, index) => (
          <ExistingId
            key={key + index}
            repoName={key}
            repoID={alternativeIDs[key]}
            setID={setID}
            removeID={removeID}
          />
        ))}
      </div>
    </div>
  )
}

export default AlternativeIdList
