import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import APICache from './apicache'
import Pagination from './pagination'
import Role, { RoleData, RoleID } from './role'
import { DraftAndBookPart } from './draft'
import Team, { TeamID, TeamPermission } from './team'
import { elevated, extend } from './utils'
import { addMinutesToDate } from '../helpers'

/**
 * User data as returned by the API.
 */
export type UserData = {
  id: number
  name: string
  is_super: boolean
  is_support: boolean
  language: string
  teams: {
    id: TeamID
    name: string
    permissions: TeamPermission[]
    role: RoleData | null
  }[]
}

export type UserTeam = {
  id: TeamID
  name: string
  // Permissions not related to role.
  permissions: TeamPermission[]
  // All permissions which user has in team.
  allPermissions: Set<TeamPermission>
  role: Role | null
}

export interface TeamWithRoles extends UserTeam, Pick<Team, 'roles' | 'status'> { }

/**
 * Session details
 */
export type SessionInfo = {
  expires: string
  is_elevated: boolean
}

export type UsersGroupedInTeams = { team: Team, users: User[] }[]

export type LoginMethodsPassword = { method: 'password' }
export type LoginMethodsProvider = { method: 'openid', provider: string, name: string }
export type LoginMethod = LoginMethodsPassword | LoginMethodsProvider

export default class User {
  /**
   * Fetch a user by their ID.
   */
  static load(id: number | string, fromCache = true): Promise<User> {
    return APICache.getOrSetNested(
      ['Users', id.toString()],
      fromCache,
      async () => new User((await axios.get(`users/${id}`)).data),
    )
  }

  /**
   * Fetch information about the current user.
   *
   * @return Instance of {@link User} corresponding to the current user,
   * or null if there is no active session.
   */
  static async me(): Promise<User | null> {
    // Do not cache this
    try {
      const user = (await axios.get('users/me')).data
      return new User(user, 'me')
    } catch (err) {
      if (err.response.status === 401) {
        return null
      }
      throw err
    }
  }

  static pagination(fromCache = true): Pagination<User> {
    return APICache.getOrSetNestedSync(
      ['Pagination', 'users'],
      fromCache,
      new Pagination(
        'users',
        (data: UserData) => new User(data),
        users => APICache.update(
          'Users',
          users.reduce((obj, user) => ({ ...obj, [user.id.toString()]: user }), {})),
      ),
    )
  }

  /**
   * Change password
   */
  static changePassword(current: string, newPass: string, newPass2: string) {
    const payload = {
      current,
      new: newPass,
      new2: newPass2,
    }

    return axios.put('users/me/password', payload)
  }

  /**
   * Current session details.
   */
  static async session(): Promise<SessionInfo> {
    // Do not cache this
    return (await axios.get(`users/me/session`)).data
  }

  /**
   * List of all available login methods.
   */
  static async allLoginMethods(fromCache = true): Promise<LoginMethod[]> {
    const methods = await APICache.getOrSet(
      'LoginMethods',
      fromCache,
      async () => {
        const methods: LoginMethod[] = (await axios.get('users/login-methods')).data
        return methods.reduce((obj, method) => ({ ...obj, [method.method]: method }), {})
      },
    )
    return Object.values(methods)
  }

  /**
   * User's identificator.
   */
  id!: number

  /**
   * User's name.
   */
  name!: string

  /**
   * Determine if user is super user.
   */
  is_super!: boolean

  /**
   * Determine if user is member of support team.
   */
  is_support!: boolean

  /**
   * User's language.
   */
  language!: string

  /**
   * Identificator to use when making requests.
   *
   * This is usually the same as {@link User#id}, except for current user,
   * in which case it is {@code me}.
   *
   * @private
   */
  apiId: string

  /**
   * All user's permissions across all teams and system.
   */
  allPermissions: Set<TeamPermission>

  /**
   * Teams for which this users is member of.
  */
  teams: UserTeam[]

  private _cacheSession?: {
    lastUpdate: Date
    data: SessionInfo & { elevated_expires?: Date }
  }

  constructor(data: UserData, apiId?: string) {
    extend(this, data)

    this.apiId = apiId || data.id.toString()

    this.teams = data.teams.map(t => {
      const role = t.role ? new Role(t.role, t.id) : null
      const rolePermissions = role ? role.permissions || [] : []
      const allPermissions = new Set([...t.permissions, ...rolePermissions])
      return {
        ...t,
        role,
        allPermissions,
      }
    })

    let allPermissions: Set<TeamPermission> = new Set()
    for (const team of this.teams) {
      allPermissions = new Set([...allPermissions, ...team.allPermissions]) as Set<TeamPermission>
    }

    this.allPermissions = allPermissions
  }

  /**
   * Check if user is in the specific team.
   */
  isInTeam(team: Team | TeamID): boolean {
    const teamId = team instanceof Team ? team.id : team
    return Boolean(this.teams.find(t => t.id === teamId))
  }

  /**
   * Check if user has specific role in one of his teams.
   */
  hasRole(role: Role | RoleID): boolean {
    const roleId = role instanceof Role ? role.id : role
    return Boolean(this.teams.find(team => team.role?.id === roleId))
  }

  /**
   * Check if user has one or more permissions in specific team.
   *
   * @param permission
   * @param team
   */
  hasPermissionsInTeam(
    permissions: TeamPermission | TeamPermission[],
    team: Team | TeamID,
  ): boolean {
    const teamId = typeof team === 'number' ? team : team.id
    const targetTeam = this.teams.find(t => t.id === teamId)
    if (!targetTeam) return false
    if (typeof permissions === 'string') {
      return targetTeam.allPermissions.has(permissions)
    }
    return permissions.every(p => targetTeam.allPermissions.has(p))
  }

  /**
   * Determine if user is in super session so he have access to hidden UIs.
   *
   * @param {boolean} fromCache - if set to false data will be fetched from the server.
   * Default: true
   */
  async isInSuperMode(fromCache = true): Promise<boolean> {
    if (this.apiId === 'me' && this.is_super) {
      const now = new Date()
      if (
        !fromCache ||
        !this._cacheSession ||
        !(addMinutesToDate(this._cacheSession.lastUpdate, 5) > now)
      ) {
        this._cacheSession = {
          lastUpdate: now,
          data: await User.session(),
        }
      }

      if (this._cacheSession.data.is_elevated) {
        return true
      }
    }
    return false
  }

  /**
   * Change name
   *
   * Require is_super to change other users name.
   * No permissions required to change own name.
   */
  changeName(name: string): Promise<void | User> {
    return elevated(() => {
      return axios.put(`users/${this.apiId}`, { name })
        .then((r: AxiosResponse<UserData>) => {
          this.name = r.data.name
          APICache.updateNested(['Users', this.id.toString()], { name })
          return this
        })
    })
  }

  /**
   * Change language.
   * Users in elevated session can change language of other users.
   * Every user can change his own language settings.
   *
   * @param language ISO code of language
   */
  async changeLanguage(language: string): Promise<void> {
    await elevated(() => axios.put(`users/${this.apiId}`, { language }))
    this.language = language
    APICache.updateNested(['Users', this.id.toString()], { language })
  }

  /**
   * Set or disbale is_support flag for this user.
   * This method is available only for super users.
   */
  async setIsSupportFlag(is_support: boolean) {
    await elevated(() => axios.put(`users/${this.id}`, { is_support }))
    this.is_support = is_support
    APICache.updateNested(['Users', this.id.toString()], { is_support })
  }

  /**
   * User's drafts.
   */
  async drafts(fromCache = true): Promise<DraftAndBookPart[]> {
    const data = await APICache.getOrSetNested(
      ['Users/Drafts', this.apiId],
      fromCache,
      async () => {
        const drafts: DraftAndBookPart[] = (await axios.get(`users/${this.apiId}/drafts`)).data
        return drafts.reduce((obj, draft) => ({ ...obj, [draft.module]: draft }), {})
      },
    )
    return Object.values(data)
  }

  async teamsWithRoles(): Promise<TeamWithRoles[]> {
    const teams = new Map(
      (await Promise.all(this.teams.map(team => Team.load(team.id)))).map(t => [t.id, t]),
    )
    return this.teams.map(userTeam => ({
      ...userTeam,
      ...teams.get(userTeam.id)!,
    }))
  }

  /**
   * Get a list of login methods enabled for a particular user.
   *
   * This endpoint can be used only by current user or in an elevated session.
   */
  async loginMethods(): Promise<LoginMethod[]> {
    // Do not cache this
    const res = await elevated(() => axios.get(`users/${this.apiId}/login-methods`))
    return res.data
  }
}
