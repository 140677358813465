import { Element } from 'slate'

export interface Equation extends Element {
  type: 'equation',
}

export const Equation = {
  isEquation(value: unknown): value is Equation {
    return Element.isElement(value) && value.type === 'equation'
  },
}

export interface Math extends Element {
    type: 'math'
    id: string
    placement: 'line' | 'block'
    /**
     * Equation serialized as MathML
     *
     * This is the content of a `<math>` element. This field is only set if the
     * equation has not been modified.
     */
    mathml: string | null
    /** Equation serialized as LaTeX */
    latex: string,
    namespaces: Record<string, string>
}

export const Math = {
  isMath(value: unknown): value is Math {
    return Element.isElement(value) && value.type === 'math'
  },
}
