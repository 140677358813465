import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Module from '../../../api/module'
import { ModuleLabel } from '../../../store/types'
import { useQuery } from '../../../hooks'
import ModulePreview from '../../../containers/ModulePreview'
import PageNavigation from '../../../components/PageNavigation'
import Section from '../../../components/Section'
import Header from '../../../components/Header'
import LockToggle from '../../../components/LockToggle'
import LimitedUI from '../../../components/LimitedUI'
import Button from '../../../components/ui/Button'
import Icon from '../../../components/ui/Icon'
import ModulesList from './components/ModulesList'
import SearchInput, { SearchQueries } from '../../../components/SearchInput'

import './index.css'

const Modules = () => {
  const [isEditingUnlocked, setIsEditingUnlocked] = React.useState<boolean>(false)
  const [activePreviewId, setActivePreviewId] = React.useState<string>('')
  const [error, setError] = React.useState<Error | null>(null)
  const [filter, setFilter] = React.useState<SearchQueries>({ text: '' })
  const { id, action } = useParams<{ id?: string, action?: string }>()
  const { page } = useQuery<{ page?: string }>()

  const history = useHistory()

  const filterByLabel = (label: ModuleLabel) => {
    setFilter(state => ({
      text: state.text,
      label: state.label && state.label.id === label.id ? undefined : label,
    }))
  }

  React.useEffect(() => {
    let mounted = true

    if (action && action !== 'preview') {
      history.push('/modules')
    } else if (id && action === 'preview') {
      Module.load(id)
        .then(mod => {
          if (mounted) setActivePreviewId(mod.id)
        })
        .catch(e => {
          if (mounted) setError(e)
        })
    }

    return () => {
      mounted = false
      setActivePreviewId('')
      setError(null)
    }
  }, [action, history, id])

  if (error) {
    return (
      <div className="contianer">
        <Section>
          <Header l10nId="modules-view-title" title="Manage modules" />
          <div className="section__content modules modules--error">
            <span className="modules__error-msg">
              {error.message}
            </span>
            <Button l10nId="modules-go-to-list" to="/modules">
              Go to modules list
            </Button>
          </div>
        </Section>
      </div>
    )
  }

  return (
    <div className={`container ${activePreviewId ? 'container--splitted' : ''}`}>
      <Section>
        <Header l10nId="modules-view-title" title="Manage modules">
          <LimitedUI permissions="module:edit">
            <LockToggle onToggle={setIsEditingUnlocked}/>
          </LimitedUI>
        </Header>
        <div className="section__content modules">
          <div className="modules__filters">
            <SearchInput
              onChange={setFilter}
              slowMode
              value={filter}
              placeholder="modules-filter-placeholder"
            />
          </div>
          <PageNavigation
            pagination={Module.pagination()}
            usePageParam={true}
            Component={({ items, onDelete }) => (
              <ModulesList
                modules={items}
                activePreviewId={activePreviewId}
                filter={filter}
                onModuleLabelClick={filterByLabel}
                isEditingUnlocked={isEditingUnlocked}
                afterDelete={onDelete}
              />
            )}
          />
        </div>
      </Section>
      {
        activePreviewId ?
          <Section>
            <Header
              l10nId="modules-view-preview"
              title="Module preview"
            >
              <Button
                id="modules-view-preview-close"
                className="close-button"
                to={`/modules${page ? `?page=${page}` : ''}`}
              >
                <Icon name="close" />
              </Button>
            </Header>
            <div className="section__content">
              <ModulePreview
                moduleId={activePreviewId}
              />
            </div>
          </Section>
          : null
      }
    </div>
  )
}


export default Modules
