import { FluentVariable } from '@fluent/bundle'
import {
  ADD_TASK,
  CLOSE_CONFIRM_DIALOG,
  REMOVE_TASK,
  SET_ACTIVE_TASK,
  SET_AVAILABLE_LOCALES,
  SET_EXPANDED_GROUPS,
  SET_LOCALE,
  SET_SELECTED_TEAMS,
  SHOW_CONFIRM_DIALOG,
} from '../../store/constants'
import { ExpandedGroups, GroupNumber } from '../../store/types'
import Button from '../../components/ui/Button'

export interface SetLocale {
  type: SET_LOCALE,
  data: string[],
}

export interface SetAvailableLocales {
  type: SET_AVAILABLE_LOCALES,
  data: string[],
}

export type ConfirmDialogOptions = {
  title: string
  buttons?: {[key: string]: string | typeof Button}
  size?: 'small' | 'medium' | 'big'
  content?: string | JSX.Element
  buttonsPosition?: 'default' | 'center' | 'start' | 'end'
  showCloseButton?: boolean
  closeOnBgClick?: boolean
  closeOnEsc?: boolean
  callback?: (key: string) => any
  vars?: Record<string, FluentVariable>
}

export interface ShowConfirmDialog {
  type: SHOW_CONFIRM_DIALOG
  data: ConfirmDialogOptions
}

export interface CloseConfirmDialog {
  type: CLOSE_CONFIRM_DIALOG
}

export interface SetSelectedTeams {
  type: SET_SELECTED_TEAMS
  data: number[]
}

export interface SetExpandedGroups {
  type: SET_EXPANDED_GROUPS
  data: ExpandedGroups
}

export type AppAction =
  SetLocale |
  SetAvailableLocales |
  ShowConfirmDialog |
  CloseConfirmDialog |
  SetSelectedTeams |
  SetExpandedGroups

export const setLocale = (locale: string[]): SetLocale => ({
  type: SET_LOCALE,
  data: locale,
})

export const setAvailableLocales = (locales: string[]): SetAvailableLocales => ({
  type: SET_AVAILABLE_LOCALES,
  data: locales,
})

export const showConfirmDialog = (options: ConfirmDialogOptions): ShowConfirmDialog => ({
  type: SHOW_CONFIRM_DIALOG,
  data: options,
})

export const closeConfirmDialog = (): CloseConfirmDialog => ({
  type: CLOSE_CONFIRM_DIALOG,
})

export const setSelectedTeams = (teams: number[]): SetSelectedTeams => {
  localStorage.setItem('selectedTeams', JSON.stringify(teams))
  return {
    type: SET_SELECTED_TEAMS,
    data: teams,
  }
}

export const syncExpandedGroups = (): SetExpandedGroups => {
  const expandedGroups: ExpandedGroups = JSON.parse(localStorage.getItem('expandedGroups') || '{}')

  return {
    type: SET_EXPANDED_GROUPS,
    data: expandedGroups,
  }
}

export const setExpandedGroup = (screenUrl: string, group: GroupNumber): SetExpandedGroups => {
  const expandedGroups: ExpandedGroups = JSON.parse(localStorage.getItem('expandedGroups') || '{}')

  if (expandedGroups[screenUrl]) {
    expandedGroups[screenUrl] = [...expandedGroups[screenUrl], group]
  } else {
    expandedGroups[screenUrl] = [group]
  }

  localStorage.setItem('expandedGroups', JSON.stringify(expandedGroups))

  return {
    type: SET_EXPANDED_GROUPS,
    data: expandedGroups,
  }
}

export const removeExpandedGroup = (screenUrl: string, group: GroupNumber): SetExpandedGroups => {
  const expandedGroups: ExpandedGroups = JSON.parse(localStorage.getItem('expandedGroups') || '{}')

  if (expandedGroups[screenUrl] && expandedGroups[screenUrl].includes(group)) {
    expandedGroups[screenUrl] = expandedGroups[screenUrl].filter(g => g !== group)
  }

  if (expandedGroups[screenUrl] && expandedGroups[screenUrl].length === 0) {
    delete expandedGroups[screenUrl]
  }

  localStorage.setItem('expandedGroups', JSON.stringify(expandedGroups))

  return {
    type: SET_EXPANDED_GROUPS,
    data: expandedGroups,
  }
}

export const addTask = (task: string) => ({
  type: ADD_TASK,
  data: task,
})

export const removeTask = (task: string) => ({
  type: REMOVE_TASK,
  data: task,
})

export const setActiveTask = (task: string | null) => ({
  type: SET_ACTIVE_TASK,
  data: task,
})
