import * as React from 'react'
import Select from 'react-select'
import { Localized } from '@fluent/react'
import Process, { ProcessSingleStep } from '../../api/process'

interface StepSelectorProps {
  isClearable?: boolean
  isDisabled?: boolean
  onChange: (step: ProcessSingleStep | null) => void
  process?: Process | null
  steps?: ProcessSingleStep[]
  filter?: (step: ProcessSingleStep) => boolean,
}

export default function StepSelector(props: StepSelectorProps) {
  const {
    isClearable = true,
    isDisabled = false,
    onChange,
    process,
    filter = selectAll,
  } = props

  const [selection, setSelection] = React.useState(null)
  const [steps, setSteps] = React.useState<ProcessSingleStep[]>(props.steps || [])

  const onSelect = React.useCallback(option => {
    setSelection(option)
    onChange(option?.value || null)
  }, [onChange])

  const options = React.useMemo(
    () => steps
      .filter(filter)
      .map(step => ({
        label: step.name,
        value: step,
      })),
    [steps, filter],
  )

  React.useEffect(() => {
    if (process != null) {
      process.steps().then(setSteps)
    }
  }, [process])

  // Clear selection when options changed.
  React.useEffect(() => {
    if (!options.includes(selection!)) {
      setSelection(null)
    }
  }, [selection, options])

  const plaeholderId = options.length
    ? 'step-selector-placeholder'
    : 'step-selector-no-steps'

  return (
    <div className="step-selector">
      <span className="step-selector__title">
        <Localized id="step-selector-title">
          Select step:
        </Localized>
      </span>
      <Localized id={plaeholderId} attrs={{ placeholder: true }}>
        <Select
          className="react-select"
          placeholder="Select..."
          value={selection}
          options={options}
          onChange={onSelect}
          isClearable={isClearable}
          isDisabled={isDisabled || options.length === 0}
        />
      </Localized>
    </div>
  )
}

function selectAll(): boolean {
  return true
}
