import * as React from 'react'
import { Localized } from '@fluent/react'
import { useLoadRoles, useLoadUsers } from '../../../api/hooks'
import { TeamID } from '../../../api/team'
import { ProcessSlot } from '../../../api/process'
import Avatar from '../../../components/ui/Avatar'
import Button from '../../../components/ui/Button'
import { SlotId, UserId } from '../../../containers/BeginProcess'
import Tooltip from '../../../components/ui/Tooltip'

interface SlotInfoProps {
  team: TeamID
  slot: ProcessSlot
  slots: Map<SlotId, UserId[]>
  onAssignUser: (slot: ProcessSlot) => void
  onUnassignUser: (slot: ProcessSlot) => void
}

const SlotInfo = ({ team, slot, slots, onAssignUser, onUnassignUser }: SlotInfoProps) => {
  const [roles] = useLoadRoles(team, slot.roles)
  const rolesNames = React.useMemo(
    () => (roles || []).map(r => r.name).join(', '),
    [roles])

  return (
    <div className="update-slots__slot">
      <div className="update-slots__info">
        <span className="update-slots__name">
          <Localized
            id="update-slots-name"
            vars={{
              name: slot.name,
              roles: rolesNames,
            }}
          >
            [slot name] for role: [role name]
          </Localized>
        </span>
        <UsersInSlot slots={slots} slot={slot} />
      </div>
      {
        slots.has(slot.id) ?
          <Button l10nId="update-slots-unassign-user" clickHandler={() => onUnassignUser(slot)} type="danger">
            Unassign user
          </Button>
          : null
      }
      <Button l10nId="update-slots-assign-user" clickHandler={() => onAssignUser(slot)}>
        Select user
      </Button>
    </div>
  )
}

export default SlotInfo

const UsersInSlot = ({ slots, slot }: { slots: Map<SlotId, UserId[]>, slot: ProcessSlot }) => {
  const usersIds = slots.get(slot.id) || []
  const [users] = useLoadUsers(usersIds)

  if (!users || users.length === 0) return null

  const usersNames = users
    .slice(1)
    .map(u => u.name)
    .join(', ')

  return <>
    <Avatar
      size="small"
      user={users[0]}
      withName={true}
    />
    {
      users.length > 1
        ?
        <Tooltip l10nId="update-slots-users" l10nVars={{ names: usersNames }}>
          <Localized id="update-slots-more-users" vars={{ count: users.length - 1 }}>
            {`and { $count } more`}
          </Localized>
        </Tooltip>
        : null
    }
  </>
}
