import * as React from 'react'
import { BulletedList, List } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
// import { Localized } from '@fluent/react'

interface ListProps extends RenderElementProps {
  element: List,
}

export default function ListComp(props: ListProps) {
  const { attributes, children, element } = props
  const Tag = BulletedList.isBulletedList(element) ? 'ul' : 'ol'
  const style = BulletedList.isBulletedList(element) ? element.bullet : element.numberStyle

  return (
    <Tag {...attributes} className="list" data-style={style}>
      {children}
    </Tag>
  )
}
