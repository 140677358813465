import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import { removeTask, setActiveTask } from '../../../../store/actions/tasks'
import { TaskData } from '../../../../store/reducers/tasks'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/ui/Button'
import Icon from '../../../../components/ui/Icon'
import Error from '../Error'
import TaskState from '../TaskState'

export default function ActiveTask() {
  const dispatch = useDispatch()
  const { tasks, activeTask } = useSelector(state => state.tasks)

  const modal = React.useRef<Modal>(null)

  React.useEffect(() => {
    if (activeTask == null) {
      modal.current?.close()
    } else {
      modal.current?.open()
    }
  }, [modal, activeTask])

  const onClose = React.useCallback(() => {
    dispatch(setActiveTask(null))
  }, [dispatch])

  // If activeTask == null then tasks.get(activeTask) == null, no need to check
  // it separately
  const task = tasks.get(activeTask!)

  return (
    <Modal
      ref={modal}
      showCloseButton={false}
      onClose={onClose}
    >
      <Task id={activeTask} task={task} />
    </Modal>
  )
}

interface TaskProps {
  id: string | null
  task: TaskData | undefined
}

function Task({ id, task }: TaskProps) {
  const dispatch = useDispatch()

  const onClose = React.useCallback(() => {
    dispatch(setActiveTask(null))

    if (id != null && task?.state === 'finished') {
      dispatch(removeTask(id))
    }
  // eslint-disable-next-line
  }, [id, task])

  if (task == null) return null

  const { state, progress, message, errors } = task
  const buttonId = state === 'finished' ? 'task-active-close' : 'task-active-minimize'

  return (
    <div className="active-task">
      {/* TODO: task name? */}
      <TaskState state={state} progress={progress} message={message} />
      <div className="tasks__errors">
        {errors.map((error, index) => <Error key={index} error={error} />)}
      </div>
      <Button id={buttonId} className="active-task__close" clickHandler={onClose}>
        <Icon size="small" name={state === 'finished' ? 'close' : 'arrow-down'}/>
        <Localized id={buttonId}>
          {state === 'finished' ? 'Close' : 'Minimise'}
        </Localized>
      </Button>
    </div>
  )
}
