import * as React from 'react'
import { Localized } from '@fluent/react'
import Team from '../../../../../api/team'
import store from '../../../../../store'
import { addAlert } from '../../../../../store/actions/alerts'
import LimitedUI from '../../../../../components/LimitedUI'
import Input from '../../../../../components/ui/Input'

import './index.css'

interface AddTeamProps {
  onAdd: (team: Team) => void
}

const AddTeam = (props: AddTeamProps) => {
  const [name, setName] = React.useState('')

  const addTeam = async (e: React.FormEvent) => {
    e.preventDefault()
    if (name.length === 0) return

    await Team.create(name)
      .then(team => {
        store.dispatch(addAlert('success', 'teams-add-team-success', { name }))
        props.onAdd(team)
      })
      .catch(() => {
        store.dispatch(addAlert('error', 'teams-add-team-error'))
      })

    setName('')
  }

  return (
    <LimitedUI onlySuperElevated={true}>
      <form className="manage-team" onSubmit={addTeam}>
        <Input
          type="text"
          value={name}
          l10nId="teams-manage-team-placeholder"
          onChange={setName}
          required
        />
        <Localized id="teams-manage-team-add" attrs={{ value: true }}>
          <input
            disabled={!name.length}
            type="submit"
            value="Add team"
          />
        </Localized>
      </form>
    </LimitedUI>
  )
}

export default AddTeam
