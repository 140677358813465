import { SlotPermission } from '../../../../api/process'
import { CurrentDraftAction } from './actions'
import {
  SET_CURRENT_DRAFT_LANG,
  SET_CURRENT_DRAFT_PERMISSIONS,
  SET_READ_ONLY,
} from './constants'

export interface CurrentDraftState {
  currentDraftLang: string
  currentDraftPermissions: SlotPermission[]
  readOnly: boolean
}

export const initialState: CurrentDraftState = {
  currentDraftLang: 'en',
  currentDraftPermissions: [],
  readOnly: false,
}

export default function reducer(
  // eslint-disable-next-line default-param-last
  state: CurrentDraftState = initialState,
  action: CurrentDraftAction,
): CurrentDraftState {
  switch (action.type) {
  case SET_CURRENT_DRAFT_LANG:
    return {
      ...state,
      currentDraftLang: action.data,
    }

  case SET_CURRENT_DRAFT_PERMISSIONS:
    return {
      ...state,
      currentDraftPermissions: action.data,
    }

  case SET_READ_ONLY:
    return {
      ...state,
      readOnly: action.data,
    }

  default:
    return state
  }
}
