import * as Sentry from '@sentry/react'
import { ReactLocalization } from '@fluent/react'
import { Element, Node } from 'slate'
import { Admonition, CrossReference } from 'cnx-designer'
import XrefComp from './render'

export default XrefComp

const getXrefs = (nodes: Node[]) => {
  const xrefs: CrossReference[] = []

  for (const node of nodes) {
    if (CrossReference.isCrossReference(node)) {
      xrefs.push(node)
    } else if (Element.isElement(node) && node.children.length) {
      xrefs.push(...getXrefs(node.children))
    }
  }

  return xrefs
}

export function collectForeignDocuments(document: Node[]): string[] {
  const documents = new Set<string>()

  for (const xref of getXrefs(document)) {
    if (xref.document) {
      documents.add(xref.document)
    }
  }

  return Array.from(documents)
}

interface Target {
  key: string,
  type: string,
}

export function renderXref(
  l10n: ReactLocalization,
  target: Target,
  counters: Map<string, number> | Iterable<[string, number]>,
  case_: string = 'nominative',
): string {
  const key = createL10nKeyForXrefLabel(target.type)
  const args = { case: case_ }

  for (const [name, value] of counters as IterableIterator<[string, number]>) {
    args[name] = value
  }

  return l10n.getString(key, args)
}

export function createL10nKeyForXrefLabel(node: Node | string) {
  const type = typeof node === 'string'
    ? node
    : Admonition.isAdmonition(node)
      ? node.kind
      : node.type

  let key = 'xref-label-unknown'
  switch (type) {
  case 'note':
    key = 'xref-label-note'
    break
  case 'important':
    key = 'xref-label-important'
    break
  case 'warning':
    key = 'xref-label-warning'
    break
  case 'tip':
    key = 'xref-label-tip'
    break
  case 'equation':
    key = 'xref-label-equation'
    break
  case 'exercise':
    key = 'xref-label-exercise'
    break
  case 'solution':
  case 'exercise_solution':
    key = 'xref-label-exercise_solution'
    break
  case 'commentary':
  case 'exercise_commentary':
    key = 'xref-label-exercise_commentary'
    break
  case 'problem':
  case 'exercise_problem':
    key = 'xref-label-exercise_problem'
    break
  case 'figure':
    key = 'xref-label-figure'
    break
  case 'theorem':
  case 'lemma':
  case 'corollary':
  case 'law':
  case 'proposition':
  case 'rule':
    key = 'xref-label-rule'
    break
  case 'statement':
  case 'rule_statement':
    key = 'xref-label-rule_statement'
    break
  case 'proof':
  case 'rule_proof':
    key = 'xref-label-rule_proof'
    break
  case 'example':
  case 'rule_example':
    key = 'xref-label-rule_example'
    break
  case 'table':
    key = 'xref-label-table'
    break

  default:
    Sentry.captureMessage(
      `Couldn't create a xref label for element: ${JSON.stringify(node)}`,
      'warning',
    )
    break
  }

  return key
}
