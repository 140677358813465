import React from 'react'
import { useSlate } from 'slate-react'
import { LOCALIZATION } from '../../../../constants'
import { getRefTargets } from '../../../../../../../containers/LocalReferenceTargets/utils'
import { renderXref } from '../../../../plugins/Xref'
import scrollToTarget from '../../../../../../../helpers/scrollToTarget'

export interface OutlineElementProps {
  children?: any
  className?: string
  id?: string | undefined
  icon?: JSX.Element | null
  toggler?: JSX.Element | null // passed from ExpandableOutlineElement
  expanded?: boolean
}

const OutlineElement
= ({ children, className, expanded, id, icon = null, toggler = null, ...props }: OutlineElementProps) => {
  const { document: l10n } = React.useContext(LOCALIZATION)
  const editor = useSlate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const targets = React.useMemo(() => getRefTargets(editor), [editor.children.length])
  const target = id && targets.find(t => t.id === id)

  const onClickHandler: React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()
    if (id) scrollToTarget("#"+id, -50)
  }

  return (
    <div
      id={`${id}--outline`}
      onClick={onClickHandler}
      className={
        expanded ?
          `outline-element outline-element-with-invisible-list ${className ? className : ''}` :
          `outline-element ${className ? className : ''}`
      }
      {...props}
    >
      {toggler}
      {icon && <div className='outline-element--icon'>{icon}</div>}
      {target
        ? <span className='outline-element--label'>
          {renderXref(l10n, { key: target.id, type: target.type }, target.counters)}
        </span>
        : null}
      {children}
    </div>)
}

export default OutlineElement
