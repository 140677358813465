import axios from '../config/axios'
import Team from './team'

const ERR_UNSUPPORTED = 1003

export type State =
  | 'created'
  | 'scheduled'
  | 'running'
  | 'finished'

export interface StateChange {
  state: State
}

export interface Progress {
  progress: number | null
  message: string
}

export interface ErrorMessage {
  error: ErrorData
}

export interface ErrorData {
  error: string
  raw: string
  message: string
  data: unknown
}

export type Message = StateChange | Progress | ErrorMessage

export default class Task extends EventTarget {
  static connect(id: string): Task {
    const prefix = window.location.protocol === 'https:' || process.env.PRODUCTION ? 'wss' : 'ws'
    const uri = `${prefix}://${window.location.host}/api/v1/tasks/${id}`
    const ws = new WebSocket(uri)
    return new Task(id, ws)
  }

  static async import(team: Team, file: File): Promise<string> {
    const data = new FormData()
    data.append('team', team.id.toString())
    data.append("file", file)

    const rsp = await axios.post('import', data)
    return rsp.data.__task
  }

  private constructor(
    public readonly id: string,
    private ws: WebSocket,
  ) {
    super()

    ws.onerror = this._onerror.bind(this)
    ws.onmessage = this._onmessage.bind(this)
    ws.onclose = this._onclose.bind(this)
  }

  close() {
    this.ws.close()
  }

  /** Submit data to the server */
  send(data: unknown) {
    this.ws.send(JSON.stringify(data))
  }

  private _onerror(ev: Event) {
    this.dispatch(new Event(ev.type, ev))
  }

  private _onmessage(ev: MessageEvent) {
    if (typeof ev.data !== 'string') {
      this.ws.close(ERR_UNSUPPORTED)
      return
    }

    try {
      const message = JSON.parse(ev.data)
      this.dispatch(new CustomEvent('message', { detail: message }))
    } catch (ex) {
      this.ws.close(1007)
    }
  }

  private _onclose(ev: CloseEvent) {
    this.dispatch(new CloseEvent(ev.type, ev))
  }

  private dispatch(event: Event) {
    const handler = this['on' + event.type]

    if (handler != null) {
      handler.call(this, event)
    }

    this.dispatchEvent(event)
  }
}
