import { match } from 'react-router'
import { History } from 'history'
import { Localized } from '@fluent/react'
import { Team } from '../../../../../api'
import Header from '../../../../../components/Header'
import Load from '../../../../../components/Load'
import PageNavigation from '../../../../../components/PageNavigation'
import Section from '../../../../../components/Section'
import MembersManager from '../MembersManager'
import RolesManager from '../RolesManager'
import TeamSettings from '../TeamSettings'

interface TeamEditorLoaderProps {
  match: match<{ id: string }>
  history: History
}

interface TeamEditorProps {
  team: Team,
  history: History
}

async function loader({ match, history }: TeamEditorLoaderProps) {
  const team = await Team.load(Number(match.params.id), false)
  if (!team) history.push('/404')
  return { team }
}

function TeamEditor({ team, history }: TeamEditorProps) {
  return <>
    <Header title={team.name} />

    <TeamSettings team={team} />

    <div className="container container--splitted">
      <Section className="roles">
        <h2 className="teams__title teams__title--large">
          <Localized id="teams-section-manage-roles-title">
            Roles
          </Localized>
        </h2>

        <PageNavigation
          pagination={team.roles}
          Component={({ items, onAdd, onDelete }) => (
            <RolesManager
              roles={items}
              team={team}
              onAdd={onAdd}
              onDelete={onDelete}
            />
          )}
        />
      </Section>

      <Section className="members">
        <h2 className="teams__title teams__title--large">
          <Localized id="teams-section-manage-members-title">
            Members
          </Localized>
        </h2>

        <PageNavigation
          pagination={team.members()}
          Component={({ items, onDelete, onUpdate }) => (
            <MembersManager
              members={items}
              team={team}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          )}
        />
      </Section>
    </div>
  </>
}

export default Load(loader)(TeamEditor)
