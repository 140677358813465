import * as React from 'react'
import { Localized } from '@fluent/react'
import User from '../../../api/user'
import Team from '../../../api/team'
import { ProcessSlot, ProcessStructure } from '../../../api/process'
import { SlotId, UserId } from '../index'
import UsersList from '../../../containers/UsersList'
import SlotInfo from './SlotInfo'
import Dialog from '../../../components/ui/Dialog'

import './index.css'

interface ConfigureSlotsProps {
  structure: ProcessStructure
  team: Team
  onChange: (slots: Map<SlotId, UserId>) => any
}

const ConfigureSlots = (props: ConfigureSlotsProps) => {
  const [slots, setSlots] = React.useState<Map<SlotId, UserId>>(new Map())
  const [currentSlot, setCurrentSlot] = React.useState<ProcessSlot | null>(null)
  const [showAssignUser, setShowAssingUser] = React.useState(false)

  const handleUserClick = (user: User) => {
    if (currentSlot) {
      const newSlots = new Map(slots).set(currentSlot.id, user.id)
      props.onChange(newSlots)
      setSlots(newSlots)
      closeAssignUserDialog()
    }
  }

  const unassignUser = (slot: ProcessSlot) => {
    const newSlots = new Map(slots)
    newSlots.delete(slot.id)
    props.onChange(newSlots)
    setSlots(newSlots)
  }

  const showAssignUserDialog = (slot: ProcessSlot) => {
    setCurrentSlot(slot)
    setShowAssingUser(true)
  }

  const closeAssignUserDialog = () => {
    setShowAssingUser(false)
  }

  return (
    <div className="configure-slots">
      {
        showAssignUser && currentSlot ?
          <Dialog
            l10nId="begin-process-assign-user-title"
            placeholder="Select user for this slot."
            size="medium"
            onClose={closeAssignUserDialog}
          >
            <UsersList
              allowedRoles={currentSlot.roles}
              teams={[props.team]}
              onUserClick={handleUserClick}
            />
          </Dialog>
          : null
      }
      <h3>
        <Localized id="begin-process-slots-title">
          Configure slots:
        </Localized>
      </h3>
      {
        props.structure.slots.map(s => (
          <SlotInfo
            key={s.id}
            slot={s}
            slots={slots}
            onAssignUser={showAssignUserDialog}
            onUnassignUser={unassignUser}
          />
        ))
      }
    </div>
  )
}

export default ConfigureSlots
