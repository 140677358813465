// Based on: https://gist.github.com/heyitsaamir/6089165fe6789eee170b46809cb61fc6

import React from 'react'
import { Node, Range } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'

const CurrentSelectionContext = React.createContext<{
  current: Range | undefined
}>({ current: undefined })

export function withCurrentSelection<T>(Component: React.ComponentType<T>) {
  // eslint-disable-next-line react/display-name
  return React.forwardRef((props: T, ref) => {
    const editor = useSlate()
    const currentSelection = React.useRef<Range | undefined>(editor.selection || undefined)

    if (ReactEditor.isFocused(editor) || editor.selection) {
      if (
        (!currentSelection.current && editor.selection)
        || (currentSelection.current && !editor.selection)
        || (
          currentSelection.current
          && editor.selection
          && !Range.equals(currentSelection.current, editor.selection)
        )
      ) {
        currentSelection.current = editor.selection || undefined
      }
    }

    if (currentSelection.current && !Node.has(editor, currentSelection.current.anchor.path)) {
      const selectionData = {
        path: currentSelection.current.anchor.path.slice(-1),
        offset: 0,
      }
      currentSelection.current = {
        anchor: selectionData,
        focus: selectionData,
      }
    }

    return (
      <CurrentSelectionContext.Provider value={{ current: currentSelection.current }}>
        <Component {...props} ref={ref} />
      </CurrentSelectionContext.Provider>
    )
  })
}

export const useCurrentSelection = () => React.useContext(CurrentSelectionContext).current
