import * as React from 'react'
import { Localized } from '@fluent/react'
import { Link } from 'react-router-dom'
import { LocationDescriptor } from 'history'

import './index.css'

export type ButtonHandler = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void

interface BaseButtonProps {
  type?: 'default' | 'danger'
  className?: string
  isDisabled?: boolean
  dataId?: string
  title?: string
  withBorder?: boolean
  [args: string]: any
}

interface LinkButtonProps extends BaseButtonProps {
  to: LocationDescriptor
}

interface ClickButtonProps extends BaseButtonProps {
  clickHandler: ButtonHandler
}

interface LocalizedButtonProps extends ClickButtonProps {
  id?: string
  l10nId: string
  vars?: Record<string, unknown>
  children?: React.ReactNode
}

interface ComponentBasedButtonProps extends ClickButtonProps {
  id: string
  children: React.ReactNode
}

type ButtonProps = LinkButtonProps | LocalizedButtonProps | ComponentBasedButtonProps

type Ref = ((instance: HTMLButtonElement | null) => void)
  | React.MutableRefObject<HTMLButtonElement | null>
  | null

const Button = ({
  id,
  l10nId,
  vars,
  type,
  className,
  isDisabled,
  children,
  clickHandler,
  to,
  dataId,
  title,
  withBorder,
  ...args
}: ButtonProps, ref: Ref) => {
  const classes: string[] = ['button']

  if (type) classes.push(`button--${type}`)
  if (className) classes.push(className)
  if (withBorder) classes.push('button--borderd')
  if (isDisabled) classes.push('button--disabled')

  const onClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (isDisabled) e.preventDefault()
    if (clickHandler) clickHandler(e)
    e.stopPropagation()
  }, [isDisabled, clickHandler])

  const content = l10nId == null
    ? children
    : <Localized id={l10nId} vars={vars}>{children}</Localized>

  if (to) {
    return (
      <Link
        id={id ?? l10nId}
        to={to}
        className={classes.join(' ')}
        data-id={dataId ? dataId : null}
        onClick={onClick}
      >
        {content}
      </Link>
    )
  }

  return (
    <button
      ref={ref}
      id={id ?? l10nId}
      className={classes.join(' ')}
      onClick={onClick}
      disabled={isDisabled}
      type="button"
      data-id={dataId ? dataId : null}
      title={title ? title : ''}
      {...args}
    >
      {content}
    </button>
  )
}

// This cast is not quite correct, as React.forwardRef returns a special object
// which, while behaves like a component in JSX, is not actually a component.
// This is required however to make inference work correctly for ButtonHandlers
// passed as closures (clickHandler={e => ...}).
export default React.forwardRef(Button) as React.ComponentType<ButtonProps>
