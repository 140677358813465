import * as React from 'react'
import { useSelector } from 'react-redux'
import { Resource } from '../../../../../api'
import AddResourceDialog from '../AddResourceDialog'
import ResourceCard from '../ResourceCard'

import './index.css'

interface ResourcesListProps {
  resources: Resource[]
  isEditingUnlocked: boolean
  showAddResource: boolean
  onCloseAddResource: () => void
  directory?: Resource
  onAdd: (resource: Resource) => void
  onDelete: (resource: Resource) => void
}

const ResourcesList = ({
  resources,
  isEditingUnlocked,
  showAddResource,
  onCloseAddResource,
  directory,
  onAdd,
  onDelete,
}: ResourcesListProps) => {
  const selectedTeams = useSelector(state => state.app.selectedTeams)

  return <div className="resources__resources-cards">
    {
      resources.map(r => selectedTeams.includes(r.team) && (
        <ResourceCard
          key={r.id}
          resource={r}
          isEditingUnlocked={isEditingUnlocked}
          afterResourceRemove={onDelete}
        />
      ))
    }
    <AddResourceDialog
      show={showAddResource}
      directory={directory}
      onClose={onCloseAddResource}
      afterCreate={onAdd}
    />
  </div>
}

export default ResourcesList
