import * as React from 'react'
import { Foreign } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

interface ForeignProps extends RenderElementProps {
  element: Foreign
}

const ForeignComp = ({ attributes, children, element }: ForeignProps) => {
  const lang = (element as Foreign).language || ''

  let flagEmoji = "❓"
  if (lang) {
    const chars = [...lang.toUpperCase()].map(c => c.charCodeAt(0) + OFFSET)
    flagEmoji = String.fromCodePoint(...chars)
  }

  return (
    <span
      {...attributes}
      className="foreign"
      data-flag={flagEmoji}
      id={element.id as string | undefined}
    >
      <span contentEditable={false} />
      {children}
    </span>
  )
}

export default ForeignComp
