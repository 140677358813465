import * as React from 'react'
import { Editor, Node, NodeEntry, Transforms } from 'slate'
import { RenderElementProps, useSlate } from 'slate-react'
import { Footnote as CnxFootnote } from 'cnx-designer'

export interface Footnote extends CnxFootnote {
  collapse?: unknown
  id?: string
}

interface FootnoteProps extends RenderElementProps {
  element: Footnote
}

const FootnoteComp = ({ attributes, children, element }: FootnoteProps) => {
  const editor = useSlate()

  const onClick = () => {
    if (!element.collapse) return

    let footnote: NodeEntry<Footnote> | undefined
    for (const entry of Node.descendants({ children: editor.children })) {
      if (entry[0] === element) {
        footnote = entry as NodeEntry<Footnote>
        break
      }
    }
    if (footnote) {
      Transforms.select(editor, Editor.start(editor, footnote[1]))
      Transforms.setNodes(editor, { collapse: false }, { at: footnote[1] })
    }
  }

  return (
    <span
      className={element.collapse ? 'footnote collapse' : 'footnote'}
      {...attributes}
      id={element.id}
      onClick={onClick}
    >
      <span contentEditable={false}/>
      {children}
    </span>
  )
}

export default FootnoteComp
