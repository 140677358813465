import { Localized } from '@fluent/react'
import Dialog from '../../../../../../../components/ui/Dialog'
import Button from '../../../../../../../components/ui/Button'
import ExampleInvalidReference from './ExampleInvalidReference'
import './index.css'

interface InvalidReferencesDialogProps {
  references: Element[]
  onClose: () => void
}

const InvalidReferencesDialog = (props: InvalidReferencesDialogProps) => {
  if (props.references.length === 0) return null

  return (
    <Dialog
      size="medium"
      l10nId="step-changer-invalid-references-dialog-title"
      placeholder="Please fix invalid references before changing step"
      onClose={props.onClose}
      showCloseButton={false}
    >
      <div className="invalid-references-dialog">
        <Localized
          id="step-changer-invalid-references-dialog-info"
          vars={{ references: props.references.length }}
        >
          {`There are { $references } invalid references in the document.`}
        </Localized>
        <ExampleInvalidReference />
      </div>
      <div className="dialog__buttons">
        <div className="step-changer__dialog-controls">
          <Button l10nId="step-changer-invalid-references-dialog-ok" clickHandler={props.onClose}>
            Ok
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default InvalidReferencesDialog
