import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import APICache from './apicache'
import Pagination from './pagination'
import Role from './role'
import { TeamID, TeamPermission } from './team'
import { elevated, extend } from './utils'

/**
 * TeamMember data.
 */
export type TeamMemberData = {
  user: number
  permissions: TeamPermission[]
  role: Role | null
}

export type NewTeamMemberData = {
  user: number
  permissions: TeamPermission[]
  role?: number
}

export type TeamMemberUpdateData = {
  permissions?: TeamPermission[]
  role?: number | null
}

export default class TeamMember {
  /**
   * Fetch specific team member from the server.
   */
  static async load(team: TeamID, id: number, fromCache = true): Promise<TeamMember> {
    const teamId = team.toString()
    const userId = id.toString()
    const cached = APICache.getNested(['TeamMembers', teamId])
    if (fromCache && cached && cached[teamId] && cached[teamId][userId]) {
      return cached[teamId][userId]
    }
    const member = new TeamMember((await axios.get(`teams/${team}/members/${id}`)).data, team)
    APICache.setNested(
      ['TeamMembers', teamId],
      { ...cached, [userId]: member })
    return member
  }

  static pagination(team: TeamID, fromCache = true): Pagination<TeamMember> {
    return APICache.getOrSetNestedSync(
      ['Pagination', `team-${team}-members`],
      fromCache,
      new Pagination(
        `teams/${team}/members`,
        (data: TeamMemberData) => new TeamMember(data, team),
        members => APICache.updateNested(
          ['TeamMembers', team.toString()],
          members.reduce((obj, member) => ({ ...obj, [member.user.toString()]: member }), {}),
        ),
      ),
    )
  }

  /**
   * Add member to the team. He will receive email invitation.
   *
   * This function requires member:add permission.
   */
  static add(team: TeamID, data: NewTeamMemberData): Promise<AxiosResponse> {
    return elevated(() => axios.post(`teams/${team}/members`, data))
  }

  /**
   * ID of Team this is member of.
   */
  team: TeamID

  /**
   * User ID.
   */
  user!: number

  /**
   * Permissions this member has.
   */
  permissions!: TeamPermission[]

  /**
   * Role this member has.
   */
  role!: Role | null

  constructor(data: TeamMemberData, team: TeamID) {
    extend(this, data)

    this.team = team
  }

  /**
   * Update a team member.
   *
   * This function requires member:edit-permissions or / and member:assign-role permissions.
   *
   * @param data - object with data to update
   */
  async update(data: TeamMemberUpdateData): Promise<TeamMember> {
    const teamId = this.team.toString()
    const userId = this.user.toString()
    const member = new TeamMember(
      (await elevated(() => axios.put(`teams/${teamId}/members/${userId}`, data))).data,
      this.team)
    APICache.updateNested(
      ['TeamMembers', teamId],
      { [userId]: member })
    return member
  }

  /**
   * Delete a member from team.
   * This function requires member:remove permission.
   */
  async delete(): Promise<void> {
    const teamId = this.team.toString()
    await elevated(() => axios.delete(`teams/${this.team}/members/${this.user}`))
    APICache.invalidate(['TeamMembers', teamId])
  }
}
