import * as React from 'react'
import Task, { State } from '../../../api/task'
import Load from '../../../components/Load'
import TaskState from '../../../containers/Tasks/components/TaskState'
import Error, { AllErrors } from './components/Error'
import ResolutionSelector from './components/ResolutionSelector'
import './index.css'

interface ImportProps {
  task: Task
}

async function loader({ match }: { match: { params: { id: string } } }) {
  const task = await Task.connect(match.params.id)
  return { task }
}

function Import({ task }: ImportProps) {
  const [state, setState] = React.useState<State | 'fetch-error'>('scheduled')
  const [progress, setProgress] = React.useState(null)
  const [message, setMessage] = React.useState('')
  const [data, setData] = React.useState(null)
  const [error, setError] = React.useState<AllErrors | null>(null)

  const onMessage = React.useCallback(ev => {
    if ('state' in ev.detail) {
      setState(ev.detail.state)
    } else if ('message' in ev.detail) {
      setProgress(ev.detail.progress)
      setMessage(ev.detail.message)
      setData(ev.detail.data)
    } else if ('error' in ev.detail) {
      setError(ev.detail.error)
    } else {
      // TODO:
      console.error('unknown message:', ev.detail)
    }
  }, [setState, setProgress, setMessage, setError])

  const onError = React.useCallback(ex => {
    setState('fetch-error')
  }, [setState])

  React.useEffect(() => {
    task.addEventListener('message', onMessage)
    task.addEventListener('error', onError)

    return () => {
      task.removeEventListener('message', onMessage)
      task.removeEventListener('error', onError)
    }
  }, [task, onMessage])

  const submitResolutions = React.useCallback(resolutions => {
    task.send(resolutions)
    setData(null)
  }, [task])

  return (
    <div className="import">
      { data == null && <TaskState state={state} progress={progress} message={message} /> }
      { error != null && <Error error={error} /> }
      { data != null && <ResolutionSelector task={task} data={data!} submitResolutions={submitResolutions} /> }
    </div>
  )
}

export default Load(loader)(Import)
