import * as React from 'react'
import { useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import { Ticket as TicketClass } from '../../../../api'
import { confirmDialog } from '../../../../helpers'
import TicketOpenDate from '../TicketOpenDate'
import Avatar from '../../../../components/ui/Avatar'
import Button from '../../../../components/ui/Button'

import './index.css'

interface TicketProps {
  ticket: TicketClass
  onTicketClick?: (ticket: TicketClass) => void
}

const Ticket = ({ ticket, onTicketClick }: TicketProps) => {
  const [otherMembers, setOtherMembers] = React.useState(
    ticket.conversation.members.filter(mId => ticket.authors.indexOf(mId) === -1),
  )
  const user = useSelector(state => state.user.user)

  if (!user) return null

  const onClick = () => {
    if (onTicketClick) {
      onTicketClick(ticket)
    }
  }

  const joinTicket = async (e: React.MouseEvent) => {
    e.stopPropagation()

    const res = await confirmDialog({
      title: 'tickets-join-ticket-confirm',
      vars: { ticket: ticket.title },
      size: 'medium',
      buttons: {
        cancel: 'tickets-join-cancel',
        join: 'tickets-join-ticket',
      },
    })

    if (res !== 'join') return

    await ticket.join()
      .then(() => {
        ticket.conversation.members.push(user.id)
        setOtherMembers([...otherMembers, user.id])
      })

    if (onTicketClick) {
      onTicketClick(ticket)
    }
  }

  return (
    <div
      className="ticket"
      onClick={onClick}
    >
      <div className="ticket__title">{ticket.title}</div>
      <div className="ticket__authors">
        {
          user.is_support
            ?
            <>
              <Localized id="tickets-author">
                Author:
              </Localized>
              {ticket.authors.map(authId => <Avatar key={authId} user={authId} withName={true} />)}
            </>
            : null
        }
        <TicketOpenDate date={ticket.opened} />
      </div>
      {
        !ticket.conversation.write && <Button l10nId="tickets-join-ticket" clickHandler={joinTicket}>
          Join ticket
        </Button>
      }
      {
        otherMembers.length
          ? <div className="ticket__members">
            <Localized id="tickets-other-members">
              Other members:
            </Localized>
            <div className="ticket__members__avatars">
              {otherMembers.map(memId => (
                <Avatar key={memId} user={memId} withName={true} />
              ))}
            </div>
          </div>
          : null
      }
    </div>
  )
}

export default Ticket
