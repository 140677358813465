import * as React from 'react'
import { Localized } from '@fluent/react'

export type ReportRequest =
  | BookProgressRequest

export type BookProgressRequest = {
  type: 'book-progress',
  book: string,
}

export type ReportButtonProps = ReportRequest & {
  l10nId: string
}

export default function ReportButton(props: ReportButtonProps) {
  const fields = React.useMemo(() => {
    switch (props.type) {
    case 'book-progress': return (
      <input type="hidden" name="book" value={props.book}/>
    )
    }
    return null
  }, [props])

  return (
    <form method="post" action="/api/v1/report" target="_blank">
      <input type="hidden" name="type" value={props.type}/>
      {fields}
      <button className="button" type="submit" id={props.l10nId}>
        <Localized id={props.l10nId}/>
      </button>
    </form>
  )
}
