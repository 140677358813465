import * as React from 'react'
import { History } from 'history'
import Resource from '../../../api/resource'
import Header from '../../../components/Header'
import PageNavigation from '../../../components/PageNavigation'
import Section from '../../../components/Section'
import LimitedUI from '../../../components/LimitedUI'
import LockToggle from '../../../components/LockToggle'
import Button from '../../../components/ui/Button'
import Icon from '../../../components/ui/Icon'
import ResourcesList from './components/ResourcesList'
import './index.css'

export const ACCEPTED_FILE_TYPES = [
  'application/*',
  'audio/*',
  'image/*',
  'text/*',
  'video/*',
]

interface ResourcesProps {
  match: {
    params: {
      id: string
    }
  }
  history: History
}

interface ResourcesState {
  currentFolder: Resource | undefined
  isEditingUnlocked: boolean
  showAddResource: boolean
}

class Resources extends React.Component<ResourcesProps> {
  state: ResourcesState = {
    currentFolder: undefined,
    isEditingUnlocked: false,
    showAddResource: false,
  }

  private mounted = true

  private toggleEditing = (status: boolean) => {
    this.setState({ isEditingUnlocked: status })
  }

  private showAddResource = () => {
    this.setState({ showAddResource: true })
  }

  private closeAddResource = () => {
    this.setState({ showAddResource: false })
  }

  private fetchResource = (id?: string) => {
    if (!id) {
      this.setState({ currentFolder: undefined })
      return
    }
    Resource.load(id)
      .then(resource => {
        if (this.mounted && resource.kind === 'directory') {
          this.setState({ currentFolder: resource })
        } else {
          this.props.history.replace(`/resources${resource.parent ? `/${resource.parent}` : ''}`)
        }
      })
      .catch(() => {
        this.props.history.push('/404')
      })
  }

  componentDidUpdate(prevProps: ResourcesProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchResource(this.props.match.params.id)
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.fetchResource(id)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  public render() {
    const { currentFolder, isEditingUnlocked, showAddResource } = this.state

    return (
      <Section>
        <Header l10nId={currentFolder ? undefined : "resources-view-title"} title="Resources">
          {
            currentFolder
              ? <h2 className="header__title">{currentFolder.name}</h2>
              : null
          }
          <LimitedUI permissions="resources:manage">
            <LockToggle onToggle={this.toggleEditing} />
            {
              isEditingUnlocked
                ? <Button id="resources-view-add" clickHandler={this.showAddResource}>
                  <Icon size="medium" name="plus" />
                </Button>
                : null
            }
          </LimitedUI>
        </Header>
        <div className="section__content resources">
          <PageNavigation
            pagination={currentFolder ? currentFolder.children() : Resource.pagination()}
            usePageParam={true}
            Component={({ items, onAdd, onDelete }) => (
              <ResourcesList
                resources={items}
                isEditingUnlocked={isEditingUnlocked}
                showAddResource={showAddResource}
                onCloseAddResource={this.closeAddResource}
                directory={currentFolder}
                onAdd={onAdd}
                onDelete={onDelete}
              />
            )}
          />
        </div>
      </Section>
    )
  }
}

export default Resources
