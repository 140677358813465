import Task, { ErrorData, Message, State } from '../../api/task'
import {
  ADD_TASK,
  ADD_TASK_ERROR,
  REMOVE_TASK,
  SET_ACTIVE_TASK,
  SET_TASK_PROGRESS,
  SET_TASK_STATE,
} from '../../store/constants'

export interface AddTaskDispatched {
  (dispatch: React.Dispatch<TasksAction>): void
}

export interface AddTask {
  type: ADD_TASK
  data: string
  socket: Task
}

export interface RemoveTask {
  type: REMOVE_TASK
  data: string
}

export interface SetActiveTask {
  type: SET_ACTIVE_TASK
  data: string | null
}

export interface SetState {
  type: SET_TASK_STATE
  data: string
  state: State
}

export interface SetProgress {
  type: SET_TASK_PROGRESS
  data: string
  progress: number | null
  message: string
}

export interface AddError {
  type: ADD_TASK_ERROR
  data: string
  error: ErrorData
}

export type TasksAction =
  | AddError
  | AddTask
  | RemoveTask
  | SetActiveTask
  | SetProgress
  | SetState

export const addTask = (
  id: string,
): AddTaskDispatched => (dispatch: React.Dispatch<TasksAction>) => {
  const task = Task.connect(id)

  task.addEventListener('message', handleMessage.bind(null, dispatch, id) as any)
  // TODO:
  // task.addEventListener('error', handleError)

  dispatch({ type: ADD_TASK, data: id, socket: task })
}

export const removeTask = (id: string): RemoveTask => ({
  type: REMOVE_TASK,
  data: id,
})

export const setActiveTask = (id: string | null): SetActiveTask => ({
  type: SET_ACTIVE_TASK,
  data: id,
})

export const setState = (id: string, state: State): SetState => ({
  type: SET_TASK_STATE,
  data: id,
  state,
})

export const setProgress = (id: string, progress: number | null, message: string): SetProgress => ({
  type: SET_TASK_PROGRESS,
  data: id,
  progress,
  message,
})

export const addError = (id: string, error: ErrorData): AddError => ({
  type: ADD_TASK_ERROR,
  data: id,
  error,
})

function handleMessage(dispatch: React.Dispatch<TasksAction>, id: string, ev: CustomEvent<Message>) {
  if ('state' in ev.detail) {
    dispatch(setState(id, ev.detail.state))
  } else if ('message' in ev.detail) {
    dispatch(setProgress(id, ev.detail.progress, ev.detail.message))
  } else if ('error' in ev.detail) {
    dispatch(addError(id, ev.detail.error))
  } else {
    // TODO:
    console.error('unknown message:', ev.detail)
  }
}
