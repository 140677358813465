import * as React from 'react'
import { Definition } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { LOCALIZATION } from '../../constants'

interface DefinitionProps extends RenderElementProps {
  element: Definition
}

const DefinitionComp = ({ children, attributes }: DefinitionProps) => {
  const { document } = React.useContext(LOCALIZATION)
  const message = document.getString('definition-label')

  return <div {...attributes} className="definition" data-label={message}>
    {children}
  </div>
}

export default DefinitionComp
