import axios from '../config/axios'
import APICache from './apicache'
import Book from './book'
import Module, { ModuleProcess } from './module'
import { TeamID } from './team'
import { elevated, extend, flattenParts } from './utils'

export type Kind = 'module' | 'group'

export type ModuleData = {
  kind: 'module'
  number: number
  title: string
  id: string
  process: ModuleProcess | null
  language: string
  team: TeamID
}

export type GroupData = {
  kind: 'group',
  number: number,
  title: string,
  parts: PartData[],
}

export type PartData = ModuleData | GroupData

export type LocationUpdate = {
  parent: number,
  index: number,
}

export type DataUpdate = {
  title?: string
}

export type Update = (LocationUpdate & DataUpdate) | DataUpdate

// Map<step name, number of modules in this step>
export type ModuleStatuses = Map<string, number>

export default class BookPart {
  /**
   * Load a book by ID.
   */
  static async load(book: Book | string, partId: string, fromCache = true): Promise<BookPart | undefined> {
    const id = book instanceof Book ? book.id : book
    const parts = await APICache.getOrSetNested(
      ['Books/Parts', id],
      fromCache,
      async () => new Book(await axios.get(`books/${id}`)).parts(fromCache),
    )
    return flattenParts(parts).find(p => p.id === partId)
  }

  /**
   * Designates whether this part is a module or a group of other parts.
   */
  kind!: Kind

  /**
   * Unique identifier of this part within a book.
   */
  number!: number

  /**
   * Title of this part.
   */
  title!: string

  /**
   * ID of the module this part corresponds to (if {@link #kind} is
   * {@code 'module'}).
   */
  id?: string

  /**
   * Process data of module for this part (if {@link #kind} is
   * {@code 'module'}).
   */
  process?: ModuleProcess | null

  /**
   * Language of this modules for this part (if {@link #kind} is
   * {@code 'module'}).
   */
  language?: string

  /**
   * Team ID in which this modules exists for this part (if {@link #kind} is
   * {@code 'module'}).
   */
  team?: TeamID

  /**
   * List of parts which make up this group (only if {@link #kind} is
   * {@code 'group'}).
   */
  parts?: BookPart[]

  /**
   * Book this is a part of.
   */
  book: Book

  constructor(data: PartData, book: Book) {
    extend(this, data)

    this.book = book

    if (data.kind === 'group') {
      this.parts = data.parts.map(data => new BookPart(data, book))
    }
  }

  /**
   * Fetch module this part references.
   */
  async module(): Promise<Module | null> {
    const res = this.id ? await Module.load(this.id) : null
    return res
  }

  /**
   * Update this part.
   *
   * This method requires elevated permissions.
   */
  async update(diff: Update): Promise<void> {
    await elevated(() => axios.put(`books/${this.book.id}/parts/${this.number}`, diff))
    APICache.invalidate(['Books/Parts', this.book.id])
  }

  /**
   * Remove this book part from its book.
   *
   * If this book part is a group this will remove all its child book parts. If
   * this book part is a module, the module it references will not be deleted.
   *
   * This method requires elevated permissions.
   */
  async delete(): Promise<void> {
    await elevated(() => axios.delete(`books/${this.book.id}/parts/${this.number}`))
    APICache.invalidate(['Books/Parts', this.book.id])
  }

  /**
   * Return @return {ModuleStatuses} of modules inside @param group from process @param processId
   */
  getModuleStatuses(processId: number, group: BookPart = this): ModuleStatuses {
    const modStatuses: ModuleStatuses = new Map()

    if (group.kind !== 'group') return modStatuses

    for (const part of group.parts!) {
      if (part.kind === 'module' && part.process && part.process.process === processId) {
        let counter = 1
        if (modStatuses.has(part.process.step.name)) {
          counter = modStatuses.get(part.process.step.name)! + 1
        }
        modStatuses.set(part.process.step.name, counter)
      } else if (part.kind === 'group') {
        const map = this.getModuleStatuses(processId, part)

        for (let [stepName, counter] of map.entries()) {
          if (modStatuses.has(stepName)) {
            counter += modStatuses.get(stepName)!
          }
          modStatuses.set(stepName, counter)
        }
      }
    }

    return modStatuses
  }
}
