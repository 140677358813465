import * as React from 'react'
import { DefinitionExample } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { LOCALIZATION } from '../../constants'

interface DefinitionExampleProps extends RenderElementProps {
  element: DefinitionExample
}

const DefinitionExampleComp = ({ children, attributes }: DefinitionExampleProps) => {
  const { document } = React.useContext(LOCALIZATION)
  const message = document.getString('definition-example-label')

  return <div {...attributes} className="definition-example" data-label={message}>
    {children}
  </div>
}

export default DefinitionExampleComp
