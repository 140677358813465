import { Editor, Element, Node, NodeEntry, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { isValidUrl } from '../../../../helpers'

export type MarkKind = 'emphasis' | 'strong'

export const Mark = {
  isMarkActive: (editor: Editor, format: MarkKind) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  },
  toggleMark: (editor: Editor, format: MarkKind) => {
    const isActive = Mark.isMarkActive(editor, format)

    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  },
}

export interface Hyperlink extends Element {
  type: 'hyperlink'
  url: string
}

export const Hyperlink = {
  isLinkActive: (editor: Editor) => {
    const [link] = Editor.nodes(editor, { match: Hyperlink.isHyperlink })
    return Boolean(link)
  },
  isHyperlink: (value: unknown): value is Hyperlink => {
    return Element.isElement(value) && value.type === 'hyperlink'
  },
  insertLink: (editor: Editor, url: string) => {
    if (Hyperlink.isLinkActive(editor)) {
      Hyperlink.unwrapLink(editor)
    }

    const link: Hyperlink = {
      type: 'hyperlink',
      url,
      children: [{ text: url }],
    }

    Transforms.insertNodes(editor, link)
  },
  unwrapLink: (editor: Editor) => {
    Transforms.unwrapNodes(editor, { match: Hyperlink.isHyperlink })
  },
}

export interface Mention extends Element {
  type: 'mention'
  userId: number
}

export const Mention = {
  insertMention: (editor: Editor, userId: number) => {
    const mention = { type: 'mention', userId, children: [{ text: '' }] }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
  },
  isMention: (value: unknown): value is Mention => {
    return Element.isElement(value) && value.type === 'mention'
  },
}

export const withPlugins = <T extends ReactEditor>(editor: T) => {
  const { normalizeNode, isInline, isVoid, insertText, insertData } = editor

  editor.isInline = element => Mention.isMention(element)
    || Hyperlink.isHyperlink(element)
    || isInline(element)

  editor.isVoid = element => Mention.isMention(element)
    || isVoid(element)

  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [, path] = entry

    if (path.length === 0 && editor.children.length === 0) {
      Transforms.insertNodes(editor, { type: 'paragraph', children: [] }, { at: [0] })
      return
    }

    normalizeNode(entry)
  }

  editor.insertText = text => {
    if (text && isValidUrl(text)) {
      Hyperlink.insertLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain')

    if (text && isValidUrl(text)) {
      Hyperlink.insertLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}
