import * as React from 'react'
import { Localized } from '@fluent/react'
import { useDispatch } from 'react-redux'
import { FilesError } from 'react-files'
import { Book, Team } from '../../../../../api'
import { addAlert } from '../../../../../store/actions/alerts'
import Spinner from '../../../../../components/Spinner'
import TeamSelector from '../../../../../components/TeamSelector'
import Button from '../../../../../components/ui/Button'
import Dialog from '../../../../../components/ui/Dialog'
import Input from '../../../../../components/ui/Input'
import ValidationErrorsDialog from '../../../../../components/ValidationErrorsDialog'
import FilesUploader from '../../../../../containers/FilesUploader'

interface AddBookDialogProps {
  show: boolean
  onSuccess: (book: Book) => void
  onClose: () => void
}

const AddBookDialog = (props: AddBookDialogProps) => {
  const [isUploading, setIsUploading] = React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])
  const [titleInput, setTitleInput] = React.useState('')
  const [team, setTeam] = React.useState<Team | null>(null)
  const [validationErrors, setValidationErrors] = React.useState<string | null>(null)
  const dispatch = useDispatch()

  const addBook = (e: React.FormEvent) => {
    e.preventDefault()

    if (!team) return

    setIsUploading(true)

    Book.create(titleInput, team.id, files[0])
      .then(book => {
        dispatch(addAlert('success', 'book-list-add-book-alert-success'))
        props.onSuccess(book)
      })
      .finally(() => {
        setIsUploading(false)
      })
  }

  const onFilesChange = (files: File[]) => {
    setFiles(files)
  }

  const onFilesError = (error: FilesError) => {
    dispatch(addAlert('error', error.message))
  }

  if (!props.show && !validationErrors) return null

  return <>
    <Dialog
      l10nId="book-list-add-book-dialog-title"
      placeholder="Add new book"
      size="medium"
      onClose={props.onClose}
    >
      {
        isUploading ?
          <Spinner />
          :
          <form onSubmit={addBook}>
            <Input
              type="text"
              l10nId="book-list-add-book-title"
              value={titleInput}
              onChange={val => setTitleInput(val)}
              minLength={3}
              required
            />
            <TeamSelector
              permission="book:edit"
              onChange={setTeam}
            />
            <FilesUploader
              onFilesChange={onFilesChange}
              onFilesError={onFilesError}
              accepts={['.zip', '.rar']}
              optional={true}
              multiple={false}
            />
            <div className="dialog__buttons">
              <Button l10nId="book-list-add-book-cancel" clickHandler={props.onClose}>
                Cancel
              </Button>
              <Localized id="book-list-add-book-confirm" attrs={{ value: true }}>
                <input
                  type="submit"
                  value="Confirm"
                  disabled={titleInput.length === 0 || !team}
                />
              </Localized>
            </div>
          </form>
      }
    </Dialog>
    <ValidationErrorsDialog
      title="book-list-import-validation"
      errors={validationErrors}
      onClear={() => setValidationErrors(null)}
    />
  </>
}

export default AddBookDialog
