import * as React from 'react'
import Select from 'react-select'
import { Localized, withLocalization, WithLocalizationProps } from '@fluent/react'
import { useSelector } from 'react-redux'
import Role from '../../../api/role'
import Invitation, { InvitationData } from '../../../api/invitation'
import Team, { TeamPermission } from '../../../api/team'
import store from '../../../store'
import { addAlert } from '../../../store/actions/alerts'
import { useIsInSuperMode } from '../../../hooks'
import data from '../../../locale/data'
import Section from '../../../components/Section'
import Header from '../../../components/Header'
import TeamPermissions, { TEAM_PERMISSIONS } from '../../../components/TeamPermissions'
import TeamSelector from '../../../components/TeamSelector'
import Spinner from '../../../components/Spinner'
import Input from '../../../components/ui/Input'
import RoleSelector from '../../../components/RoleSelector'
import './index.css'

const Invitations = ({ getString }: WithLocalizationProps) => {
  const user = useSelector(state => state.user.user)
  const [isSending, setIsSending] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [isEmailVaild, setIsEmailVaild] = React.useState(false)
  const [language, setLanguage] = React.useState<typeof data.languages[0]>(data.languages[0])
  const [team, setTeam] = React.useState<Team | null>(null)
  const [role, setRole] = React.useState<Role | null>(null)
  const [permissions, setPermissions] = React.useState<TeamPermission[]>([])
  const isInSuperMode = useIsInSuperMode(user)
  const inputRef = React.useRef<Input>(null)

  const sendInvitation = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!isEmailVaild || !team) return

    setIsSending(true)

    const data: InvitationData = {
      email,
      language: language.code,
      team: team.id,
      permissions,
    }

    if (role) {
      data.role = role.id
    }

    await Invitation.create(data)
      .then(() => {
        setEmail('')
        store.dispatch(addAlert('success', 'invitation-send-alert-success', { email }))
      })

    setIsSending(false)
  }

  const hanleInputChange = (val: string) => {
    setEmail(val)
    if (val.length === 0) {
      inputRef!.current!.unTouch()
    }
  }

  const handleInputValidation = (status: boolean) => {
    if (isEmailVaild !== status) {
      setIsEmailVaild(status)
    }
  }

  const handleLanguageChange = ({ value }: { value: typeof data.languages[0], label: string }) => {
    setLanguage(value)
  }

  const handleTeamChange = (team: Team) => {
    setTeam(team)
    setPermissions([])
  }

  const handleRoleChange = (option: { value: Role, label: string } | null) => {
    setRole(option ? option.value : option)
  }

  const handlePermissionsChange = (permissions: TeamPermission[]) => {
    setPermissions(permissions)
  }

  // User can give another user only subset of his permission in team
  let disabledPermissions: TeamPermission[] = TEAM_PERMISSIONS
  if (team) {
    if (isInSuperMode) {
      disabledPermissions = []
    } else {
      const usrTeam = user?.teams.find(t => t.id === team.id)
      if (usrTeam && usrTeam.role && usrTeam.role.permissions) {
        disabledPermissions = TEAM_PERMISSIONS.filter(p => !usrTeam.role!.permissions!.includes(p))
      }
    }
  }

  return (
    <div className="container">
      <Section>
        <Header l10nId="invitation-view-title" title="Invite new user" />
        <div className="section__content">
          <div className="invitations">
            {
              isSending
                ? <Spinner />
                : <form onSubmit={sendInvitation}>
                  <Input
                    ref={inputRef}
                    type="email"
                    l10nId="invitation-email"
                    value={email}
                    onChange={hanleInputChange}
                    isValid={handleInputValidation}
                    errorMessage="invitation-email-validation-invalid"
                    required
                  />
                  <Select
                    className="react-select"
                    placeholder={getString('invitation-select-language')}
                    value={{ value: language, label: language.name }}
                    options={data.languages.map(lan => ({ value: lan, label: lan.name }))}
                    formatOptionLabel={formatOptionLabel}
                    onChange={handleLanguageChange as any}
                  />
                  <TeamSelector
                    // currently only users with `is_super` may see invitations screen
                    // and only they can invite users using email so this check for permission
                    // is not required
                    // permission="member:add"
                    onChange={handleTeamChange}
                  />
                  <RoleSelector
                    team={team || undefined}
                    className="react-select"
                    placeholder={getString('invitation-select-role')}
                    isClearable={true}
                    isDisabled={!team}
                    value={role ? { value: role, label: role.name } : null}
                    formatOptionLabel={formatOptionLabel}
                    onChange={handleRoleChange as any}
                  />
                  <TeamPermissions
                    selected={permissions}
                    disabled={disabledPermissions}
                    onChange={handlePermissionsChange}
                  />
                  <Localized id="invitation-send" attrs={{ value: true }}>
                    <input
                      type="submit"
                      value="Send invitation"
                      disabled={!isEmailVaild || !email}
                    />
                  </Localized>
                </form>
            }
          </div>
        </div>
      </Section>
    </div>
  )
}

export default withLocalization(Invitations)

const formatOptionLabel = (option: { label: string, value: any }) => option.label
