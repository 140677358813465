import * as React from 'react'
import { Localized } from '@fluent/react'
import Task from '../../../../api/task'
import Button from '../../../../components/ui/Button'
import Radio from '../../../../components/ui/Radio'

export interface ResolutionSelectorProps {
  task: Task
  data: Summary[]
  submitResolutions: (resolutions: Resolution[]) => void
}

interface Summary {
  kind: ItemKind
  path: string
  id: string
  title: string
  existing: Existing[]
}

type ItemKind = 'book' | 'module'

type Existing = { id: string } & DuplicateReason

type DuplicateReason = DuplicateUuid | DuplicateSlug | DuplicateId

interface DuplicateUuid {
  reason: 'uuid'
}

interface DuplicateSlug {
  reason: 'slug'
  slug: string
}

interface DuplicateId {
  reason: 'alternative-id'
  repository: string
  contentId: string
}

type Resolution = ResolutionCreateNew | ResolutionUseExisting | ResolutionReplace

interface ResolutionCreateNew {
  resolution: 'create-new'
}

type ResolutionUseExisting = { resolution: 'use-existing' } & DuplicateReason

type ResolutionReplace = { resolution: 'replace-existing' } & DuplicateReason

export default function ResolutionSelector({ task, data, submitResolutions }: ResolutionSelectorProps) {
  const [resolutions, setResolutions] = React.useState<Resolution[]>(() => {
    const resolutions: Resolution[] = new Array(data.length)
    for (let i = 0; i < data.length; ++i) {
      resolutions[i] = { resolution: 'create-new' }
    }
    return resolutions
  })

  const setResolution = React.useCallback((index, resolution) => {
    setResolutions(resolutions => {
      const newResolutions = Array.from(resolutions)
      newResolutions[index] = resolution
      return newResolutions
    })
  }, [setResolutions])

  const allUseUuid = React.useCallback(() => setResolutions(resolutions => {
    return data.map((summary, i) => summary.existing.some(ex => ex.reason === 'uuid')
      ? { resolution: 'use-existing', reason: 'uuid' }
      : resolutions[i])
  }), [data, setResolutions])

  const allReplaceUuid = React.useCallback(() => setResolutions(resolutions => {
    return data.map((summary, i) => summary.existing.some(ex => ex.reason === 'uuid')
      ? { resolution: 'replace-existing', reason: 'uuid' }
      : resolutions[i])
  }), [data, setResolutions])

  const allRepositories = React.useMemo(() => {
    const repositories = new Set<string>()
    for (const summary of data) {
      for (const existing of summary.existing) {
        if (existing.reason === "alternative-id") {
          repositories.add(existing.repository)
        }
      }
    }
    return repositories
  }, [data])

  const allUseRepository = React.useCallback((repository: string) => setResolutions(resolutions => {
    return data.map((summary, i) => {
      const item: DuplicateId | undefined = summary.existing.find(
        (ex: Existing): ex is Existing & DuplicateId => ex.reason === 'alternative-id' && ex.repository == repository)
      return item == null
        ? resolutions[i]
        : { resolution: 'use-existing', reason: 'alternative-id', repository, contentId: item.contentId }
    })
  }), [data, setResolutions])

  const allReplaceRepository = React.useCallback((repository: string) => setResolutions(resolutions => {
    return data.map((summary, i) => {
      const item: DuplicateId | undefined = summary.existing.find(
        (ex: Existing): ex is Existing & DuplicateId => ex.reason === 'alternative-id' && ex.repository == repository)
      return item == null
        ? resolutions[i]
        : { resolution: 'replace-existing', reason: 'alternative-id', repository, contentId: item.contentId }
    })
  }), [data, setResolutions])

  const onSubmitResolutions = React.useCallback(() => {
    submitResolutions(resolutions)
  }, [resolutions, submitResolutions])

  return (
    <div className="resolution-selector">
      <div className="buttons">
        <Button l10nId="import-resolutions-use-all-uuid" clickHandler={allUseUuid} />
        <Button l10nId="import-resolutions-replace-all-uuid" clickHandler={allReplaceUuid} />
        {Array.from(allRepositories.values(), repository => <>
          <Button
            l10nId="import-resolutions-use-all-repository"
            vars={{ repository }}
            clickHandler={allUseRepository.bind(null, repository)}
          />
          <Button
            l10nId="import-resolutions-replace-all-repository"
            vars={{ repository }}
            clickHandler={allReplaceRepository.bind(null, repository)}
          />
        </>)}
      </div>

      <div className="resolution-selector__items">
        {data.map((item, index) => (
          <ItemResolution
            key={index}
            summary={item}
            resolution={resolutions[index]}
            onSetResolution={setResolution.bind(null, index)}
          />
        ))}
      </div>

      <div className="buttons">
        <Button l10nId="import-resolutions-submit" clickHandler={onSubmitResolutions} />
      </div>
    </div>
  )
}

interface ItemResoltionProps {
  summary: Summary
  resolution: Resolution
  onSetResolution: (resolution: Resolution) => void
}

function ItemResolution({ summary, resolution, onSetResolution }: ItemResoltionProps) {
  const resolutions: Resolution[] = React.useMemo(() => {
    const resolutions: Resolution[] = [{ resolution: 'create-new' }]

    for (const existing of summary.existing) {
      resolutions.push({ resolution: 'use-existing', ...existing })
      resolutions.push({ resolution: 'replace-existing', ...existing })
    }

    return resolutions
  }, [summary])

  return <>
    <div className="resolution-item">
      <span className="resolution-item__kind">
        {summary.kind}
      </span>
      <span>
        {summary.title}
        <br/>
        {summary.path}
      </span>
    </div>

    <form className="resolution-item__resolution">
      <Radio
        name="resolution"
        options={resolutions}
        value={resolution}
        label={option => (
          <Localized
            id={"import-resolution-" + option.resolution}
            vars={{ ...option }}
          />
        )}
        comparator={compareResolutions}
        onChange={onSetResolution}
      />
    </form>
  </>
}

function compareResolutions(a: Resolution, b: Resolution): boolean {
  switch (a.resolution) {
  case "create-new": return b.resolution === 'create-new'

  case "use-existing":
  case "replace-existing":
    return b.resolution === a.resolution && compareDuplicateReason(a, b)
  }

  return false
}

function compareDuplicateReason(a: DuplicateReason, b: DuplicateReason): boolean {
  if (a.reason !== b.reason) return false

  switch (a.reason) {
  case "uuid": return true
  case "slug": return a.slug === (b as DuplicateSlug).slug
  case "alternative-id": return a.repository === (b as DuplicateId).repository
    && a.contentId === (b as DuplicateId).contentId
  }

  return false
}
