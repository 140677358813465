import * as React from 'react'
import Select, { Props } from 'react-select'
import { withLocalization, WithLocalizationProps } from '@fluent/react'
import { Team } from '../../api'
import { useLoadAllBooks } from '../../api/hooks'

interface BookSelectorProps extends Omit<Props, 'options'>, WithLocalizationProps {
  team?: Team | number
}

const customSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    position: 'static',
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: 200,
  }),
}

const BookSelector = (props: BookSelectorProps) => {
  const [books] = useLoadAllBooks()
  const teamId = props.team ? typeof props.team === 'number' ? props.team : props.team.id : null

  const options = React.useMemo(
    () => books
      .filter(b => !teamId || b.team === teamId)
      .map(b => ({ value: b, label: b.title })),
    [books, teamId])

  return (
    <Select
      className="react-select book-selector"
      placeholder={props.getString('book-selector-placeholder')}
      options={options}
      {...props}
      isClearable={true}
      styles={customSelectStyles}
    />
  )
}

export default withLocalization(BookSelector)
