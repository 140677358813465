import Module from '../../api/module'
import * as constants from '../../store/constants'
import { AddAlert, addAlert } from '../../store/actions/alerts'
import {
  LabelID,
  Labels,
  ModuleID,
  ModuleLabel,
  ModuleLabelData,
  ModulesWithLabels,
  ReferenceTarget,
} from '../../store/types'

export interface SetReferenceTargets {
  type: constants.SET_REFERENCE_TARGETS,
  data: {
    moduleId: string,
    targets: ReferenceTarget[],
  },
}

export interface CreateLabel {
  type: constants.CREATE_LABEL
  data: ModuleLabelData
}

export interface RemoveLabel {
  type: constants.REMOVE_LABEL
  data: ModuleLabel | LabelID
}

export interface SetLabels {
  type: constants.SET_LABELS
  data: Labels
}

export interface UpdateLabel {
  type: constants.UPDATE_LABEL
  data: {
    id: LabelID
    data: ModuleLabelData
  }
}

export interface SetModulesWithLabels {
  type: constants.SET_MODULES_WITH_LABELS
  data: ModulesWithLabels
}

export interface AddLabelToModule {
  type: constants.ADD_LABEL_TO_MODULE
  data: {
    module: ModuleID
    label: ModuleLabel | LabelID
  }
}

export interface RemoveLabelFromModule {
  type: constants.REMOVE_LABEL_FROM_MODULE
  data: {
    module: ModuleID
    label: ModuleLabel | LabelID
  }
}

export type ModulesAction
  = SetReferenceTargets
  | SetLabels
  | CreateLabel
  | RemoveLabel
  | UpdateLabel
  | SetModulesWithLabels
  | AddLabelToModule
  | RemoveLabelFromModule

interface AlertsMessages {
  success?: {
    l10nId: string
    args?: { [key: string]: any },
  }
  error?: {
    l10nId: string
    args?: { [key: string]: any },
  }
}

export const archiveModule = (mod: Module, alerts?: AlertsMessages, onSuccess?: () => void) => (
  dispatch: React.Dispatch<AddAlert>,
) => mod.archive()
  .then(() => {
    dispatch(addAlert(
      'success',
      alerts?.success?.l10nId || 'module-remove-success',
      alerts?.success?.args,
    ))
    if (onSuccess) onSuccess()
  })
  .catch(() => {
    dispatch(addAlert(
      'error',
      alerts?.error?.l10nId || 'module-remove-error',
      alerts?.error?.args,
    ))
  })

export const setReferenceTargets = (
  moduleId: string,
  targets: ReferenceTarget[],
): SetReferenceTargets => ({
  type: constants.SET_REFERENCE_TARGETS,
  data: {
    moduleId,
    targets,
  },
})

export const fetchReferenceTargets = (forModule: Module) => async (dispatch: any) => {
  const rsp = await forModule.referenceTargets()
  const targets = new Map<string, ReferenceTarget>()

  for (const rt of rsp) {
    if (rt.context) continue
    const type = matchApiRefTypeToElementType(rt.type)
    targets.set(rt.id, {
      id: rt.id,
      type: rt.type,
      description: rt.description,
      counters: [[type, rt.counter]],
      children: [],
    })
  }

  for (const rt of rsp) {
    if (!rt.context) continue

    const context = targets.get(rt.context)
    if (!context) {
      console.warn(`Context ${rt.context} missing for element ${rt.id}, ignoring`)
      continue
    }

    const type = matchApiRefTypeToElementType(rt.type)
    context.children.push({
      id: rt.id,
      type: rt.type,
      description: rt.description,
      counters: [[type, rt.counter], ...context.counters],
      children: [],
    })
  }

  dispatch(setReferenceTargets(forModule.id, Array.from(targets.values())))
}

export const setLabels = (labels: Labels): SetLabels => ({
  type: constants.SET_LABELS,
  data: labels,
})

export const createLabel = (label: ModuleLabelData): CreateLabel => ({
  type: constants.CREATE_LABEL,
  data: label,
})

export const removeLabel = (label: ModuleLabel | LabelID): RemoveLabel => ({
  type: constants.REMOVE_LABEL,
  data: label,
})

export const updateLabel = (id: LabelID, data: ModuleLabelData): UpdateLabel => ({
  type: constants.UPDATE_LABEL,
  data: {
    id,
    data,
  },
})

export const setModulesWithLabels = (mwl: ModulesWithLabels): SetModulesWithLabels => ({
  type: constants.SET_MODULES_WITH_LABELS,
  data: mwl,
})

export const addLabelToModule = (
  module: ModuleID,
  label: ModuleLabel | LabelID,
): AddLabelToModule => ({
  type: constants.ADD_LABEL_TO_MODULE,
  data: {
    module,
    label,
  },
})

export const removeLabelFromModule = (
  module: ModuleID,
  label: ModuleLabel | LabelID,
): RemoveLabelFromModule => ({
  type: constants.REMOVE_LABEL_FROM_MODULE,
  data: {
    module,
    label,
  },
})

export const syncModulesLabels = () => (dispatch: React.Dispatch<ModulesAction>) => {
  const labels: Labels = JSON.parse(localStorage.getItem('labels') || '{}')
  const modulesWithLabels = JSON.parse(localStorage.getItem('modulesWithLabels') || '{}')
  dispatch(setLabels(labels))
  dispatch(setModulesWithLabels(modulesWithLabels))
}

const matchApiRefTypeToElementType = (type: string) => {
  switch (type) {
  case 'problem':
    return 'exercise_problem'

  case 'solution':
    return 'exercise_solution'

  case 'commentary':
    return 'exercise_commentary'

  case 'example':
    return 'rule_example'

  case 'proof':
    return 'rule_proof'

  case 'statement':
    return 'rule_statement'

  default:
    return type
  }
}
