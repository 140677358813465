import * as React from 'react'
import { Localized } from '@fluent/react'
import { useSelector } from 'react-redux'
import Button, { ButtonHandler } from '../../components/ui/Button'
import Dialog from '../../components/ui/Dialog'

import './index.css'

const ConfirmDialog = () => {
  const { showConfirmDialog: show, confirmDialogOptions: options } = useSelector(state => state.app)
  if (!show) return null

  const {
    title,
    content,
    buttons,
    buttonsPosition = 'default',
    showCloseButton = true,
    closeOnBgClick = true,
    closeOnEsc = true,
    callback = (key: string) => console.warn(
      `Resolved with: ${key}. You did not provide callback for button click.`,
    ),
    vars,
  } = options

  const onClick: ButtonHandler = ev => {
    const key = (ev.target as HTMLButtonElement)!.dataset.id as string
    callback(key)
  }

  return (
    <Dialog
      size="medium"
      l10nId={title}
      placeholder="Confirm action"
      onClose={() => callback('close')}
      showCloseButton={showCloseButton}
      closeOnBgClick={closeOnBgClick}
      closeOnEsc={closeOnEsc}
      vars={vars}
    >
      {
        content ?
          typeof content === 'string' ?
            <div className="confirm-dialog__content">
              <Localized id={content}>
                {content}
              </Localized>
            </div>
            : content
          : null
      }
      {
        buttons ?
          <div className={`dialog__buttons dialog__buttons--${buttonsPosition}`}>
            {
              Object.entries(buttons).map(([key, val], i) => (
                <Button id={key} key={key+i} dataId={key} clickHandler={onClick}>
                  {
                    typeof val === 'string' ?
                      <Localized id={val}>
                        {val}
                      </Localized>
                      : val as typeof Button
                  }
                </Button>
              ))
            }
          </div>
          : null
      }
    </Dialog>
  )
}

export default ConfirmDialog
