import * as React from 'react'
import * as Counters from 'react-counters'
import { Proof } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { RULE, RULE_PROOF } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface RuleProofProps extends RenderElementProps {
  element: Proof
}

const RuleProofComp = ({ children, attributes }: RuleProofProps) => {
  const { document } = React.useContext(LOCALIZATION)
  Counters.useCounter(attributes.ref, { increment: [RULE_PROOF] })
  const rule = Counters.useCounterValue(attributes.ref, RULE)
  const proof = Counters.useCounterValue(attributes.ref, RULE_PROOF)
  const message = document.getString('rule-proof-label', { rule, proof })

  return <div {...attributes} className="rule-proof" data-label={message}>
    {children}
  </div>
}

export default RuleProofComp
