import * as React from 'react'
import store from '../../../store'
import { removeLabel } from '../../../store/actions/modules'
import { ModuleLabel as ModuleLabelType } from '../../../store/types'
import { confirmDialog } from '../../../helpers'
import ModuleLabelManager from '../ModuleLabelManager'
import ModuleLabel from '../../../components/ModuleLabel'
import Button from '../../../components/ui/Button'

import './index.css'

interface MLEItemProps {
  label: ModuleLabelType
  currentlyEditingId: string
}

const MLEItem = ({ label, currentlyEditingId }: MLEItemProps) => {
  const [editMode, setEditMode] = React.useState(label.id === currentlyEditingId)

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const handleRemove = async () => {
    const res = await confirmDialog({
      title: 'module-labels-editor-remove-confirm',
      size: 'medium',
      vars: { label: label.name },
      buttons: {
        cancel: 'module-labels-editor-cancel',
        remove: 'module-labels-editor-remove',
      },
    })
    if (res === 'remove') {
      store.dispatch(removeLabel(label))
    }
  }

  React.useEffect(() => {
    if (currentlyEditingId === label.id) {
      setEditMode(true)
    }
  }, [currentlyEditingId, label.id])

  return (
    <div className="module-labels-editor__item">
      {
        editMode
          ?
          <ModuleLabelManager
            label={label}
            onCancel={toggleEditMode}
            onUpdate={toggleEditMode}
          />
          :
          <>
            <ModuleLabel label={label} />
            <div className="module-labels-editor__buttons">
              <Button l10nId="module-labels-editor-edit" clickHandler={toggleEditMode}>
                Edit
              </Button>
              <Button l10nId="module-labels-editor-remove" clickHandler={handleRemove}>
                Remove
              </Button>
            </div>
          </>
      }
    </div>
  )
}

export default MLEItem
