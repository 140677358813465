import * as CNXML from 'slate-cnxml'
import { Editor, Node, Path, Point, Span, Text, Transforms } from 'slate'
import { Marker, Suggestion, SuggestionsEditor } from 'slate-suggestions'

const SUGGESTION_ELEMENTS = ['insert', 'remove', 'move-source', 'split-point', 'join']

/**
 * All parents in which a {@link Marker} is a block element
 *
 * FIXME(aiwenar): This is not a good solution, but I have no better ideas at
 * the moment.
 */
const BLOCK_PARENTS = [
  'admonition',
  'exercise',
  'exercise_problem',
  'exercise_solution',
  'exercise_commentary',
  'figure',
  'glossary',
  'definition',
  'list',
  'list_item',
  'media',
  'rule',
  'rule_statement',
  'rule_proof',
  'rule_example',
  'section',
]

export function withDeserializeSuggestions<T extends CNXML.DeserializingEditor>(editor: T) {
  const { deserializeElement } = editor

  editor.deserializeElement = (el, at, ctx) => {
    if (
      el.namespaceURI !== CNXML.EDITING_NAMESPACE
      || !SUGGESTION_ELEMENTS.includes(el.localName)
    ) {
      if (el.namespaceURI === CNXML.CNXML_NAMESPACE
      && el.localName === 'span'
      && el.getAttribute('effect') === 'editing:wrapper') {
        CNXML.children(editor, el, at, ctx)
      } else {
        deserializeElement(el, at, ctx)
      }

      const suggestion = el.getAttributeNS(CNXML.EDITING_NAMESPACE, 'suggestion')
      switch (suggestion) {
      case 'insert':
      case 'remove':
        Transforms.setNodes(editor, { suggestion }, { at })
        break

      case 'move':
        Transforms.setNodes(editor, {
          suggestion,
          movedFrom: el.getAttributeNS(CNXML.EDITING_NAMESPACE, 'moved-from'),
        }, { at })
        break
      }

      const splitFrom = el.getAttributeNS(CNXML.EDITING_NAMESPACE, 'split-from')
      if (splitFrom != null) {
        Transforms.setNodes(editor, { splitFrom }, { at })
      }

      return
    }

    const [parent] = Editor.parent(editor, at)
    const inline = !BLOCK_PARENTS.includes(parent.type as any)

    switch (el.localName) {
    case 'move-source':
      Transforms.insertNodes(editor, {
        suggestion: 'marker',
        type: 'move-source',
        id: el.getAttribute('id'),
        inline,
        children: [{ text: '' }],
      }, { at })
      return

    case 'split-point':
      Transforms.insertNodes(editor, {
        suggestion: 'marker',
        type: 'split-point',
        id: el.getAttribute('id'),
        inline,
        children: [{ text: '' }],
      }, { at })
      return

    case 'join':
      Transforms.insertNodes(editor, {
        suggestion: 'marker',
        type: 'join',
        id: el.getAttribute('id'),
        inline,
        properties: unpackProperties(editor, el),
        children: [{ text: '' }],
      }, { at })
      return
    }

    const end = Editor.pathRef(editor, at, { affinity: 'forward' })

    // Deserialize children of this suggestion.
    CNXML.children(editor, el, at, ctx)

    Transforms.setNodes(editor, { suggestion: el.localName }, {
      at: Editor.range(editor, at, Path.previous(end.unref()!)),
      match: Text.isText,
      mode: 'highest',
    })
  }

  return editor
}

export function serializeSuggestion(
  node: Node,
  attrs: CNXML.CommonAttrs,
  children: CNXML.Node,
) {
  if (Marker.isMoveSource(node)) {
    return CNXML.jsx('move-source' as any, { ...attrs, xmlns: CNXML.EDITING_NAMESPACE, children })
  }

  if (Marker.isSplit(node)) {
    return CNXML.jsx('split-point' as any, { ...attrs, xmlns: CNXML.EDITING_NAMESPACE, children })
  }

  if (Marker.isJoin(node)) {
    return CNXML.jsx('join' as any, {
      ...attrs,
      xmlns: CNXML.EDITING_NAMESPACE,
      children: [
        children,
        packProperties(node.properties),
      ],
    })
  }


  // Not our node, we cannot serialize it. Instead we add our attributes to
  // `attrs` and return `null`, to defer to other serializers.

  const attrsR = attrs as unknown as Record<string, unknown>

  if (Suggestion.isInsert(node) || Suggestion.isRemove(node)) {
    attrsR.editingSuggestion = node.suggestion
  }

  if (Suggestion.isMove(node)) {
    attrsR.editingSuggestion = 'move'
    attrsR['editingMoved-from'] = node.movedFrom
  }

  if (Suggestion.isSplit(node)) {
    attrsR['editingSplit-from'] = node.splitFrom
  }

  return null
}

export function serializeSuggestionText(
  node: Node,
  attrs: CNXML.CommonAttrs,
  children: CNXML.Node,
) {
  if (Suggestion.isInsert(node) || Suggestion.isRemove(node)) {
    return CNXML.jsx(node.suggestion, {
      ...attrs,
      xmlns: CNXML.EDITING_NAMESPACE,
      children,
    })
  }

  if (Suggestion.isMove(node)) {
    return CNXML.jsx('span' as any, {
      ...attrs,
      effect: 'editing:wrapper',
      editingSuggestion: 'move',
      'editingMoved-from': node.movedFrom,
      children,
    })
  }

  return null
}

function packProperties(properties: Record<string, unknown>): CNXML.Node {
  const nodes = []
  for (const [key, value] of Object.entries(properties)) {
    let type
    let repr

    if (value === null) {
      type = 'null'
      repr = []
    } else { switch (typeof value) {
    case 'string':
      type = 'string'
      repr = value
      break

    case 'number':
      type = 'number'
      repr = value.toString()
      break

    case 'object':
      type = 'object'
      repr = packProperties(value as Record<string, unknown>)
      break
    } }

    nodes.push(CNXML.jsx(
      'property' as any,
      { xmlns: CNXML.EDITING_NAMESPACE, type, children: repr, name: key },
    ))
  }

  return nodes
}

function unpackProperties(editor: CNXML.DeserializingEditor, el: Element): Record<string, unknown> {
  const properties = {}

  for (const child of el.children) {
    const type = child.getAttribute('type')
    const name = child.getAttribute("name")!!
    let value

    switch (type) {
    case 'object':
      value = unpackProperties(editor, child)
      break

    case 'string':
      value = child.textContent
      break

    case 'number':
      value = Number(child.textContent)
      break

    default:
      editor.reportError('unknown-property-type', { type })
      continue
    }

    properties[name] = value
  }

  return properties
}

export interface HiddenSuggestionsEditor extends SuggestionsEditor {
  showSuggestions: boolean
}

export function withHiddenSuggestions<T extends SuggestionsEditor>(editor: T): T & HiddenSuggestionsEditor {
  const { apply } = editor
  const ed: T & HiddenSuggestionsEditor = editor as unknown as T & HiddenSuggestionsEditor
  ed.showSuggestions = false

  editor.apply = (op): void => {
    if (editor.showSuggestions || op.type !== 'set_selection' || op.newProperties == null) {
      return apply(op)
    }

    if (op.newProperties.anchor != null) {
      op.newProperties.anchor = checkPointIsVisible(ed, op.newProperties.anchor, op.properties?.anchor)
    }
    if (op.newProperties.focus != null) {
      op.newProperties.focus = checkPointIsVisible(ed, op.newProperties.focus, op.properties?.focus)
    }

    return apply(op)
  }

  return ed
}

function checkPointIsVisible(
  editor: HiddenSuggestionsEditor,
  point: Point | undefined,
  previous: Point | undefined,
): Point | undefined {
  if (point == null) return undefined

  // Check if point is visible
  if (Editor.levels(editor, {
    at: point.path,
    match: Suggestion.isRemove,
    voids: true,
  }).next().value == null) return point

  const isNext = previous == null || Point.isAfter(point, previous)
  const span: Span = isNext
    ? [Editor.last(editor, point)[1], Editor.last(editor, [])[1]]
    : [Editor.first(editor, point)[1], Editor.first(editor, [])[1]]

  const [, next] = Editor.nodes(editor, {
    reverse: !isNext,
    at: span,
    match: Text.isText,
    voids: true,
    mode: 'all',
  })

  if (next == null) return point

  const [node, path] = next
  const offset = isNext
    ? 1
    : node.text.length - 1

  return { path, offset }
}
