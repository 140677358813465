import { combineReducers } from 'redux'
import * as fromApp from './app'
import * as fromUser from './user'
import * as fromModules from './modules'
import * as fromAlerts from './alerts'
import * as fromConversations from './conversations'
import * as fromTasks from './tasks'

// This is the root state of the app
// It contains every substate of the app
export interface State {
  app: fromApp.AppReduxState
  user: fromUser.UserReduxState
  modules: fromModules.ModulesReduxState
  alerts: fromAlerts.AlertsReduxState
  conversations: fromConversations.State
  tasks: fromTasks.State
}

// initialState of the app
export const initialState: State = {
  app: fromApp.initialState,
  user: fromUser.initialState,
  modules: fromModules.initialState,
  alerts: fromAlerts.initialState,
  conversations: fromConversations.initialState,
  tasks: fromTasks.initialState,
}

// Root reducer of the app
// Returned reducer will be of type Reducer<State>
export const reducer = combineReducers<State>({
  app: fromApp.reducer,
  user: fromUser.reducer,
  modules: fromModules.reducer,
  alerts: fromAlerts.reducer,
  conversations: fromConversations.reducer,
  tasks: fromTasks.reducer,
})
