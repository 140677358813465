import React from 'react'
import AletrnativeIdListElement from './AletrnativeIdListElement'
import Button from '../../../components/ui/Button'

export interface NewIdInputProps {
  setID: (repo: string, ID: string) => void
}

export default function NewIdInput({ setID }: NewIdInputProps) {
  const [repoName, setRepoName] = React.useState('')
  const [repoID, setRepoID] = React.useState('')
  const onAddID = React.useCallback(setID.bind(null, repoName, repoID), [setID, repoName, repoID])

  return <AletrnativeIdListElement repoName={repoName} repoID={repoID} setRepoName={setRepoName} setRepoID={setRepoID}>
    <Button
      l10nId="alternative-id-add-new-id-button"
      type="default"
      clickHandler={onAddID}
    />
  </AletrnativeIdListElement>
}
