import * as React from 'react'
import { useSelector } from 'react-redux'

const useIsLabelExisting = (name: string) => {
  const [state, setState] = React.useState(false)
  const labels = useSelector(state => state.modules.labels)

  const checkIfLabelExists = () => {
    const values = Object.values(labels)
    setState(Boolean(values.find(l => l.name.toLowerCase() === name.toLowerCase())))
  }

  React.useEffect(() => {
    checkIfLabelExists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return state
}

export default useIsLabelExisting
