import { AxiosResponse } from 'axios'
import axios, { BASE_API_URL } from '../config/axios'
import APICache from './apicache'
import BookPart from './bookpart'
import { TeamID } from './team'
import Pagination from './pagination'
import { elevated, extend } from './utils'

export type BookStatus = 'archived'

export type BookData = {
  id: string,
  title: string,
  team: TeamID
  license: string | null
  status?: BookStatus
}

export type Diff = {
  title?: string,
  license?: string | null,
}

export type NewModule = {
  title?: string,
  module: string,
}

export type NewGroup = {
  title: string,
  parts?: (NewModule | NewGroup)[],
}

export type NewPart = (NewModule | NewGroup) & {
  parent: number,
  index: number,
}

/**
 * Result of creating a new part in a book.
 */
export type NewPartData = {
  /**
   * Unique (within a book) ID of the created part.
   */
  number: number,

  /**
   * List of parts just created within this group.
   *
   * Present only if the part to be created was a group.
   */
  parts?: NewPartData[],
}

export interface AlternativeIds {
  [repository_url: string]: string
}

export type ExportOptions = {
  format?: 'legacy' | 'git',
  drafts?: boolean,
  suggestions?: 'accept' | 'reject',
}

export default class Book {
  /**
   * Load a book by ID.
   */
  static load(id: string, fromCache = true): Promise<Book> {
    return APICache.getOrSetNested(
      ['Books', id],
      fromCache,
      async () => new Book((await axios.get(`books/${id}`)).data),
    )
  }

  static pagination(fromCache = true): Pagination<Book> {
    return APICache.getOrSetNestedSync(
      ['Pagination', 'books'],
      fromCache,
      new Pagination(
        'books',
        (bd: BookData) => new Book(bd),
        books => APICache.update('Books', {
          ...books.reduce((obj, book) => ({ ...obj, [book.id]: book }), {}),
        }),
      ),
    )
  }

  /**
   * Create a new book.
   *
   * This function requires elevated permissions.
   *
   * @param title   title of the book
   * @param team   ID of team in which book should be created
   * @param content file containing initial contents of the book, in format
   * compatible with Connexion's ZIP export
   */
  static async create(title: string, team: number, content?: File): Promise<Book> {
    let data: FormData | { title: string, team: number }
    let config: { headers: { 'Content-Type': string } }
    if (content) {
      data = new FormData()
      data.append('title', title)
      data.append('team', team.toString())
      data.append('file', content)
      config = { headers: { 'Content-Type': 'multipart' } }
    } else {
      data = { title, team }
      config = { headers: { 'Content-Type': 'application/json' } }
    }

    const book = new Book((await axios.post('books', data, config)).data)
    return APICache.setNested(['Books', book.id], book)
  }

  /**
   * Book's ID.
   */
  id!: string

  /**
   * Book's title.
   */
  title!: string

  /**
   * ID of team for which this book belongs.
   */
  team!: TeamID

  /**
   * URL of the license under which this book is distributed
   */
  license!: string | null

  /**
   * Book status.
   */
  status?: BookStatus

  constructor(data: BookData) {
    extend(this, data)
  }

  /**
   * Mapping from alternative content repositories to
   * IDs this book has in those content repositories.
   */
  async alternativeIds(fromCache = true): Promise<AlternativeIds> {
    const ids = await APICache.getOrSetNested(
      ['Book/AlternativeIds', this.id],
      fromCache,
      async () => (await axios.get(`books/${this.id}/alternative-ids`)).data,
    )
    return ids
  }

  /**
   * Get ID this book has in alternative content repository
   */
  async alternativeIdForRepository(repository: string, fromCache = true): Promise<string> {
    const cached = APICache.getNested(['Book/AlternativeIds', this.id])
    if (fromCache && cached && cached[repository]) return cached[repository]
    const id: string = (await axios.get(`books/${this.id}/alternative-ids/${repository}`)).data
    APICache.setNested(
      ['Book/AlternativeIds', this.id],
      { ...cached || {}, [repository]: id },
    )
    return id
  }

  /**
   * Set ID this book has in alternative content repository
   * This endpoint is only available to users with the [`book:edit`]
   */
  async setAlternativeIdForRepository(repository: string, id: string): Promise<void> {
    await axios.put(`books/${this.id}/alternative-ids/${repository}`, id)
    const cached = APICache.getNested(['Book/AlternativeIds', this.id]) || {}
    APICache.setNested(
      ['Book/AlternativeIds', this.id],
      { ...cached, [repository]: id },
    )
  }

  /**
   * Unset ID this module has in alternative content repository
   * This endpoint is only available to users with the [`module:edit`]
   */
  async deleteAlternativeIdForRepository(repository: string): Promise<void> {
    await axios.delete(`books/${this.id}/alternative-ids/${repository}`)
      .then(() => {
        const cached = APICache.getNested(['Modules/AlternativeIds', this.id]) || {}
        delete cached[repository]
        APICache.setNested(['Modules/AlternativeIds', this.id], cached)
        return this
      })
  }

  /**
   * Fetch this book's structure.
   */
  parts(fromCache = true): Promise<BookPart> {
    return APICache.getOrSetNested(
      ['Books/Parts', this.id],
      fromCache,
      async () => new BookPart((await axios.get(`books/${this.id}/parts`)).data, this),
    )
  }

  /**
   * Update this book.
   *
   * This method requires elevated permissions.
   */
  async update(diff: Diff): Promise<void> {
    await elevated(() => axios.put(`books/${this.id}`, diff))
    this.title = diff.title || this.title
    this.license = diff.license !== undefined ? diff.license : this.license
  }

  /**
   * Create a new book part.
   *
   * This method requires elevated permissions.
   */
  async createPart(data: NewPart): Promise<NewPartData> {
    const rsp = await elevated(() => axios.post(`books/${this.id}/parts`, data))
    APICache.invalidate(['Books/Parts', this.id])
    return rsp.data
  }

  /**
   * Archive this book.
   *
   * This method requires book:edit permission.
   */
  archive(): Promise<AxiosResponse> {
    this.status = 'archived'
    return axios.post(`books/${this.id}/archive`, { archive: true })
  }

  /**
   * Restore this book from archive.
   *
   * This method requires book:edit permission.
   */
  restore(): Promise<AxiosResponse> {
    this.status = undefined
    return axios.post(`books/${this.id}/archive`, { archive: false })
  }

  /**
   * Export and download this book in a CNX-compatible ZIP archive.
   */
  export({ format, drafts, suggestions }: ExportOptions = {}) {
    const url = new URL(`${BASE_API_URL}books/${this.id}/export`, window.location.toString())

    if (format != null) url.searchParams.set("format", format)
    if (drafts != null) url.searchParams.set("drafts", drafts ? "true" : "false")
    if (suggestions != null) url.searchParams.set("suggestions", suggestions)

    window.open(url.toString())
  }

  /**
   * Check if book part exists in this book.
   */
  async hasBookPart(id: string): Promise<boolean> {
    const parts = await this.parts()
    return Boolean(findBookPartById(id, [parts]))
  }
}

const findBookPartById = (
  id: string, parts: BookPart[],
): BookPart | undefined => parts.find(part => {
  if (part.id === id) return true
  if (part.parts) return findBookPartById(id, part.parts)
  return false
})
