import * as React from 'react'
import { Localized } from '@fluent/react'
import Dialog from '../../../../../../../components/ui/Dialog'
import Button from '../../../../../../../components/ui/Button'

export type WrongTargetL10n =
  'step-changer-dialog-wrong-target'
  | 'step-changer-dialog-wrong-target-suggestions'
  | 'step-changer-dialog-wrong-target-highlights'
  | 'step-changer-dialog-wrong-target-media-text'

interface WrongTargetDialogProps {
  show: boolean
  wrongTargetL10n: WrongTargetL10n
  total: number
  onClose: () => void
}

const WrongTargetDialog = (props: WrongTargetDialogProps) => {
  if (!props.show) return null

  return (
    <Dialog
      size="medium"
      l10nId="step-changer-dialog-wrong-target-title"
      placeholder="Wrong target"
      onClose={props.onClose}
    >
      <div>
        <Localized
          id={props.wrongTargetL10n}
          vars={{ total: props.total }}
        >
          We could not find target which you selected.
          Please try again later or contact administrator.
        </Localized>
      </div>
      <div className="step-changer__dialog-controls">
        <Button l10nId="step-changer-dialog-suggestions-ok" clickHandler={props.onClose}>
          Ok
        </Button>
      </div>
    </Dialog>
  )
}

export default WrongTargetDialog
