import * as React from 'react'
import { Module } from '../../../api'
import Button from '../../../components/ui/Button'
import ModuleInfo from '../../../components/ModuleInfo'

interface SingleModuleProps {
  mod: Module
  onModuleClick: (mod: Module) => void
  onModuleRemove: (mod: Module) => void
}

const SingleModule = ({ mod, onModuleClick, onModuleRemove }: SingleModuleProps) => (
  <li className="modulesList__item">
    <span onClick={() => onModuleClick(mod)}>
      <ModuleInfo mod={mod}/>
    </span>
    <Button l10nId="module-list-remove" type="danger" clickHandler={() => onModuleRemove(mod)}>
      Remove
    </Button>
  </li>
)

export default SingleModule
