import * as React from 'react'
import Icon from '../../../../../../../components/ui/Icon'
import OutlineElement, { OutlineElementProps } from '../OutlineElement'
import Button, { ButtonHandler } from '../../../../../../../components/ui/Button'

const ExpandandleOutlineElement = ({ children, ...props }: OutlineElementProps) => {
  const [isExpanded, setIsExpanded] = React.useState(true)
  const toggleElement: ButtonHandler = event => {
    event.stopPropagation()
    setIsExpanded(prevState => !prevState)
  }

  const toggler = (
    <Button
      id="outline-element-toggle"
      className="expandable-element--toggler"
      clickHandler={toggleElement}
      withBorder={false}
    >
      <Icon size='extra-small' name={isExpanded ? 'arrow-down' : 'arrow-right'} />
    </Button>
  )

  return (
    <OutlineElement expanded={!isExpanded} {...props} toggler={toggler}>
      {children}
    </OutlineElement>
  )
}

export default ExpandandleOutlineElement
