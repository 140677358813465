import * as React from 'react'
import { useSelector } from 'react-redux'
import { Editor, Transforms } from 'slate'
import { Localized, withLocalization, WithLocalizationProps } from '@fluent/react'
import { State } from '../../../../../../../store/reducers'
import Avatar from '../../../../../../../components/ui/Avatar'
import Button from '../../../../../../../components/ui/Button'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'
import {
  Highlight,
  HIGHLIGHT_COLORS,
  HighlightColor,
} from '../../../../../../../screens/app/Draft/plugins/Highlights'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'

import './index.css'

interface HighlightToolsProps extends WithLocalizationProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const HighlightTools = ({ editor, ...props }: HighlightToolsProps) => {
  const selection = useCurrentSelection()
  const [highlightText, setHighlightText] = React.useState('')
  const user = useSelector((state: State) => state.user.user)
  const node = Editor.above(editor, { at: selection, match: Highlight.isHighlight })

  React.useEffect(() => setHighlightText(''), [selection?.anchor])
  if (!node) return null

  const [highlight, path] = node

  const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const textContent = (e.target as HTMLTextAreaElement).value
    setHighlightText(() => textContent)
    Transforms.setNodes(editor, { textContent }, { at: path, match: Highlight.isHighlight })
  }

  const removeHighlight = () => {
    Transforms.unwrapNodes(editor, { at: path, match: Highlight.isHighlight })
  }

  return (
    <ToolGroup
      title="editor-tools-highlight-title"
      toggleState={props.toggleState}
      onToggle={() => props.onToggle('highlightTools')}
    >
      {
        user?.id === highlight.user
          ?
          <>
            <div className="color-picker">
              {
                HIGHLIGHT_COLORS.map(color => (
                  <Color
                    key={color}
                    isActive={highlight.color === color}
                    color={color}
                    onSelect={color => Transforms.setNodes(editor, { color }, { at: path })}
                  />
                ))
              }
            </div>
            <div className="highlight__content">
              <textarea
                onChange={onChange}
                value={highlightText.length ? highlightText : highlight.textContent}
                placeholder={props.getString('editor-highlight-message-placeholder')}
                rows={5}
              />
            </div>
          </>
          : <div className="highlight__content">
            <textarea className="highlight__message" readOnly value={highlight.textContent} />
            <span className="highlight__author">
              <Localized id="editor-highlight-author">
                Author:
              </Localized>
              <Avatar size="small" withName={true} user={highlight.user} />
            </span>
          </div>
      }
      <Button l10nId="editor-tools-highlight-remove" clickHandler={() => removeHighlight()}>
        Remove highlight
      </Button>
    </ToolGroup>
  )
}

export default withLocalization(HighlightTools)

interface ColorProps {
  isActive: boolean
  color: HighlightColor
  onSelect: (color: HighlightColor) => void
}

const Color = ({ isActive, color, onSelect }: ColorProps) => {
  const onClick = () => {
    if (isActive) return
    onSelect(color)
  }

  const classes = ['color-picker__item', `highlight--${color}`]
  if (isActive) {
    classes.push('color-picker__item--active')
  }

  return <div className={classes.join(' ')} onClick={onClick} />
}
