import * as React from 'react'
import * as Counters from 'react-counters'
import { Solution } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { EXERCISE, EXERCISE_SOLUTION } from '../../counters'
import { LOCALIZATION } from '../../constants'

interface ExerciseSolutionProps extends RenderElementProps {
  element: Solution
}

const ExerciseSolutionComp = ({ children, attributes }: ExerciseSolutionProps) => {
  const { document } = React.useContext(LOCALIZATION)
  Counters.useCounter(attributes.ref, { increment: [EXERCISE_SOLUTION] })
  const exercise = Counters.useCounterValue(attributes.ref, EXERCISE)
  const solution = Counters.useCounterValue(attributes.ref, EXERCISE_SOLUTION)
  const message = document.getString('exercise-solution-label', { exercise, solution })

  return <div {...attributes} className="exercise-solution" data-label={message}>
    {children}
  </div>
}

export default ExerciseSolutionComp
