import * as React from 'react'
import { RenderElementProps } from 'slate-react'
import { Highlight } from '..'

interface HighlightProps extends RenderElementProps {
  element: Highlight
}

const HighlightComp = ({ element, children, attributes }: HighlightProps) => (
  <span
    className={`highlight highlight--${element.color}`}
    {...attributes}
  >
    {children}
  </span>
)

export default HighlightComp
