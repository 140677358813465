import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import APICache from './apicache'
import Pagination from './pagination'
import { TeamID, TeamPermission } from './team'
import { elevated, extend } from './utils'

export type RoleStatus = 'archived'

export type RoleID = number

/**
 * Role data.
 */
export type RoleData = {
  id: RoleID
  name: string
  permissions?: TeamPermission[] // only user with role:edit can see this field
  status?: RoleStatus
}

export interface RoleUpdateData {
  name?: string
  permissions?: TeamPermission[]
}

export default class Role {
  /**
   * Fetch specific role from the server.
   */
  static async load(id: number, team: TeamID, fromCache = true): Promise<Role> {
    const roleId = id.toString()
    const teamId = id.toString()
    const cached = APICache.getNested(['Roles', teamId])
    if (fromCache && cached && cached[teamId] && cached[teamId][roleId]) {
      return cached[teamId][roleId]
    }
    const role = new Role((await axios.get(`teams/${team}/roles/${id}`)).data, team)
    APICache.setNested(
      ['Roles', teamId],
      { ...cached, [roleId]: role })
    return role
  }

  static pagination(team: TeamID, fromCache = true): Pagination<Role> {
    return APICache.getOrSetNestedSync(
      ['Pagination', `team-${team}-roles`],
      fromCache,
      new Pagination(
        `teams/${team}/roles`,
        (data: RoleData) => new Role(data, team),
        roles => APICache.updateNested(
          ['Roles', team.toString()],
          roles.reduce((obj, res) => ({ ...obj, [res.id]: res }), {}),
        ),
      ),
    )
  }

  /**
   * Create a new role.
   *
   * This function requires role:edit permission.
   *
   * @param team
   * @param name
   * @param permissions - Permission[]
   */
  static async create(
    team: TeamID,
    name: string,
    permissions: TeamPermission[] = [],
  ): Promise<Role> {
    const newRole = new Role(
      (await elevated(() => axios.post(`teams/${team}/roles`, { name, permissions }))).data,
      team,
    )
    const teamId = team.toString()
    const roleId = newRole.id.toString()
    APICache.updateNested(
      ['Roles', teamId],
      { [roleId]: newRole })
    return newRole
  }

  /**
   * ID of team in which this roles was created.
   */
  team: TeamID

  /**
   * Roles's ID.
   */
  id!: RoleID

  /**
   * Roles's name.
   */
  name!: string

  /**
   * Roles's permissions.
   */
  permissions?: TeamPermission[]

  /**
   * Role status.
   */
  status?: RoleStatus

  constructor(data: RoleData, team: TeamID) {
    extend(this, data)

    this.team = team
  }

  /**
   * Update a role.
   *
   * This function requires role:edit permission.
   *
   * @param {RoleUpdateData} data - object with data to update
   */
  async update(data: RoleUpdateData): Promise<Role> {
    const role = new Role(
      (await elevated(() => axios.put(`teams/${this.team}/roles/${this.id}`, data))).data,
      this.team)
    this.name = data.name || this.name
    this.permissions = data.permissions || this.permissions
    return role
  }


  /**
   * Archive this role.
   *
   * This method requires role:edit permission and elevated session.
   */
  archive(): Promise<AxiosResponse> {
    this.status = 'archived'
    return elevated(() => axios.post(
      `teams/${this.team}/roles/${this.id}/archive`, { archive: true }))
  }

  /**
   * Restore this role from archive.
   *
   * This method requires role:edit permission and elevated session.
   */
  restore(): Promise<AxiosResponse> {
    this.status = undefined
    return elevated(() => axios.post(
      `teams/${this.team}/roles/${this.id}/archive`, { archive: false }))
  }
}
