import axios, { BASE_API_URL } from '../config/axios'
import Draft from './draft'
import Pagination from './pagination'
import APICache, { APICacheMap } from './apicache'
import { TeamID } from './team'
import { elevated, extend } from './utils'

export type ModuleStatus = 'archived'

/**
 * Module data as returned by the API.
 */
export type ModuleData = {
  id: string
  title: string
  language: string
  license: string | null
  process: ModuleProcess | null
  team: TeamID
  alternative_ids?: AlternativeIds
  status?: ModuleStatus
}

/**
 * Short info about current process for module.
 */
export type ModuleProcess = {
  process: number,
  step: {
    id: number,
    name: string,
  },
  version: number,
}

export type RefTargetType
  = 'commentary'
  | 'example'
  | 'exercise'
  | 'figure'
  | 'note'
  | 'solution'
  | 'subfigure'

export type RefTarget = {
  /**
   * This target element's ID.
   */
  id: string,
  /**
   * This element's type.
   */
  type: RefTargetType,
  /**
   * A short description of this element intended to make it easier for users
   * to select the correct element when creating a cross-document reference.
   */
  description: string | null,
  /**
   * ID of a reference target “owning” this one.
   */
  context: string | null,
  /**
   * Value of the type-counter at this element.
   *
   * For elements that have `context` type counter resets at context.
   */
  counter: number,
}

export interface AlternativeIds {
  [repository_url: string]: string
}

export default class Module {
  /**
   * Fetch module by ID.
   */
  static load(id: string, fromCache = true): Promise<Module> {
    return APICache.getOrSetNested(
      ['Modules', id],
      fromCache,
      async () => new Module((await axios.get(`modules/${id}`)).data),
    )
  }

  static pagination(fromCache = true): Pagination<Module> {
    return APICache.getOrSetNestedSync(
      ['Pagination', 'modules'],
      fromCache,
      new Pagination(
        'modules',
        (bd: ModuleData) => new Module(bd),
        modules => APICache.update('Modules', {
          ...modules.reduce((obj, mod) => ({ ...obj, [mod.id]: mod }), {}),
        }),
      ),
    )
  }

  /**
   * Create a new module.
   * This function requires elevated permissions.
   *
   * @param title
   * @param language - ISO language tag
   * @param team - team ID in which module should be created.
   */
  static async create(title: string, language: string, team: TeamID): Promise<Module> {
    const rsp = await elevated(() => axios.post('modules', { title, language, team }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }))
    const mod = new Module(rsp.data)
    return APICache.setNested(['Modules', mod.id], mod)
  }

  /**
   * ID of this module.
   */
  id!: string

  /**
   * Title of this module.
   */
  title!: string

  /**
   * Language of this document.
   */
  language!: string

  /**
   * URL of the license under which this module is distributed
   */
  license!: string | null

  /**
   * Short info about current process for this module.
   */
  process!: ModuleProcess | null

  /**
   * ID of team for which this modules belongs.
   */
  team!: TeamID

  /**
   * Mapping from alternative content repositories to
   * IDs this module has in those content repositories.
   */
  alternative_ids!: AlternativeIds

  /**
   * Module status.
   */
  status?: ModuleStatus

  constructor(data: ModuleData) {
    extend(this, data)
    if (!data.alternative_ids) {
      this.alternative_ids = {}
    }
  }

  /**
   * Mapping from alternative content repositories to
   * IDs this module has in those content repositories.
   */
  async alternativeIds(fromCache = true): Promise<AlternativeIds> {
    const ids = await APICache.getOrSetNested(
      ['Modules/AlternativeIds', this.id],
      fromCache,
      async () => (await axios.get(`modules/${this.id}/alternative-ids`)).data,
    )
    this.alternative_ids = ids
    return ids
  }

  /**
   * Get ID this module has in alternative content repository
   */
  async alternativeIdForRepository(repository: string, fromCache = true): Promise<string> {
    const cached = APICache.getNested(['Modules/AlternativeIds', this.id])
    if (fromCache && cached && cached[repository]) return cached[repository]
    const id: string = (await axios.get(`modules/${this.id}/alternative-ids/${repository}`)).data
    APICache.setNested(
      ['Modules/AlternativeIds', this.id],
      { ...cached || {}, [repository]: id },
    )
    this.alternative_ids[repository] = id
    return id
  }

  /**
   * Set ID this module has in alternative content repository
   * This endpoint is only available to users with the [`module:edit`]
   */
  async setAlternativeIdForRepository(repository: string, id: string): Promise<void> {
    await axios.put(`modules/${this.id}/alternative-ids/${repository}`, id)
    const cached = APICache.getNested(['Modules/AlternativeIds', this.id]) || {}
    APICache.setNested(
      ['Modules/AlternativeIds', this.id],
      { ...cached, [repository]: id },
    )
    this.alternative_ids[repository] = id
  }

  /**
   * Unset ID this module has in alternative content repository
   * This endpoint is only available to users with the [`module:edit`]
   */
  async deleteAlternativeIdForRepository(repository: string): Promise<void> {
    await axios.delete(`modules/${this.id}/alternative-ids/${repository}`)
    const cached = APICache.getNested(['Modules/AlternativeIds', this.id]) || {}
    delete cached[repository]
    this.alternative_ids = cached
    APICache.setNested(['Modules/AlternativeIds', this.id], cached)
  }

  /**
   * Fetch list of files for this module. This list does not include index.cnxml.
   */
  files(fromCache = true): Promise<string[]> {
    return APICache.getOrSetNested(
      ['Modules/Files', this.id],
      fromCache,
      async () => (await axios.get(`modules/${this.id}/files`)).data,
    )
  }

  /**
   * Fetch all books ids in which this module occurs.
   */
  books(fromCache = true): Pagination<string> {
    return APICache.getOrSetNestedSync(
      ['Pagination', `ModulesBooks-${this.id}`],
      fromCache,
      new Pagination(
        `/modules/${this.id}/books`,
        undefined,
        undefined,
      ),
    )
  }

  /**
   * Fetch contents of a file.
   */
  async read(name: string): Promise<string> {
    // Do not cache this.
    return (await axios.get(`modules/${this.id}/files/${name}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })).data
  }

  /**
   * Fetch list of possible reference targets in this module.
   */
  referenceTargets(fromCache = true): Promise<RefTarget[]> {
    return APICache.getOrSetNested(
      ['Modules/XrefTargets', this.id],
      fromCache,
      async () => (await axios.get(`modules/${this.id}/xref-targets`)).data,
    )
  }

  /**
   * Get an existing draft for this module, or {@code null}.
   */
  async draft(): Promise<Draft | null> {
    try {
      return await Draft.load(this)
    } catch (ex) {
      return null
    }
  }

  /**
   * Begin process for this module.
   *
   * This method requires editing-process:manage permission.
   *
   * @param process processId
   * @param slots array of pairs [slotId, userId]
   */
  async beginProcess(data: { process: number, slots: [number, number][]}): Promise<void> {
    await axios.post(`modules/${this.id}`, data)
    const pagination = this.books()
    await pagination.loadAll()
    APICache.invalidate(
      ['Modules', this.id],
      'Drafts',
      ...pagination.items().map(id => ['Books/Parts', id] as [keyof APICacheMap, string]))
  }

  /**
   * Archive this module.
   *
   * This method requires module:edit permission.
   */
  async archive(): Promise<void> {
    await axios.post(`modules/${this.id}/archive`, { archive: true })
    this.status = 'archived'
  }

  /**
   * Restore this module from archive.
   *
   * This method requires module:edit permission.
   */
  async restore(): Promise<void> {
    await axios.post(`modules/${this.id}/archive`, { archive: false })
    this.status = undefined
  }

  /**
   * Export and download this module in a CNX-compatible ZIP archive.
   */
  export() {
    window.open(`${BASE_API_URL}modules/${this.id}/export`)
  }
}
