import * as React from 'react'
import { WithClasses } from 'cnx-designer'
import { Element, NodeEntry, Transforms } from 'slate'
import { useSlate } from 'slate-react'
import { WithContext as ReactTags } from 'react-tag-input'
import { Localized, withLocalization, WithLocalizationProps } from '@fluent/react'
import { StorageContext } from '../../../../../../../api/storage'
import { createUUID } from '../../../../../../../helpers'

import './index.css'

interface ClassesProps {
  node: NodeEntry<Element & Partial<WithClasses>>
}

type Tag = { id: string, text: string }

const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space]

const Classes = ({ node }: ClassesProps) => {
  const editor = useSlate()
  const [block, path] = node

  const updateTags = (tags: Tag[]) => {
    Transforms.setNodes(editor, { classes: tags.map(t => t.text) }, { at: path })
  }

  return <SharedComp classes={block.classes || []} onUpdate={updateTags} />
}

export default Classes

export const DocumentClasses = () => {
  const storageContext = React.useContext(StorageContext)
  const [classes, setClasses] = React.useState(storageContext.documentClasses || [])

  const updateTags = (tags: Tag[]) => {
    const arrayOfClasses = tags.map(t => t.text)
    setClasses(WithClasses.normalizeClasses(arrayOfClasses))
    storageContext.setDocumentClasses(arrayOfClasses)
  }

  return <SharedComp classes={classes} onUpdate={updateTags} />
}

interface SharedCompProps extends WithLocalizationProps {
  classes: string[]
  onUpdate: (tags: Tag[]) => void
}

const SharedComp = withLocalization(({ classes, onUpdate, getString }: SharedCompProps) => {
  const id = React.useMemo(createUUID, [])
  const tags: Tag[] = React.useMemo(() => classes.map(c => ({ id: c, text: c })), [classes])

  const onClick = () => {
    const input = document.getElementById(id) as HTMLInputElement
    input.focus()
  }

  const updateTags = (tags: Tag[]) => {
    onUpdate(tags)
  }

  const handleDelete = (i: number) => {
    updateTags(tags.filter((_, index) => index !== i))
  }

  const handleAddition = (tag: Tag) => {
    updateTags([...tags, tag])
  }

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = [...tags]
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    updateTags(newTags)
  }

  return (
    <div id="class-list-select" className="classes" onClick={onClick}>
      <span className="classes__title">
        <Localized id="editor-tools-classes-title">
          List of classes
        </Localized>
      </span>
      <ReactTags
        autofocus={false}
        id={id}
        tags={tags}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        placeholder={getString('editor-tools-classes-placeholder')}
      />
    </div>
  )
})
