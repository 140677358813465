import BookPart, { GroupData } from "./bookpart"

export const removeBookPartsWhichDoesNotMatchDrafts = (
  groupOrModule: BookPart, drafts: Set<string>,
): BookPart | null => {
  if (groupOrModule.kind === 'module') {
    if (drafts.has(groupOrModule.id!)) {
      return groupOrModule
    }
    return null
  }

  const parts: BookPart[] = []

  groupOrModule.parts!.forEach(p => {
    if (p.kind === 'module') {
      if (drafts.has(p.id!)) {
        parts.push(p)
      }
    } else if (p.kind === 'group') {
      const trimmedGroup = removeBookPartsWhichDoesNotMatchDrafts(p, drafts)
      if (trimmedGroup) {
        parts.push(trimmedGroup)
      }
    }
  })

  return parts.length
    ? new BookPart({ ...groupOrModule, parts } as GroupData, groupOrModule.book)
    : null
}

const findOne = (parts: BookPart[], ids: string[]): string | undefined => {
  for (const part of parts) {
    if (part.kind === 'module' && ids.includes(part.id!)) return part.id!
    if (part.kind === 'group') {
      const found = findOne(part.parts!, ids)
      if (found) return found
    }
  }
  return undefined
}

export const sortDraftIdsByBookParts = (
  parts: BookPart[],
) => (
  id1: string,
  id2: string,
): number => {
  const found = findOne(parts, [id1, id2])
  if (found === id1) return -1
  if (found === id2) return 1
  return 0
}
