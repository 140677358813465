import * as React from 'react'
import * as CNXML from 'slate-cnxml'
import { Figure } from 'cnx-designer'
import { Editor, NodeEntry, Path, Element as SlateElement, Transforms } from 'slate'
import { RenderElementProps } from 'slate-react'
import { LOCALIZATION } from '../../constants'

import '../cnxml'

export interface MediaText extends SlateElement {
  type: 'media_text'
}

export const MediaText = {
  isMediaText: (value: any): value is MediaText => {
    return SlateElement.isElement(value) && value.type === 'media_text'
  },
}

export const normalizeMediaText = (editor: Editor, nodeEntry: NodeEntry): boolean => {
  const [element, path] = nodeEntry
  if (MediaText.isMediaText(element)) {
    const prev = Editor.previous(editor, { at: path })
    if (!prev || !Figure.isFigure(prev[0])) {
      Transforms.setNodes(editor, { type: 'paragraph' }, { at: path })
      return true
    }
  }
  return false
}

/**
 * Deserialize MediaText element. Return true if it was dserialized and false otherwise.
 */
export const deserializeMediaText = (
  editor: CNXML.DeserializingEditor,
  el: Element,
  at: Path,
): boolean => {
  if (el.namespaceURI === CNXML.EDITING_NAMESPACE && el.localName === 'media-text') {
    CNXML.buildElement(editor, el, at, {
      type: 'media-text',
    }, { ...CNXML.INLINE })
    CNXML.normalizeLine(editor, at)
    return true
  }
  return false
}

/**
 * Serialize MediaText element to xml element.
 */
export const serializeMediaText: CNXML.PartialSerializer<any, any> = (node, attrs, children) => {
  if (MediaText.isMediaText(node)) {
    return CNXML.jsx('media-text', {
      ...attrs,
      xmlns: CNXML.EDITING_NAMESPACE,
      children,
    })
  }
  return null
}

interface MediaTextProps extends RenderElementProps {
  element: MediaText
}

const MediaTextComp = (props: MediaTextProps) => {
  const context = React.useContext(LOCALIZATION)
  const message = context.ui.getString('media-text-label') || 'Text on media:'

  return (
    <div
      {...props.attributes}
      className="media-text"
      data-label={message}
    >
      {props.children}
    </div>
  )
}

export default MediaTextComp
