import * as React from 'react'
import { useSelector } from 'react-redux'
import { Localized } from '@fluent/react'
import { Team, User } from '../../api'
import UserInfo from '../../components/UserInfo'
import PageNavigation from '../../components/PageNavigation'
import Input from '../../components/ui/Input'

import './index.css'

interface UsersListProps {
  teams?: Team[]
  isSearchable?: boolean
  allowedRoles?: number[]
  onUserClick: (user: User) => void
}

const UsersListComp = ({ teams, isSearchable, allowedRoles, onUserClick }: UsersListProps) => {
  const user = useSelector(state => state.user.user)
  const [filterInput, setFilterInput] = React.useState('')
  const filterRegexp = new RegExp(filterInput, 'i')

  const handleFilterInput = (val: string) => {
    if (val !== filterInput) {
      setFilterInput(val)
    }
  }

  if (!user) return null

  return (
    <div className="usersList">
      {
        isSearchable ?
          <Input
            type="text"
            l10nId="user-profile-team-list-search"
            onChange={handleFilterInput}
            required
          />
          : null
      }
      <PageNavigation
        pagination={User.pagination()}
        usePageParam={false}
        Component={({ items }) => {
          const filtered = items.filter(u => {
            if (filterInput && !u.name.match(filterRegexp)) return false
            if (teams?.length && !teams.some(t => u.isInTeam(t))) return false
            if (allowedRoles?.length && !allowedRoles.some(r => u.hasRole(r))) return false
            return true
          })

          return <ul className="usersList__list">
            {
              filtered.length ?
                filtered.map(user => (
                  <UserListItem
                    key={user.id}
                    user={user}
                    onUserClick={onUserClick}
                  />
                ))
                :
                <li className="usersList__item--no-results">
                  <Localized id="user-profile-team-list-no-results">
                    There are no users with specified criteria.
                  </Localized>
                </li>
            }
          </ul>
        }}
      />
    </div>
  )
}

export default UsersListComp

function UserListItem({ user, onUserClick }: { user: User, onUserClick: (user: User) => void }) {
  const onClick = () => {
    onUserClick(user)
  }

  return (
    <li id="user-list-item-select" className="usersList__item" onClick={onClick}>
      <UserInfo user={user} />
    </li>
  )
}
