import * as React from 'react'
import { Link } from 'react-router-dom'
import { Localized } from '@fluent/react'
import Notification, {
  ConversationNewMessage,
  Data,
  isConversationNewMessage,
  isProcessCanceled,
  isProcessChangeAdvanced,
  isProcessChangeChangedProcess,
  isProcessFinished,
  isSlotAssignmentFilled,
  isSlotAssignmentVacated,
  isSupportNewTicket,
  ProcessCanceled,
  ProcessChangeAdvanced,
  ProcessChangeChangedProcess,
  ProcessFinished,
  SlotAssignmentFilled,
  SlotAssignmentVacated,
  SupportNewTicket,
} from '../../../../api/notification'

const NotificationText = ({ notification }: { notification: Notification<Data> }) => {
  if (isConversationNewMessage(notification.data)) {
    return <ConversationNewMessageComp data={notification.data} />
  } else if (isProcessCanceled(notification.data)) {
    return <ProcessCanceledComp data={notification.data} />
  } else if (isProcessFinished(notification.data)) {
    return <ProcessFinishedComp data={notification.data} />
  } else if (isProcessChangeAdvanced(notification.data)) {
    return <ProcessChangeAdvancedComp data={notification.data} />
  } else if (isProcessChangeChangedProcess(notification.data)) {
    return <ProcessChangeChangedProcessComp data={notification.data} />
  } else if (isSlotAssignmentFilled(notification.data)) {
    return <SlotAssignmentFilledComp data={notification.data} />
  } else if (isSlotAssignmentVacated(notification.data)) {
    return <SlotAssignmentVacatedComp data={notification.data} />
  } else if (isSupportNewTicket(notification.data)) {
    return <SupportNewTicketComp data={notification.data} />
  }

  return <Localized id="notification-unknown">
    Unknown action
  </Localized>
}

export default NotificationText

const ConversationNewMessageComp = ({ data }: { data: ConversationNewMessage }) => (
  <Localized
    id="notification-conversation-new-message"
    elems={{ linkto: <Link to={`/users/${data.author.id}`}/> }}
    vars={{ author: data.author.name }}
  >
    <div className="notification__action" />
  </Localized>
)

const ProcessCanceledComp = ({ data }: { data: ProcessCanceled }) => (
  <Localized
    id="notification-process-end-cancelled"
    elems={{ linkto: <Link to={`/modules/${data.module.id}`}/> }}
    vars={{ module: data.module.title }}
  >
    <div className="notification__action" />
  </Localized>
)

const ProcessFinishedComp = ({ data }: { data: ProcessFinished }) => (
  <Localized
    id="notification-process-end-finished"
    elems={{ linkto: <Link to={`/modules/${data.module.id}`}/> }}
    vars={{ module: data.module.title }}
  >
    <div className="notification__action" />
  </Localized>
)

const ProcessChangeAdvancedComp = ({ data }: { data: ProcessChangeAdvanced }) => (
  <Localized
    id="notification-process-change-advanced"
    elems={{ linkto: <Link to={`/drafts/${data.draft.id}`}/> }}
    vars={{ draft: data.draft.title, step: data.step.name }}
  >
    <div className="notification__action" />
  </Localized>
)

const ProcessChangeChangedProcessComp = ({ data }: { data: ProcessChangeChangedProcess }) => (
  <Localized
    id="notification-process-change-changed-process"
    elems={{ linkto: <Link to={`/drafts/${data.draft.id}`}/> }}
    vars={{ draft: data.draft.title, process: data.process.name }}
  >
    <div className="notification__action" />
  </Localized>
)

const SlotAssignmentFilledComp = ({ data }: { data: SlotAssignmentFilled }) => (
  <Localized
    id="notification-slot-assignment-filled"
    elems={{ linkto: <Link to={`/drafts/${data.draft.id}`}/> }}
    vars={{ draft: data.draft.title, slot: data.slot.name }}
  >
    <div className="notification__action" />
  </Localized>
)

const SlotAssignmentVacatedComp = ({ data }: { data: SlotAssignmentVacated }) => (
  <Localized
    id="notification-slot-assignment-vacated"
    elems={{ linkto: <Link to={`/drafts/${data.draft.id}`}/> }}
    vars={{ draft: data.draft.title, slot: data.slot.name }}
  >
    <div className="notification__action" />
  </Localized>
)

const SupportNewTicketComp = ({ data }: { data: SupportNewTicket }) => (
  <Localized
    id="notification-support-new-ticket"
    elems={{ linkto: <Link to={`/users/${data.author.id}`}/> }}
    vars={{ author: data.author.name, title: data.ticket.title }}
  >
    <div className="notification__action" />
  </Localized>
)
