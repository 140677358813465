import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Localized } from '@fluent/react'
import { Book } from '../../api'
import { addAlert } from '../../store/actions/alerts'
import confirmDialog from '../../helpers/confirmDialog'
import LimitedUI from '../../components/LimitedUI'
import EditBook from '../../components/EditBook'
import Button from '../../components/ui/Button'
import CardTitle from './CardTitle'
import Icon from '../ui/Icon'
import Tooltip from '../ui/Tooltip'

import './index.css'

export interface BookCardProps {
  book: Book
  isEditingUnlocked: boolean
  afterDelete: (book: Book) => void
}

const BookCard = ({ book, isEditingUnlocked, afterDelete }: BookCardProps) => {
  const dispatch = useDispatch()
  const [showEditBook, setShowEditBook] = React.useState(false)

  const removeBook = async () => {
    const res = await confirmDialog({
      title: 'book-delete-title',
      vars: { title: book.title },
      buttons: {
        cancel: 'book-delete-cancel',
        confirm: 'book-delete-confirm',
      },
    })

    if (res === 'confirm') {
      book.archive()
        .then(() => {
          dispatch(addAlert('success', 'book-delete-alert-success'))
          afterDelete(book)
        })
        .catch(e => {
          dispatch(addAlert('error', e.message))
        })
    }
  }

  const showEdit = () => {
    setShowEditBook(true)
  }

  const closeEdit = () => {
    setShowEditBook(false)
  }

  return (
    <div className={`card ${isEditingUnlocked ? 'card--editing' : ''}`}>
      {
        isEditingUnlocked ?
          <div className="card__content">
            <CardTitle book={book} />
            <div className="card__buttons">
              <LimitedUI team={book.team} permissions="book:edit">
                <Button id="book-card-edit" clickHandler={showEdit}>
                  <Localized id="book-card-edit">
                    Edit
                  </Localized>
                </Button>
                <Button id="book-card-remove" type="danger" clickHandler={removeBook}>
                  <Localized id="book-card-remove">
                    Remove
                  </Localized>
                </Button>
              </LimitedUI>
            </div>
          </div>
          :
          <Link to={`books/${book.id}`} className="card__content">
            <CardTitle book={book} />
          </Link>
      }
      <Tooltip l10nId="book-card-drafts-details" className="bookList__details-icon">
        <Link to={`books/details/${book.id}`}>
          <Icon size="small" name="details"/>
        </Link>
      </Tooltip>
      {
        showEditBook ?
          <EditBook
            book={book}
            onClose={closeEdit}
          />
          : null
      }
    </div>
  )
}

export default BookCard
