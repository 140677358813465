import * as React from 'react'
import MathJax from 'react-mathjax-preview'
import { RenderElementProps, useSelected } from 'slate-react'
import { Math } from '..'

import './index.css'

interface MathProps extends RenderElementProps {
  element: Math
}

export default function MathComp({ element, attributes, children }: MathProps) {
  const selected = useSelected()
  const displayClass = element.placement === 'line' ? 'displayInline' : null

  return (
    <div
      className={`math ${displayClass}`}
      data-selected={selected}
      {...attributes}
      {...element.namespaces}
      contentEditable={false}
    >
      <MathJax math={element.mathml} />
      {children}
    </div>
  )
}
