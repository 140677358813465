import { parse } from 'querystring'
import { useLocation } from 'react-router'

const useQuery = <T extends { [key: string]: string | string[] | undefined }>(): T => {
  const location = useLocation()
  const query = parse(location.search.replace('?', ''))
  return query as T
}

export default useQuery
