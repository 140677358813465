import * as React from 'react'

export interface RadioProps<T> {
  /**
   * Name assigned to the value within a form
   *
   * May be omitted if there is only one radio group within a form, and it is
   * never submitted.
   */
  name?: string
  value: T
  options: T[]
  label: (option: T) => React.ReactNode
  comparator?: (a: T, b: T) => boolean
  defaultSelection?: number
  onChange: (option: T) => void
}

export default function Radio<T>({
  name = "__radio",
  value,
  options,
  label,
  comparator = defaultComparator,
  defaultSelection = 0,
  onChange,
}: RadioProps<T>) {
  const selection = options.findIndex(a => comparator(value, a))

  const changeListener = React.useCallback(ev => {
    const value = Number(ev.target.value)
    onChange(options[value])
  }, [options])

  return <>
    {options.map((option, index) => (
      <label key={index}>
        <input
          type="radio"
          name={name}
          value={index}
          checked={selection === index}
          onChange={changeListener}
        />
        {label(option)}
      </label>
    ))}
  </>
}

function defaultComparator<T>(a: T, b: T): boolean {
  return a === b
}
