import * as React from 'react'
import { Localized } from '@fluent/react'
import { useSelector } from 'react-redux'
import { User } from '../../api'
import { DraftAndBookPart } from '../../api/draft'
import store from '../../store'
import { updateUserIsSupport, updateUserName } from '../../store/actions/user'
import { confirmDialog } from '../../helpers'
import { useIsInSuperMode } from '../../hooks'
import DraftsList from '../../components/DraftsList'
import EditableText from '../../components/EditableText'
import Avatar from '../../components/ui/Avatar'
import Button from '../../components/ui/Button'
import LimitedUI from '../../components/LimitedUI'

import './index.css'

interface UserProfileProps {
  user: User
  onUpdate: (user: User) => void
}

const UserProfile = ({ user, onUpdate }: UserProfileProps) => {
  const loggedUser = useSelector(state => state.user.user)
  const [userName, setUserName] = React.useState(user?.name || '')
  const [drafts, setDrafts] = React.useState<DraftAndBookPart[]>([])
  const [isSupport, setIsSupport] = React.useState(Boolean(user?.is_support))
  const isInSuperMode = useIsInSuperMode(user)

  const handleNameChange = (name: string) => {
    if (!user || !loggedUser) return
    const usr = user.id === loggedUser.id ? loggedUser : user
    store.dispatch(updateUserName(usr, name, onUpdate))
  }

  const toggleIsSupporFlag = async () => {
    if (!user) return
    const newState = !isSupport
    if (!newState) {
      const res = await confirmDialog({
        title: 'user-profile-support-flag-remove-confirm',
        size: 'medium',
        vars: { user: user.name },
        buttons: {
          cancel: 'user-profile-support-flag-remove-confirm-cancel',
          remove: 'user-profile-support-flag-remove-confirm-remove',
        },
      })

      if (res !== 'remove') return
    }

    store.dispatch(updateUserIsSupport(user, newState))
  }

  React.useEffect(() => {
    let mounted = true

    if (user) {
      setUserName(user.name)
      setIsSupport(user.is_support)

      if (
        loggedUser
        && (loggedUser.is_super || loggedUser.allPermissions.has('editing-process:manage'))
      ) {
        user.drafts()
          .then(drafts => {
            if (mounted) setDrafts(drafts)
          })
      }
    }

    return () => {
      mounted = false
      setUserName('...')
      setIsSupport(false)
      setDrafts([])
    }
  }, [user, loggedUser])

  if (!user || !loggedUser) return null

  return <div className="profile">
    <div className="profile__top">
      <div className="profile__avatar">
        <Avatar size="big" disableLink user={user}/>
      </div>
      <div className="profile__main-info">
        <h2 className="profile__name">
          {
            user.id === loggedUser.id || isInSuperMode ?
              <EditableText
                text={userName}
                onAccept={handleNameChange}
                minLength={3}
                maxLength={30}
                placeholder="user-profile-user-name"
              />
              : user.name
          }
        </h2>
      </div>
    </div>
    <div className="profile__info">
      <LimitedUI permissions="editing-process:manage">
        <h3 className="profile__title">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Localized id="user-profile-users-drafts">User's drafts</Localized>
        </h3>
        <DraftsList drafts={drafts} />
      </LimitedUI>
    </div>
    <LimitedUI onlySuperElevated={true}>
      <div className="profile__flags">
        <Button
          l10nId={isSupport
            ? 'user-profile-support-flag-remove'
            : 'user-profile-support-flag-add'}
          clickHandler={toggleIsSupporFlag}
        >
          Toggle support flag
        </Button>
      </div>
    </LimitedUI>
  </div>
}

export default UserProfile
