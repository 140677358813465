import { Link } from 'react-router-dom'
import './index.css'

const Error404 = () => (
  <div className="error404">
    <h1>This page does not exists. Please go back to <Link to="/">dashboard</Link>.</h1>
  </div>
)

export default Error404
