import * as React from 'react'
import { useSelector } from 'react-redux'
import { ModuleLabel } from '../../store/types'
import Input from '../../components/ui/Input'

export type SearchQueries = {
  text: string
  label?: ModuleLabel | null
  mimeCategory?: string
}

interface SearchInputProps {
  value?: SearchQueries
  placeholder?: string
  slowMode?: boolean
  onChange: (search: SearchQueries) => void
}

const SearchInput = ({ value, placeholder, slowMode = false, onChange }: SearchInputProps) => {
  const labels = useSelector(state => state.modules.labels)

  // Used when slowMode === true
  let typingTimer: NodeJS.Timeout

  const handleChange = (input: string | null) => {
    if (slowMode) {
      clearTimeout(typingTimer)
    }

    // Match label:Name and label:"Name with spaces"
    const labelRgx = new RegExp(/label:(("|').+("|')|\w+)/, 'g')

    // Match mime:cat/
    const mimeCategoryRgx = new RegExp(/mime:(\w|\d)+\//, 'g')

    const result: SearchQueries = {
      text: input as string || '',
    }
    if (input !== null) {
      const labelMatch = input.match(labelRgx)
      if (labelMatch) {
        const labelName = labelMatch[0].replace(/("|'|label:)/g, '').toLowerCase()
        const label = Object.values(labels).find(l => l.name.toLowerCase() === labelName) || null
        if (label) {
          result.label = label
          result.text = result.text.replace(labelRgx, '')
        }
      }
      const mimeCategoryMatch = input.match(mimeCategoryRgx)
      if (mimeCategoryMatch) {
        const mimeCategory = mimeCategoryMatch[0].replace(/mime:/, '').toLowerCase()
        result.mimeCategory = mimeCategory
        result.text = result.text.replace(mimeCategoryRgx, '')
      }

      result.text = result.text.trim()
    }


    if (slowMode) {
      typingTimer = setTimeout(() => {
        onChange(result)
      }, 500)
    } else {
      onChange(result)
    }
  }

  const val = value
    ? [
      value.label ? 'label:"' + value.label.name + '"' : '',
      value.mimeCategory ? 'mime:' + value.mimeCategory : '',
      value.text,
    ].join(' ').trim()
    : undefined

  return (
    <div className="search-input">
      <Input
        type="text"
        value={val}
        l10nId={placeholder}
        onChange={handleChange}
      />
    </div>
  )
}

export default SearchInput
