import axios from '../config/axios'
import store from '../store'
import { addTask } from '../store/actions/tasks'
import { elevated } from './utils'

/**
 * Schedule creation of a backup
 */
export async function createBackup(): Promise<void> {
  const rsp = await elevated(() => axios.post('admin/create-backup'))

  if ('__task' in rsp.data) {
    store.dispatch(addTask(rsp.data.__task))
  }
}
