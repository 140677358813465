import * as React from 'react'
import { Video } from 'cnx-designer'
import { Editor } from 'slate'
import ToolGroup from '../ToolGroup'
import MediaAttribute from '../MediaAttribute'
import { OnToggle } from '../../index'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'

interface VideoToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const VideoTools = ({ toggleState, editor, onToggle }: VideoToolsProps) => {
  const selection = useCurrentSelection()

  const node = Editor.above(editor, { at: selection, match: Video.isVideo })
  if (!node) return null

  return (
    <ToolGroup
      title="editor-tools-video-title"
      toggleState={toggleState}
      className="video-tools"
      onToggle={() => onToggle('videoTools')}
    >
      <MediaAttribute node={node} attribute="intendedUse" />
    </ToolGroup>
  )
}

export default VideoTools
