import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Team } from '../../../../../api'
import { confirmDialog } from '../../../../../helpers'
import { addAlert } from '../../../../../store/actions/alerts'
import Button from '../../../../../components/ui/Button'

import './index.css'

interface TeamListItemProps {
  team: Team
  isInSuperMode: boolean
  onTeamClick: (t: Team) => void
  afterTeamRemove: (team: Team) => void
}

const TeamListItem = ({
  team,
  isInSuperMode,
  onTeamClick,
  afterTeamRemove,
}: TeamListItemProps) => {
  const dispatch = useDispatch()

  const onClick = () => {
    onTeamClick(team)
  }

  const removeTeam = async (e: React.MouseEvent) => {
    e.stopPropagation()
    const res = await confirmDialog({
      title: 'teams-dialog-remove-title',
      vars: { team: team.name },
      buttons: {
        cancel: 'teams-dialog-buttons-cancel',
        confirm: 'teams-remove',
      },
    })
    if (res === 'confirm') {
      team.archive()
        .then(() => {
          dispatch(addAlert('success', 'teams-remove-success'))
          afterTeamRemove(team)
        })
    }
  }

  return (
    <li
      className="teams__team"
      onClick={onClick}
    >
      {team.name}
      {
        isInSuperMode && <Button l10nId="teams-remove" type="danger" clickHandler={removeTeam}>
          Remove
        </Button>
      }
    </li>
  )
}

export default TeamListItem
