import * as React from 'react'
import Select from 'react-select'
import { withLocalization, WithLocalizationProps } from '@fluent/react'
import { SlotDetails } from '../../../../api/draft'
import { Slot } from '../../../../api/process'
import { UserData } from '../../../../api/user'
import Avatar from '../../../../components/ui/Avatar'
import { useLoadUser } from '../../../../api/hooks'

export type SlotWithUser = SlotDetails & { user: Omit<SlotDetails['user'], 'null'> }

interface SlotInfoProps extends WithLocalizationProps {
  current: SlotWithUser
  team: number
  userId: UserData['id']
  mapping: Map<number, number>
  slots: Slot[]
  onChange: (user: number, slot: number | null) => void
}

function SlotInfo({ current, team, userId, mapping, slots, getString, onChange }: SlotInfoProps) {
  const [user, isLoadingUser] = useLoadUser(userId)

  const defaultOption = React.useMemo(() => ({
    value: null as number | null,
    label: getString('change-process-slots-unassign'),
  }), [getString])

  const role = React.useMemo(
    () => user ? user.teams.find(t => t.id === team)?.role?.id : 0,
    [team, user],
  )

  // Do not show as an option slots which are already a target for different slot
  const filteredSlotsForOptions = React.useMemo(() => {
    const occupiedSlots: Set<number> = new Set()

    for (const [slotId, targetSlotId] of mapping.entries()) {
      if (slotId === current.id) continue
      occupiedSlots.add(targetSlotId)
    }

    return slots.filter(slot => !occupiedSlots.has(slot.id))
  }, [mapping, current, slots])

  const options = React.useMemo(() => {
    const options = [defaultOption]

    for (const s of filteredSlotsForOptions) {
      if (s.roles.length === 0 || (s.roles as (number | undefined)[]).includes(role)) {
        options.push({
          value: s.id,
          label: s.name,
        })
      }
    }

    return options
  }, [filteredSlotsForOptions, role, defaultOption])

  const option = options.find(o => o.value === mapping.get(current.id)) || defaultOption

  const onSelectChange = React.useCallback(option => {
    onChange(current.id, option.value)
  }, [current, onChange])

  if (isLoadingUser || !user) {
    return <div className="map-slots__slot">
      ...
    </div>
  }

  return (
    <div className="map-slots__slot">
      <Avatar
        size="small"
        user={user.id}
        withName={true}
        disableLink={true}
      />
      <span className="map-slots__name">{current.name}</span>
      <span className="map-slots__arrow">→</span>
      <Select
        className="react-select"
        value={option}
        options={options}
        onChange={onSelectChange}
      />
    </div>
  )
}
export default withLocalization(SlotInfo)
