import { ReferenceTarget } from '../store/types'

const sortRefTargets = (a: ReferenceTarget, b: ReferenceTarget) => {
  const ac = a.counters[a.counters.length - 1][1]
  const bc = b.counters[b.counters.length - 1][1]

  if (ac > bc) {
    return 1
  } else if (ac < bc) {
    return -1
  }
  return 0
}

export default sortRefTargets
