import * as React from 'react'
import * as Counters from 'react-counters'
import { Admonition, Title } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { ADMONITION } from '../../counters'
import { LOCALIZATION } from '../../constants'

import './index.css'

interface AdmonitionProps extends RenderElementProps {
  element: Admonition
}

const AdmonitionComp = ({ children, attributes, element }: AdmonitionProps) => {
  const { document } = React.useContext(LOCALIZATION)

  Counters.useCounter(attributes.ref, {
    increment: [ADMONITION],
  })

  const counter = Counters.useCounterValue(attributes.ref, ADMONITION)
  const type = element.kind
  const message = document.getString('admonition-label', { type })
  const hasTitle = Title.isTitle(element.children[0])

  return (
    <div
      className="admonition"
      data-type={type}
      data-label={message}
      id={element.id as string | undefined}
      {...attributes}
    >
      { !hasTitle && <span className="admonition-title" contentEditable={false}>{message} {counter}</span> }
      {children}
    </div>
  )
}

export default AdmonitionComp
