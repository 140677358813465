import * as React from 'react'
import { useSelector } from 'react-redux'
import { Role, Team, TeamMember, User } from '../../../../../../api'
import { TeamPermission } from '../../../../../../api/team'
import { useLoadUser } from '../../../../../../api/hooks'
import store from '../../../../../../store'
import { addAlert } from '../../../../../../store/actions/alerts'
import { confirmDialog } from '../../../../../../helpers'
import { useIsInSuperMode } from '../../../../../../hooks'
import LimitedUI from '../../../../../../components/LimitedUI'
import Avatar from '../../../../../../components/ui/Avatar'
import Button from '../../../../../../components/ui/Button'
import TeamPermissions, { TEAM_PERMISSIONS } from '../../../../../../components/TeamPermissions'
import RoleSelector from '../../../../../../components/RoleSelector'

interface MemberProps {
  member: TeamMember
  team: Team
  afterMemberRemove: (member: TeamMember) => void
  afterRoleChange: (member: TeamMember) => void
  afterMemberPermissionsChange: (member: TeamMember) => void
}

const Member = ({
  member,
  team,
  afterMemberRemove,
  afterRoleChange,
  afterMemberPermissionsChange,
}: MemberProps) => {
  const user = useSelector(state => state.user.user)
  const [showPermissions, setShowPermissions] = React.useState(false)
  const isInSuperMode = useIsInSuperMode(user)

  const togglePermissions = () => {
    setShowPermissions(!showPermissions)
  }

  const removeMember = async () => {
    const user = await User.load(member.user)

    const res = await confirmDialog({
      title: 'teams-member-remove-confirm-dialog',
      vars: {
        user: user.name,
        team: team.name,
      },
      showCloseButton: false,
      buttons: {
        cancel: 'teams-member-cancel',
        confirm: 'teams-member-remove',
      },
    })

    if (res === 'confirm') {
      member.delete()
        .then(() => {
          store.dispatch(addAlert('success', 'teams-member-remove-success'))
          afterMemberRemove(member)
        })
    }
  }

  const handleMemberRoleChange = async (
    { value: role }: { value: Role, label: string },
  ) => {
    await member.update({ role: role.id })
      .then(m => {
        store.dispatch(addAlert('success', 'teams-member-role-change-success'))
        afterRoleChange(m)
      })
  }

  const handleMemberPermissionsChange = async (permissions: TeamPermission[]) => {
    await member.update({ permissions })
      .then(m => {
        store.dispatch(addAlert('success', 'teams-member-permissions-change-success'))
        afterMemberPermissionsChange(m)
      })
  }

  const [memberUser] = useLoadUser(member.user)

  // If other user added member our global state can be out of sync.
  if (!memberUser) return null

  const usrTeam = user?.teams.find(t => t.id === team.id)

  const isUserAbleToChangePermissions = isInSuperMode ||
    (user && user.hasPermissionsInTeam('member:edit-permissions', team))

  // User can give another user only permissions which he has in a team.
  let disabledPermissions: TeamPermission[] = []
  if (usrTeam && showPermissions) {
    if (isInSuperMode) {
      disabledPermissions = []
    } else {
      disabledPermissions = TEAM_PERMISSIONS.filter(p => !usrTeam.allPermissions.has(p))
    }
  }

  return (
    <li className="teams__member">
      <div
        className={`teams__user ${isUserAbleToChangePermissions ? 'clickable' : ''}`}
        onClick={togglePermissions}
      >
        <Avatar user={memberUser} />
        <div className="teams__user-name">{memberUser.name}</div>
      </div>
      <div className="teams__member-controls">
        <LimitedUI team={team} permissions="member:assign-role">
          <RoleSelector
            team={team}
            className="react-select"
            value={member.role
              ? { value: member.role, label: member.role.name }
              : null}
            onChange={handleMemberRoleChange as any}
          />
        </LimitedUI>
        <LimitedUI team={team} permissions="member:remove">
          <Button l10nId="teams-member-remove" clickHandler={removeMember}>
            Remove
          </Button>
        </LimitedUI>
      </div>
      {
        isUserAbleToChangePermissions && showPermissions ?
          <LimitedUI team={team} permissions="member:edit-permissions">
            <div className="teams__member-permissions">
              <TeamPermissions
                selected={member.permissions}
                disabled={disabledPermissions}
                onChange={handleMemberPermissionsChange}
              />
            </div>
          </LimitedUI>
          : null
      }
    </li>
  )
}

export default Member
