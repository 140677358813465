import * as React from 'react'
import * as Sentry from '@sentry/react'
import { Node } from 'slate'
import { PersistingEditor } from 'slate-persistence'
import { useSlate } from 'slate-react'
import { isKeyHotkey } from 'is-hotkey'
import { StorageContext } from '../../../../../api/storage'
import { confirmDialog, saveAsFile } from '../../../../../helpers'
import ErrorDialog from './components/ErrorDialog'
import AfterExportDialog from './components/AfterExportDialog'
import Button from '../../../../../components/ui/Button'
import Icon from '../../../../../components/ui/Icon'
import Spinner from '../../../../../components/Spinner'
import store from '../../../../../store'
import { addAlert } from '../../../../../store/actions/alerts'

import './index.css'

interface SaveButtonProps {
  readOnly: boolean
  document: Node[]
  hasChanges: boolean
  afterSave: () => void
}

// Ctrl / Command (MacOS) + S
const isSaveHotkey = isKeyHotkey('mod+s')

const SaveButton = ({ afterSave, ...props }: SaveButtonProps) => {
  const editor = useSlate()
  const storage = React.useContext(StorageContext)
  const [isSaving, setIsSaving] = React.useState(false)
  const [showErrorDialog, setShowErrorDialog] = React.useState(false)
  const [error, setError] = React.useState('')
  const [showAfterExportDialog, setShowAfterExportDialog] = React.useState(false)

  const onSave = React.useCallback(async () => {
    setIsSaving(true)

    try {
      const res = await storage.write(editor)
        .catch(async e => {
          if (e.response && e.response.status === 412) {
            const res = await confirmDialog({
              title: 'draft-save-incorrect-version-title',
              content: 'draft-save-incorrect-version-content',
              buttons: {
                cancel: 'draft-save-incorrect-version-button-cancel',
                overwrite: 'draft-save-incorrect-version-button-overwrite',
              },
              showCloseButton: false,
            })
            return res
          }
          throw e
        })

      if (res) {
        if (res === 'overwrite') {
          await storage.write(editor, true)
        } else if (res === 'cancel' || res === 'close') {
          setIsSaving(false)
          return
        } else {
          throw new Error(`Undefined response from confirmDialog: ${res}`)
        }
      }

      if (PersistingEditor.isPersistingEditor(editor)) {
        await editor.documentDB.save(editor.children, storage.tag)
      }

      store.dispatch(addAlert('success', 'editor-tools-save-alert-success'))
    } catch (e) {
      Sentry.captureMessage(e, 'error')
      setShowErrorDialog(true)
      setError(e.toString())
    }

    setIsSaving(false)
    afterSave()
  }, [editor, storage, afterSave])

  const exportDocument = () => {
    const filename = `${storage.title}_${storage.id}.cnxml`.replace(/\s+/g, '_')
    const data = storage.serialize(editor)
    saveAsFile(filename, data)
    setShowErrorDialog(false)
    setShowAfterExportDialog(true)
  }

  const handleSaveWithShortcut = React.useCallback((e: KeyboardEvent) => {
    if (isSaveHotkey(e)) {
      e.preventDefault()
      onSave()
    }
  }, [onSave])

  React.useEffect(() => {
    window.addEventListener('keydown', handleSaveWithShortcut)
    return () => window.removeEventListener('keydown', handleSaveWithShortcut)
  }, [handleSaveWithShortcut])

  if (props.readOnly) return null

  return (
    <>
      <Button
        id="editor-tools-save"
        className="save-button"
        clickHandler={onSave}
        isDisabled={isSaving || !props.hasChanges}
        withBorder={true}
      >
        <Icon size="small" name="save" />
        Save
        {isSaving ? <Spinner /> : null}
      </Button>
      <ErrorDialog
        show={showErrorDialog}
        error={error}
        onClose={() => setShowErrorDialog(false)}
        onExport={exportDocument}
      />
      <AfterExportDialog
        show={showAfterExportDialog}
        onClose={() => setShowAfterExportDialog(false)}
      />
    </>
  )
}

export default SaveButton
