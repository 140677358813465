const scrollToTarget = (cssSelector: string, topOffset?: number) => {
  const target = document.querySelector(cssSelector) as HTMLElement | undefined
  if (!target) return

  // This is in setTimeout because without this it wasn't working when targeting element
  // in Slate editor. Probably because there are some side effects when clicking on an item.
  setTimeout(() => {
    const currentPosition = target.style.position
    const currentTop = target.style.top

    if (topOffset) {
      target.style.position = 'relative'
      target.style.top = topOffset + 'px'
    }

    target.scrollIntoView({ behavior: 'smooth' })

    if (topOffset) {
      target.style.position = currentPosition
      target.style.top = currentTop
    }
  }, 0)
}

export default scrollToTarget
