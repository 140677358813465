import { Localized } from '@fluent/react'
import { INVALID_REFERENCE_CLASS } from '../../../../constants'

const ExampleInvalidReference = () => <div>
  <p>
    <Localized id="step-changer-invalid-references-dialog-example">
      Invalid references looks like this:
    </Localized>
  </p>
  <div className="editor">
    <span className={INVALID_REFERENCE_CLASS}>
      <Localized id="step-changer-invalid-references-dialog-example-reference">
        Example text of invalid reference
      </Localized>
    </span>
  </div>
</div>

export default ExampleInvalidReference
