import * as React from 'react'
import { Image } from 'cnx-designer'
import { RenderElementProps } from 'slate-react'
import { Localized } from '@fluent/react'

interface ImageProps extends RenderElementProps {
  element: Image & { replacementSrc?: string },
  mediaUrl: (src: string) => string
}

const ImageComp = (props: ImageProps) => {
  const { element, mediaUrl } = props

  return (
    <div {...props.attributes} className={element.replacementSrc && 'missing-image'}>
      <div contentEditable={false}>
        {element.replacementSrc ?
          <>
            <Localized id="figure-missing-image-top" vars={{ src: element.src }} elems={{ br: <br /> }}>
              <span className="missing-image__message" />
            </Localized>
            <img
              src={element.replacementSrc}
              alt=""
              id={element.id as string | undefined}
            />
            <span className="missing-image__message">
              <Localized id="figure-missing-image-bottom" vars={{ src: element.src }}>
                Don&quot;t worry, we will fix it!
              </Localized>
            </span>
          </>
          :
          <img
            src={mediaUrl(element.src)}
            alt=""
            id={element.id as string | undefined}
          />}
      </div>
      {props.children}
    </div>
  )
}

export default ImageComp
