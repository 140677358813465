import * as React from 'react'
import * as Sentry from '@sentry/react'
import { Localized } from '@fluent/react'
import { RouteComponentProps, withRouter } from 'react-router'
import Button from '../../components/ui/Button'

import './index.css'

interface ErrorBoundaryProps {
  children: React.ReactNode
  extraMessage?: string
  buttons?: {
    continueWorking?: boolean
    goToDashboard?: boolean
    reloadPage?: boolean
  }
}

interface ErrorBoundaryState {
  error: null
  eventId?: string
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps & RouteComponentProps> {
  state: ErrorBoundaryState = {
    error: null,
    eventId: undefined,
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error })
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  private continueWorking = () => {
    this.setState({ error: null, eventId: undefined })
  }

  private moveToDashboard = () => {
    this.props.history.push("/")
    this.setState({ error: null, eventId: undefined })
  }

  private showReportDialog = () => {
    Sentry.showReportDialog({ eventId: this.state.eventId })
  }

  render() {
    const hasReport = this.state.eventId ? 'true' : 'false'
    const {
      extraMessage = 'error-boundary-info',
      buttons: {
        continueWorking = true,
        goToDashboard = false,
        reloadPage = true,
      } = {},
    } = this.props

    if (this.state.error) {
      return (
        <div className="error-message">
          <h1>
            <Localized id="error-boundary-title">
              Something went wrong
            </Localized>
          </h1>
          <Localized id={extraMessage} elems={{ p: <p/> }} vars={{ hasReport }}>
            <div />
          </Localized>
          <div className="buttons">
            {
              continueWorking
                && <Button l10nId="error-boundary-button-continue-working" clickHandler={this.continueWorking}>
                  Continue working
                </Button>
            }
            {
              goToDashboard
                && <Button l10nId="error-boundary-button-go-to-dashboard" clickHandler={this.moveToDashboard}>
                  Go to dashboard
                </Button>
            }
            {
              reloadPage && <Button l10nId="error-boundary-button-reload" clickHandler={window.location.reload}>
                Reload page
              </Button>
            }
            {
              hasReport ?
                <Button l10nId="error-boundary-button-fill-report" clickHandler={this.showReportDialog} >
                  Fill out a report
                </Button>
                : null
            }
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
