import * as React from 'react'
import { Localized } from '@fluent/react'
import { Ticket } from '../../../../api'
import TicketComp from '../Ticket'

import './index.css'

interface TicketsListProps {
  tickets: Ticket[]
  onTicketClick?: (ticket: Ticket) => void
}

const TicketsList = ({ tickets, onTicketClick }: TicketsListProps) => <ul className="tickets__list">
  {
    tickets.length
      ? tickets.map(ticket => !ticket.closed && (
        <li key={ticket.id} className="tickets__list-item">
          <TicketComp
            ticket={ticket}
            onTicketClick={onTicketClick}
          />
        </li>
      ))
      : <li className="tickets__list-item tickets__list-item--no-tickets">
        <Localized id="tickets-no-tickets">
          No tickets were found
        </Localized>
      </li>
  }
</ul>

export default TicketsList
