const updateImgSrcs = (cnxml: string, id: string) => {
  const p = new DOMParser()
  const xmlDoc = p.parseFromString(cnxml, 'application/xml')
  xmlDoc.querySelectorAll('image').forEach(img => {
    img.setAttribute('src', `/api/v1/modules/${id}/files/${img.getAttribute('src')}`)
  })
  // Add a space to each element to prevent the browser from collapsing them
  xmlDoc.querySelectorAll('*').forEach(elem => {
    const node = document.createTextNode(" ")
    elem.appendChild(node)
  })
  return new XMLSerializer().serializeToString(xmlDoc)
}

export default updateImgSrcs
